import styled from "styled-components";
import useDeviceSize from "../../../hooks/useDeviceSize";
import InformationBox from "./InformationBox";
import { roomDetailType } from "../Detail";

import { useEffect, useState } from "react";
import Navigation, { getMaxPage } from "../../../components/Navigation";

function DetailTop({ roomDetailState }: { roomDetailState?: roomDetailType }) {
  const { isMobile, isLaptop, isPC } = useDeviceSize();

  const [detailImageState, setDetailImageState] = useState<string[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isSmaller, setIsSmaller] = useState(false);
  const itemsPerPage = isMobile ? 4 : 5;
  const totalImages = detailImageState.length;
  const isFirstPage = activeIndex === 0;
  const isLastPage = activeIndex === totalImages - 1;

  const currentPageImages =
    detailImageState?.slice(
      Math.floor(activeIndex / itemsPerPage) * itemsPerPage,
      Math.floor(activeIndex / itemsPerPage) * itemsPerPage + itemsPerPage
    ) || [];

  const convertedCategoryId = (categoryid?: number) => {
    switch (categoryid) {
      case 1:
        return "임대 - 맨션";
      case 2:
        return "임대 - 주택";
      case 3:
        return "임대 - 단층맨션";
      case 4:
        return "임대 - 기타";
      case 5:
        return "매매 - 토지";
      case 6:
        return "매매 - 맨션";
      case 7:
        return "매매 - 점포";
      case 8:
        return "매매 - 주택";
      case 9:
        return "매매 - 기타";
      case 10:
        return "점포 - 사무실";
      case 11:
        return "점포 - 창고";
      case 12:
        return "점포 - 점포";
      case 13:
        return "점포 - 기타";
      case 14:
        return "하우버맨션";
    }
  };

  const handleNextPage = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % totalImages);
  };

  const handlePrevPage = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? totalImages - 1 : prevIndex - 1
    );
  };

  const handleThumbnailClick = (index: number) => {
    setActiveIndex(
      index + Math.floor(activeIndex / itemsPerPage) * itemsPerPage
    );
  };

  useEffect(() => {
    const img = new Image();
    img.src = detailImageState ? detailImageState[activeIndex] : "";
    img.onload = () => {
      const containerWidth = isPC ? 700.5 : isLaptop ? 655 : 385;
      const containerHeight = isPC ? 700.5 : isLaptop ? 655 : 385;

      if (
        img.naturalWidth < containerWidth &&
        img.naturalHeight < containerHeight
      ) {
        setIsSmaller(true);
      } else {
        setIsSmaller(false);
      }
    };
  }, [detailImageState, activeIndex, isLaptop, isPC]);

  useEffect(() => {
    if (roomDetailState) {
      setDetailImageState([
        roomDetailState.thumbnailUrl,
        roomDetailState.floorPlanUrl,
        ...roomDetailState.roomImgUrlList,
      ]);
      setActiveIndex(0);
    }
  }, [roomDetailState]);

  return (
    <DetailTopWrapper>
      <InformationBox
        decoration={true}
        width={{ pc: "100%", laptop: "100%", mobile: "100%" }}
        height={{ pc: "119px", laptop: "112px", mobile: "65px" }}
      >
        <TitleInner>
          {convertedCategoryId(roomDetailState?.detailCategory.id)}
          <TitleName>{roomDetailState?.name}</TitleName>
        </TitleInner>
      </InformationBox>
      <DetailImageWrapper>
        <DetailImage
          src={detailImageState[activeIndex]}
          isSmaller={isSmaller}
        />
      </DetailImageWrapper>
      <SmallImageWrapper>
        {isMobile && (
          <>
            <Navigation
              onClickNext={() => handleNextPage()}
              onClickPrev={() => handlePrevPage()}
              page={activeIndex}
              translateY={30}
              maxPage={getMaxPage(1, detailImageState?.length)}
              mobile
            />

            {currentPageImages.map((item, index) => (
              <DetailImageSmall
                key={index}
                src={item}
                onClick={() => handleThumbnailClick(index)}
              ></DetailImageSmall>
            ))}
          </>
        )}
        {!isMobile && (
          <>
            <Navigation
              onClickNext={() => handleNextPage()}
              onClickPrev={() => handlePrevPage()}
              page={activeIndex}
              translateY={40}
              maxPage={getMaxPage(1, detailImageState?.length)}
            />
            {currentPageImages.map((item, index) => (
              <DetailImageSmall
                key={index}
                src={item}
                onClick={() => handleThumbnailClick(index)}
              ></DetailImageSmall>
            ))}
          </>
        )}
      </SmallImageWrapper>
    </DetailTopWrapper>
  );
}

export default DetailTop;

const DetailTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  @media screen and (min-width: 1280px) {
    margin-top: 89px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 87px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 19px;
  }
`;

const TitleInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  @media screen and (min-width: 1280px) {
    gap: 10px;
    font-size: 19px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 10px;
    font-size: 19px;
  }
  @media screen and (max-width: 1023px) {
    gap: 5px;
    font-size: 10px;
  }
`;

const TitleName = styled.div`
  font-weight: bold;
  width: 100%;
  text-align: center;
  @media screen and (min-width: 1280px) {
    font-size: 38px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 34px;
    font-weight: 600;
  }
  @media screen and (max-width: 1023px) {
    font-size: 20px;
  }
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const DetailImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media screen and (min-width: 1280px) {
    height: 700.5px;
    margin-top: 8px;
    border-radius: 10px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    height: 655px;
    margin-top: 6.5px;
    border-radius: 10px;
  }
  @media screen and (max-width: 1023px) {
    height: 385px;
    border-radius: 5px;
  }
  overflow: hidden;
`;

const DetailImage = styled.img<{ isSmaller: boolean }>`
  object-fit: contain;
  ${(props) =>
    props.isSmaller
      ? `
    width: auto;
    height: 100%;
  `
      : `
    max-width: 100%;
    max-height: 100%;
  `}

  @media screen and (min-width: 1280px) {
    border-radius: 10px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    border-radius: 10px;
  }
  @media screen and (max-width: 1023px) {
    border-radius: 5px;
  }
`;

const SmallImageWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  @media screen and (min-width: 1280px) {
    gap: 12.36px;
    margin-top: 13px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 11.56px;
    margin-top: 12.5px;
  }
  @media screen and (max-width: 1023px) {
    gap: 5.42px;
    margin-top: 8px;
  }
`;

const DetailImageSmall = styled.img`
  background-color: #eaebef;
  cursor: pointer;
  @media screen and (min-width: 1280px) {
    width: 130px;
    height: 130px;
    border-radius: 10px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 121px;
    height: 121px;
    border-radius: 10px;
  }
  @media screen and (max-width: 1023px) {
    width: 92px;
    height: 92px;
    border-radius: 5px;
  }
`;
