import { atom } from "recoil";
import { mansionPropertyType } from "../../pages/mansionPage/Mansion";

export const mansionPropertyDefaultValue = {
  page: 1,
  size: 10,
  sortOption: "WideOrder",
  detailCategoryId: 1,
  subwayIdList: [],
  districtIdList: [],
  minPrice: 0,
  maxPrice: 1000000,
  includeManageFee: false,
  nonDeposit: false,
  nonGratuity: false,
  roomStructureList: [],
  higherThen2F: false,
  roomTypeList: [],
  minArea: 0,
  maxArea: 100,
  withInYear: null,
  subwayTimeConsumed: null,
  propertyOptionRequest: {
    freeInternet: false,
    petsAllowed: false,
    morePeople: false,
    designerMention: false,
    duplexMention: false,
    closeToStation: false,
    induction: false,
    gasRange: false,
    allElectricMansion: false,
    cityGas: false,
    indoorWashingBoard: false,
    outdoorWashingBoard: false,
    bidet: false,
    standAloneToilet: false,
    unitBath: false,
    bathroomDryer: false,
    airConditioning: false,
    storageSpace: false,
    trashDisposal24H: false,
    garbageStorage: false,
    intercom: false,
    deliveryBox: false,
    carParking: false,
    elevator: false,
    bicycleParking: false,
    bikeParking: false,
    womenOnly: false,
    mensOnly: false,
    autoLock: false,
    cctv: false,
    foreignerAllowed: false,
    overseasScreeningAvailable: false,
    furnitureOptions: false,
  },
};

export const mansionPropertyDataStateAtom = atom<mansionPropertyType>({
  key: "mansionPropertyDataState",
  default: mansionPropertyDefaultValue,
});
