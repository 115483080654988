import React from 'react';
import { styled } from 'styled-components';
import { RegionInnerType } from '..';
import CheckBoxSet from './CheckBoxSet';
import useDetailChoiceOpenModalState from '../../../store/modal/detailChoice';
import DetailChoiceModal from '../DetailChoiceModal';

const CheckBoxList = ({ regionData }: RegionInnerType) => {
  const { detailChoiceOpen, detailData } = useDetailChoiceOpenModalState();

  return (
    <Container>
      <BoxList>
        {regionData.map((item) => (
          <CheckBoxSet {...item} key={item.id} />
        ))}
      </BoxList>
      {detailChoiceOpen && detailData && (
        <DetailChoiceModal data={detailData} />
      )}
    </Container>
  );
};

export default CheckBoxList;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  position: relative;
`;
const BoxList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
