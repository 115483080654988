import styled from "styled-components";
import { sidebarStateType } from "./MainDesignSidebar";
import SetMainBanner from "./SetMainBanner";
import SetIconCategory from "./SetIconCategory";
import SetSubBanner from "./SetSubBanner";
import SetLogo from "./SetLogo";

function SidebarContents({ sidebarState }: { sidebarState: sidebarStateType }) {
  return sidebarState === "logo" ? (
    <SidebarContnetsWrapper>
      <SetLogo />
    </SidebarContnetsWrapper>
  ) : (
    <SidebarContnetsWrapper>
      <SetMainBanner />
      <SetIconCategory />
      <SetSubBanner />
    </SidebarContnetsWrapper>
  );
}

export default SidebarContents;

const SidebarContnetsWrapper = styled.div`
  flex: 1;
  padding: 47px 45px 0 41px;
`;
