import React, { useState, useEffect, createContext, useContext } from "react";
import styled from "styled-components";
import { Outlet, useNavigate } from "react-router-dom";
import ResponsiveWrapper from "../../components/ResponsiveWrapper";
import axiosInstance from "../../services/axiosConfig";
import searchIcon from "../../../src/assets/images/mainPage/search-gray.png";

export const SearchContext = createContext<
  | {
      searchTerm: string;
      setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
    }
  | undefined
>(undefined);
const Title = styled.div`
  font-size: 38px;
  font-weight: 600;
  color: black;
  margin-top: 83px;
  cursor: pointer;

  @media screen and (min-width: 1280px) {
    font-size: 38px;
    margin-top: 83px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 34px;
    margin-top: 70px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 30px;
    margin-top: 60px;
  }
`;

const Subtitle = styled.div`
  font-size: 26px;
  margin-top: 25px;
  font-weight: 400;

  @media screen and (min-width: 1280px) {
    font-size: 26px;
    margin-top: 18px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 24px;
    margin-top: 16px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 22px;
    margin-top: 14px;
  font-weight: 400;

  }
`;

const SearchSection = styled.div`
  background-color: #f7f9fa;
  padding: 80px 168px;
  border-radius: 10px;
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: 1023px) {
    padding: 0px;
    margin-top: 0px;
    min-height: 300px;
  }
`;

const SearchTitle = styled.div`
  font-size: 26px;
  font-weight: bold;
  color: #333333;
  margin-top: 6px;
  font-weight: 600;

  @media screen and (min-width: 1280px) {
    font-size: 26px;
    margin-top: 6px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 24px;
    margin-top: 5px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 26px;
    margin-top: 43px;
  }
`;

const SearchSubtitle = styled.div`
  font-size: 19px;
  color: grey;
  margin-top: 20px;

  @media screen and (min-width: 1280px) {
    font-size: 19px;
    margin-top: 20px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 18px;
    margin-top: 18px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 15px;
    margin-top: 16px;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 10px 49px;
  max-width: 800px;
  width: 100%;
  margin-top: 46px;
  box-sizing: border-box;
  border: solid 1px #cccccc;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.04);
  background-color: #ffffff;

  @media screen and (min-width: 1280px) {
    padding: 10px 49px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 10px 40px;
  }

  @media screen and (max-width: 1023px) {
    padding: 7px 20px;
    box-shadow: 0px -1px 5px 2px rgba(0, 0, 0, 0.1);
  }
`;

const SearchIcon = styled.img`
  margin-right: 8px;
  color: grey;
  width: 25px;

  @media screen and (min-width: 1280px) {
    margin-right: 8px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-right: 6px;
  }

  @media screen and (max-width: 1023px) {
    width: 20px;
    margin-right: 4px;
  }
`;

const Box = styled.div`

`

const Divider = styled.div`
  height: 24px;
  width: 1px;
  background-color: lightgrey;
  margin: 0 8px;

  @media screen and (min-width: 1280px) {
    margin: 0 8px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin: 0 8px;
  }

  @media screen and (max-width: 1023px) {
    margin: 0 8px;
  }
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  flex-grow: 1;
  font-size: 19px;

  @media screen and (min-width: 1280px) {
    font-size: 19px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 18px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 15px;
  }
`;
const MobileTagBox = styled.div`
display:flex;
overflow:hidden;
width:100%;
justify-content: center;
`;

const TagsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-weight: 500;

  margin-top: 13px;
  align-items: center;
  color: #333333;



  @media screen and (min-width: 1280px) {
    margin-top: 12px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 12px;
  }

  @media screen and (max-width: 1023px) {
    margin-top: 18px;
    flex-wrap: nowrap;

    justify-content: center;
    justify-content: flex-start;

    overflow-x: auto;

  scrollbar-width: none; 

  &::-webkit-scrollbar {
   display:none;
  }
  }

  @media screen and (max-width: 1023px) {
    & > button:first-child {
      margin-left: 20px; 
    }

    & > button:last-child {
      margin-right: 20px; 
    }
  }
`;

const Tag = styled.div`
display: flex;
align-items: center;
  background-color: #ffffff;
  color: #555555;
  border: solid 1.5px #cccccc;
  border-radius: 17px;
  padding: 4px 16px;
  margin: 10px 12px;
  cursor: pointer;
  font-size: 19px;
  height: 34px;
  white-space: nowrap;
  font-weight: 400;
  


  @media screen and (min-width: 1280px) {
    padding: 4px 16px;
    margin: 10px 9px;
    font-size: 19px;
    line-height: 18px;
    /* height: 34px; */
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 4px 14px;
   
    font-size: 18px;
    line-height: 17px;
    /* height: 32px; */
  }

  @media screen and (max-width: 1023px) {
    padding: 4px 12px;
    margin: 0 5px 5px 5px;
    font-size: 17px;
    line-height: 16px;
    /* height: 30px; */
  }
`;

const HashSpan = styled.span`
  font-size: 16px;
  margin-right: 1px;
  color: #999999;

  @media screen and (min-width: 1280px) {
    font-size: 16px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 15px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 14px;
  }
`;

const QnA: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [popularTags, setPopularTags] = useState<
    { id: number; text: string }[]
  >([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPopularTags = async () => {
      try {
        const response = await axiosInstance.get("/qna/keyword");
        // console.log("키워드:", response);
        setPopularTags(response.data);
      } catch (error) {
        // console.error("Error fetching popular tags:", error);
      }
    };

    fetchPopularTags();
  }, []);

  const handleTagClick = (tag: string) => {
    setSearchTerm(tag);
    navigate("/qna/home");
  };
  
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    if (e.target.value) {
      navigate("/qna/home");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <SearchContext.Provider value={{ searchTerm, setSearchTerm }}>
      <>
        {isMobile ? (
          <SearchSection>
            <ResponsiveWrapper>
              <SearchTitle onClick={() => navigate("/qna/home")}>Q&A</SearchTitle>
              <SearchSubtitle>
                일본 현지 부동산 직원들이 직접 답변해드립니다.
              </SearchSubtitle>
              <SearchContainer>
            
                <SearchIcon src={searchIcon} 
                />
      
                <Divider />
                <SearchInput
                  type="text"
                  placeholder="검색어를 입력해주세요"
                  value={searchTerm}
                  onChange={handleInputChange}

                />
              </SearchContainer>
            </ResponsiveWrapper>

<MobileTagBox>
              <TagsContainer>
                {popularTags.slice(0, 4).map((tag) => (
                  <Tag key={tag.id} onClick={() => handleTagClick(tag.text)}>
                    <HashSpan>#</HashSpan>
                    {tag.text}
                  </Tag>
                ))}
              </TagsContainer>
              </MobileTagBox>
           

          </SearchSection>
        ) : (
          <>
            <ResponsiveWrapper>
            <Title onClick={() => navigate("/qna/home")}>Q&A</Title>
              <Subtitle>여러분의 즐거운 일본 생활을 위한 Q&A입니다</Subtitle>
            </ResponsiveWrapper>

            <SearchSection>
              <SearchTitle>질문과 답변</SearchTitle>
              <SearchSubtitle>
                일본 현지 부동산 직원들이 직접 답변해드립니다
              </SearchSubtitle>

              <SearchContainer>
                <SearchIcon src={searchIcon} />
                {/* <Divider /> */}
                <SearchInput
                  type="text"
                  placeholder="검색어를 입력해주세요"
                  value={searchTerm}
                  onChange={handleInputChange}
                />
              </SearchContainer>
              <TagsContainer>
                인기
                {popularTags.map((tag) => (
                  <Tag key={tag.id} onClick={() => handleTagClick(tag.text)}>
                    <HashSpan>#</HashSpan>
                    {tag.text}
                  </Tag>
                ))}
              </TagsContainer>
            </SearchSection>
          </>
        )}
      </>
      <ResponsiveWrapper>
        <Outlet />
      </ResponsiveWrapper>
    </SearchContext.Provider>
  );
};

export default QnA;
