import { atom, useRecoilState } from "recoil";

export const sidebarStateAtom = atom<boolean>({
  key: "sidebarState",
  default: false,
});

export const useSidebarState = () => {
  const [sidebarState, setSidebarState] = useRecoilState(sidebarStateAtom);
  const modifySidebarState = () => setSidebarState(!sidebarState);
  return { sidebarState, modifySidebarState, setSidebarState };
};
