// src/context/ScrapCountContext.tsx
import React, { createContext, useContext, useState } from "react";

interface ScrapCount {
  all: number;
  review: number;
  room: number;
}

interface ScrapCountContextProps {
  counts: ScrapCount;
  setCounts: React.Dispatch<React.SetStateAction<ScrapCount>>;
}

const ScrapCountContext = createContext<ScrapCountContextProps | undefined>(undefined);

export const useScrapCount = () => {
  const context = useContext(ScrapCountContext);
  if (!context) {
    throw new Error("useScrapCount must be used within a ScrapCountProvider");
  }
  return context;
};

export const ScrapCountProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [counts, setCounts] = useState<ScrapCount>({ all: 0, review: 0, room: 0 });

  return (
    <ScrapCountContext.Provider value={{ counts, setCounts }}>
      {children}
    </ScrapCountContext.Provider>
  );
};
