import styled from "styled-components";
import searchblack from "../../../../assets/images/header/search-black.png";
import scrapgray from "../../../../assets/images/header/scrap-gray.png";
import userIcon from "../../../../assets/images/header/usericon.png";
import { useRecoilValue } from "recoil";
import { logoStateAtom } from "../../../../recoil/adminMainDesign/logoStateAtom";

function CloneHeaderContents() {
  const logoState = useRecoilValue(logoStateAtom);

  const SRCANDPATH = [
    { src: scrapgray, path: "/" },
    { src: userIcon, path: "/" },
  ];

  return (
    <HeaderContentsWrapper>
      <HeaderContentsInner>
        <ContentsLeft>
          <HamburgerButton></HamburgerButton>
          {logoState.fileUrl ? (
            <Logo>
              <StyledImage src={logoState.fileUrl}></StyledImage>
            </Logo>
          ) : (
            <Logo>LOGO</Logo>
          )}
        </ContentsLeft>
        <ContentsRight>
          <SearchDiv>
            <SearchIcon src={searchblack} />
            <Horizon />
            <SearchInput placeholder="통합검색"></SearchInput>
          </SearchDiv>
          <ButtonDiv>
            {SRCANDPATH.map((item, index) => (
              <IconButton key={index} src={item.src}></IconButton>
            ))}
          </ButtonDiv>
        </ContentsRight>
      </HeaderContentsInner>
    </HeaderContentsWrapper>
  );
}

export default CloneHeaderContents;

const HeaderContentsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (min-width: 1280px) {
    height: 133px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    height: 111px;
  }
  @media screen and (max-width: 1023px) {
    height: 46px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  }
`;

const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  @media screen and (min-width: 1280px) {
    width: 1280px;
    padding: 0 73px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 1024px;
    padding: 0 45px;
  }
  @media screen and (max-width: 1023px) {
    width: 425px;
    padding: 0 20px;
  }
`;

const HeaderContentsInner = styled(HeaderBox)`
  justify-content: space-between;
  @media screen and (max-width: 1023px) {
    /* padding: 0 20px; */
  }
`;

const ContentsLeft = styled.div`
  @media screen and (max-width: 1023px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90px;
  }
`;

const HamburgerButton = styled.div`
  display: none;
  background-color: #e6e6e6;
  width: 25px;
  height: 17px;
  @media screen and (max-width: 1023px) {
    display: flex;
  }
`;

const Logo = styled.div`
  text-decoration: none;
  max-width: 200px;
  max-height: 80px;
  @media screen and (min-width: 1280px) {
    font-size: 40px;
  }
`;

const ContentsRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  @media screen and (min-width: 1280px) {
    width: 875px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 731px;
  }
  @media screen and (max-width: 1023px) {
    width: 58px;
  }
`;

const SearchDiv = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid #eeeeee;
  border-radius: 50px;
  @media screen and (min-width: 1280px) {
    width: 542px;
    height: 53px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 452px;
    height: 44px;
  }
  @media screen and (max-width: 1023px) {
    /* display: none; */
  }
`;

const SearchIcon = styled.img`
  display: block;
  @media screen and (min-width: 1280px) {
    width: 25px;
    margin: 0 8px 0 14.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 20px;
    margin: 0 6.5px 0 11.5px;
  }
  @media screen and (max-width: 1023px) {
    width: 20px;
  }
`;

const Horizon = styled.div`
  border-right: 0.55px solid #828c94;

  @media screen and (min-width: 1280px) {
    width: 1px;
    height: 24px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 1px;
    height: 20px;
  }
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  border: none;
  font-family: "Noto Sans KR";
  @media screen and (min-width: 1280px) {
    margin: 0 18px;
    height: 28px;
    font-size: 23px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin: 0 15px;
    height: 28px;
    font-size: 19px;
  }
  @media screen and (max-width: 1023px) {
    display: none;
  }
  ::placeholder {
    color: #bbbbbb;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  @media screen and (min-width: 1280px) {
    gap: 30px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 25px;
  }
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

const IconButton = styled.img`
  cursor: pointer;
  @media screen and (min-width: 1280px) {
    width: 35px;
    height: 35px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 30px;
    height: 30px;
  }
  @media screen and (max-width: 1023px) {
    width: 23px;
    height: 23px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
`;
