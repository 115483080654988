import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import styled from "styled-components";
import { Navigation, Pagination, Thumbs } from "swiper/modules";
import SwiperClass from "swiper/types/swiper-class";
import SlideLeft from "../../../../assets/images/ResidentReview/SlideLeft.png";
import SlideRight from "../../../../assets/images/ResidentReview/SlideRight.png";

type CombinedImageType =
  | { type: "url"; data: ImageType }
  | { type: "file"; data: File };

interface ImageType {
  id: number;
  url: string;
}
interface SwiperComponentProps {
  images: CombinedImageType[];
  onDeleteImage: (index: number, id?: number) => void;
  imgCount: number;
}

const AdminSwiperComponent: React.FC<SwiperComponentProps> = ({
  images,
  onDeleteImage,
  imgCount,
}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    return () => {
      images.forEach((image) => {
        if (image instanceof File) {
          URL.revokeObjectURL(URL.createObjectURL(image));
        }
      });
    };
  }, [images]);

  const handleSlideChange = (swiper: SwiperClass) => {
    setActiveIndex(swiper.activeIndex);
  };

  return (
    <Container>
      <MainSwiper
        spaceBetween={10}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[Navigation, Pagination, Thumbs]}
        onSlideChange={handleSlideChange}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img
              style={{ width: "100%", height: "100%" }}
              src={
                image.type === "file"
                  ? URL.createObjectURL(image.data)
                  : image.data.url
              }
              alt={`Slide ${index + 1}`}
            />
            <DeleteButton
              onClick={() =>
                onDeleteImage(
                  index,
                  image.type === "url" ? image.data.id : undefined
                )
              }
            >
              X
            </DeleteButton>
          </SwiperSlide>
        ))}
      </MainSwiper>

      <ThumbsContainer>
        <NavigationButton className="swiper-button-prev">
          <img src={SlideLeft} alt="Previous Slide" />
        </NavigationButton>
        <ThumbsSwiper
          onSwiper={setThumbsSwiper}
          slidesPerView={7}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[Thumbs]}
          initialSlide={activeIndex}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <img
                style={{ border: "1px solid #EAEBEF" }}
                src={
                  image.type === "file"
                    ? URL.createObjectURL(image.data)
                    : image.data.url
                }
              />
            </SwiperSlide>
          ))}
        </ThumbsSwiper>
        <NavigationButton className="swiper-button-next">
          <img src={SlideRight} alt="Next Slide" />
        </NavigationButton>
      </ThumbsContainer>
    </Container>
  );
};

export default AdminSwiperComponent;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 50px;
  margin-bottom: 105px;
`;

const MainSwiper = styled(Swiper)`
  width: 780px;
  height: 500px;
  margin-bottom: 10px;
  border-radius: 13px;
`;

const ThumbsSwiper = styled(Swiper)`
  width: 780px;
  pointer-events: none;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  .swiper-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  img {
    width: 106px;
    height: 95px;
    object-fit: cover;
    border-radius: 8px;
  }
`;

const NavigationButton = styled.div<{ left?: boolean }>`
  ${(props) => (props.left ? "left: 10px;" : "right: -10px;")}
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  visibility: hidden;
  img {
    visibility: visible;
    width: 55px;
    height: 55px;
  }
`;

const DeleteButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #919191;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
`;

const ThumbsContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 780px;
  margin-top: 10px;
  justify-content: center;
`;
