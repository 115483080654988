

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import MarlerX from "@google/markerclustererplus";
import { MapContentsWrap, MapWrap } from './Map.style';
import type { searchedMapDataI, MarkerProps, locationI } from '../Map';
import pin from '../../../assets/images/mapPage/pin.png';
import selectedPin from '../../../assets/images/mapPage/selectedPin.png';
import cluster from '../../../assets/images/mapPage/cluster.png';
import cluster1 from '../../../assets/images/mapPage/cluster1.png';
import cluster2 from '../../../assets/images/mapPage/cluster2.png';
import myLocationPin from '../../../assets/images/mapPage/myLocation.png';
import { getZoomLevel } from '../../../utils/googleMap';
import MarkerClusterer from '@google/markerclustererplus';

interface Props {
  mapRef: React.RefObject<HTMLDivElement>;
  searchedMapData: searchedMapDataI;
  setPinDetail: React.Dispatch<React.SetStateAction<number>>
  setShowFilterList: React.Dispatch<React.SetStateAction<boolean>>
  fetchMansionRoomList: (neLat: string, neLng: string, swLat: string, swLng: string) => Promise<void>
  location: locationI
  pinDetail: number
}


export default function MapContents({ mapRef, searchedMapData, setPinDetail, setShowFilterList, fetchMansionRoomList, location, pinDetail }: Props) {
  const selectedPinIdx = useRef<number>(-1);
  const markersRef = useRef<any[]>([]); // 마커 객체를 저장할 레퍼런스
  const clustererRef = useRef<any>()
  const myLocationRef = useRef<any>()

  const styles = useMemo<any[]>( ()=> [
    {
      url: cluster2,
      height: 40,
      width: 40,
      anchor: [0, 0],
      textColor: 'white',
      textSize: 16,
      anchorText: [12,1],
    },{
      url: cluster1, 
      height: 48,
      width: 48,
      anchor: [0, 0],
      textColor: 'white',
      textSize: 16,
      anchorText: [16,1],
    },{
      url: cluster, 
      height: 60,
      width: 60,
      anchor: [0, 0],
      textColor: 'white',
      textSize: 20,
      anchorText: [16,1],
    },
  ], []);
  
  const clusterDraw = useCallback((map: any)=>{
    const shouldCluster = map.getZoom() <= 14;
    const markers = markersRef.current

    if (clustererRef.current) {
      clustererRef.current.clearMarkers();
    }
    if (shouldCluster && searchedMapData.simpleResponseList.length !== 0) {
      
      const markerClusterer = new MarkerClusterer(map, markers, {
        styles: styles,
        calculator: (markers, numStyles) => {
          const count = markers.length;
          const index = Math.min(numStyles - 1, Math.floor(count / 5));
          const style = styles[index];

          // title 속성을 추가하여 반환 객체를 수정
          return {
            text: count.toString(),
            index: index,
            style: style,
            title: ""
          };
        },
        maxZoom: 14,
        minimumClusterSize: 1
      });
      clustererRef.current = markerClusterer;

  }
  }, [searchedMapData.simpleResponseList.length])

  const markerDraw = useCallback((data: MarkerProps[], map: any) => {
    markersRef.current.forEach(marker => marker.setMap(null));
    data.forEach(({ lat, lng, id }: MarkerProps, idx) => {
      const markerPin = new (window as any).google.maps.Marker({
        key: id,
        position: { lat: lat, lng: lng },
        map: map,
        icon: {
          url: selectedPinIdx.current === idx ? selectedPin : pin, // 선택 여부에 따른 마커 이미지 설정
          scaledSize: new (window as any).google.maps.Size(30, 40),
        },
        title: null,
      });

      // 마커 객체를 레퍼런스에 저장
      markersRef.current[idx] = markerPin;

      markerPin.addListener('click', () => {
        // 새로운 선택된 마커의 아이콘을 업데이트
        markerPin.setIcon({
          url: selectedPin,
          scaledSize: new (window as any).google.maps.Size(30, 40),
        });

        setPinDetail(idx)

        // 이전에 마커를 클릭한 기록이 있고, 마커리스트 순서에 존재하면
        if (selectedPinIdx.current !== -1 && markersRef.current[selectedPinIdx.current]) {
          markersRef.current[selectedPinIdx.current].setIcon({
            url: pin,
            scaledSize: new (window as any).google.maps.Size(30, 40),
          });
          // 클릭한 마커의 순서와 현재 선택된 마커의 순서가 동일할 시
          if(selectedPinIdx.current === idx){
            selectedPinIdx.current = -1;
            setPinDetail(-1);
          }else{
            //동일하지 않을시 새로운 마커가 눌렸다고 판단
            selectedPinIdx.current = idx;
            setPinDetail(id);
          }
        }else{
          //이 전에 마커를 클릭한 기록이 없을 경우
          setPinDetail(id);
          selectedPinIdx.current = idx;
        }
        setShowFilterList(false);
      });
    });

    clusterDraw(map)
     
  }, [searchedMapData.simpleResponseList]);


  useEffect(() => {
    if (!(window as any).google) {
      
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places,geometry`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        initializeMap();
      };
      document.head.appendChild(script);
    } else {
      initializeMap();
    }
  }, [searchedMapData.key, searchedMapData.lat, searchedMapData.lng, searchedMapData.ratio, location.lat, location.lng]);

  const initializeMap = () => {
    if (mapRef.current && (window as any).google) {
      const map = new (window as any).google.maps.Map(mapRef.current, {
        center: { lat: searchedMapData.lat, lng: searchedMapData.lng },
        zoom: getZoomLevel(searchedMapData.ratio),
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        fullscreenControl: false,
      });

      (window as any).google.maps.event.addListener(map, 'zoom_changed', function() {
        // 현재 지도 화면의 경계 가져오기
        const bounds = map.getBounds();

        // 북동쪽과 남서쪽 경계의 위도와 경도 값 가져오기
        const ne = bounds.getNorthEast(); // 북동쪽 경계
        const sw = bounds.getSouthWest(); // 남서쪽 경계

        const neLat = ne.lat(); // 북동 위도
        const neLng = ne.lng(); // 북동 경도
        const swLat = sw.lat(); // 남서 위도
        const swLng = sw.lng(); // 남서 경도


        fetchMansionRoomList(neLat, neLng, swLat, swLng)
      });

      (window as any).google.maps.event.addListener(map, 'dragend', function() {
        // 현재 지도 화면의 경계 가져오기
        const bounds = map.getBounds();

        // 북동쪽과 남서쪽 경계의 위도와 경도 값 가져오기
        const ne = bounds.getNorthEast(); // 북동쪽 경계
        const sw = bounds.getSouthWest(); // 남서쪽 경계

        const neLat = ne.lat(); // 북동 위도
        const neLng = ne.lng(); // 북동 경도
        const swLat = sw.lat(); // 남서 위도
        const swLng = sw.lng(); // 남서 경도


        fetchMansionRoomList(neLat, neLng, swLat, swLng)
      });

      if(location.lat !== 0 && location.lng !== 0){
        if(myLocationRef.current){
          myLocationRef.current.setMap(null)
        }
        const temp = new (window as any).google.maps.Marker({
          key: `myLocation`,
          position: { lat: location.lat, lng: location.lng },
          map: map,
          icon: {
            url: myLocationPin,
            scaledSize: new (window as any).google.maps.Size(30, 30),
          },
          title: 'myLocation',
        });
        myLocationRef.current = temp;
        map.setCenter(location);
      }else{
        if(myLocationRef.current){
          myLocationRef.current.setMap(null);
          myLocationRef.current = null;
        }
      }

      markerDraw(searchedMapData.simpleResponseList, map);
    }
  };

  return <MapWrap><MapContentsWrap $mobilePinShow={pinDetail !== -1} ref={mapRef}/></MapWrap>
}

