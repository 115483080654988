import { useState } from "react";
import styled from "styled-components";
import ResponsiveWrapper from "../../../components/ResponsiveWrapper";
import useDeviceSize from "../../../hooks/useDeviceSize";
import whiteArrow from "../../../assets/images/mainPage/arrow-white.png";
import { useNavigate } from "react-router-dom";
import { selectedOptionType } from "../../../recoil/adminMainDesign/categoryIconStateAtom";
import usePropertyState from "../../../hooks/usePropertyState";
import {
  mansionPropertyDataStateAtom,
  mansionPropertyDefaultValue,
} from "../../../recoil/mansionPage/mansionPropertyState";
import { useRecoilState } from "recoil";

// 메인 페이지 카테고리 컴포넌트
function Categories({
  iconResponses,
}: {
  iconResponses?: {
    fileUrl: string;
    id: number;
    name: string;
    selectedOption: selectedOptionType;
  }[];
}) {
  const { isMobile } = useDeviceSize();
  const [categoryPageState, setCategoryPageState] = useState<number>(1);

  const [propertyState, setPropertyState] = useRecoilState(
    mansionPropertyDataStateAtom
  );

  const { selectOnePropertyOptions } = usePropertyState();

  const navigate = useNavigate();

  const getMaxPage = () => {
    if (iconResponses) {
      const maxPage = Math.ceil(iconResponses && iconResponses?.length / 4);

      return maxPage;
    } else {
      return 0;
    }
  };

  const handleOnClickCategory = (category: selectedOptionType) => {
    if (
      category &&
      propertyState.propertyOptionRequest.hasOwnProperty(category)
    ) {
      setPropertyState({
        ...mansionPropertyDefaultValue,
        propertyOptionRequest: {
          ...mansionPropertyDefaultValue.propertyOptionRequest,
          [category]: true,
        },
      });
      selectOnePropertyOptions(category, true);
      navigate(`/mansion`, {
        state: 1,
      });
    }
  };

  return (
    <ResponsiveWrapper>
      <Wrapper>
        {isMobile && (
          <CategoriesBox>
            {getMaxPage() >= 2 && (
              <NavigationDiv>
                <NavigationButton
                  isdeactivate={categoryPageState === 1}
                  onClick={() => {
                    if (categoryPageState !== 1) {
                      setCategoryPageState(categoryPageState - 1);
                    }
                  }}
                >
                  <WhiteArrowIcon
                    src={whiteArrow}
                    style={{ transform: "rotate(180deg)" }}
                  ></WhiteArrowIcon>
                </NavigationButton>
                <NavigationButton
                  isdeactivate={categoryPageState === getMaxPage()}
                  onClick={() => {
                    if (categoryPageState !== getMaxPage()) {
                      setCategoryPageState(categoryPageState + 1);
                    }
                  }}
                >
                  <WhiteArrowIcon src={whiteArrow}></WhiteArrowIcon>
                </NavigationButton>
              </NavigationDiv>
            )}
            {iconResponses &&
              iconResponses
                .slice((categoryPageState - 1) * 4, categoryPageState * 4)
                .map((item, index) => (
                  <CategoryItem
                    key={item.name}
                    onClick={() => handleOnClickCategory(item.selectedOption)}
                  >
                    <CategoryImgWrapper>
                      <StyledImage
                        src={item.fileUrl}
                        alt={item.name}
                      ></StyledImage>
                    </CategoryImgWrapper>
                    {item.name}
                  </CategoryItem>
                ))}
          </CategoriesBox>
        )}
        {!isMobile && (
          <CategoriesBox>
            {iconResponses &&
              iconResponses.map((item) => (
                <CategoryItem
                  key={item.name}
                  onClick={() => handleOnClickCategory(item.selectedOption)}
                >
                  <CategoryImgWrapper>
                    <StyledImage
                      src={item.fileUrl}
                      alt={item.name}
                    ></StyledImage>
                  </CategoryImgWrapper>
                  {item.name}
                </CategoryItem>
              ))}
          </CategoriesBox>
        )}
      </Wrapper>
    </ResponsiveWrapper>
  );
}

export default Categories;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media screen and (min-width: 1280px) {
    height: 329px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    height: 291px;
  }
  @media screen and (max-width: 1023px) {
    height: 199px;
  }
`;

const CategoriesBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media screen and (min-width: 1280px) {
    gap: 30px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 26px;
  }
  @media screen and (max-width: 1023px) {
    gap: 15px;
    width: 385px;
  }
`;

const CategoryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  @media screen and (min-width: 1280px) {
    gap: 23px;
    font-size: 16px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 17px;
    font-size: 15px;
  }
  @media screen and (max-width: 1023px) {
    gap: 8.5px;
    font-size: 12px;
  }
`;

const CategoryImgWrapper = styled.div`
  overflow: hidden;
  @media screen and (min-width: 1280px) {
    width: 92.5px;
    height: 92.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 75.5px;
    height: 75.5px;
  }
  @media screen and (max-width: 1023px) {
    width: 63px;
    height: 63px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
`;

const NavigationDiv = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  pointer-events: none;
`;

const NavigationButton = styled.button<{ isdeactivate?: boolean }>`
  width: 23px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.isdeactivate ? "#f2f2f2" : "#dddddd")};
  border: none;
  border-radius: 7px;
  pointer-events: all;
`;

const WhiteArrowIcon = styled.img`
  width: 7px;
  height: 14px;
`;
