import styled from "styled-components";
import InformationBox from "./InformationBox";
import useDeviceSize from "../../../hooks/useDeviceSize";
import { roomDetailType } from "../Detail";
import { Fragment } from "react/jsx-runtime";

function DetailInformation({
  roomDetailState,
}: {
  roomDetailState?: roomDetailType;
}) {
  const { isMobile } = useDeviceSize();

  const convertedRoomStructure = () => {
    switch (roomDetailState?.structure) {
      case "woodcarving":
        return "목조";
      case "rebar":
        return "철근구조";
      case "ironFrame":
        return "철골구조";
      case "other":
        return "기타";
      case "non":
        return "해당없음";
    }
  };

  const convertedRoomType = () => {
    const roomType = roomDetailState?.roomType;
    if (roomType === "non") {
      return "해당없음";
    } else {
      const convertedRoomType =
        roomType && roomType.slice(-1, roomType.length) + roomType.slice(0, -1);

      return convertedRoomType?.toUpperCase();
    }
  };

  const convertContractTerm = () => {
    const months = roomDetailState?.contractTerm
      ? roomDetailState?.contractTerm
      : 0;
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;
    if (months === 0) {
      return "해당없음";
    } else {
      if (years > 0) {
        return remainingMonths > 0
          ? `${years}년 ${remainingMonths}개월`
          : `${years}년`;
      } else {
        return `${remainingMonths}개월`;
      }
    }
  };

  const convertPenalty = () => {
    if (
      roomDetailState?.penaltyOption === "해당없음" ||
      roomDetailState?.penaltyAmount === "해당없음"
    ) {
      return "해당없음";
    } else {
      return `${roomDetailState?.penaltyOption} 해약 시 ${roomDetailState?.penaltyAmount}`;
    }
  };

  const INFORMATION = [
    { title: "방타입", detail: convertedRoomType() },
    { title: "건축년도", detail: roomDetailState && roomDetailState.constructedDate },
    {
      title: "계약기간",
      detail: convertContractTerm(),
    },
    {
      title: "위약금",
      detail: convertPenalty(),
    },
    { title: "면적", detail: `${roomDetailState?.area.toLocaleString()}㎡` },
    {
      title: "층수",
      detail: `${roomDetailState?.numOfFloor}층 / ${roomDetailState?.totalFloor}층 건물`,
    },
    { title: "구조", detail: convertedRoomStructure() },
    { title: "방향", detail: roomDetailState?.direction },
  ];

  return (
    <DetailInformationWrapper>
      <InformationBox
        decoration={true}
        width={{ pc: "100%", laptop: "100%", mobile: "100%" }}
      >
        {isMobile ? (
          <InformationItemWrapper>
            {INFORMATION.map((item, index) => (
              <InformationItem key={index}>
                <InformationTitle>{item.title}</InformationTitle>
                <InformationDetail>{item.detail}</InformationDetail>
              </InformationItem>
            ))}
            {roomDetailState?.moreInfo &&
              roomDetailState.moreInfo.split("$").map((item, index) => (
                <Fragment key={index}>
                  {index > 0 && (
                    <InformationItem key={index}>
                      <InformationTitle>
                        {item && item.split("@")[0]}
                      </InformationTitle>
                      <InformationDetail>
                        {item && item.split("@")[1]}
                      </InformationDetail>
                    </InformationItem>
                  )}
                </Fragment>
              ))}
          </InformationItemWrapper>
        ) : (
          <InformationItemWrapper>
            <ItemWrapperRight>
              {INFORMATION.slice(0, 4).map((item, index) => (
                <InformationItem key={index}>
                  <InformationTitle>{item.title}</InformationTitle>
                  <InformationDetail>{item.detail}</InformationDetail>
                </InformationItem>
              ))}
              {roomDetailState?.moreInfo &&
                roomDetailState.moreInfo.split("$").map((item, index) => (
                  <Fragment key={index}>
                    {index > 0 && index % 2 === 1 && (
                      <InformationItem key={index}>
                        <InformationTitle>
                          {item && item.split("@")[0]}
                        </InformationTitle>
                        <InformationDetail>
                          {item && item.split("@")[1]}
                        </InformationDetail>
                      </InformationItem>
                    )}
                  </Fragment>
                ))}
            </ItemWrapperRight>
            <ItemWrapperLeft>
              {INFORMATION.slice(4, 8).map((item, index) => (
                <InformationItem key={index}>
                  <InformationTitle>{item.title}</InformationTitle>
                  <InformationDetail>{item.detail}</InformationDetail>
                </InformationItem>
              ))}
              {roomDetailState?.moreInfo &&
                roomDetailState.moreInfo.split("$").map((item, index) => (
                  <Fragment key={index}>
                    {index > 0 && index % 2 === 0 && (
                      <InformationItem key={index}>
                        <InformationTitle>
                          {item && item.split("@")[0]}
                        </InformationTitle>
                        <InformationDetail>
                          {item && item.split("@")[1]}
                        </InformationDetail>
                      </InformationItem>
                    )}
                  </Fragment>
                ))}
            </ItemWrapperLeft>
          </InformationItemWrapper>
        )}
      </InformationBox>
    </DetailInformationWrapper>
  );
}

export default DetailInformation;

const DetailInformationWrapper = styled.div`
  @media screen and (min-width: 1280px) {
    margin-top: 52px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 42px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 21px;
  }
`;

const InformationItemWrapper = styled.div`
  display: flex;
  @media screen and (min-width: 1280px) {
    margin: 52px 32.5px 43px;
    row-gap: 43.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin: 53px 32.5px 40px;
    row-gap: 39px;
  }
  @media screen and (max-width: 1023px) {
    margin: 37px 25px 30px;
    row-gap: 17px;
    flex-direction: column;
  }
`;

const ItemWrapperRight = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1280px) {
    width: 362px;
    row-gap: 43.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 339px;
    row-gap: 39px;
  }
  @media screen and (max-width: 1023px) {
  }
`;
const ItemWrapperLeft = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1280px) {
    row-gap: 43.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    row-gap: 39px;
  }
  @media screen and (max-width: 1023px) {
  }
`;

const InformationItem = styled.div`
  display: flex;

  @media screen and (min-width: 1280px) {
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
  }
  @media screen and (max-width: 1023px) {
  }
`;
const InformationTitle = styled.div`
  font-weight: bold;
  @media screen and (min-width: 1280px) {
    width: 113px;
    font-size: 19px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 105px;
    font-size: 19px;
  }
  @media screen and (max-width: 1023px) {
    width: 99px;
    font-size: 15px;
  }
`;

const InformationDetail = styled.div`
  @media screen and (min-width: 1280px) {
    font-size: 19px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 19px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
  }
`;

const Test = styled.div`
  width: 300px;
  height: 100px;
  border: 1px solid black;
`;
