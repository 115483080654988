import { atom, useRecoilState } from "recoil";

type adminMansionPropertyType = {
  regionId: number;
  page: number;
  categoryIdList?: number[];
  keyword?: string;
};

export const adminMansionPropertyStateAtom = atom<adminMansionPropertyType>({
  key: "adminMansionPropertyState",
  default: { regionId: 1, page: 1 },
});

export const useAdminMansionPropertyState = () => {
  const [adminMansionPropertyState, setAdminMansionPropertyState] =
    useRecoilState(adminMansionPropertyStateAtom);
  return { adminMansionPropertyState, setAdminMansionPropertyState };
};
