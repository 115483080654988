import { atom } from "recoil";

export type paginationDataType = {
  detailCategoryId: number;
  page: number;
  size: number;
  sortOption: string;
};

export const paginationDataStateAtom = atom<paginationDataType>({
  key: "paginationDataState",
  default: {
    detailCategoryId: 0,
    page: 1,
    size: 10,
    sortOption: "",
  },
});
