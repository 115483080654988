import React from 'react';
import styled from 'styled-components';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
}

const MyPagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const isMobile = window.innerWidth < 1024; // 모바일 화면 여부 확인
  const pageRange = isMobile ? 5 : 10; // 모바일에서는 5개 단위, PC에서는 10개 단위

  const startPage = Math.floor((currentPage - 1) / pageRange) * pageRange + 1;
  const endPage = Math.min(startPage + pageRange - 1, totalPages);

  const handleNextPageRange = () => {
    if (endPage < totalPages) {
      onPageChange(startPage + pageRange);
    }
  };

  const handlePreviousPageRange = () => {
    if (startPage > 1) {
      onPageChange(startPage - pageRange);
    }
  };

  return (
    <Pcc>
      <PaginationContainer>
        <ArrowButton
          onClick={handlePreviousPageRange}
          disabled={startPage === 1}
        >
          &lt;
        </ArrowButton>
        <Pagination>
          {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
            <PageButton
              key={index}
              selected={currentPage === startPage + index}
              onClick={() => onPageChange(startPage + index)}
            >
              {startPage + index}
            </PageButton>
          ))}
        </Pagination>
        <ArrowButton
          onClick={handleNextPageRange}
          disabled={endPage >= totalPages}
        >
          &gt;
        </ArrowButton>
      </PaginationContainer>
    </Pcc>
  );
};

export default MyPagination;

const Pcc = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  width: 400px;
  justify-content: space-between;
  @media (max-width: 500px) {
    width: 250px;
  }
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const PageButton = styled.button<{ selected?: boolean }>`
  width: 30px;
  padding: 8px 0;
  border: 0px solid #ccc;
  cursor: pointer;

  background-color: transparent;
  text-align: center;
  color: ${(props) => (props.selected ? "#99393a" : "#9BA2AE")};
  font-size: 18px;
  font-weight: bold;

  &:hover {
    background-color: #f0f0f0;

  }
`;

const ArrowButton = styled.button<{ disabled?: boolean }>`
  margin: 0 5px;
  padding: 8px 12px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  color: ${(props) => (props.disabled ? "transparent" : "inherit")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  opacity: ${(props) => (props.disabled ? 0 : 1)};
`;
