import styled from "styled-components";

function ViewMoreButton({
  buttonState,
  onClickButton,
}: {
  buttonState: boolean;
  onClickButton: () => void;
}) {
  return (
    <Button onClick={onClickButton}>
      {buttonState ? "접기 >" : "펼치기 >"}
    </Button>
  );
}

export default ViewMoreButton;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #333333;
  font-family: "Noto Sans KR";
  font-size: 15px;
  font-weight: bold;
  color: #383838;
  background-color: #ffffff;
  border-radius: 7px;
  width: 295px;
  height: 44px;
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;
