import React, { useState, ChangeEvent, useEffect} from "react";
import styled from "styled-components";
import axios from "axios";

interface Props {
    onChange: (value: string, isValid: boolean, isIdUsed:boolean) => void;
  }

const NicknameInput: React.FC<Props> = ({ onChange }) => {
  const [nickname, setNickname] = useState("");
  const [isValid, setIsValid] = useState(false); // 유효성 검사 여부 상태
  const [isIdUsed, setIsIdUsed] = useState(false);


  const handleNicknameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setNickname(value);
    setIsValid(isValidNickname(value));
  };

  const isValidNickname = (nickname: string): boolean => {
    // 한글, 일본어, 영어, 숫자만 허용하는 정규식
    const regex = /^[a-zA-Z0-9가-힣ぁ-ゔァ-ヴー々〆〤]*$/;
    return regex.test(nickname) && nickname.length >= 2 && nickname.length <= 10;
  };

  useEffect(() => {
    if (nickname === "") return;

    const handler = setTimeout(() => {
      const checkUserId = async () => {
        try {
          const response = await axios.get(
            "https://houberapp.com/auth/register/check",
            {
              params: {
                key: "nickName",
                value: nickname,
              },
            }
          );

          setIsIdUsed(!response.data);

        } catch (error) {

        }
      };

      checkUserId();
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [nickname]);

  useEffect(() => {
    onChange(nickname, isValid, isIdUsed);
  }, [nickname, isValid, onChange]);

  return (
    <div>
      <DivInput>
        <InputField
          minLength={2}
          maxLength={10}
          placeholder="닉네임(2~10자)"
          value={nickname}
          onChange={handleNicknameChange}
          isValid={isValid}
        />
      </DivInput>
      {isIdUsed && <StyledP>중복된 닉네임입니다.</StyledP>}
    </div>
  );
};

export default NicknameInput;

const InputField = styled.input<{ isValid: boolean }>`
  border-radius: 10px;
  width: 100%;
  padding-left: 20px;
  font-size: 23px;
  border: 1px solid #ccc;
  height: 60px;
  outline: none;
  transition: border-color 0.3s ease;
  font-family: "Noto Sans KR";
  font-weight: 500;
     @media(max-width:500px){
  height: 34px;
  font-size: 12px;
    }
`;

const DivInput = styled.div`
  display: flex;
  border: none;
  border-radius: 5px;
  background-color: white;
  margin-top: 5px;
  margin-bottom: 15px;
`;


const StyledP = styled.div`
  color: red;
  margin-bottom: 15px; 

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;
