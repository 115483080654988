import React, {
  useState,
  useEffect,
  KeyboardEvent,
  ChangeEvent,
  useRef,
} from "react";
import styled, { keyframes } from "styled-components";
import ResponsiveWrapper from "../../components/ResponsiveWrapper";
import axiosInstance from "../../services/axiosConfig";
import PhotoIcon from "../../assets/images/qna/Group 5.png";
import FileIcon from "../../assets/images/qna/free-icon-add-file-4682648.png";
import FileIcon1 from "../../assets/images/qna/dd.png";
import LinkIcon from "../../assets/images/qna/free-icon-link-11818934 복사.png";
import LinkIconClick from "../../assets/images/qna/free-icon-link-11818934.png";
import CheckImg from "../../assets/images/qna/check.png";
import { useNavigate } from "react-router-dom";
import back from "../../assets/images/qna/back_mobile.png";
import keypad from "../../assets/images/qna/free-icon-font-keyboard-down-12400684.png";
// import LogoImg from "../../assets/images/qna/houberLogo1.png"
import LoadingOverlay from "../../components/LoadingOverlay";
import { logoStateAtom } from "../../recoil/adminMainDesign/logoStateAtom";
import { useSetRecoilState } from "recoil";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #fff;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 92px;
  margin-bottom: 55px;

  @media screen and (max-width: 1023px) {
    margin: 0;
    height: 46px;
    padding: 0 20px;
  }
`;

const Logo = styled.div`
  max-width: 150px;
  text-decoration: none;
  overflow: hidden;
  max-height: 100px;
  cursor: pointer;
  @media screen and (min-width: 1280px) {
    font-size: 40px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 25px;
  }
  @media screen and (max-width: 1023px) {
    width: 15px;
  }
`;

const BtnBox = styled.div`
  display: flex;
`;

const Button1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 22px;
  font-size: 23px;
  background-color: #ffffff;
  color: #99393a;
  border: solid 2px #99393a;
  border-radius: 26px;
  cursor: pointer;
  font-weight: 600;
  margin-right: 10px;
  width: 137px;
  height: 43px;

  @media screen and (max-width: 1023px) {
    background-color: transparent;
    color: #a0a0a0;
    font-size: 18px;
    font-weight: 500;
    border: none;
    padding: 0;
    justify-content: flex-end;
  }
`;

const Button2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 22px;
  font-size: 23px;
  background-color: #99393a;
  color: white;
  border: none;
  border-radius: 26px;
  cursor: pointer;
  width: 137px;
  height: 43px;
  font-weight: 600;


  @media screen and (max-width: 1023px) {
    background-color: transparent;
    color: #99393a;
    font-size: 18px;
    font-weight: bold;
    width: auto;
    padding: 0;
  }
`;

const IconBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 77px;
  @media screen and (max-width: 1023px) {
    height: 67px;
  }

  img {
    width: 24px;
    /* height: 24px; */
    cursor: pointer;
  }
`;

const Divider = styled.div`
  width: 2px;
  height: 20px;
  margin: 0 26px;
  background-color: #ccc;
`;

const GuideBox = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 57px;
  box-sizing: border-box;
  width: 100%;
  margin-top: 85px;

  @media screen and (max-width: 1023px) {
    padding: 0;
    margin-top: 40px;
    display: flex;
    height: calc(100vh - 46px - 67px - 45px);
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
  }
`;

const Guide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  padding: 47px 55px;
  border-radius: 10px;
  margin-bottom: 80px;
  width: 100%;
  height: 338px;
`;

const Div1 = styled.div`
  font-size: 26px;
  font-weight: 600;
  color: #333333;
`;

const Div2 = styled.div`
  font-size: 19px;
  color: #555555;
  font-weight: 500;

`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #cccccc;
  width: 100%;
`;

const InputContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 2px solid #cccccc;
  width: 100%;
  min-height: 150px; /* 최소 높이 설정 */

  @media screen and (max-width: 1023px) {
    border-bottom: 0;
  }
`;

const Input = styled.input`
  padding: 10px 0;
  font-size: 38px;
  color: #333333;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  outline: none;
  width: 90%;
  font-weight: 500;

  &::placeholder {
    color: #bbbbbb;
  }
  @media screen and (max-width: 1023px) {
    font-size: 32px;
    width: 80%;
  }
`;

const Input2 = styled.input`
  padding: 10px 0;
  font-size: 27px;
  color: #777777;
  border: none;
  width: 100%;
  outline: none;

  @media screen and (max-width: 1023px) {
    font-size: 18px;
  }
`;

const AutoResizeTextArea = styled.textarea`
  width: 100%;
  padding: 67px 1px;
  font-size: 23px;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  box-sizing: border-box;
  resize: none; /* 수동 조정 비활성화 */
  outline: none;
  min-height: 100px;
  min-height: 280px;
  letter-spacing: 0.5px; /* 글자 간 간격 조절 */
  line-height: 1.5; /* 줄 간격 조절 */
  white-space: pre-wrap; /* 공백 문자와 줄바꿈을 그대로 유지 */
  overflow: hidden; /* 스크롤바 숨김 */

  font-family: 'Noto Sans', sans-serif;
  font-weight: 500;

  &::placeholder {
    color: #bbbbbb;
  }
  @media screen and (max-width: 1023px) {
    font-size: 18px;
    padding: 20px 1px;
    margin: 0;
  }
`;

const CharacterCount = styled.div`
  display: flex;
  text-align: right;
  font-size: 23px;
  color: #999999;
  align-items: center;
  font-weight: 500;

  @media screen and (max-width: 1023px) {
    font-size: 18px;
  }
`;

const FixedFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 20px;
  z-index: 1000;
`;

const PageContainer = styled.div`
  padding-bottom: 67px; /* FixedFooter의 높이만큼 여유 공간 추가 */
`;

const UploadedItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const UploadedItemLinkContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-height: 600px;
  border: 0px solid #ccc;
  border-radius: 10px;
  margin-bottom: 16px;
  overflow: hidden;
  padding: 1px;
  @media screen and (max-width: 1023px) {
    /* min-height: 100px; */
    height: auto;
  }
`;

const LinkStyled = styled.a`
  color: blue; /* 기본 링크 색상 */
  text-decoration: underline; /* 밑줄 추가 */
  &:hover {
    color: darkblue; /* 호버 시 색상 */
  }
  font-size: 23px;
`;

const UploadedItemContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 600px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 16px;
  overflow: hidden;
`;

const UploadedItemFileContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-height: 600px;
  border: 0px solid #ccc;
  border-radius: 10px;
  margin-bottom: 16px;
  overflow: hidden;
  @media screen and (max-width: 1023px) {
    /* min-height: 120px; */
    margin-bottom: 0;
    height: auto;
    max-width: 385px;
  }
`;

const UploadedImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* 이미지가 컨테이너에 맞게 조정되도록 설정 */
  border-radius: 10px;
`;

const UploadedFile = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 30px 42px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 16px;
  height: 93px;
  @media screen and (max-width: 1023px) {
    padding: 18px 24px;
  }
`;

const FileIconImg = styled.img`
  width: 34px;
  margin-right: 22px;
`;

const Check = styled.img`
  width: 32px;
  margin-left: 22px;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

const Modal = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? "flex" : "none")};
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, 60px); /* Adjust to perfectly center */
  background-color: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 1;
  padding: 17px;
  flex-direction: row;
  width: 480px;
  height: 95px;

  @media screen and (max-width: 1023px) {
    position: fixed; /* Change from absolute to fixed */
    transform: translate(-50%, -50%); /* Adjust to perfectly center */
    top: 50%; /* Start positioning from the center */
    left: 50%; /* Start positioning from the center */
    flex-direction: column;
    width: 387px;
    height: 137px;
    padding: 16px;
  }
`;

const Input1 = styled.input`
  padding: 14px 20px;
  font-size: 16px;
  border: 1px solid #555555;
  border-radius: 5px;
  width: 100%;

  &:focus {
    border-color: #99393a20; /* 원하는 테두리 색상으로 변경 */
    border: 4px solid #99393a20;
    outline: none; /* 기본 파란색 테두리 제거 */
  }
  @media screen and (max-width: 1023px) {
    width: auto;
  }
`;
const BtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
`;
const Button = styled.button`
  padding: 10px 20px;
  font-size: 19px;
  background-color: transparent;
  color: #99393a;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
`;

const CancelButton = styled.button`
  padding: 10px 20px;
  font-size: 19px;
  background-color: transparent;
  color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
`;

const BackButton = styled.img`
  display: flex;
  width: 25px;
  border: none;
  background: none;
  cursor: pointer;
  align-items: center;
`;
const Keypad = styled.img`
  width: 25px;
`;

const ModalBackground = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? "flex" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
`;

const TagsInput = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  width: 100%;
  padding: 0 8px;
  /* border: 1px solid rgb(1, 186, 138); */
  border-radius: 6px;
  padding-bottom: 150px;


  @media screen and (max-width: 1023px) {
  padding-bottom: 0px;
    margin: 0;
    border-radius: 0px;

    border-top: 2px solid #cccccc;
  }

  > ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0 0;

    > .tag {
      width: auto;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #99393a;
      padding: 0 8px;
      font-size: 23px;
      list-style: none;
      border-radius: 26px;
      margin: 0 8px 8px 0;
      background: rgb(242, 243, 244);
      border-radius: 15px;
      font-weight: 500;

      > .tag-close-icon {
        display: block;
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        font-size: 14px;
        margin-left: 8px;
        color: #99393a;
        border-radius: 50%;
        background: #fff;
        cursor: pointer;
      }
    }
  }

  > input {
    flex: 1;
    border: none;
    height: 46px;
    font-size: 23px;
    outline: none;
    font-weight: 600;

    @media screen and (max-width: 1023px) {
      font-size: 15px;
      font-weight: 500;
  }
    padding: 4px 0 0 0;
  }
`;

const StyledImage = styled.img`
  width: 100%;
`;

const QnaForm: React.FC = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const [charCount, setCharCount] = useState(0);
  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [link, setLink] = useState("");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const modalRef = useRef<HTMLDivElement>(null);

  const removeTags = (indexToRemove: number) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };
  const addTags = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;
    let inputVal = input.value.trim();

    // 입력된 태그에서 공백 및 특수문자 제거
    inputVal = inputVal.replace(/[^a-zA-Z0-9가-힣ぁ-んァ-ン一-龥]/g, "");

    if (
      event.key === "Enter" &&
      inputVal !== "" &&
      !tags.includes(inputVal) &&
      tags.length < 8
    ) {
      setTags([...tags, inputVal]);
      input.value = "";
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\s+/g, ""); // 공백 제거
  
    if (value.length > 10) {
      value = value.slice(0, 10); // 최대 10자만 허용
    }
  
    input.value = value;
  };
  
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLinkAttach = () => {
    setShowModal(!showModal);
  };

  const handleConfirm = () => {
    let urlInput = (
      document.getElementById("url-input") as HTMLInputElement
    ).value.trim();
    if (!urlInput) {
      alert("URL을 입력해주세요.");
      return;
    }
    if (!urlInput.startsWith("http://") && !urlInput.startsWith("https://")) {
      urlInput = "http://" + urlInput;
    }
    setLink(urlInput);
    setShowModal(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setShowModal(false);
      }
    };

    if (showModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showModal]);
  const handlePhotoUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newImages = Array.from(e.target.files);
      const totalImages = uploadedImages.length + newImages.length;
  
      // 각 이미지 파일의 크기가 5MB 미만인지 확인합니다.
      const validFiles = newImages.filter(
        (file) => file.size < 5 * 1024 * 1024
      );
  
      if (newImages.length > validFiles.length) {
        alert("5MB 미만의 이미지 파일만 업로드할 수 있습니다.");
      }
  
      if (totalImages > 10) {
        const remainingSlots = 10 - uploadedImages.length;
        alert(
          `최대 10개의 이미지만 업로드할 수 있습니다. ${remainingSlots}개만 추가됩니다.`
        );
        setUploadedImages([
          ...uploadedImages,
          ...validFiles.slice(0, remainingSlots),
        ]);
      } else {
        setUploadedImages([...uploadedImages, ...validFiles]);
      }
    }
  };
  

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files).slice(0, 1);
      const validFiles = newFiles.filter(
        (file) => file.size <= 50 * 1024 * 1024
      );

      if (validFiles.length > 0) {
        setUploadedFiles(validFiles);
      } else {
        alert("파일 크기는 최대 50MB까지 가능합니다.");
      }
    }
  };

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
    setCharCount(e.target.value.length);
  };

  const handleContentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value);
  };

  const removeFile = () => {
    setUploadedFiles([]);
  };

  const removeImage = (index: number) => {
    setUploadedImages(uploadedImages.filter((_, i) => i !== index));
  };

  const handleSubmit = async (saveStatus: number) => {
    if (tags.length < 3) {
      alert("키워드를 최소 3개 입력해야 합니다.");
      return;
    }

    if (!title.trim() || !content.trim()) {
      alert("제목과 내용을 입력해주세요.");
      return;
    }

    const formattedTags = tags.map((tag) => tag.replace(/^#/, "")).join("$");

    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("link", link);
    formData.append("saveStatus", saveStatus.toString());
    formData.append("keyword", formattedTags);

    uploadedImages.forEach((image) => {
      formData.append("imageAndFile", image);
    });

    uploadedFiles.forEach((file) => {
      formData.append("imageAndFile", file);
    });

    try {
      setIsSubmitting(true); // Start spinner

      const response = await axiosInstance.post("/qna/submit", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      navigate(-1);
    } catch (error) {
      // console.error("Error submitting form:", error);
      alert("등록 실패");
    } finally {
      setIsSubmitting(false); // Stop spinner
    }
  };

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto"; // 높이 초기화
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`; // 내용에 따라 높이 조정
    }
  };

  useEffect(() => {
    handleInput(); // 초기 내용에 따라 높이 설정
  }, []);

  const handleModalClose = () => {
    setShowModal(false);
  };

  const [isKeypadVisible, setIsKeypadVisible] = useState(false);

  const handleFocus = () => setIsKeypadVisible(true);
  const handleBlur = () => setIsKeypadVisible(false);
  const handleKeypadClick = () => setIsKeypadVisible(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [logoState, setLogoState] = useState<string>("");
  const setRecoilLogoState = useSetRecoilState(logoStateAtom);


  const fetchLogoImage = async () => {
    try {
      const response: { data: string } = await axiosInstance.get(
        "https://houberapp.com/normal/home/logo"
      );

      setLogoState(response.data && response.data);
      setRecoilLogoState({ id: 0, fileUrl: response.data && response.data });
    } catch (error) {}
  };
  useEffect(() => {
  
    fetchLogoImage();
  }, []);


  return (
    <>
      <LoadingOverlay isSubmitting={isSubmitting} />

      {isMobile ? (
        <PageContainer>
          {/* <ResponsiveWrapper> */}
          <Container>
            <Header>
              {isKeypadVisible ? (
                <Keypad src={keypad} onClick={handleKeypadClick} />
              ) : (
                <BackButton src={back} onClick={() => navigate(-1)} />
              )}
              <BtnBox>
                <Button2 onClick={() => handleSubmit(1)}>올리기</Button2>
              </BtnBox>
            </Header>
          </Container>
          {/* </ResponsiveWrapper> */}
          <Line />
          <ResponsiveWrapper>
            <></>
          </ResponsiveWrapper>
          <Line />
          <ResponsiveWrapper>
            <Container>
              <GuideBox>
                <div>
                  <InputContainer>
                    <Input
                      type="text"
                      placeholder="제목을 입력해주세요"
                      value={title}
                      onChange={handleTitleChange}
                      maxLength={40}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                    <CharacterCount>{charCount} / 40</CharacterCount>
                  </InputContainer>
                  <InputContainer2>
                    <AutoResizeTextArea
                      ref={textAreaRef}
                      onInput={handleInput}
                      onChange={handleContentChange}
                      value={content}
                      placeholder="내용을 입력해주세요"
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                    {link && (
                      <UploadedItemLinkContainer>
                        <LinkStyled
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {link}
                        </LinkStyled>
                        <RemoveButton onClick={() => setLink("")}>
                          ×
                        </RemoveButton>
                      </UploadedItemLinkContainer>
                    )}
                    {uploadedFiles.map((file, index) => (
                      <UploadedItemFileContainer key={index}>
                        <UploadedFile>
                          <FileIconImg src={FileIcon1} />
                          <div>{file.name}</div>
                          <Check src={CheckImg} />
                          <RemoveButton onClick={() => removeFile()}>
                            ×
                          </RemoveButton>
                        </UploadedFile>
                      </UploadedItemFileContainer>
                    ))}
                    {uploadedImages.map((image, index) => (
                      <UploadedItemContainer key={index}>
                        <UploadedImage
                          src={URL.createObjectURL(image)}
                          alt={`uploaded ${index}`}
                        />
                        <RemoveButton onClick={() => removeImage(index)}>
                          ×
                        </RemoveButton>
                      </UploadedItemContainer>
                    ))}
                  </InputContainer2>
                </div>
                <TagsInput>
                  <ul id="tags">
                    {tags.map((tag, index) => (
                      <li key={index} className="tag">
                        <span className="tag-title">{tag}</span>
                        <span
                          className="tag-close-icon"
                          onClick={() => removeTags(index)}
                        >
                          ×
                        </span>
                      </li>
                    ))}
                  </ul>
                  <input
                    className="tag-input"
                    type="text"
                    onKeyUp={addTags}
                    onChange={handleInputChange} // 입력 도중 공백 제거
                    placeholder="#주요 키워드를 입력해보세요 (필수 3개)"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </TagsInput>
              </GuideBox>
              <FixedFooter>
                <IconBar>
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    style={{ display: "none" }}
                    id="photo-upload"
                    onChange={handlePhotoUpload}
                  />
                  <label htmlFor="photo-upload">
                    <img src={PhotoIcon} alt="Photo" />
                  </label>
                  <Divider />
                  <input
                    type="file"
                    accept="application/pdf"
                    style={{ display: "none" }}
                    id="file-upload"
                    onChange={handleFileUpload}
                  />
                  <label htmlFor="file-upload">
                    <img src={FileIcon} alt="File" />
                  </label>
                  <Divider />
                  <img
                    src={showModal ? LinkIconClick : LinkIcon}
                    alt="File"
                    onClick={handleLinkAttach}
                  />
                </IconBar>
                <Button1 onClick={() => handleSubmit(0)}>임시 저장</Button1>
              </FixedFooter>
            </Container>
          </ResponsiveWrapper>
          <ModalBackground show={showModal}>
            <ResponsiveWrapper>
              <Modal show={showModal} ref={modalRef}>
                <Input1
                  id="url-input"
                  type="text"
                  placeholder="URL을 입력해주세요"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                <BtnContainer>
                  <CancelButton onClick={handleModalClose}>취소</CancelButton>
                  <Button onClick={handleConfirm}>확인</Button>
                </BtnContainer>
              </Modal>
            </ResponsiveWrapper>
          </ModalBackground>
        </PageContainer>
      ) : (
        <>
          <ResponsiveWrapper>
            <Container>
              <Header>
              {logoState && logoState.length > 0 ? (
            <Logo
              onClick={() => {
                navigate("/");
              }}
            >
              <StyledImage src={logoState}></StyledImage>
            </Logo>
                ) : (
                  <Logo onClick={() => navigate("/")}>LOGO</Logo>
                )}

                {/* <Logo src={LogoImg} onClick={() => navigate('/')}/> */}
                <BtnBox>
                  <Button1 onClick={() => handleSubmit(0)}>임시저장</Button1>
                  <Button2 onClick={() => handleSubmit(1)}>올리기</Button2>
                </BtnBox>
              </Header>
            </Container>
          </ResponsiveWrapper>
          <Line />
          <ResponsiveWrapper>
            <IconBar>
              <input
                type="file"
                accept="image/*"
                multiple
                style={{ display: "none" }}
                id="photo-upload"
                onChange={handlePhotoUpload}
              />
              <label htmlFor="photo-upload">
                <img src={PhotoIcon} alt="Photo" />
              </label>
              <Divider />
              <input
                type="file"
                accept="application/pdf"
                style={{ display: "none" }}
                id="file-upload"
                onChange={handleFileUpload}
              />
              <label htmlFor="file-upload">
                <img src={FileIcon} alt="File" />
              </label>
              <Divider />
              <img
                src={showModal ? LinkIconClick : LinkIcon}
                alt="File"
                onClick={handleLinkAttach}
              />
            </IconBar>
            <Modal show={showModal} ref={modalRef}>
              <Input1
                id="url-input"
                type="text"
                placeholder="URL을 입력해주세요"
              />
              <Button onClick={handleConfirm}>확인</Button>
            </Modal>
          </ResponsiveWrapper>
          <Line />
          <ResponsiveWrapper>
            <Container>
              <GuideBox>
                <Guide>
                  <Div1>Q&A 글 작성 가이드</Div1>
                  <Div2>
                    ◦  참고가 되는 사진을 올려주시면 더 좋은 답변을 얻을 수
                    있습니다
                  </Div2>
                  <Div2>
                    ◦  글 작성시 키워드 3개는 필수 입력 항목입니다 (최대 8개까지
                    입력 가능)
                  </Div2>
                  <Div2>
                    ◦  일본 정보 및 부동산과 관련없는 질문은 삭제될 수 있습니다
                  </Div2>
                  <Div2>
                    ◦ 작성하신 글은 비슷한 어려움을 겪는 고객분들을 위해 삭제할
                    수 없습니다
                  </Div2>
                </Guide>
                <InputContainer>
                  <Input
                    type="text"
                    placeholder="제목을 입력해주세요"
                    value={title}
                    onChange={handleTitleChange}
                    maxLength={40}
                  />
                  <CharacterCount>{charCount} / 40</CharacterCount>
                </InputContainer>
                <InputContainer2>
                  <AutoResizeTextArea
                    ref={textAreaRef}
                    onInput={handleInput}
                    onChange={handleContentChange}
                    value={content}
                    placeholder="내용을 입력해주세요"
                  />
                  {link && (
                    <UploadedItemLinkContainer>
                      <LinkStyled
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link}
                      </LinkStyled>
                      <RemoveButton onClick={() => setLink("")}>×</RemoveButton>
                    </UploadedItemLinkContainer>
                  )}
                  {uploadedFiles.map((file, index) => (
                    <UploadedItemFileContainer key={index}>
                      <UploadedFile>
                        <FileIconImg src={FileIcon1} />
                        <div>{file.name}</div>
                        <Check src={CheckImg} />
                        <RemoveButton onClick={() => removeFile()}>
                          ×
                        </RemoveButton>
                      </UploadedFile>
                    </UploadedItemFileContainer>
                  ))}
                  {uploadedImages.map((image, index) => (
                    <UploadedItemContainer key={index}>
                      <UploadedImage
                        src={URL.createObjectURL(image)}
                        alt={`uploaded ${index}`}
                      />
                      <RemoveButton onClick={() => removeImage(index)}>
                        ×
                      </RemoveButton>
                    </UploadedItemContainer>
                  ))}
                </InputContainer2>
                <TagsInput>
                  <ul id="tags">
                    {tags.map((tag, index) => (
                      <li key={index} className="tag">
                        <span className="tag-title">{tag}</span>
                        <span
                          className="tag-close-icon"
                          onClick={() => removeTags(index)}
                        >
                          ×
                        </span>
                      </li>
                    ))}
                  </ul>
                  <input
                    className="tag-input"
                    type="text"
                    onKeyUp={addTags}
                    onChange={handleInputChange} // 입력 도중 공백 제거
                    placeholder="#주요 키워드를 입력해보세요 (필수 3개)"
                  />
                </TagsInput>
              </GuideBox>
            </Container>
          </ResponsiveWrapper>
        </>
      )}
    </>
  );
};

export default QnaForm;

const Line = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #ddd;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledSpinner = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid rgba(150, 150, 150, 0.3);
  border-top: 5px solid #99393a;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 9999;
`;
