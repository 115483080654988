import { useEffect, useState } from "react";
import styled from "styled-components";
import ResponsiveWrapper from "../../ResponsiveWrapper";
import loginIcon from "../../../assets/images/mainPage/sign-in.png";
import { useNavigate } from "react-router-dom";
import userIcon from "../../../assets/images/header/usericon.png";
import scrapIcon from "../../../assets/images/header/scrap-gray.png";
import { useSidebarState } from "../../../recoil/mainPge/sidebarStateAtom";
import axiosInstance from "../../../services/axiosConfig";

function SidebarHeader() {
  const { modifySidebarState } = useSidebarState();
  const isLogin = sessionStorage.getItem("Authorization");
  const navigate = useNavigate();

  const [userInfoState, setUserInfoState] = useState<{
    nickname?: string;
    profileImageUrl?: string;
  }>({});

  const fetchUserInfo = async () => {
    const response: { data: { nickName: string; profileImageUrl: string } } =
      await axiosInstance.get("mypage/viewMyHome");

    setUserInfoState({
      nickname: response.data.nickName,
      profileImageUrl: response.data.profileImageUrl,
    });
  };
  useEffect(() => {
    if (isLogin && !userInfoState.nickname && !userInfoState.profileImageUrl) {
      fetchUserInfo();
    }
  }, []);

  return (
    <>
      {isLogin ? (
        <LoginWrapper>
          <ResponsiveWrapper>
            <Headertop>
              <UserName>
                {userInfoState && userInfoState.nickname}{" "}
                <div style={{ fontWeight: "normal" }}>님</div>
              </UserName>
              환영합니다
            </Headertop>
            <HeaderNavigationArea>
              <HeaderNavigationItem
                onClick={() => {
                  navigate("/mypage/home");
                  modifySidebarState();
                }}
              >
                {userInfoState && userInfoState.profileImageUrl ? (
                  <StyledUserIcon src={userInfoState.profileImageUrl} />
                ) : (
                  <StyledUserIcon src={userIcon} />
                )}
                마이페이지
              </HeaderNavigationItem>
              <HeaderNavigationItem
                onClick={() => {
                  navigate("/mypage/scrab/all");
                  modifySidebarState();
                }}
              >
                <StyledScrapIcon src={scrapIcon} />
                스크랩함
              </HeaderNavigationItem>
            </HeaderNavigationArea>
          </ResponsiveWrapper>
        </LoginWrapper>
      ) : (
        <NoLoginWrapper>
          <ResponsiveWrapper>
            <LoginButton onClick={() => navigate("/login")}>
              <StyledLoginIcon src={loginIcon}></StyledLoginIcon>로그인
            </LoginButton>
          </ResponsiveWrapper>
        </NoLoginWrapper>
      )}
    </>
  );
}

export default SidebarHeader;

const NoLoginWrapper = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  width: 100%;
  min-height: 73px;
  background-color: white;
`;

const LoginWrapper = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  width: 100%;
  min-height: 158px;
  background-color: white;
`;

const LoginButton = styled.div`
  display: flex;
  gap: 10px;
  font-size: 15px;
  letter-spacing: -0.05em;
  font-weight: 500;
`;

const StyledLoginIcon = styled.img`
  width: 17px;
  height: 17px;
`;

const Headertop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 15px;
  letter-spacing: -0.05em;
`;

const UserName = styled.div`
  display: flex;
  gap: 8px;
  font-size: 25px;
  font-weight: bold;
  letter-spacing: -0.04em;
`;

const HeaderNavigationArea = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 31.5px;
`;

const HeaderNavigationItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6.25px;
  font-size: 15px;
  cursor: pointer;
  letter-spacing: -0.05em;
  font-weight: 500;
`;

const StyledUserIcon = styled.img`
  width: 21px;
  height: 21px;
  border-radius: 50px;
`;

const StyledScrapIcon = styled.img`
  width: 16px;
  height: 16px;
`;
