import React, { useState } from "react";
import { styled } from "styled-components";
import PortalWrapper from "../../../components/PortalWrapper";
import map from "../../../assets/images/mansionPage/map.png";
import { IoClose } from "react-icons/io5";
import AdminChoiceAreaDataOptionBox from "./AdminChoiceAreaDataOptionBox";

const AdminChoiceArea = ({
  getChoiceAreaModalState,
}: {
  getChoiceAreaModalState: (newState: boolean) => void;
}) => {
  return (
    <PortalWrapper>
      <Container>
        <CloseButton
          onClick={() => getChoiceAreaModalState(false)}
          size={"30"}
          color={"gray"}
        />
        <InnerContentBox>
          <Title>지역을 선택해주세요</Title>
          <StyledImage src={map} />
          <AdminChoiceAreaDataOptionBox
            getChoiceAreaModalState={getChoiceAreaModalState}
          />
        </InnerContentBox>
      </Container>
    </PortalWrapper>
  );
};

export default AdminChoiceArea;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: white;
  width: 100%;
  height: 90vh;
  @media screen and (min-width: 1280px) {
    padding: 60px 45px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 45px 44px;
  }
  @media screen and (max-width: 1023px) {
    padding: 40px 20px;
  }
`;

const Title = styled.div`
  position: absolute;
  color: #00000060;
  @media screen and (min-width: 1280px) {
    top: 20px;
    left: 20px;
    font-size: large;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    top: 15px;
    left: 15px;
    font-size: medium;
  }
  @media screen and (max-width: 1023px) {
    top: 9px;
    left: 9px;
    font-size: medium;
    color: black;
  }
`;
const CloseButton = styled(IoClose)`
  position: absolute;
  cursor: pointer;
  @media screen and (min-width: 1280px) {
    top: 20px;
    right: 20px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    top: 15px;
    right: 15px;
  }
  @media screen and (max-width: 1023px) {
    top: 5px;
    right: 5px;
  }
`;

const StyledImage = styled.img`
  height: 100%;
  z-index: 10;
  border-radius: 10px;
  @media screen and (min-width: 1280px) {
    width: 700px;
    height: 700px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 522px;
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;

const InnerContentBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #dedede;
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 2%;
`;
const SecondInnerContentBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 1023px) {
    flex-direction: column;
    gap: 30px;
  }
`;
const Left = styled.div`
  width: 69%;
  height: 100%;
  border: 1px solid #dedede;
  background-color: white;
  border-radius: 10px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: none;
  }
  @media screen and (min-width: 1280px) {
    padding: 30px 25px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 30px 25px;
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
    padding: 20px 15px;
  }
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 29%;
  @media screen and (max-width: 1023px) {
    width: 100%;
    justify-content: flex-end;
  }
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  position: absolute;
  cursor: pointer;
  color: gray;
  border: transparent;
  background-color: transparent;
  span {
    font-size: 15px;
  }
  @media screen and (min-width: 1280px) {
    top: 20px;
    left: 20px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    top: 15px;
    left: 15px;
  }
  @media screen and (max-width: 1023px) {
    top: 5px;
    left: 5px;
  }
`;
