import { Outlet } from "react-router-dom";
import styled from "styled-components";
import AdminHeader from "./AdminHeader";


function AdminLayout() {
  return (
    <>
      <AdminHeader/>
      <Outlet />
    </>
  );
}

export default AdminLayout;
