export const getZoomLevel = (ratio:number) =>{
  if (ratio <= 2) {
    return 21;
  } else if (ratio <= 5) {
    return 20;
  } else if (ratio <= 10) {
    return 19;
  } else if (ratio <= 20) {
    return 18;
  } else if (ratio <= 50) {
    return 17;
  } else if (ratio <= 100) {
    return 16;
  } else if (ratio <= 200) {
    return 15;
  } else if (ratio <= 500) {
    return 14;
  } else if (ratio <= 1000) {
    return 13;
  } else if (ratio <= 2000) {
    return 12;
  } else if (ratio <= 5000) {
    return 11;
  } else if (ratio <= 10000) {
    return 10;
  } else if (ratio <= 20000) {
    return 9;
  } else if (ratio <= 50000) {
    return 8;
  } else if(ratio === 200001){
    return 6;
  } else {
    return 7; // 50,000 초과
  }
}