import styled from "styled-components";
import ResponsiveWrapper from "../../ResponsiveWrapper";
import searchIcon from "../../../assets/images/header/search-black.png";
import xIcon from "../../../assets/images/mainPage/x-icon.png";
import xIconGrary from "../../../assets/images/mainPage/x-icon-gray.png";
import { useMobileSearchState } from "../../../recoil/mainPge/mobileSearchStateAtom";
import { useEffect, useState } from "react";
import { useDebounceSearchvValue } from "../../../hooks/useDebounceSearchValue";
import axiosInstance from "../../../services/axiosConfig";
import { useNavigate } from "react-router-dom";
import { useRecentSearchDataState } from "../../../recoil/mainPge/recentSearchStateAtom";

function MobileSearchArea() {
  const navigate = useNavigate();
  const { modifyMobileSearchState } = useMobileSearchState();
  const {
    recentSearchDataState,
    updateRecentSearchData,
    setRecentSearchDataState,
    removeSearchData,
  } = useRecentSearchDataState();
  const [searchInputState, setSearchInputState] = useState<string>("");
  const [recommendListState, setRecommendListState] = useState<
    {
      id: number;
      keyword: string;
    }[]
  >([]);
  const debouncedInputValue = useDebounceSearchvValue(searchInputState, 500);

  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0) {
      setSearchInputState("");
      setRecommendListState([]);
    }
    setSearchInputState(e.target.value);
  };

  const fetchRecommendKeyword = async (keyword: string) => {
    const response: { data: { id: number; keyword: string }[] } =
      await axiosInstance.get("normal/home/search/keyword", {
        params: { keyword: keyword },
      });

    setRecommendListState(response.data);
  };

  useEffect(() => {
    if (debouncedInputValue && debouncedInputValue.length > 1) {
      fetchRecommendKeyword(debouncedInputValue);
    }
  }, [debouncedInputValue]);

  return (
    <ResponsiveWrapper>
      <Wrapper>
        <SearchDiv>
          <SearchInput
            placeholder="검색어를 입력해주세요"
            value={searchInputState}
            onChange={(e) => handleOnChangeInput(e)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (searchInputState.length > 1) {
                  navigate(`/search?keyword=${searchInputState}`, {
                    state: { keyword: searchInputState },
                  });
                  updateRecentSearchData(searchInputState);
                  modifyMobileSearchState();
                } else {
                  alert("검색어를 2글자 이상 입력해주세요");
                }
              }
            }}
          ></SearchInput>
          <GrayXIconDiv>
            {searchInputState && (
              <GrayXIcon
                src={xIconGrary}
                onClick={() => setSearchInputState("")}
              ></GrayXIcon>
            )}
          </GrayXIconDiv>
          <SearchIcon
            src={searchIcon}
            onClick={() => {
              if (searchInputState.length > 1) {
                navigate(`/search?keyword=${searchInputState}`, {
                  state: { keyword: searchInputState },
                });
                updateRecentSearchData(searchInputState);
                modifyMobileSearchState();
              } else {
                alert("검색어를 2글자 이상 입력해주세요");
              }
            }}
          ></SearchIcon>
        </SearchDiv>
        {recommendListState.length > 0 && (
          <RecommendSearchAreaWrapper>
            <RecommendSearchArea>
              {recommendListState.map((item, index) => (
                <RecommendSearchItem
                  onClick={() => {
                    navigate(`/search?keyword=${item.keyword}`, {
                      state: { id: item.id },
                    });
                    modifyMobileSearchState();
                    updateRecentSearchData(item.keyword);
                  }}
                >
                  {item.keyword}
                </RecommendSearchItem>
              ))}
            </RecommendSearchArea>
          </RecommendSearchAreaWrapper>
        )}
        <RecentSearchArea>
          <RecentSearchHeader>
            최근 검색어
            <DeleteAllButton onClick={() => setRecentSearchDataState([])}>
              전체 삭제 <StyledCloseImage src={xIcon} />
            </DeleteAllButton>
          </RecentSearchHeader>
        </RecentSearchArea>
        <RecentSearchItemArea>
          {recentSearchDataState.length > 0 ? (
            recentSearchDataState.map((item, index) => (
              <RecentSearchItem
                onClick={() => {
                  navigate(`/search?keyword=${item}`, {
                    state: { keyword: item },
                  });
                  modifyMobileSearchState();
                }}
              >
                {item}
                <StyledCloseImage
                  src={xIcon}
                  width={8}
                  height={8}
                  onClick={(e) => {
                    e.stopPropagation();
                    removeSearchData(index);
                  }}
                />
              </RecentSearchItem>
            ))
          ) : (
            <NoRecentSearchDiv>최근 검색어가 없습니다.</NoRecentSearchDiv>
          )}
        </RecentSearchItemArea>
        <RecommandSearchArea>
          <RecommandSearchHeader>추천 검색어</RecommandSearchHeader>
          <RecommandSearchItemArea>
            {recommendListState.map((item, index) => (
              <RecommandSearchItem
                key={index}
                onClick={() => {
                  navigate(`/search?keyword=${item.keyword}`, {
                    state: { id: item.id },
                  });
                  modifyMobileSearchState();
                  updateRecentSearchData(item.keyword);
                }}
              >
                {item.keyword}
              </RecommandSearchItem>
            ))}
          </RecommandSearchItemArea>
        </RecommandSearchArea>
      </Wrapper>
    </ResponsiveWrapper>
  );
}

export default MobileSearchArea;

const Wrapper = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SearchDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 18px;
  width: 100%;
  height: 46px;
  padding: 5px 24px 5px 25px;
  background-color: #f2f2f2;
  border-radius: 50px;
`;

const SearchInput = styled.input`
  height: 100%;
  width: 279px;
  box-sizing: border-box;
  border: none;
  background-color: #f2f2f2;
  font-size: 15px;
  font-family: "Noto Sans KR";
  outline: none;
  padding-right: 10px;
  font-weight: 500;
`;

const SearchIcon = styled.img`
  width: 23px;
  height: 23px;
  margin-left: 15px;
`;

const GrayXIcon = styled.img`
  width: 19px;
  height: 19px;
`;

const GrayXIconDiv = styled.div`
  width: 19px;
  height: 19px;
`;

const RecentSearchArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30.5px;
`;

const RecentSearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
`;

const DeleteAllButton = styled.div`
  display: flex;
  align-items: center;
  gap: 3.5px;
  color: #777777;
  font-size: 14px;
  font-weight: 500;
`;

const StyledCloseImage = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  width: 9px;
  height: 9px;
`;

const RecentSearchItemArea = styled.div`
  display: flex;
  gap: 9px;
  flex-wrap: wrap;
  padding: 23px 0 34px;
  border-bottom: 1px solid #c9c9c9;
`;

const RecentSearchItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-size: 12px;
  min-width: 87.5px;
  height: 30px;
  padding: 0 10px;
  color: #777777;
  border: 1px solid black;
  border-radius: 5px;
  font-weight: 500;
`;

const RecommandSearchArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30.5px;
`;

const RecommandSearchHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
`;

const RecommandSearchItemArea = styled.div`
  display: flex;
  gap: 9px;
  flex-wrap: wrap;
  padding: 23px 0 34px;
`;

const RecommandSearchItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  min-width: 79px;
  height: 30px;
  padding: 0 17px;
  color: #99393a;
  border: 1px solid #99393a;
  border-radius: 5px;
  font-weight: 500;
`;

const RecommendSearchAreaWrapper = styled.div`
  border-radius: 20px;
  overflow: hidden;
  border: 2px solid #eeeeee;
  margin-top: 10px;
`;

const RecommendSearchArea = styled.div`
  background-color: white;
  z-index: 100000;
  padding: 16px 0;
  max-height: 150px;
  overflow-y: auto;
`;

const RecommendSearchItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 23px;
  gap: 6px;
  min-height: 30px;
  font-size: 15px;
  font-weight: 500;
  &:hover {
    background-color: #f3f3f3;
  }
`;

const NoRecentSearchDiv = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 15px;
  color: #777777;
  font-weight: 500;
`;
