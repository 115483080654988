import styled from "styled-components";
import ResponsiveWrapper from "../../ResponsiveWrapper";
import { useNavigate } from "react-router-dom";
import languageIcon from "../../../assets/images/header/global.png";
import arrowIcon from "../../../assets/images/ResidentReview/DownIcon.png";

function SidebarFooter() {
  const isLogin = sessionStorage.getItem("Authorization");
  const navigate = useNavigate();

  return (
    <ResponsiveWrapper>
      <Wrapper>
        <UtilityWrapper>
          <LanguageDiv>
            언어변경
            <ChangeLanguageButton>
              <StyledLanguageIcon src={languageIcon} />
              한국어
              <StyledArrowIcon src={arrowIcon} />
            </ChangeLanguageButton>
          </LanguageDiv>
          {isLogin && (
            <LogoutDiv
              onClick={() => {
                if (isLogin) {
                  let logoutConfirm = window.confirm("로그아웃하시겠습니까?");
                  if (logoutConfirm) {
                    window.sessionStorage.removeItem("Authorization");
                    window.sessionStorage.removeItem("RefreshToken");
                    alert("로그아웃 되었습니다.");
                    navigate("/");
                  }
                }
              }}
            >
              로그아웃
            </LogoutDiv>
          )}
        </UtilityWrapper>
      </Wrapper>
    </ResponsiveWrapper>
  );
}

export default SidebarFooter;

const Wrapper = styled.header`
  width: 100%;
  height: 112px;
`;

const UtilityWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 26.5px;
  width: 100%;
  font-size: 15px;
`;

const LanguageDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: bold;
`;

const ChangeLanguageButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 106px;
  height: 30px;
  border: 1px solid black;
  border-radius: 7px;
  font-family: "Noto Sans KR";
  letter-spacing: -0.05em;
  font-size: 15px;
  background-color: white;
  gap: 5px;
`;

const LogoutDiv = styled.div``;

const StyledLanguageIcon = styled.img`
  width: 14px;
  height: 14px;
`;

const StyledArrowIcon = styled.img`
  width: 8.5px;
  height: 4px;
`;
