import styled from "styled-components";
import InformationBox from "./InformationBox";
import { roomDetailType } from "../Detail";
import houseIcon from "../../../assets/images/popularMansion/house.png";

function DetailMansionExplain({
  roomDetailState,
}: {
  roomDetailState?: roomDetailType;
}) {
  return (
    <DetailExplainWrapper>
      <InformationBox
        decoration={true}
        width={{ pc: "100%", laptop: "100%", mobile: "100%" }}
      >
        <DetailExplainInner>
          <ExplainTop>
            <ExplainIcon src={houseIcon} />
            <ExplainTitle>맨션 설명</ExplainTitle>
          </ExplainTop>
          <ExplainContents>{roomDetailState?.description}</ExplainContents>
        </DetailExplainInner>
      </InformationBox>
    </DetailExplainWrapper>
  );
}

export default DetailMansionExplain;

const DetailExplainWrapper = styled.div`
  display: flex;
  width: 100%;
  @media screen and (min-width: 1280px) {
    margin-top: 56px;
    padding-bottom: 100px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 53px;
    padding-bottom: 90px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 35px;
    padding-bottom: 60px;
  }
`;
const DetailExplainInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (min-width: 1280px) {
    padding: 32px 29px;
    gap: 25px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 29px 27px;
    gap: 25px;
  }
  @media screen and (max-width: 1023px) {
    padding: 24px 22px;
    gap: 24px;
  }
`;

const ExplainTop = styled.div`
  display: flex;
  @media screen and (min-width: 1280px) {
    gap: 5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 5px;
  }
  @media screen and (max-width: 1023px) {
    gap: 7px;
  }
`;

const ExplainIcon = styled.img`
  @media screen and (min-width: 1280px) {
    width: 27px;
    height: 27px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 27px;
    height: 26px;
  }
  @media screen and (max-width: 1023px) {
    width: 22px;
    height: 23px;
  }
`;

const ExplainTitle = styled.div`
  font-weight: 600;
  @media screen and (min-width: 1280px) {
    font-size: 23px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 20px;
  }
`;

const ExplainContents = styled.div`
  white-space: pre-line;
  @media screen and (min-width: 1280px) {
    font-size: 19px;
    line-height: 25px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 19px;
    line-height: 25px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 14px;
    line-height: 20px;
  }
`;
