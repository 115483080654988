import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";


// 드롭다운 버튼 스타일
const DropdownButton = styled.button`
  padding: 12px 20px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
  font-size: 23px;
  color: #555555;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
    padding: 13px;
  }

  &::after {
    content: "";
    display: inline-block;
    margin-left: 8px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #000; /* 화살표 색상 */
    transform: translateY(2px); /* 화살표 위치 조정 */
  }
`;

// 드롭다운 메뉴 스타일
const DropdownMenu = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? "block" : "none")};
  position: absolute;
  top: 110%;
  left: 0;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 1;
  min-width: 100%;
  overflow: hidden;
  font-weight: 500;
`;

// 드롭다운 항목 스타일
const DropdownItem = styled.div<{ selected: boolean }>`
  padding: 12px 20px;
  cursor: pointer;
  font-size: 23px;
  font-weight: 500;
  color: #555555;
  background-color: ${(props) => (props.selected ? "#fce8d8" : "#ffffff")};
  &:hover {
    background-color: #f1f1f1;
  }
  &:first-child {
    border-radius: 10px 10px 0 0; /* 첫 번째 항목 모서리 둥글게 */
  }
  &:last-child {
    border-radius: 0 0 10px 10px; /* 마지막 항목 모서리 둥글게 */
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
    padding: 13px;
  }
`;

interface Option {
  id: number;
  label: string;
}

interface DropdownProps {
  options: Option[];
  selectedOption: Option | null;
  onOptionSelect: (option: Option) => void;
  placeholder?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  selectedOption,
  onOptionSelect,
  placeholder = "선택해주세요",
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setShowMenu((prev) => !prev);

  const handleOptionClick = (option: Option) => {
    onOptionSelect(option);
    setShowMenu(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    if (showMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu]);

  return (
    <>
        <DropdownButton onClick={toggleDropdown}>
          {selectedOption ? selectedOption.label : placeholder}
        </DropdownButton>
        <DropdownMenu show={showMenu}>
          {options.map((option) => (
            <DropdownItem
              key={option.id}
              selected={option.id === selectedOption?.id}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
    </>
  );
};

export default Dropdown;
