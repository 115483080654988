import { create } from 'zustand';

interface ItemType {
  id: number;
  name: string;
}
interface Prop {
  returnItems: any[];
  items: ItemType[];
  add: (data: any[]) => void;
  init: () => void;
  del: (name: string, relate: 'top' | 'parent' | 'name') => void;
  setItems: (data: ItemType[]) => void;
  selectAll: (select: boolean, data: ItemType[]) => void;
}

const useCheckItems = create<Prop>((set, get) => ({
  returnItems: [],
  items: [],
  add: (data: any) =>
    set((prev) => ({ returnItems: [...prev.returnItems, ...data] })),
  del: (name: string, relate: 'top' | 'parent' | 'name') =>
    set((prev) => ({
      returnItems: prev.returnItems.filter((item) => item[relate] !== name),
    })),
  init: () => set(() => ({ returnItems: [], items: [] })),
  setItems: (data: ItemType[]) =>
    set(() => ({
      items: data,
    })),
  selectAll: (select: boolean, data: ItemType[]) => {
    const { returnItems } = get();
    if (select) {
      const newItems = data.filter(
        (item) =>
          !returnItems.some((existingItem) => existingItem.name === item.name)
      );
      set((prev) => ({
        returnItems: [...prev.returnItems, ...newItems],
      }));
    } else {
      set(() => ({
        returnItems: returnItems.filter(
          (item) => !data.some((modalItem) => modalItem.name === item.name)
        ),
      }));
    }
  },
}));


export default useCheckItems;
