import styled from "styled-components";
import global from "../../../assets/images/header/global.png";
import useDeviceSize from "../../../hooks/useDeviceSize";
import { useNavigate } from "react-router-dom";
import arrowIcon from "../../../assets/images/ResidentReview/DownIcon.png";

function HeaderTop() {
  const token = window.sessionStorage.getItem("Authorization");
  const { isPC, isLaptop, isMobile } = useDeviceSize();
  const navigate = useNavigate();
  return (
    <HeaderTopWrapper>
      <HeaderTopInner>
        <UtilityBox>
          <LoginLogout
            onClick={() => {
              if (token) {
                let logoutConfirm = window.confirm("로그아웃하시겠습니까?");
                if (logoutConfirm) {
                  window.sessionStorage.removeItem("Authorization");
                  window.sessionStorage.removeItem("RefreshToken");
                  alert("로그아웃 되었습니다.");
                  navigate("/");
                }
              } else {
                navigate("/login");
              }
            }}
          >
            {token ? "로그아웃" : "로그인/가입"}
          </LoginLogout>
          <LanguageSelectButton>
            <StyledImage src={global} width={isPC ? 13 : 11} />
            한국어
            <StyledArrowIcon src={arrowIcon}></StyledArrowIcon>
          </LanguageSelectButton>
        </UtilityBox>
      </HeaderTopInner>
    </HeaderTopWrapper>
  );
}

export default HeaderTop;

const HeaderTopWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  border-bottom: 1px solid #a4a4a4;

  @media screen and (min-width: 1280px) {
    height: 53px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    height: 50px;
  }
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  @media screen and (min-width: 1280px) {
    width: 1280px;
    padding: 0 60px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 1024px;
    padding: 0 45px;
  }
  @media screen and (max-width: 1023px) {
    width: 425px;
  }
`;

const HeaderTopInner = styled(HeaderBox)`
  justify-content: flex-end;
`;

const RechoiceArea = styled.a`
  cursor: pointer;
  text-decoration: underline;

  @media screen and (min-width: 1280px) {
    font-size: 16px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 15px;
  }
`;

const UtilityBox = styled.div`
  display: flex;
  align-items: center;

  height: 100%;
  @media screen and (min-width: 1280px) {
    gap: 22px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 11px;
  }
`;

const LoginLogout = styled.a`
  cursor: pointer;
  letter-spacing: -0.05em;
  @media screen and (min-width: 1280px) {
    font-size: 16px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 15px;
  }
`;

const LanguageSelectButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #ffffff;
  font-family: "Noto Sans KR";
  cursor: pointer;
  letter-spacing: -0.05em;
  border: 1px solid black;
  @media screen and (min-width: 1280px) {
    width: 103px;
    height: 31px;
    font-size: 16px;
    gap: 6px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 86px;
    height: 26px;
    font-size: 15px;
    gap: 5px;
  }
`;

const StyledImage = styled.img`
  display: block;
`;

const StyledArrowIcon = styled.img`
  @media screen and (min-width: 1280px) {
    width: 8px;
    height: 4px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 6.5px;
    height: 3.25px;
  }
`;
