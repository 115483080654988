import styled from "styled-components";
import HeaderTop from "./HeaderComponents/HeaderTop";
import HeaderContents from "./HeaderComponents/HeaderContents";
import HeaderBottom from "./HeaderComponents/HeaderBottom";

function Header() {
  return (
    <Wrapper>
      <HeaderTop />
      <HeaderContents  />
      <HeaderBottom />
    </Wrapper>
  );
}

export default Header;

const Wrapper = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  padding: 0 45px;
  height: 100%;
  @media screen and (min-width: 1280px) {
    width: 1280px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 1024px;
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;
