import { atom, useRecoilState } from "recoil";

export type MansionOptionsType = {
  freeInternet: boolean;
  petsAllowed: boolean;
  morePeople: boolean;
  designerMention: boolean;
  duplexMention: boolean;
  closeToStation: boolean;
  induction: boolean;
  gasRange: boolean;
  allElectricMansion: boolean;
  cityGas: boolean;
  indoorWashingBoard: boolean;
  outdoorWashingBoard: boolean;
  bidet: boolean;
  standAloneToilet: boolean;
  unitBath: boolean;
  bathroomDryer: boolean;
  airConditioning: boolean;
  storageSpace: boolean;
  trashDisposal24H: boolean;
  garbageStorage: boolean;
  intercom: boolean;
  deliveryBox: boolean;
  carParking: boolean;
  elevator: boolean;
  bicycleParking: boolean;
  bikeParking: boolean;
  womenOnly: boolean;
  mensOnly: boolean;
  autoLock: boolean;
  cctv: boolean;
  foreignerAllowed: boolean;
  overseasScreeningAvailable: boolean;
  furnitureOptions: boolean;
};

export type registrationDataType = {
  selectedCategory: number;
  name: string;
  agency: string;
  structedYear: string;
  thumbnailImage: File | null;
  fileUrl: string;
  mansionExplain: string;
  point1: string;
  point2: string;
  point3: string;
  mansionOptionsState: MansionOptionsType;
  zipCode: string;
  address: string;
  additionalAddress: string;
  latitude: string;
  longitude: string;
  totalFloors: string;
  closeStation: {
    stationId: number;
    timeConsumed: number;
    stationKName: string;
  }[];
  districtId: number;
  districtKName: string;
};

const defaultValue: registrationDataType = {
  selectedCategory: 0,
  name: "",
  agency: "",
  structedYear: "",
  thumbnailImage: null,
  fileUrl: "",
  mansionExplain: "",
  point1: "",
  point2: "",
  point3: "",
  totalFloors: "",
  mansionOptionsState: {
    freeInternet: false,
    petsAllowed: false,
    morePeople: false,
    designerMention: false,
    duplexMention: false,
    closeToStation: false,
    induction: false,
    gasRange: false,
    allElectricMansion: false,
    cityGas: false,
    indoorWashingBoard: false,
    outdoorWashingBoard: false,
    bidet: false,
    standAloneToilet: false,
    unitBath: false,
    bathroomDryer: false,
    airConditioning: false,
    storageSpace: false,
    trashDisposal24H: false,
    garbageStorage: false,
    intercom: false,
    deliveryBox: false,
    carParking: false,
    elevator: false,
    bicycleParking: false,
    bikeParking: false,
    womenOnly: false,
    mensOnly: false,
    autoLock: false,
    cctv: false,
    foreignerAllowed: false,
    overseasScreeningAvailable: false,
    furnitureOptions: false,
  },
  zipCode: "",
  address: "",
  additionalAddress: "",
  latitude: "",
  longitude: "",
  closeStation: [],
  districtId: 0,
  districtKName: "",
};
export const adminRegistrationStateAtom = atom<registrationDataType>({
  key: "adminRegistrationState",
  default: defaultValue,
});

export const useAdminRegistrationState = () => {
  const [registrationDataState, setRegistrationDataState] = useRecoilState(
    adminRegistrationStateAtom
  );

  const updateRegistrationDataOptions = (
    optionKey: keyof MansionOptionsType,
    value: boolean
  ) => {
    setRegistrationDataState((prevState) => ({
      ...prevState,
      mansionOptionsState: {
        ...prevState.mansionOptionsState,
        [optionKey]: value,
      },
    }));
  };

  const resetRegistrationDataState = () => {
    setRegistrationDataState(defaultValue);
  };

  return {
    registrationDataState,
    setRegistrationDataState,
    resetRegistrationDataState,
    updateRegistrationDataOptions,
  };
};
