import React, { useState } from "react";
import styled, { createGlobalStyle, css } from "styled-components";
import { Outlet, Route, Routes } from "react-router-dom";
import TAB from "./components/TAB";

const Line = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #ddd;
`;


const MyPage: React.FC = () => {
  let [tab, setTab] = useState(0);

  return (
    <>
      <TAB tab={tab} setTab={setTab} />
      <Line />
      <Outlet></Outlet>
    </>
  );
};

export default MyPage;
