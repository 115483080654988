import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

// 드롭다운 컨테이너 스타일
const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

// 드롭다운 버튼 스타일
// 드롭다운 버튼 스타일
const DropdownButton = styled.button`
  padding: 8px 16px;
  background-color: #F7F9FA;
  border: 0px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight:bold;
  display: flex;
  align-items: center;
  justify-content: center; /* 가운데 정렬 */
  position: relative;
  color: #666666;

  &::after {
    content: '';
    display: inline-block;
    margin-left: 8px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #C1C1C1; /* 화살표 색상 */
    transform: translateY(2px); /* 화살표 위치 조정 */
  }
`;

// 드롭다운 메뉴 스타일
const DropdownMenu = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  top: 0px;
  left: 90px;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 10px; 
  z-index: 1;
  min-width: 115px;
  overflow: hidden; 
`;

// 드롭다운 항목 스타일
const DropdownItem = styled.div<{ selected: boolean }>`
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  text-align: center; /* 글자 가운데 정렬 */
  background-color: ${(props) => (props.selected ? '#fce8d8' : '#ffffff')};
  &:hover {
    background-color: #f1f1f1;
  }
  &:first-child {
    border-radius: 10px 10px 0 0; /* 첫 번째 항목 모서리 둥글게 */
  }
  &:last-child {
    border-radius: 0 0 10px 10px; /* 마지막 항목 모서리 둥글게 */
  }
`;


const options = [
  { id: 1, label: '최신순' },
  { id: 2, label: '과거순' },
  { id: 3, label: '확인' },
  { id: 4, label: '미확인' },
];

interface DropdownProps {
  selectedOption: typeof options[0];
  onOptionSelect: (option: typeof options[0]) => void;
}

const Dropdown: React.FC<DropdownProps> = ({ selectedOption, onOptionSelect }) => {
  const [showMenu, setShowMenu] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setShowMenu((prev) => !prev);

  const handleOptionClick = (option: typeof options[0]) => {
    onOptionSelect(option);
    setShowMenu(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef}>
       <DropdownButton onClick={toggleDropdown}>
        정렬 
      </DropdownButton>
      <DropdownMenu show={showMenu}>
        {options.map((option) => (
          <DropdownItem
            key={option.id}
            selected={option.id === selectedOption.id}
            onClick={() => handleOptionClick(option)}
          >
            {option.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </DropdownContainer>
  );
};

export default Dropdown;
