import { atom, useRecoilState } from "recoil";
import { fileDataType } from "../../pages/adminMansionRegistration/components/RoomRegistrationImage";
export type registrationRoomDataType = {
  roomNumber: string;
  status: "Public" | "Temp";
  roomType: string;
  floorPlan: fileDataType;
  roomImg: fileDataType[];
  deletedTargetImgId: number[];
  addRoomImg: fileDataType[];

  area: string;
  numOfFloors: string;
  contractTerm: number;
  structure: "woodcarving" | "rebar" | "ironFrame" | "other" | "non";
  penaltyOption:
    | "해당없음"
    | "6개월 이내"
    | "1년 이내"
    | "1년 6개월 이내"
    | "2년 이내"
    | "2년 6개월 이내"
    | "3년 이내";
  penaltyAmount:
    | "해당없음"
    | "1개월"
    | "2개월"
    | "3개월"
    | "4개월"
    | "5개월"
    | "6개월";
  rent: string;
  direction:
    | "해당없음"
    | "동향"
    | "서향"
    | "남향"
    | "북향"
    | "동남향"
    | "북동향"
    | "서남향"
    | "북서향";
  managementFee: string;
  deposit: string;
  gratuity: string;
  keyExchangeFee: string;
  fireInsuranceCost: string;
  guaranteeReviewFee: string;
  brokerageFee: string;
  moreFixedCost: { key: string; value: string }[];
  moreInitialCost: { key: string; value: string }[];
  moreInfo: { key: string; value: string }[];
};

const defautValue: registrationRoomDataType = {
  roomNumber: "",
  status: "Temp",
  roomType: "r1",
  floorPlan: {} as fileDataType,
  roomImg: [] as fileDataType[],
  addRoomImg: [],
  deletedTargetImgId: [],
  area: "",
  numOfFloors: "",
  contractTerm: 6,
  structure: "woodcarving",
  penaltyAmount: "1개월",
  penaltyOption: "6개월 이내",
  rent: "",
  direction: "동향",
  managementFee: "",
  deposit: "",
  gratuity: "",
  keyExchangeFee: "",
  fireInsuranceCost: "",
  guaranteeReviewFee: "",
  brokerageFee: "",
  moreFixedCost: [],
  moreInitialCost: [],
  moreInfo: [],
};

export const adminRegistrationRoomStateAtom = atom<registrationRoomDataType[]>({
  key: "adminRegistrationRoomState",
  default: [defautValue],
});

export const useAdminRegistrationRoomState = () => {
  const [adminRegistrationRoomState, setAdminRegistrationRoomState] =
    useRecoilState(adminRegistrationRoomStateAtom);

  const handleAddRoom = () => {
    setAdminRegistrationRoomState([...adminRegistrationRoomState, defautValue]);
  };

  const handleRemoveRoom = (index: number) => {
    setAdminRegistrationRoomState(
      adminRegistrationRoomState.filter((_, i) => i !== index)
    );
  };

  const handleUpdateRoom = (
    index: number,
    updatedRoomData: registrationRoomDataType
  ) => {
    const updatedRooms = [...adminRegistrationRoomState];
    updatedRooms[index] = updatedRoomData;
    setAdminRegistrationRoomState(updatedRooms);
  };

  const handleResetRoom = () => {
    setAdminRegistrationRoomState([defautValue]);
  };

  return {
    adminRegistrationRoomState,
    handleAddRoom,
    handleRemoveRoom,
    handleUpdateRoom,
    handleResetRoom,
  };
};
