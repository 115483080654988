import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PRHeader from "./components/PostReviewHeader";
import ImageUpload from "./components/ImageUpload";
import CategoryContainer from "./components/CategoryContainer";
import PostContents from "./components/PostContents";
import axiosInstance from "../../../services/axiosConfig";
import { useParams, useNavigate } from "react-router-dom";

interface ImageType {
  id: number;
  url: string;
}

type CombinedImageType =
  | { type: "url"; data: ImageType }
  | { type: "file"; data: File };

const PostReview: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const numericId = id ? Number(id) : undefined;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [tumbnail, setThumbnail] = useState<string>("");
  const [buildingType, setBuildingType] = useState<string>("");
  const [residentsCount, setResidentsCount] = useState<string>("");
  const [roomType, setRoomType] = useState<string>("");
  const [districtId, setDistrictId] = useState<number | null>(null);
  const [keyword, setKeyword] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [textAreaValue, setTextAreaValue] = useState<string>("");
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [keywordArray, setKeywordArray] = useState<string[]>([]);
  const [imageUrls, setImageUrls] = useState<ImageType[]>([]);
  const [combinedImages, setCombinedImages] = useState<CombinedImageType[]>([]);
  const [deletedImageIndices, setDeletedImageIndices] = useState<number[]>([]);
  const [hashtags, setHashtags] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const fetchReview = async () => {
        try {
          const res = await axiosInstance.get(
            `https://houberapp.com/admin/review/detail/${id}`
          );
          const data = res.data;
          setThumbnail(data.thumbnail);
          setTitle(data.title);
          setTextAreaValue(data.content);
          setBuildingType(data.buildingType);
          if (data.residentsCount !== null) {
            setResidentsCount(data.residentsCount);
          }
          setRoomType(data.roomType);
          setDistrictId(data.districtId);
          setKeywordArray(data.keyword);
          setImageUrls(
            data.reviewImg.map((img: { id: string; url: string }) => ({
              id: parseInt(img.id, 10),
              url: img.url,
            }))
          );
        } catch (error) {
        }
      };
      fetchReview();
    }
  }, [id]);

  useEffect(() => {
    const combined: CombinedImageType[] = [
      ...imageUrls.map((img) => ({ type: "url", data: img } as const)),
      ...selectedFiles.map((file) => ({ type: "file", data: file } as const)),
    ];
    setCombinedImages(combined);
  }, [imageUrls, selectedFiles]);

  const handleImageSelect = (file: File | null) => {
    setSelectedImage(file);
  };

  const handleFileChange = (files: File[]) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleDeleteImage = (index: number) => {
    const item = combinedImages[index];

    if (item.type === "url" && item.data.id !== undefined) {
      setImageUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
      setDeletedImageIndices((prev) => [...prev, item.data.id]);
    } else if (item.type === "file") {
      const fileIndex = index - imageUrls.length;
      setSelectedFiles((prevFiles) =>
        prevFiles.filter((_, i) => i !== fileIndex)
      );
    }

    setCombinedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const saveDraft = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      if (selectedImage) formData.append("thumbnail", selectedImage);
      formData.append("buildingType", buildingType || "");
      formData.append("residentsCount", residentsCount || "");
      formData.append("roomType", roomType || "");
      if (districtId !== 0) {
        formData.append("districtId", districtId?.toString() || " ");
      }
      formData.append("keyword", keyword || "");
      formData.append("title", title || "");
      formData.append("saveStatus", "0"); 
      formData.append("content", textAreaValue || "");
      selectedFiles.forEach((file) => {
        formData.append("reviewImg", file);
      });
      formData.append("deleteImgIds", deletedImageIndices.join(","));

      const endpoint = numericId
        ? `https://houberapp.com/admin/review/update/${numericId}`
        : "https://houberapp.com/admin/review/";
      const method = numericId ? "put" : "post"; 

      const res = await axiosInstance({
        method: method,
        url: endpoint,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.status === 200) {
        alert("임시저장 성공!");
        navigate(-1);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const publishPost = async () => {
    if (hashtags.length >= 5) {
      setIsLoading(true);
      try {
        const formData = new FormData();
        if (selectedImage) formData.append("thumbnail", selectedImage);
        formData.append("buildingType", buildingType);
        formData.append("residentsCount", `${residentsCount}`);
        formData.append("roomType", roomType || "");
        if (districtId !== 0) {
          formData.append("districtId", districtId?.toString() || " ");
        }
        formData.append("keyword", keyword);
        formData.append("title", title);
        formData.append("saveStatus", "1"); 
        formData.append("content", textAreaValue);
        selectedFiles.forEach((file) => {
          formData.append("reviewImg", file);
        });
        formData.append("deleteImgIds", deletedImageIndices.join(","));

        const endpoint = numericId
          ? `https://houberapp.com/admin/review/update/${numericId}`
          : "https://houberapp.com/admin/review/";
        const method = numericId ? "put" : "post"; 

        const res = await axiosInstance({
          method: method,
          url: endpoint,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.status === 200) {
          alert("등록 성공!");
          navigate(-1);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    } else {
      alert('해시태그 5개 이상 작성해주세요.');
    }
  };

  return (
    <ResidentReviewWrapper>
      <Container>
        {isLoading && (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        )}
        <PRHeader onSaveDraft={saveDraft} onPublishPost={publishPost} />
        <ImageUpload onImageSelect={handleImageSelect} tumbnail={tumbnail} />
        <CategoryContainer
          buildingType={buildingType}
          setBuildingType={setBuildingType}
          residentsCount={residentsCount}
          setResidentsCount={setResidentsCount}
          roomType={roomType}
          setRoomType={setRoomType}
          districtId={districtId}
          setDistrictId={setDistrictId}
          keyword={keyword}
          keywordArray={keywordArray}
          setKeyword={setKeyword}
          hashtags={hashtags}
          setHashtags={setHashtags}
        />
        <PostContents
          title={title}
          setTitle={setTitle}
          textAreaValue={textAreaValue}
          setTextAreaValue={setTextAreaValue}
          onFileChange={handleFileChange}
          onDeleteImage={handleDeleteImage}
          combinedImages={combinedImages}
          imgCount={imageUrls.length}
        />
      </Container>
    </ResidentReviewWrapper>
  );
};

export default PostReview;

const ResidentReviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #edeef1;
`;

const Container = styled.div`
  width: 1323px;
  margin-top: 50px;
  background-color: white;
  padding-bottom: 100px;
`;

const Spinner = styled.div`
  border: 8px solid #f3f3f3;
  border-top: 8px solid #99393a; 
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const SpinnerWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
`;


// import React, { useState, useEffect } from "react";
// import styled from "styled-components";
// import PRHeader from "./components/PostReviewHeader";
// import ImageUpload from "./components/ImageUpload";
// import CategoryContainer from "./components/CategoryContainer";
// import PostContents from "./components/PostContents";
// import axios from "axios";
// import { useParams } from "react-router-dom";
// import axiosInstance from "../../../services/axiosConfig";
// import { useNavigate } from "react-router-dom";
// interface ImageType {
//   id: number;
//   url: string;
// }

// type CombinedImageType =
//   | { type: "url"; data: ImageType }
//   | { type: "file"; data: File };

// const PostReview = () => {
//   const { id } = useParams<{ id: string }>();
//   const numericId = id ? Number(id) : undefined;

//   const [selectedImage, setSelectedImage] = useState<File | null>(null);
//   const [tumbnail, setThumbnail] = useState<string>("");
//   const [buildingType, setBuildingType] = useState<string>("");
//   const [residentsCount, setResidentsCount] = useState<string>("");
//   const [roomType, setRoomType] = useState<string>("");
//   const [districtId, setDistrictId] = useState<number | null>(null);
//   const [keyword, setKeyword] = useState<string>("");
//   const [title, setTitle] = useState<string>("");
//   const [textAreaValue, setTextAreaValue] = useState<string>("");
//   const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
//   const [keywordArray, setKeywordArray] = useState<string[]>([]);
//   const [imageUrls, setImageUrls] = useState<ImageType[]>([]);
//   const [combinedImages, setCombinedImages] = useState<CombinedImageType[]>([]);
//   const [deletedImageIndices, setDeletedImageIndices] = useState<number[]>([]);
//   const [hashtags, setHashtags] = useState<string[]>([]);
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (id) {
//       const fetchReview = async () => {
//         try {
//           const res = await axiosInstance.get(
//             `https://houberapp.com/admin/review/detail/${id}`,

//           );
//           console.log(res);
//           const data = res.data;
//           setThumbnail(data.thumbnail);
//           setTitle(data.title);
//           setTextAreaValue(data.content);
//           setBuildingType(data.buildingType);
//           if(data.residentsCount !==null){
//           setResidentsCount(data.residentsCount);
//           }
//           setRoomType(data.roomType);
//           setDistrictId(data.districtId);
//           setKeywordArray(data.keyword);
//           setImageUrls(
//             data.reviewImg.map((img: { id: string; url: string }) => ({
//               id: parseInt(img.id, 10),
//               url: img.url,
//             }))
//           );
//         } catch (error) {
//           console.error(error);
//         }
//       };
//       fetchReview();
//     }
//   }, [id]);

//   useEffect(() => {
//     const combined: CombinedImageType[] = [
//       ...imageUrls.map((img) => ({ type: "url", data: img } as const)),
//       ...selectedFiles.map((file) => ({ type: "file", data: file } as const)),
//     ];
//     setCombinedImages(combined);
//   }, [imageUrls, selectedFiles]);

//   const handleImageSelect = (file: File | null) => {
//     setSelectedImage(file);
//   };

//   const handleFileChange = (files: File[]) => {
//     setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
//   };

//   const handleDeleteImage = (index: number) => {
//     const item = combinedImages[index];

//     if (item.type === "url" && item.data.id !== undefined) {
//       setImageUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
//       setDeletedImageIndices((prev) => [...prev, item.data.id]);
//     } else if (item.type === "file") {
//       const fileIndex = index - imageUrls.length;
//       setSelectedFiles((prevFiles) =>
//         prevFiles.filter((_, i) => i !== fileIndex)
//       );
//     }

//     setCombinedImages((prevImages) => prevImages.filter((_, i) => i !== index));
//   };

//   console.log(deletedImageIndices);

//   const saveDraft = async () => {
//     try {
//       const formData = new FormData();
//       if (selectedImage) formData.append("thumbnail", selectedImage);
//       formData.append("buildingType", buildingType || "");
//       formData.append("residentsCount", residentsCount || "");
//       formData.append("roomType", roomType || "");
//       if(districtId !== 0){
//       formData.append("districtId", districtId?.toString() || " ");
//       }
//       formData.append("keyword", keyword || "");
//       formData.append("title", title || "");
//       formData.append("saveStatus", "0"); 
//       formData.append("content", textAreaValue || "");
//       selectedFiles.forEach((file) => {
//         formData.append("reviewImg", file);
//       });
//       formData.append("deleteImgIds", deletedImageIndices.join(","));
  
//       const endpoint = numericId
//         ? `https://houberapp.com/admin/review/update/${numericId}`
//         : "https://houberapp.com/admin/review/";
//       const method = numericId ? "put" : "post"; 
  
//       const res = await axiosInstance({
//         method: method,
//         url: endpoint,
//         data: formData,
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
  
//       if (res.status === 200) {
//         alert("임시저장 성공!");
//         navigate(-1);
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };
  

// const publishPost = async () => {
//   if (hashtags.length >= 5) {
//     try {
//       const formData = new FormData();
//       if (selectedImage) formData.append("thumbnail", selectedImage);
//       formData.append("buildingType", buildingType);
//       formData.append("residentsCount", `${residentsCount}`);
//       formData.append("roomType", roomType || "");
//       if(districtId !== 0){
//         formData.append("districtId", districtId?.toString() || " ");
//         }
//       formData.append("keyword", keyword);
//       formData.append("title", title);
//       formData.append("saveStatus", "1");
//       formData.append("content", textAreaValue);
//       selectedFiles.forEach((file) => {
//         formData.append("reviewImg", file);
//       });
//       formData.append("deleteImgIds", deletedImageIndices.join(","));

//       const endpoint = numericId
//         ? `https://houberapp.com/admin/review/update/${numericId}`
//         : "https://houberapp.com/admin/review/";
//       const method = numericId ? "put" : "post"; 

//       const res = await axiosInstance({
//         method: method,
//         url: endpoint,
//         data: formData,
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });

//       if (res.status === 200) {
//         alert("등록 성공!");
//         navigate(-1);
//       }
//       console.log(res);
//     } catch (error) {
//       console.error(error);
//     }
//   } else {
//     alert('해시태그 5개 이상 작성해주세요.');
//   }
// };


//   console.log(tumbnail);

//   return (
//     <ResidentReviewWrapper>
//       <Container>
//         <PRHeader onSaveDraft={saveDraft} onPublishPost={publishPost} />
//         <ImageUpload onImageSelect={handleImageSelect} tumbnail={tumbnail} />
//         <CategoryContainer
//           buildingType={buildingType}
//           setBuildingType={setBuildingType}
//           residentsCount={residentsCount}
//           setResidentsCount={setResidentsCount}
//           roomType={roomType}
//           setRoomType={setRoomType}
//           districtId={districtId}
//           setDistrictId={setDistrictId}
//           keyword={keyword}
//           keywordArray={keywordArray}
//           setKeyword={setKeyword}
//           hashtags={hashtags}
//           setHashtags={setHashtags}
//         />
//         <PostContents
//           title={title}
//           setTitle={setTitle}
//           textAreaValue={textAreaValue}
//           setTextAreaValue={setTextAreaValue}
//           onFileChange={handleFileChange}
//           onDeleteImage={handleDeleteImage}
//           combinedImages={combinedImages}
//           imgCount={imageUrls.length}
//         />
//       </Container>
//     </ResidentReviewWrapper>
//   );
// };

// export default PostReview;

// const ResidentReviewWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 100%;
//   background-color: #edeef1;
// `;

// const Container = styled.div`
//   width: 1323px;
//   margin-top: 50px;
//   background-color: white;
//   padding-bottom: 100px;
// `;
