import React, { ChangeEvent, useEffect } from "react";
import styled from "styled-components";
import TextArea from "./TextAera";
import PictureIcon from "../../../../assets/images/ResidentReview/PictureIcon.png";
import AdminSwiperComponent from "./AmdinSlider";

type CombinedImageType =
  | { type: "url"; data: ImageType }
  | { type: "file"; data: File };

interface ImageType {
  id: number;
  url: string;
}

interface PostContentsProps {
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  textAreaValue: string;
  setTextAreaValue: React.Dispatch<React.SetStateAction<string>>;
  onFileChange: (files: File[]) => void;
  onDeleteImage: (index: number, id?: number) => void;
  combinedImages: CombinedImageType[];
  imgCount: number;
}

const PostContents: React.FC<PostContentsProps> = ({
  title,
  setTitle,
  textAreaValue,
  setTextAreaValue,
  onFileChange,
  onDeleteImage,
  combinedImages,
  imgCount,
}) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setTitle(value.slice(0, 40));
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      onFileChange(filesArray);
    }
  };

  const handleTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaValue(event.target.value);
  };

  return (
    <Container>
      <ImageUploadDiv>
        <StyledInput
          type="file"
          accept="image/*"
          multiple
          onChange={handleFileChange}
        />
        <div
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <img style={{ width: "39px", height: "39px" }} src={PictureIcon} />
          <p style={{ fontSize: "20px" }}>사진 첨부하기</p>
        </div>
      </ImageUploadDiv>

      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Seperator />
        <TitleContainer>
          <TitleInput
            placeholder="제목을 입력해주세요."
            value={title || ""}
            onChange={handleInputChange}
            maxLength={40}
          />
          <CharCount>{title.length}/40</CharCount>
        </TitleContainer>
        <Seperator style={{ marginBottom: "65px" }} />
        {combinedImages.length > 0 && (
          <AdminSwiperComponent
            images={combinedImages}
            onDeleteImage={onDeleteImage}
            imgCount={imgCount}
          />
        )}
        <TextArea value={textAreaValue || ""} onChange={handleTextAreaChange} />
        <div style={{ marginBottom: "300px" }} />
      </div>
    </Container>
  );
};

export default PostContents;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1000;
`;

const TitleContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 60px;
`;

const TitleInput = styled.input`
  width: 100%;
  font-size: 47px;
  font-weight: bold;
  border: none;
  padding: 0 10px;
  resize: none;
  outline: none;
  height: 127px;
  font-family: "Noto Sans KR";
`;

const CharCount = styled.div`
  font-size: 35px;
  color: #bbbbbb;
`;

const Seperator = styled.div`
  width: 1207px;
  height: 2px;
  background-color: #cccccc;
`;

const ImageUploadDiv = styled.div`
  width: 186px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 60px;
  background-color: #f7f9fa;
  border-radius: 11px;
  margin-bottom: 24px;
  margin-top: 107px;
`;
