import styled from "styled-components";
import { searchedRoomType } from "../Mansion";
import { useNavigate } from "react-router-dom";
import useDeviceSize from "../../../hooks/useDeviceSize";
import arrowRignt from "../../../assets/images/mainPage/bannerarrow-right.png";

function RoomItem({ roomInfo }: { roomInfo: searchedRoomType }) {
  const navigate = useNavigate();
  const { isMobile } = useDeviceSize();

  const onClickRoomItem = () => {
    navigate(`/detail?roomId=${roomInfo?.id}`);
  };

  function formatPrice(price: number): string {
    return price === 0 ? "없음" : `${price.toLocaleString()}엔`;
  }
  return (
    <RoomItemWrapper onClick={() => onClickRoomItem()}>
      <FloorPlanDiv>
        <StyledImage src={roomInfo.floorPlanUrl} alt={"floorPlan"} />
      </FloorPlanDiv>
      <ExtraRoomInfoDiv>
        {!isMobile ? (
          <>
            <ExtraRoomInfo>
              <div>{roomInfo.roomNum}</div>
              <div>{roomInfo.numOfFloor}층</div>
            </ExtraRoomInfo>
            <ExtraRoomInfo>
              <div>
                {roomInfo.roomType === "non" ? (
                  <>해당없음</>
                ) : (
                  <>
                    {roomInfo.roomType.slice(-1, roomInfo.roomType.length)}
                    {roomInfo.roomType.slice(0, -1).toUpperCase()}
                  </>
                )}
              </div>
              <div>{roomInfo.area}㎡</div>
            </ExtraRoomInfo>
            <ExtraRoomInfo>
              <div>{formatPrice(roomInfo.rent)}</div>
              <div>{formatPrice(roomInfo.managementFee)}</div>
            </ExtraRoomInfo>
            <ExtraRoomInfo>
              <div>{formatPrice(roomInfo.deposit)}</div>
              <div>{formatPrice(roomInfo.gratuity)}</div>
            </ExtraRoomInfo>
          </>
        ) : (
          <>
            <MobileExtraRoomInfoDiv>
              <RentAndManagementFee>
                {formatPrice(roomInfo.rent)} /{" "}
                {formatPrice(roomInfo.managementFee)}
              </RentAndManagementFee>
              <DepositAndGuaranty>
                <DepositAndGuarantyItem>
                  <DepositAndGuarantyTitle>시키킹</DepositAndGuarantyTitle>
                  {formatPrice(roomInfo.deposit)}
                </DepositAndGuarantyItem>
                <DepositAndGuarantyItem>
                  <DepositAndGuarantyTitle>레이킹</DepositAndGuarantyTitle>
                  {formatPrice(roomInfo.gratuity)}
                </DepositAndGuarantyItem>
              </DepositAndGuaranty>
              <RoomTypeAreaFloorNum>
                {roomInfo.roomType === "non" ? (
                  <>
                    해당없음 / {roomInfo.area}㎡ / {roomInfo.numOfFloor}층
                  </>
                ) : (
                  <>
                    {roomInfo.roomType.slice(-1, roomInfo.roomType.length)}
                    {roomInfo.roomType.slice(0, -1).toUpperCase()} /{" "}
                    {roomInfo.area}㎡ / {roomInfo.numOfFloor}층
                  </>
                )}
              </RoomTypeAreaFloorNum>
              <GoToDetailButton
                onClick={() => navigate(`/detail?roomId=${roomInfo?.id}`)}
              >
                <StyledArrowImage src={arrowRignt}></StyledArrowImage>
              </GoToDetailButton>
            </MobileExtraRoomInfoDiv>
          </>
        )}
      </ExtraRoomInfoDiv>
    </RoomItemWrapper>
  );
}

export default RoomItem;

const RoomItemWrapper = styled.div`
  display: flex;
  cursor: pointer;
  @media screen and (min-width: 1280px) {
    height: 103.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    height: 87px;
  }
  @media screen and (max-width: 1023px) {
    height: 105px;
  }
`;

const FloorPlanDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #a5a5a5;
  cursor: pointer;
  overflow: hidden;
  @media screen and (min-width: 1280px) {
    margin-left: 11px;
    width: 140px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-left: 9px;
    width: 117.5px;
  }
  @media screen and (max-width: 1023px) {
    width: 141.5px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

const ExtraRoomInfoDiv = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  flex: 1;
  @media screen and (min-width: 1280px) {
    margin-left: 30px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-left: 25px;
  }
`;

const ExtraRoomInfo = styled.div`
  height: 100%;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 1280px) {
    gap: 11px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 10px;
  }
`;

const MobileExtraRoomInfoDiv = styled.div`
  flex-direction: column;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  flex: 1;
  padding: 8px 0 11px 11px;
`;

const RentAndManagementFee = styled.div`
  color: #99393a;
  font-size: 12px;
  font-weight: 600;
`;

const DepositAndGuaranty = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const DepositAndGuarantyItem = styled.div`
  display: flex;
  font-size: 12px;
  gap: 12px;
`;

const DepositAndGuarantyTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f5f5f5;
  width: 50px;
  height: 17px;
`;

const RoomTypeAreaFloorNum = styled.div`
  font-size: 12px;
`;

const GoToDetailButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  height: 100%;
  width: 30px;
`;

const StyledArrowImage = styled.img`
  width: 37px;
  height: 40px;
  transform: translate(0, -8px);
`;
