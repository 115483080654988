import styled from "styled-components";
import BannerArrowLeft from "../../../assets/images/mainPage/bannerarrow-left.png";
import BannerArrowRight from "../../../assets/images/mainPage/bannerarrow-right.png";
import useDeviceSize from "../../../hooks/useDeviceSize";

function HouberMansionNavigation({
  onClickPrev,
  onClickNext,
  page,
  maxPage,
  translateX,
}: {
  onClickPrev: () => void;
  onClickNext: () => void;
  page: number;
  maxPage?: number;
  translateX: number;
}) {
  const { isPC, isMobile, isLaptop } = useDeviceSize();

  return (
    <NavigationButtonDiv $translateX={translateX}>
      <NavigationButton
        onClick={onClickPrev}
        $isFirst={page === 0}
        $translateY={isPC ? -10 : isLaptop ? -33 : -15}
      >
        <StyledArrowImage
          src={BannerArrowLeft}
          style={{ transform: "rotate(90deg)" }}
        />
      </NavigationButton>
      <NavigationButton
        onClick={onClickNext}
        $isLast={page === maxPage}
        $translateY={isPC ? -15 : isLaptop ? -40 : -35}
      >
        <StyledArrowImage
          src={BannerArrowRight}
          style={{ transform: "rotate(90deg)" }}
        />
      </NavigationButton>
    </NavigationButtonDiv>
  );
}

export default HouberMansionNavigation;

export const getMaxPage = (pagePerItems: number, listLength?: number) => {
  if (listLength) {
    const maxPage = Math.ceil(listLength / pagePerItems) - 1;
    return maxPage;
  }
};

const NavigationButtonDiv = styled.div<{ $translateX: number }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  height: 100%;
  z-index: 10;
  pointer-events: none;
  transform: translate(
    ${(props) => props.$translateX && `${props.$translateX}px`},
    0
  );
`;

const NavigationButton = styled.div<{
  $translateY?: number;
  $isFirst?: boolean;
  $isLast?: boolean;
}>`
  pointer-events: auto;
  visibility: ${(props) => (props.$isFirst || props.$isLast ? "hidden" : "")};
  border-radius: 50px;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.1);
  transform: translate(
    0,
    ${(props) => props.$translateY && `${props.$translateY}px`}
  );
  cursor: pointer;
  @media screen and (min-width: 1280px) {
    width: 52px;
    height: 52px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 50px;
    height: 50px;
  }
  @media screen and (max-width: 1023px) {
    width: 30px;
    height: 30px;
  }
`;

const StyledArrowImage = styled.img`
  @media screen and (min-width: 1280px) {
    width: 52px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 50px;
  }
  @media screen and (max-width: 1023px) {
    width: 30px;
  }
`;
