import styled from "styled-components";
import { useAdminRegistrationRoomState } from "../../../recoil/mansionManagement/adminRegistrationRoomStateAtom";

function RoomRegistrationRoomNumber({ roomIndex }: { roomIndex: number }) {
  const { handleUpdateRoom, adminRegistrationRoomState } =
    useAdminRegistrationRoomState();

  const formatInput = (input: string) => {
    const cleaned = input.replace(/\D/g, ""); // 숫자가 아닌 모든 문자를 제거

    return cleaned;
  };

  return (
    <InformationWrapper>
      <InformationTitle>호실</InformationTitle>
      <StyledInput
        value={adminRegistrationRoomState[roomIndex].roomNumber}
        onChange={(e) => {
          const format = formatInput(e.target.value);
          handleUpdateRoom(roomIndex, {
            ...adminRegistrationRoomState[roomIndex],
            roomNumber: format,
          });
        }}
      ></StyledInput>
    </InformationWrapper>
  );
}

export default RoomRegistrationRoomNumber;

const InformationWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 137px;
  font-weight: 600;
  padding-left: 23px;
  border-bottom: 2px solid #e6e6e6;
  gap: 267.5px;
`;

const InformationTitle = styled.div`
  font-size: 25px;
  font-weight: bold;
  letter-spacing: -2px;
`;

const StyledInput = styled.input`
  width: 141px;
  height: 60px;
  font-size: 18px;
  background-color: #f7f8f9;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 15px;
  font-family: "Noto Sans KR";
  font-weight: 500;
`;
