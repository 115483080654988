import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import ResponsiveWrapper from "../../../components/ResponsiveWrapper";
import xIcon from "../../../assets/images/mainPage/x-icon.png";
import MansionOptionBar from "./MobileOptionBar";

function MansionOptionModal({ closeModal }: { closeModal: () => void }) {
  const modalRoot = document.querySelector("#mansionMobileOption");

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, []);

  return mounted
    ? createPortal(
        <MansionMobileOptionWrapper>
          <MansionMobileOptionHeader>
            <ResponsiveWrapper>
              <HeaderInner>
                <HeaderTitle>필터</HeaderTitle>
                <CloseIcon src={xIcon} onClick={closeModal}></CloseIcon>
              </HeaderInner>
            </ResponsiveWrapper>
          </MansionMobileOptionHeader>
          <OptionBarLayout>
            <ResponsiveWrapper>
              <MansionOptionBar closeModal={closeModal}></MansionOptionBar>
            </ResponsiveWrapper>
          </OptionBarLayout>
        </MansionMobileOptionWrapper>,
        modalRoot as HTMLElement
      )
    : null;
}

export default MansionOptionModal;

const MansionMobileOptionWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 10000;
  font-family: "Noto Sans KR";
  display: flex;
  flex-direction: column;
`;

const MansionMobileOptionHeader = styled.div`
  display: flex;
  justify-content: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  height: 46px;
`;

const HeaderInner = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;

const HeaderTitle = styled.div`
  font-size: 15px;
  font-weight: bold;
`;

const CloseIcon = styled.img`
  width: 17px;
  height: 17px;
`;

const OptionBarLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex: 1;
  overflow-y: auto;
`;
