import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DownIcon from '../../../../assets/images/ResidentReview/DownIcon.png'

interface SortOptionsProps {
  onSelect: (option: string) => void;
  selectedoption: string
}

const AdminSortOpt: React.FC<SortOptionsProps> = ({ onSelect,selectedoption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>('정렬');
  const options = [
    { label: '최신순', value: 'latest' },
    { label: '과거순', value: 'oldest' },
    { label: '인기순', value: 'mostRecommend'}
  ];

  useEffect(() => {
    const matchedOption = options.find(option => option.value === selectedoption);
    if (matchedOption) {
      setSelectedOption(matchedOption.label);
    }
  }, [selectedoption]);

  const handleSelect = (option: { label: string, value: string }) => {
    setSelectedOption(option.label);
    onSelect(option.value);
    setIsOpen(false);
  };

  return (
    <DropdownContainer>
      <DropdownHeader onClick={() => setIsOpen(!isOpen)}>
        {selectedOption}
        <img style={{width:'15px', height:'8px', paddingLeft:'5px'}} src={DownIcon} alt="Down Icon" />
      </DropdownHeader>
      {isOpen && (
        <DropdownList>
          {options.map((option, index) => (
            <DropdownItem
              key={index}
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default AdminSortOpt;

const DropdownContainer = styled.div`
  position: relative;
  width: auto;
  margin-bottom: 15px;
`;

const DropdownHeader = styled.div`
  padding: 10px 15px;
  background-color: #e8e8e8;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const DropdownList = styled.ul`
  position: absolute;
  width: 90px;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
`;

const DropdownItem = styled.li`
  width: 80px;
  padding: 8px 5px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgb(153, 57, 58,0.7);
  }
`;


