import styled from "styled-components";

interface DropdownContainerProps {
  isForm02?: boolean;
}

const DropdownContainer = styled.div<DropdownContainerProps>`
  position: relative;
  display: inline-block;
  flex: 1;
  margin: 10px 1px 0 1px;
  width: 100%;
  max-width: ${(props) => (props.isForm02 ? "100%" : "336px")};
  min-width: 171px;

  @media screen and (max-width: 1023px) {
    max-width: 100%;
  }
`;
// max-width: ${(props) => (props.isForm02 ? '100%' : '400px')};

export default DropdownContainer;
