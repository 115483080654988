import styled from "styled-components";
import useDeviceSize from "../../../hooks/useDeviceSize";
import { ReactNode } from "react";

function InformationBox({
  children,
  decoration,
  borderRadius,
  width,
  height,
}: {
  children: ReactNode;
  decoration?: boolean;
  borderRadius?: string;
  width?: {
    pc: string;
    laptop: string;
    mobile: string;
  };
  height?: {
    pc: string;
    laptop: string;
    mobile: string;
  };
}) {
  const { isMobile } = useDeviceSize();
  return (
    <Wrapper width={width} height={height} $borderRadius={borderRadius}>
      {decoration && <BoxDecoration />}
      {children}
    </Wrapper>
  );
}

export default InformationBox;

const Wrapper = styled.div<{
  $borderRadius?: string;
  width?: {
    pc: string;
    laptop: string;
    mobile: string;
  };
  height?: {
    pc: string;
    laptop: string;
    mobile: string;
  };
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  @media screen and (min-width: 1280px) {
    box-shadow: 0 5px 7px 5px rgba(0, 0, 0, 0.1);
    border-radius: ${(props) =>
      props.$borderRadius ? props.$borderRadius : "10px"};
    width: ${(props) => props.width && props.width.pc};
    height: ${(props) => props.height && props.height.pc};
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    box-shadow: 0 5px 7px 5px rgba(0, 0, 0, 0.1);
    border-radius: ${(props) =>
      props.$borderRadius ? props.$borderRadius : "10px"};
    width: ${(props) => props.width && props.width.laptop};
    height: ${(props) => props.height && props.height.laptop};
  }
  @media screen and (max-width: 1023px) {
    box-shadow: 0 3px 5px 3px rgba(0, 0, 0, 0.1);
    border-radius: ${(props) =>
      props.$borderRadius ? props.$borderRadius : "5px"};
    width: ${(props) => props.width && props.width.mobile};
    height: ${(props) => props.height && props.height.mobile};
  }
`;

const BoxDecoration = styled.div`
  background-color: #99393a;
  width: 100%;
  @media screen and (min-width: 1280px) {
    height: 3.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    height: 4px;
  }
  @media screen and (max-width: 1023px) {
    height: 2px;
  }
`;
