import React, { ChangeEvent } from "react";
import styled from "styled-components";
import Timer from "../../FindIdPwPage/components/Timer";

interface ConfirmBoxProps {
  verificationCode: string;
  isverificationCodeValid: boolean;
  handleVerificationCodeChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleConfirmClick: () => void;
  sendPhoneNumber:() => void;
}

const ConfirmBox: React.FC<ConfirmBoxProps> = ({
  verificationCode,
  isverificationCodeValid,
  handleVerificationCodeChange,
  handleConfirmClick,
  sendPhoneNumber,
}) => {
  return (
    <ConfirmBoxContainer>
      <LayoutDiv>
        <ConfirmText>인증번호를 입력해주세요</ConfirmText>
        <DivInput>
          <InputField
            style={{border: "none", margin: "0px" }}
            type="text"
            value={verificationCode}
            onChange={handleVerificationCodeChange}
            placeholder="인증번호 6자리"
          />
          { isverificationCodeValid&& (<Timer onTimeout={() => alert("시간 초과!")} />)}
         
          <AuthButton
            disabled={verificationCode.length !== 6 || !isverificationCodeValid}
            onClick={handleConfirmClick}
          >
           {isverificationCodeValid ? "확인":"인증완료"} 
          </AuthButton>
        </DivInput>
        <DivInput className="resend">
          <div>* 인증번호를 받지 못하셨나요?</div>
          <ResendBTN  onClick={()=>{sendPhoneNumber();alert("재전송 되었습니다.")}}>재전송하기</ResendBTN>
        </DivInput>
      </LayoutDiv>
    </ConfirmBoxContainer>
  );
};

export default ConfirmBox;

const ConfirmBoxContainer = styled.div`
  width: 100%;
  height: 208px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;

   @media (max-width: 1024px) {
    height: 172px;
  }
  @media (max-width: 500px) {
    height: 142px;
  }
`;

const LayoutDiv = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
    
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 500px) {
    width: 374px;
    height: 142px;
    align-items: center;
    justify-content: center;
  }
`;

const ConfirmText = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  width: 470px;

    @media (max-width: 1024px) {
    font-weight: 700;
    width: 90%;
    font-size: 15px;
  }
  @media (max-width: 500px) {
    font-weight: 700;
    width: 322px;
    font-size: 14px;
  }
`;

const DivInput = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(187, 185, 185, 0.5);
  border-radius: 5px;
  background-color: white;
  align-items: center;
  &.resend {
    background-color: #f7f7f7;
    border: none;
    margin-top: 20px;

   @media (max-width: 1024px) {
      margin-top: 0px;
      font-size: 13px;
    }
    @media (max-width: 500px) {
      width: 322px;
      height: 42px;
      margin-top: 0px;
      font-size: 13px;
    }
  }
  @media (max-width: 1024px) {
    width: 90%;
    height: 52px;
  }
  @media (max-width: 500px) {
    width: 322px;
    height: 42px;
  }
`;

const InputField = styled.input`
  border-radius: 10px;
  width: 330px;
  padding-left: 20px;
  margin: 5px 0;
  font-size: 16px;
  border: 1px solid #ccc;
  height: 60px;
  outline: none;
 display: flex;
 align-items: center;
 font-family: "Noto Sans KR";
 font-weight: 500;
    @media (max-width: 1024px) {
      width: 270px;
    height: 40px;
    font-size: 13px;
  }
  @media (max-width: 500px) {
    width: 230px;
    height: 34px;
    font-size: 11px;
  }
`;

const AuthButton = styled.button<{ disabled: boolean }>`
  font-size: 15.5px;
  background-color: white;
  color: ${({ disabled }) => (disabled ? "#BBBBBB" : "black")};
  border: none;
  border-radius: 5px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  transition: background-color 0.3s ease;
  font-weight: 400;
padding: 0;
margin: 0 10px 0;
  &:disabled {
    cursor: not-allowed;
  }

  @media (max-width: 1024px) {
    font-size: 11px;

    padding: 0;
  }
  @media (max-width: 500px) {
    font-size: 9px;

  }
`;

const ResendBTN = styled.button`
  border: none;
  background-color: #f7f7f7;
  font-size: 22px;
  font-weight: 700;
  color: #777777;
  cursor: pointer;
  @media (max-width: 1024px) {
    font-size: 20px;
  }
  @media (max-width: 500px) {
    font-size: 19px;
  }
`;
