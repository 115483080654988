import styled from "styled-components";

function SearchContentsHeader({
  title,
  itemNumber,
}: {
  title: string;
  itemNumber: number;
}) {
  return (
    <HeaderWrapper>
      <String>{title}</String>
      <ColoredString>{itemNumber}</ColoredString>
    </HeaderWrapper>
  );
}

export default SearchContentsHeader;

const HeaderWrapper = styled.div`
  display: flex;
  @media screen and (min-width: 1280px) {
    margin-top: 74px;
    gap: 8px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 58px;
    gap: 6px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 28px;
    gap: 6px;
  }
`;

const String = styled.div<{ colored?: boolean }>`
  @media screen and (min-width: 1280px) {
    font-size: 26px;
    font-weight: bold;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
    font-weight: bold;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
    font-weight: bold;
  }
`;

const ColoredString = styled.div`
  color: #99393a;
  @media screen and (min-width: 1280px) {
    font-size: 26px;
    font-weight: bold;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
    font-weight: bold;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
    font-weight: bold;
  }
`;
