import styled from "styled-components";
import CloneHeaderTop from "./CloneHeaderTop";
import CloneHeaderContents from "./CloneHeaderContents";
import CloneHeaderBottom from "./CloneHeaderBottom";

function CloneHeader() {
  return (
    <Wrapper>
      <CloneHeaderTop />
      <CloneHeaderContents />
      <CloneHeaderBottom />
    </Wrapper>
  );
}

export default CloneHeader;

const Wrapper = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  padding: 0 45px;
  height: 100%;
  width: 1280px;
`;
