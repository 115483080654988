import React, { useState } from "react";
import styled from "styled-components";
import DownIcon from "../../../assets/images/ResidentReview/DownIcon.png"
import EarthIcon from "../../../assets/images/ResidentReview/EarthIcon.png"
const countries = [
  { id: 1, code: "82", label: "한국" },
  { id: 2, code: "1", label: "미국" },
  { id: 3, code: "44", label: "영국" },
  { id: 4, code: "81", label: "일본" },
  { id: 5, code: "49", label: "독일" },
  { id: 6, code: "33", label: "프랑스" },
  { id: 7, code: "39", label: "이탈리아" },
  { id: 8, code: "86", label: "중국" },
  { id: 9, code: "7", label: "러시아" },
  { id: 10, code: "91", label: "인도" },
  { id: 11, code: "61", label: "호주" },
  { id: 12, code: "34", label: "스페인" },
  { id: 13, code: "55", label: "브라질" },
  { id: 14, code: "27", label: "남아프리카 공화국" },
  { id: 15, code: "31", label: "네덜란드" },
  { id: 16, code: "47", label: "노르웨이" },
  { id: 17, code: "46", label: "스웨덴" },
  { id: 18, code: "41", label: "스위스" },
  { id: 19, code: "358", label: "핀란드" },
  { id: 20, code: "52", label: "멕시코" },
  { id: 21, code: "62", label: "인도네시아" },
  { id: 22, code: "64", label: "뉴질랜드" },
  { id: 23, code: "63", label: "필리핀" },
  { id: 24, code: "48", label: "폴란드" },
  { id: 25, code: "351", label: "포르투갈" },
  { id: 26, code: "66", label: "태국" },
  { id: 27, code: "90", label: "터키" },
  { id: 28, code: "380", label: "우크라이나" },
  { id: 29, code: "971", label: "아랍에미리트" },
  { id: 30, code: "972", label: "이스라엘" },
];

interface CountrySelectProps {
  countryCode: string;
  setCountryCode: (code: string) => void;
}

const CountrySelect: React.FC<CountrySelectProps> = ({
  countryCode,
  setCountryCode,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleCountrySelect = (code: string) => {
    setCountryCode(code);
    setDropdownOpen(false);
  };

  return (
    <SelectContainer>
      <SelectField onClick={handleDropdownToggle}>
        <Earth>
    <img src={EarthIcon} style={{width:'100%', height:'100%'}}/>
    </Earth>
        {countries.find((country) => country.code === countryCode)?.label} +
        {countryCode} <DowIcon src={DownIcon} style={{ position:'absolute',right: "5%" }}></DowIcon>
      </SelectField>
      {dropdownOpen && (
        <Dropdown>
          <TopArrowContainer>
            <Arrow>▲</Arrow>
          </TopArrowContainer>
          {countries.map((country) => (
            <DropdownItem
              key={country.code}
              onClick={() => handleCountrySelect(country.code)}
            >
           {country.label} +{country.code}
            </DropdownItem>
          ))}{" "}
          <BottomArrowContainer>
            <Arrow>▼</Arrow>
          </BottomArrowContainer>
        </Dropdown>
      )}
    </SelectContainer>
  );
};

export default CountrySelect;

const SelectContainer = styled.div`
  position: relative;
`;

const SelectField = styled.div`
  width: 100%;
  padding-left: 20px;
  margin: 5px 0;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
  background-color: white;
  height: 60px;
  display: flex;
  align-items: center; 
  font-size: 23px;
  font-family: "Noto Sans KR";
  font-weight: 500;
  @media (max-width: 1024px) {
    height: 60px;
    font-size: 22px;
  }
  @media (max-width: 500px) {
    height: 38px;
    font-size: 12px;
  }
`;

const Dropdown = styled.ul`
  width: 100%;
  max-height: 320px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f7f7f7;
  list-style: none;
  position: relative;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

    @media (max-width: 500px) {
      max-height: 270px;
  }
`;

const DropdownItem = styled.li`
  padding: 5px;
  padding-left: 10px;
  cursor: pointer;
  position: relative;
  &:hover {
    background-color: #8888ff;
    color: white;
  }

  &::before {
    content: "✔";
    opacity: 0;
    padding-right: 5px;
  }

  &:hover::before {
    opacity: 1;
  }

  @media (max-width: 500px) {
    font-size: 11px;
  }
`;

const Arrow = styled.div`
  text-align: center;
  position: sticky;
  z-index: 1;
  font-size: 13px;
    @media (max-width: 500px) {
    font-size: 11px;
  }
`;

const ArrowContainer = styled.div`
  text-align: center;
  position: sticky;
  background-color: #f7f7f7; 
  z-index: 1;
  padding:10px;
      @media (max-width: 500px) {
      padding:7px;
  }
`;

const TopArrowContainer = styled(ArrowContainer)`
  top: 0;
`;

const BottomArrowContainer = styled(ArrowContainer)`
  bottom: 0;
`;


const DowIcon = styled.img`
  width: 18px;
  height: 9px;
  @media (max-width: 500px) {
    width: 10px;
    height: 5px;
  }
`;

const Earth = styled.div`
  width: 21px;
  height: 21px;
  margin-right: 7px;
  @media (max-width: 500px) {
  margin-right: 4px;
    width: 13px;
    height: 13px;
  }
`;