import { Fragment, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useAdminRegistrationRoomState } from "../../../recoil/mansionManagement/adminRegistrationRoomStateAtom";
import { useLocation } from "react-router-dom";
import axiosInstance from "../../../services/axiosConfig";
import addIcon from "../../../assets/images/mansionManagement/add.png";
import deleteIcon from "../../../assets/images/mansionManagement/delete.png";

function RoomRegistrationInfo({ roomIndex }: { roomIndex: number }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mansionId = queryParams.get("mansionId");

  const { adminRegistrationRoomState, handleUpdateRoom } =
    useAdminRegistrationRoomState();

  const roomInfo = adminRegistrationRoomState[roomIndex];

  const [selectState, setSelectState] = useState<
    | null
    | "roomType"
    | "direction"
    | "contractTerm"
    | "penaltyOption"
    | "penaltyAmount"
    | "struncture"
  >(null);

  const [maxFloorState, setMaxFloorState] = useState<number>(0);

  const optionDivRef = useRef<HTMLDivElement | null>(null);

  const formatInput = (input: string) => {
    const cleaned = input.replace(/\D/g, "");

    return cleaned;
  };

  const formatAreaInput = (input: string) => {
    const cleaned = input.replace(/[^-\.0-9]/g, "");

    return cleaned;
  };

  const convertedStructure = (
    structure: "woodcarving" | "rebar" | "ironFrame" | "other" | "non"
  ) => {
    switch (structure) {
      case "woodcarving":
        return "목조";
      case "rebar":
        return "철근구조";
      case "ironFrame":
        return "철골구조";
      case "other":
        return "기타";
      case "non":
        return "해당 없음";
    }
  };

  const fetchMaxFloor = async () => {
    try {
      const response = await axiosInstance.get(
        `/admin/property/floor/${mansionId}`
      );

      setMaxFloorState(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddMoreInfo = () => {
    handleUpdateRoom(roomIndex, {
      ...adminRegistrationRoomState[roomIndex],
      moreInfo: [...roomInfo.moreInfo, { key: "", value: "" }],
    });
  };

  const deleteMoreInfo = (index: number) => {
    const updatedMoreInfo = roomInfo.moreInfo.filter((_, i) => i !== index);
    handleUpdateRoom(roomIndex, {
      ...adminRegistrationRoomState[roomIndex],
      moreInfo: updatedMoreInfo,
    });
  };

  const handleMoreInfoChange = (
    index: number,
    field: "key" | "value",
    value: string
  ) => {
    const updatedMoreInfo = roomInfo.moreInfo.map((info, i) =>
      i === index ? { ...info, [field]: value } : info
    );
    handleUpdateRoom(roomIndex, {
      ...adminRegistrationRoomState[roomIndex],
      moreInfo: updatedMoreInfo,
    });
  };

  const formatNumberWithCommas = (input: string) => {
    return input.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const removeCommas = (input: string) => {
    return input.replace(/,/g, "");
  };

  useEffect(() => {
    fetchMaxFloor();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        optionDivRef.current &&
        !optionDivRef.current.contains(event.target as Node)
      ) {
        setSelectState(null);
      }
    };
    if (selectState) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectState]);

  return (
    <InfoWrapper>
      <InfoTitle>
        기본정보{" "}
        <AddItemButton
          onClick={handleAddMoreInfo}
          src={addIcon}
        ></AddItemButton>
      </InfoTitle>
      <InfoItemWrapper>
        <InfoWrapperLeft>
          <InfoItem>
            방타입
            <InfoSelect onClick={() => setSelectState("roomType")}>
              {roomInfo.roomType === "non" ? (
                <>해당 없음</>
              ) : (
                <>
                  {roomInfo.roomType.slice(-1, roomInfo.roomType.length)}
                  {roomInfo.roomType.slice(0, -1).toUpperCase()}
                </>
              )}

              {selectState === "roomType" && (
                <InfoOptionDiv ref={optionDivRef}>
                  <InfoOption
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUpdateRoom(roomIndex, {
                        ...adminRegistrationRoomState[roomIndex],
                        roomType: "non",
                      });

                      setSelectState(null);
                    }}
                  >
                    해당 없음
                  </InfoOption>
                  {["1", "2", "3", "4", "5"].map((numberItem, numberIndex) => (
                    <Fragment key={numberIndex}>
                      {["r", "k", "dk", "ldk"].map((typeItem, typeIndex) => (
                        <InfoOption
                          key={typeIndex}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleUpdateRoom(roomIndex, {
                              ...adminRegistrationRoomState[roomIndex],
                              roomType: typeItem + numberItem,
                            });

                            setSelectState(null);
                          }}
                        >
                          {numberItem}
                          {typeItem.toUpperCase()}
                        </InfoOption>
                      ))}
                    </Fragment>
                  ))}
                </InfoOptionDiv>
              )}
            </InfoSelect>
          </InfoItem>
          <InfoItem>
            방향
            <InfoSelect onClick={() => setSelectState("direction")}>
              {roomInfo.direction}
              {selectState === "direction" && (
                <InfoOptionDiv ref={optionDivRef}>
                  {[
                    "해당없음",
                    "동향",
                    "서향",
                    "남향",
                    "북향",
                    "동남향",
                    "북동향",
                    "서남향",
                    "북서향",
                  ].map((item, index) => (
                    <InfoOption
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleUpdateRoom(roomIndex, {
                          ...adminRegistrationRoomState[roomIndex],
                          direction: item as
                            | "해당없음"
                            | "동향"
                            | "서향"
                            | "남향"
                            | "북향"
                            | "동남향"
                            | "북동향"
                            | "서남향"
                            | "북서향",
                        });

                        setSelectState(null);
                      }}
                    >
                      {item}
                    </InfoOption>
                  ))}
                </InfoOptionDiv>
              )}
            </InfoSelect>
          </InfoItem>
          <InfoItem>
            계약기간
            <InfoSelect onClick={() => setSelectState("contractTerm")}>
              {roomInfo.contractTerm === 0 ? (
                <>해당없음</>
              ) : (
                <>
                  {Math.floor(roomInfo.contractTerm / 12) !== 0 && (
                    <>{Math.floor(roomInfo.contractTerm / 12)}년</>
                  )}
                  {roomInfo.contractTerm % 12 !== 0 && (
                    <> {roomInfo.contractTerm % 12}개월</>
                  )}
                </>
              )}

              {selectState === "contractTerm" && (
                <InfoOptionDiv ref={optionDivRef}>
                  {[0, 6, 12, 18, 24, 30, 36].map((item, index) => (
                    <Fragment key={index}>
                      {item === 0 ? (
                        <InfoOption
                          onClick={(e) => {
                            e.stopPropagation();
                            handleUpdateRoom(roomIndex, {
                              ...adminRegistrationRoomState[roomIndex],
                              contractTerm: item,
                            });

                            setSelectState(null);
                          }}
                        >
                          해당없음
                        </InfoOption>
                      ) : (
                        <InfoOption
                          onClick={(e) => {
                            e.stopPropagation();
                            handleUpdateRoom(roomIndex, {
                              ...adminRegistrationRoomState[roomIndex],
                              contractTerm: item,
                            });

                            setSelectState(null);
                          }}
                        >
                          {Math.floor(item / 12) !== 0 && (
                            <>{Math.floor(item / 12)}년</>
                          )}
                          {item % 12 !== 0 && <> {item % 12}개월</>}
                        </InfoOption>
                      )}
                    </Fragment>
                  ))}
                </InfoOptionDiv>
              )}
            </InfoSelect>
          </InfoItem>
          <InfoItem>
            위약금
            <InfoSelect onClick={() => setSelectState("penaltyOption")}>
              {roomInfo.penaltyOption}
              {selectState === "penaltyOption" && (
                <InfoOptionDiv ref={optionDivRef}>
                  {[
                    "해당없음",
                    "6개월 이내",
                    "1년 이내",
                    "1년 6개월 이내",
                    "2년 이내",
                    "2년 6개월 이내",
                    "3년 이내",
                  ].map((item, index) => (
                    <InfoOption
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleUpdateRoom(roomIndex, {
                          ...adminRegistrationRoomState[roomIndex],
                          penaltyOption: item as
                            | "해당없음"
                            | "6개월 이내"
                            | "1년 이내"
                            | "1년 6개월 이내"
                            | "2년 이내"
                            | "2년 6개월 이내"
                            | "3년 이내",
                        });

                        setSelectState(null);
                      }}
                    >
                      {item}
                    </InfoOption>
                  ))}
                </InfoOptionDiv>
              )}
            </InfoSelect>
          </InfoItem>
          {roomInfo.moreInfo.map((item, index) => (
            <Fragment key={index}>
              {index % 2 === 0 && (
                <MoreInfoWrapper>
                  <MoreInfoNameInput
                    value={item.key}
                    onChange={(e) =>
                      handleMoreInfoChange(index, "key", e.target.value)
                    }
                  />
                  <MoreInfoValueInput
                    value={item.value}
                    onChange={(e) =>
                      handleMoreInfoChange(index, "value", e.target.value)
                    }
                  />
                  <DeleteMoreInfoButton
                    src={deleteIcon}
                    onClick={() => deleteMoreInfo(index)}
                  ></DeleteMoreInfoButton>
                </MoreInfoWrapper>
              )}
            </Fragment>
          ))}
        </InfoWrapperLeft>
        <InfoWrapperRight>
          <InfoItem>
            면적
            <InfoBox>
              <InfoAreaInput
                value={formatNumberWithCommas(
                  adminRegistrationRoomState[roomIndex].area
                )}
                onChange={(e) => {
                  const format = formatAreaInput(removeCommas(e.target.value));
                  handleUpdateRoom(roomIndex, {
                    ...adminRegistrationRoomState[roomIndex],
                    area: format,
                  });
                }}
              ></InfoAreaInput>
              ㎡
            </InfoBox>
          </InfoItem>
          <InfoItem>
            층수
            <InfoBox>
              <InfoFloorInput
                value={roomInfo.numOfFloors}
                onChange={(e) => {
                  const format = formatInput(e.target.value);
                  if (Number(format) <= maxFloorState) {
                    handleUpdateRoom(roomIndex, {
                      ...adminRegistrationRoomState[roomIndex],
                      numOfFloors: format,
                    });
                  }
                }}
              ></InfoFloorInput>
              층 / {maxFloorState}층
            </InfoBox>
          </InfoItem>
          <InfoItem>
            구조
            <InfoSelect onClick={() => setSelectState("struncture")}>
              {convertedStructure(roomInfo.structure)}
              {selectState === "struncture" && (
                <InfoOptionDiv ref={optionDivRef}>
                  {["non", "woodcarving", "rebar", "ironFrame", "other"].map(
                    (item, index) => (
                      <InfoOption
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleUpdateRoom(roomIndex, {
                            ...adminRegistrationRoomState[roomIndex],
                            structure: item as
                              | "woodcarving"
                              | "rebar"
                              | "ironFrame"
                              | "other"
                              | "non",
                          });

                          setSelectState(null);
                        }}
                      >
                        {convertedStructure(
                          item as
                            | "woodcarving"
                            | "rebar"
                            | "ironFrame"
                            | "other"
                            | "non"
                        )}
                      </InfoOption>
                    )
                  )}
                </InfoOptionDiv>
              )}
            </InfoSelect>
          </InfoItem>
          <InfoItem>
            취소시
            <InfoSelect onClick={() => setSelectState("penaltyAmount")}>
              {roomInfo.penaltyAmount}
              {selectState === "penaltyAmount" && (
                <InfoOptionDiv ref={optionDivRef}>
                  {[
                    "해당없음",
                    "1개월",
                    "2개월",
                    "3개월",
                    "4개월",
                    "5개월",
                    "6개월",
                  ].map((item, index) => (
                    <InfoOption
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleUpdateRoom(roomIndex, {
                          ...adminRegistrationRoomState[roomIndex],
                          penaltyAmount: item as
                            | "해당없음"
                            | "1개월"
                            | "2개월"
                            | "3개월"
                            | "4개월"
                            | "5개월"
                            | "6개월",
                        });

                        setSelectState(null);
                      }}
                    >
                      {item}
                    </InfoOption>
                  ))}
                </InfoOptionDiv>
              )}
            </InfoSelect>
          </InfoItem>
          {roomInfo.moreInfo.map((item, index) => (
            <Fragment key={index}>
              {index % 2 === 1 && (
                <MoreInfoWrapper>
                  <MoreInfoNameInput
                    value={item.key}
                    onChange={(e) =>
                      handleMoreInfoChange(index, "key", e.target.value)
                    }
                  />
                  <MoreInfoValueInput
                    value={item.value}
                    onChange={(e) =>
                      handleMoreInfoChange(index, "value", e.target.value)
                    }
                  />
                  <DeleteMoreInfoButton
                    src={deleteIcon}
                    onClick={() => deleteMoreInfo(index)}
                  ></DeleteMoreInfoButton>
                </MoreInfoWrapper>
              )}
            </Fragment>
          ))}
        </InfoWrapperRight>
      </InfoItemWrapper>
    </InfoWrapper>
  );
}

export default RoomRegistrationInfo;

const InfoWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding-left: 23px;
  padding: 84px 0;
  border-bottom: 2px solid #e6e6e6;
  justify-content: space-between;
`;

const InfoTitle = styled.div`
  display: flex;
  font-size: 25px;
  font-weight: bold;
  margin-top: 8px;
  letter-spacing: -2px;
  gap: 10.5px;
  font-weight: 600;
  padding-left: 23px;
`;

const AddItemButton = styled.img`
  width: 27px;
  height: 27px;
  cursor: pointer;
`;

const InfoItemWrapper = styled.div`
  display: flex;
  gap: 30px;
  width: 904px;
`;

const InfoWrapperLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 33px;
  height: 100%;
  padding-right: 50px;
`;
const InfoWrapperRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 33px;
  height: 100%;
  padding-right: 50px;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 313.5px;
  font-size: 20px;
  font-weight: 500;
`;

const InfoBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 190px;
  height: 60px;
  font-size: 18px;
  background-color: #f7f8f9;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 30px;
  gap: 8px;
`;

const InfoAreaInput = styled.input`
  width: 100%;
  height: 90%;
  border: none;
  outline: none;
  text-align: right;
  background-color: #f7f8f9;
  font-size: 18px;
  font-family: "Noto Sans KR";
  font-weight: 500;
`;

const InfoFloorInput = styled.input`
  max-width: 30%;
  height: 90%;
  border: none;
  outline: none;
  text-align: right;
  background-color: #f7f8f9;
  font-size: 18px;
  font-family: "Noto Sans KR";
  font-weight: 500;
`;

const InfoSelect = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 190px;
  height: 60px;
  font-size: 18px;
  background-color: #f7f8f9;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 15px;
  cursor: pointer;
`;

const InfoOptionDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 190px;
  max-height: 245px;
  border: 1px solid black;
  top: -1px;
  left: -1px;
  background-color: #f7f8f9;
  z-index: 10;
  border-radius: 5px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: none;
  }
`;

const InfoOption = styled.div`
  display: flex;
  align-items: center;
  min-height: 47px;
  padding: 0 15px;
`;

const MoreInfoWrapper = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  width: 313.5px;
  font-size: 20px;
  gap: 20px;
`;

const MoreInfoNameInput = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  font-size: 20px;
  font-family: "Noto Sans KR";
  font-weight: 500;
`;
const MoreInfoValueInput = styled.input`
  display: flex;
  align-items: center;
  width: 190px;
  height: 60px;
  font-size: 18px;
  background-color: #f7f8f9;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 15px;
  font-family: "Noto Sans KR";
  font-weight: 500;
`;

const DeleteMoreInfoButton = styled.img`
  width: 27px;
  height: 27px;
  position: absolute;
  right: -50px;
  cursor: pointer;
`;
