// src/pages/PrivacyPolicyContent.tsx
import React from "react";
import styled from "styled-components";

const PrivacyPolicyContent: React.FC = () => {
  return (
    <>
      <Title>個人情報保護方針</Title>
      <TermsContent>
        <p>
          株式会社エイダス「（以下、「当社」といいます）」は、総合不動産情報サイト「ハウバー（英字名：Houber）
          <br />
          （以下、「本サービス」といいます）」の運営・管理に伴って「個人情報の保護に関する法律（以下、「個人情報保護法」といいます。）」
          <br />
          等の関係法令その他の規範に従い、個人情報を適切に取り扱うことが当社の社会的責任であることを認識し、
          <br />
          次のとおり、お客様等の個人情報保護を宣言いたします。
          <br />
          <br />
          また、ユーザーは、本サービスへの登録を行ったことで、本サービスの利用に関連して当社が知り得たユーザーの個人情報について、
          <br />
          当社が保護措置を講じた上で以下の通り取り扱うことに同意したこととします。
          <br />
        </p>
        <h3>第1条 個人情報の利用目的</h3>
        <p>
          当社は、ユーザーの個人情報を各項記載の目的で取得し、利用します。
          <br />
          １）本サービスにより、ユーザーに対して物件の空室情報をリアルタイムで提供するため
          <br />
          ２）前項の目的の達成に必要な範囲での、個人情報の第三者への提供
          <br />
          ３）本サービスの利用に際してのユーザーとしての登録のため
          <br />
          ４）本サービスに関連するサービス及び情報更新の通知・連絡のため
          <br />
          ５）当社の事業活動及び市場調査に関する分析・統計のため
          <br />
          ６）ユーザーが本サービスを利用している間におけるユーザーとの各種連絡のため
          <br />
          ７）その他、本サービスの目的および当社のプライバシーポリシーに記載した目的のため
        </p>
        <h3>第2条 個人情報の委託</h3>
        <p>
          当社は、第1条の利用目的の範囲内で、ユーザーの情報の全部又は一部を他の事業者に委託する場合があります。委託先については、十分な保護水準を持つ委託先を選定し、個人情報の取扱いに関する契約を締結します。
        </p>
        <br />
        <h3>第3条 個人情報の第三者提供</h3>
        <p>
          当社は、（１）前１条に定める場合、（２）ユーザーご本人の同意がある場合、
          <br />
          （３）ユーザーに明示した利用目的達成に必要な範囲で提携する事業者との間で個人情報を共同利用する場合又は
          <br />
          （４）法令に基づく場合を除き、取得した個人情報を第三者に提供することはありません。
        </p>
        <h3>第4条 特定個人情報等の取扱い</h3>
        <p>
          特定個人情報等は、個人情報保護法により利用目的が限定されており、当社はその目的を超えて取得・利用しません。
          <br />
          個人情報保護法で認められている場合を除き、特定個人情報等を第三者に提供しません。
        </p>
        <h3>
          第5条
          個人情報を与えることの任意性及び当該情報を与えなかった場合に生じる結果
        </h3>
        <p>
          当社が取得する個人情報は、全てユーザーの意思によってご提供いただくものです。なお、必須項目をご提供いただけない場合は、
          <br />
          本サービスの利用をお断りする場合がございますので、あらかじめご了承下さい。
        </p>
        <h3>第6条 継続的な改善</h3>
        <p>
          当社は、社会情勢等の変化を踏まえ、個人情報保護のための管理体制及び取組を継続的に見直し、改善いたします。
        </p>
        <h3>第7条 条項の改定について</h3>
        <p>
          当社は、法令等に定めのある場合を除き、本条項を適宜改定できるものとし、当該改定内容がご本人に重大な影響を及ぼすおそれがある場合には、
          <br />
          ご本人に通知し、または当社ホームページ等を通じて適切な方法で告知するものとします。
        </p>
        <h3>第8条 匿名加工情報及び仮名加工情報の取扱</h3>
        <p>
          当社が、匿名加工情報または仮名加工情報の取り扱いを行う場合は、個人情報保護法及びガイドラインに従って適正に取り扱うとともに、
          <br />
          安全管理について適切な措置を講じます。
        </p>
        <h3>第9条 個人情報に関する権利</h3>
        <p>
          当社は、ユーザーご本人からの求めにより、当社が保有する個人情報の利用目的の通知、開示、内容の訂正、
          <br />
          追加又は削除・利用の停止・消去及び第三者への提供の停止（「開示等」といいます。）に応じます。
          <br />
          ご請求者がご本人であることをご確認させていただくとともに、当社所定の請求書類等をご提出いただいたうえで手続きを行い、
          <br />
          後日、書面または電磁的記録にて回収させていただきます。なお、ご本人以外の方からのご請求については、
          <br />
          代理権の存在を示す資料（委任状など）のご提出が必要となります。
          <br />
          <br />
          開示、訂正、削除等に関する窓口は、以下の「お問い合わせ先」をご覧下さい。
          <br />
        </p>
        <h3>[個人情報保護管理者]</h3>
        <p>株式会社エイダス 李 知炯</p>
        <h3>[個人情報についてのお問い合わせ先]</h3>
        <p>
          当社のお客様等の個人情報の取扱いに関するご質問やご要望、苦情等につきましては、下記お問い合わせ窓口までお申し出ください。
        </p>
        <h3>株式会社エイダス</h3>
        <p>
          電話：06-6655-0328（受付時間：平日10時～19時）
          <br />
          メールアドレス：property@aders-international.com
        </p>
      </TermsContent>
    </>
  );
};

export default PrivacyPolicyContent;

// Styled Components
const Title = styled.h1`
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
`;

const TermsContent = styled.div`
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.5;

  h3 {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  &::-webkit-scrollbar {
    width: 4px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: none;
  }
`;
