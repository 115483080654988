import styled from "styled-components";
import useDeviceSize from "../../../hooks/useDeviceSize";
import { mansionListDataType } from "../Mansion";
import usePropertyState from "../../../hooks/usePropertyState";
import { useRecoilState } from "recoil";
import { useEffect, useRef, useState } from "react";
import { mansionPropertyDataStateAtom } from "../../../recoil/mansionPage/mansionPropertyState";
import arrowIcon from "../../../assets/images/ResidentReview/DownIcon.png";
import filterIcon from "../../../assets/images/mypage/settings.png";
import MansionMobileSortOption from "./MansionMobileSortOption";
import MansionOptionBar from "./MobileOptionBar";
import MansionOptionModal from "./MobileOptionModal";

function MansionContentsHeader({
  mansionState,
}: {
  mansionState?: mansionListDataType;
}) {
  const { pagination, updatePagination } = usePropertyState();
  const [mansionPropertyState, setMansionPropertyState] = useRecoilState(
    mansionPropertyDataStateAtom
  );

  const [paginationAreaState, setPaginationAreaState] = useState<
    "size" | "sort" | null
  >(null);

  const [mobileModalState, setMobileModalState] = useState<boolean>(false);

  const { isPC, isLaptop, isMobile } = useDeviceSize();

  const selectAreaRef = useRef<HTMLDivElement | null>(null);

  const SORTOPTION = [
    {
      name: "면적 넓은 순",
      propertyName: "WideOrder",
    },
    {
      name: "월세 낮은 순",
      propertyName: "LowestRent",
    },
    {
      name: "월세 높은 순",
      propertyName: "HighestRent",
    },
    {
      name: "최신 건축 순",
      propertyName: "LatestConstructed",
    },
  ];

  const convertedCategoryId = () => {
    switch (pagination.detailCategoryId) {
      case 1:
        return "임대 > 맨션";
      case 2:
        return "임대 > 주택";
      case 3:
        return "임대 > 단층맨션";
      case 4:
        return "임대 > 기타";
      case 5:
        return "매매 > 토지";
      case 6:
        return "매매 > 맨션";
      case 7:
        return "매매 > 점포";
      case 8:
        return "매매 > 주택";
      case 9:
        return "매매 > 기타";
      case 10:
        return "점포 > 사무실";
      case 11:
        return "점포 > 창고";
      case 12:
        return "점포 > 점포";
      case 13:
        return "점포 > 기타";
      case 14:
        return "하우버맨션";
    }
  };

  const convertedSortOption = (
    option: "WideOrder" | "LowestRent" | "HighestRent" | "LatestConstructed"
  ) => {
    switch (option) {
      case "WideOrder":
        return "면적 넓은 순";
      case "LowestRent":
        return "월세 낮은 순";
      case "HighestRent":
        return "월세 높은 순";
      case "LatestConstructed":
        return "최신 건축 순";
    }
  };

  useEffect(() => {
    setPaginationAreaState(null);
  }, [mansionPropertyState.size, mansionPropertyState.sortOption]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectAreaRef.current &&
        !selectAreaRef.current.contains(event.target as Node)
      ) {
        setPaginationAreaState(null);
      }
    };

    if (paginationAreaState) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [paginationAreaState]);

  return (
    <>
      {isMobile && mobileModalState && (
        <MansionOptionModal
          closeModal={() => {
            setMobileModalState(false);
          }}
        ></MansionOptionModal>
      )}
      {isMobile && (
        <MobileTitleArea>
          <>{convertedCategoryId()}</>
          <MobileTitle>{convertedCategoryId()?.split(">")[0]}</MobileTitle>
        </MobileTitleArea>
      )}
      <ContentsHeaderWrapper>
        <MaxItem>
          총 {mansionState ? mansionState?.totalElements : 0} 건
        </MaxItem>
        {!isMobile && (
          <FilterDiv>
            <FilterButton
              width={isPC ? 100.5 : 84.5}
              onClick={() => setPaginationAreaState("size")}
            >
              {mansionPropertyState.size}건 <StyledArrowImage src={arrowIcon} />
              {paginationAreaState === "size" && (
                <SelectArea ref={selectAreaRef}>
                  {[10, 20, 30, 40, 50].map((item, index) => (
                    <SelectItem
                      key={index}
                      onClick={() => {
                        setPaginationAreaState(null);
                        setMansionPropertyState({
                          ...mansionPropertyState,
                          size: item,
                        });
                      }}
                      $isSelected={item === mansionPropertyState.size}
                    >
                      {item}건
                    </SelectItem>
                  ))}
                </SelectArea>
              )}
            </FilterButton>
            <FilterButton
              width={isPC ? 184.5 : 155}
              onClick={() => setPaginationAreaState("sort")}
            >
              {convertedSortOption(
                mansionPropertyState.sortOption as
                  | "WideOrder"
                  | "LowestRent"
                  | "HighestRent"
                  | "LatestConstructed"
              )}
              <StyledArrowImage src={arrowIcon} />
              {paginationAreaState === "sort" && (
                <SelectArea ref={selectAreaRef}>
                  {SORTOPTION.map((item, index) => (
                    <SelectItem
                      key={index}
                      onClick={() => {
                        setPaginationAreaState(null);
                        setMansionPropertyState({
                          ...mansionPropertyState,
                          sortOption: item.propertyName,
                        });
                      }}
                      $isSelected={
                        item.propertyName === mansionPropertyState.sortOption
                      }
                    >
                      {item.name}
                    </SelectItem>
                  ))}
                </SelectArea>
              )}
            </FilterButton>
          </FilterDiv>
        )}
        {isMobile && (
          <>
            <FilterDiv>
              <StyledFilterImage
                src={filterIcon}
                onClick={() => setMobileModalState(true)}
              />
              <MobileFilterButton
                onClick={() => {
                  setPaginationAreaState("sort");
                }}
              >
                정렬 <StyledArrowImage src={arrowIcon} />
              </MobileFilterButton>
            </FilterDiv>
            {paginationAreaState === "sort" && (
              <ModalOverlay onClick={() => setPaginationAreaState(null)}>
                <ModalContent onClick={(e) => e.stopPropagation()}>
                  <MansionMobileSortOption />
                </ModalContent>
              </ModalOverlay>
            )}
          </>
        )}
      </ContentsHeaderWrapper>
    </>
  );
}

export default MansionContentsHeader;

const ContentsHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MaxItem = styled.div`
  font-weight: bold;
  @media screen and (min-width: 1280px) {
    font-size: 23px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 19px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
  }
`;

const FilterDiv = styled.div`
  display: flex;
  align-items: center;
  @media screen and (min-width: 1280px) {
    gap: 14px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 12px;
  }
  @media screen and (max-width: 1023px) {
    gap: 8px;
  }
`;

const FilterButton = styled.div<{ width: number }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  width: ${(props) => `${props.width}px`};
  border: 1px solid #cccccc;
  border-radius: 10px;
  cursor: pointer;
  @media screen and (min-width: 1280px) {
    height: 41px;
    font-size: 16px;
    padding: 0 15px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    height: 34.5px;
    font-size: 13px;
    padding: 0 12px;
  }
`;

const SelectArea = styled.div`
  align-self: baseline;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 10px;
  background-color: white;
  @media screen and (min-width: 1280px) {
    left: 0;
    top: 0;
    transform: translateY(41px);
    padding: 8px 6px;
    gap: 7px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    left: 0;
    top: 0;
    transform: translateY(34.5px);
    padding: 6px 5px;
    gap: 6px;
  }
`;

const SelectItem = styled.div<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  background-color: ${(props) => props.$isSelected && "#f5ebeb"};
  @media screen and (min-width: 1280px) {
    padding-left: 11px;
    height: 26.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding-left: 9px;
    height: 22px;
  }
`;

const MobileTitleArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  gap: 8px;
  font-size: 10px;
`;

const MobileTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const StyledFilterImage = styled.img`
  width: 16px;
  height: 22px;
  transform: rotate(90deg) scaleX(-1);
`;

const MobileFilterButton = styled.div`
  position: relative;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  width: 57.5px;
  height: 28.5px;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
`;

const StyledArrowImage = styled.img`
  @media screen and (min-width: 1280px) {
    width: 11.5px;
    height: 5.75px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 10px;
    height: 5px;
  }
  @media screen and (max-width: 1023px) {
    width: 10px;
    height: 5px;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 2000;
`;

const ModalContent = styled.div`
  z-index: 2200;
  width: 100%;
  height: 286px;
  background-color: white;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  animation: slide-up 0.3s forwards;
  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;
