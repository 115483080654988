import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import PrivacyPolicyContent from "../../PrivacyPolicyContent";
import TermsAgreeContent from "../../TermsAgreeContent";
import {useState} from "react";
import Modal from "../../Modal";

function FooterRight() {
    const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
    const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

    const openPrivacyModal = () => setIsPrivacyModalOpen(true);
    const closePrivacyModal = () => setIsPrivacyModalOpen(false);
    const openTermsModal = () => setIsTermsModalOpen(true);
    const closeTermsModal = () => setIsTermsModalOpen(false);


    const navigate = useNavigate();
  return (
    <Wrapper>
      <FooterNavigationButton onClick={openPrivacyModal}>
          개인정보처리방침
      </FooterNavigationButton>
      <FooterNavigationButton onClick={openTermsModal}>
          이용약관
      </FooterNavigationButton>
      <FooterNavigationButton onClick={() => navigate("/qna/home")}>
        Q＆A
      </FooterNavigationButton>
        {/* 개인정보처리방침 모달 */}
        <Modal isOpen={isPrivacyModalOpen} onClose={closePrivacyModal}>
            <PrivacyPolicyContent />
        </Modal>

        {/* 이용약관 모달 */}
        <Modal isOpen={isTermsModalOpen} onClose={closeTermsModal}>
            <TermsAgreeContent />
        </Modal>
    </Wrapper>
  );
}

export default FooterRight;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1280px) {
    width: 30%;
    padding: 7px 0;
    padding-left: 57px;
    gap: 15px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 25%;
    padding: 5px 0;
    padding-left: 50.5px;
    gap: 12px;
  }
`;

const FooterNavigationButton = styled.div`
  cursor: pointer;
`;
