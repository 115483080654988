import React, { useState } from "react";
import { styled } from "styled-components";
import useChoiceAreaModalState from "../../store/modal/choiceArea";
import PortalWrapper from "../PortalWrapper";
import map from "../../assets/images/mansionPage/map.png";
import { FaChevronLeft } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import CheckBoxList from "./CheckBoxList";
import useCheckItems from "../../store/modal/checkItems";
import Preview from "./Preview";
import useDetailChoiceOpenModalState from "../../store/modal/detailChoice";
import DataOptionBox from "./DataOptionBox";
import LineCheckBoxList from "./CheckBoxList/LineCheckBoxList";

type CityType = { id: number; name: string };
type LineType = { id: number; name: string };
export type RegionInner = {
  name: string;
  cityList: CityType[];
  id: number;
  propertyCount: number;
};
export type RegionInnerType = {
  regionData: RegionInner[];
};

export type RegionLineInner = {
  name: string;
  lineList: LineType[];
  id: number;
};
export type RegionLineInnerType = {
  regionLineData: RegionLineInner[];
};

const ChoiceArea = () => {
  const { close, dataType } = useChoiceAreaModalState();
  const [step, setStep] = useState<number>(0);
  const [regionInner, setRegionInner] = useState<RegionInnerType | null>(null);
  const [regionLineInner, setRegionLineInner] =
    useState<RegionLineInnerType | null>(null);
  const { init } = useCheckItems();
  const { close: detailClose } = useDetailChoiceOpenModalState();

  return (
    <PortalWrapper>
      <Container>
        <CloseButton
          onClick={() => {
            close();
            init();
          }}
          size={"30"}
          color={"gray"}
        />
        {step !== 0 && (
          <BackButton
            onClick={() => {
              setStep((prev) => (prev -= 1));
              init();
              detailClose();
            }}
          >
            <FaChevronLeft size={"17"} />
            <span>뒤로가기</span>
          </BackButton>
        )}
        {step === 0 ? (
          <InnerContentBox>
            <Title>
              {dataType === "location" ? "지역" : "노선"}을 선택해주세요
            </Title>
            <StyledImage src={map} />
            <DataOptionBox
              dataType={dataType}
              setRegionInner={setRegionInner}
              setRegionLineInner={setRegionLineInner}
              setStep={setStep}
            />
          </InnerContentBox>
        ) : (
          <SecondInnerContentBox>
            <Left>
              {regionInner && <CheckBoxList {...regionInner} />}
              {regionLineInner && <LineCheckBoxList {...regionLineInner} />}
            </Left>
            <Right>
              <Preview dataType={dataType} />
            </Right>
          </SecondInnerContentBox>
        )}
      </Container>
    </PortalWrapper>
  );
};

export default ChoiceArea;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: white;
  width: 100%;
  height: 90vh;
  color: #333333;
  @media screen and (min-width: 1280px) {
    padding: 60px 45px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 45px 44px;
  }
  @media screen and (max-width: 1023px) {
    padding: 40px 20px;
  }
`;

const Title = styled.div`
  position: absolute;
  color: #555555;
  z-index: 100;
  font-weight: 600;
  opacity: 0.5;
  @media screen and (min-width: 1280px) {
    top: 20px;
    left: 20px;
    font-size: 23px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    top: 15px;
    left: 15px;
    font-size: 19px;
  }
  @media screen and (max-width: 1023px) {
    top: 9px;
    left: 9px;
    font-size: 15px;
  }
`;
const CloseButton = styled(IoClose)`
  position: absolute;
  cursor: pointer;
  @media screen and (min-width: 1280px) {
    top: 20px;
    right: 20px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    top: 15px;
    right: 15px;
  }
  @media screen and (max-width: 1023px) {
    top: 5px;
    right: 5px;
  }
`;

const StyledImage = styled.img`
  z-index: 10;
  border-radius: 10px;
  @media screen and (min-width: 1280px) {
    width: 650px;
    height: 650px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 600px;
    height: 600px;
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
    height: 70%;
  }
`;

const InnerContentBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #dedede;
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 2%;
`;
const SecondInnerContentBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 1023px) {
    flex-direction: column;
    gap: 30px;
  }
`;
const Left = styled.div`
  width: 69%;
  height: 100%;
  border: 1px solid #dedede;
  background-color: white;
  border-radius: 10px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: none;
  }
  @media screen and (min-width: 1280px) {
    padding: 30px 25px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 30px 25px;
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
    padding: 20px 15px;
  }
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 29%;
  @media screen and (max-width: 1023px) {
    width: 100%;
    justify-content: flex-end;
  }
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  position: absolute;
  cursor: pointer;
  color: gray;
  border: transparent;
  background-color: transparent;

  span {
    font-weight: 500;
    font-size: 15px;
  }
  @media screen and (min-width: 1280px) {
    top: 20px;
    left: 20px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    top: 15px;
    left: 15px;
  }
  @media screen and (max-width: 1023px) {
    top: 5px;
    left: 5px;
  }
`;
