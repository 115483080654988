import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reset from "styled-reset";
import { createGlobalStyle } from "styled-components";
import { RecoilRoot } from "recoil";
import ScrollToTop from "./components/ScrollTopFix";

const GlobalStyle = createGlobalStyle`
 ${reset}
 div, header, footer{
   box-sizing: border-box;
 }

`;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <ScrollToTop>
      <RecoilRoot>
        <div id="choiceArea"></div>
        <div id="mansionMobileOption"></div>
        <GlobalStyle />
        <App />
      </RecoilRoot>
    </ScrollToTop>
  </BrowserRouter>
);
