import styled from "styled-components";
import checkIcon from "../../../assets/images/mansionManagement/check.png";
import arrowIcon from "../../../assets/images/ResidentReview/DownIcon.png";
import { useEffect, useRef, useState } from "react";
import { Text } from "./Map.style";
import usePropertyState from "../../../hooks/usePropertyState";
import PortalWrapper from "../../../components/PortalWrapper";

interface props {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}
function CategoryModal({setOpenModal}: props) {
  const modalRef = useRef<HTMLDivElement>(null);
  const { pagination, updatePagination } = usePropertyState();
  const [nowCategory, setNowCategory] = useState<number>(pagination.detailCategoryId)

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        setOpenModal(prev=>!prev)
      }
    };
    window.addEventListener('mousedown', handleClick);
    return () => window.removeEventListener('mousedown', handleClick);
  }, [modalRef, nowCategory]);

  const convertedCategoryId = (id: number) => {
    switch (id) {
      case 1:
        return "맨션";
      case 2:
        return "주택";
      case 3:
        return "단층맨션";
      case 4:
        return "기타";
      case 5:
        return "토지";
      case 6:
        return "맨션";
      case 7:
        return "점포";
      case 8:
        return "주택";
      case 9:
        return "기타";
      case 10:
        return "사무실";
      case 11:
        return "창고";
      case 12:
        return "점포";
      case 13:
        return "기타";
      case 14:
        return "하우버맨션";
    }
  };
  return (
    <PortalWrapper>
      <CategoryModalWrapper ref={modalRef}>
        <ModalTop>
          <Text $fontSize="20px" $fontWeight="600">
            카테고리 선택
          </Text>
          <ModalButton
            style={{ width: 68 }}
            onClick={() => {
              setOpenModal(prev=>!prev)
              updatePagination({ detailCategoryId: nowCategory });
            }}
          >
            선택
          </ModalButton>
        </ModalTop>
        <ModalCategoryArea>
          <ModalCategoryInner>
            임대
            {[1, 2, 3, 4].map((item, index) => (
              <CategoryItem
                key={index}
                onClick={() => setNowCategory(item)}
              >
                <ArrowIcon src={arrowIcon} />
                {convertedCategoryId(item)}
                {item === nowCategory && <CheckIcon src={checkIcon} />}
              </CategoryItem>
            ))}
          </ModalCategoryInner>
          <ModalCategoryInner>
            매매
            {[5, 6, 7, 8, 9].map((item, index) => (
              <CategoryItem
                key={index}
                onClick={() => setNowCategory(item)}
              >
                <ArrowIcon src={arrowIcon} />
                {convertedCategoryId(item)}
                {item === nowCategory && <CheckIcon src={checkIcon} />}
              </CategoryItem>
            ))}
          </ModalCategoryInner>
          <ModalCategoryInner>
            점포{" "}
            {[10, 11, 12, 13].map((item, index) => (
              <CategoryItem
                key={index}
                onClick={() => setNowCategory(item)}
              >
                <ArrowIcon src={arrowIcon} />
                {convertedCategoryId(item)}
                {item === nowCategory && <CheckIcon src={checkIcon} />}
              </CategoryItem>
            ))}
          </ModalCategoryInner>
          <ModalCategoryInner>
            하우버 맨션
            <CategoryItem onClick={() => setNowCategory(14)}>
              <ArrowIcon src={arrowIcon} />
              {convertedCategoryId(14)}
              {14 === nowCategory && <CheckIcon src={checkIcon} />}
            </CategoryItem>
          </ModalCategoryInner>
        </ModalCategoryArea>
      </CategoryModalWrapper>
      </PortalWrapper>
  );
}

export default CategoryModal;

const CategoryModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 24px;
  width: 747px;
  height: 435px;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.1);
  z-index: 3;
  @media screen and (max-width: 1023px) {
    width: 400px;
    height: fit-content;
    max-height: 100vh;
    overflow: auto;
    overflow:auto ;
  }
`;

const ModalTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
`;

const ModalButton = styled.button`
  font-family: "Noto Sans KR";
  border: none;
  font-size: 18px;
  border-radius: 5px;
  font-weight: 600;
  background-color: #e6e6e6;
  height: 100%;
  cursor: pointer;
  @media screen and (max-width: 1023px) {
    font-size: 16px; /* 모바일에서 폰트 크기 조정 */
  }
`;

const ModalCategoryArea = styled.div`
  width: 100%;
  display: flex;
  margin-top: 28px;
  @media screen and (min-width: 1024px) {
    justify-content: center;
    margin-left: 12px;
  }
  @media screen and (max-width: 1023px) {
    flex-wrap: wrap; /* 모바일에서 카테고리 리스트가 넘치면 줄바꿈 */
    margin-top: 20px; /* 모바일에서 여백을 줄임 */
    justify-content: space-between
  }
`;

const ModalCategoryInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  font-size: 20px;
  width: 152px;

  @media screen and (max-width: 1023px) {
    gap: 22px;
    font-size: 16px;
    width: fit-content;
    padding-right: 40px;
    margin-bottom: 32px;
  }
`;

const CategoryItem = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  cursor: pointer;
  @media screen and (max-width: 1023px) {
    width: 120px;
  }
`;

const ArrowIcon = styled.img`
  height: 8px;
  width: 15px;
  transform: rotate(270deg);
`;

const CheckIcon = styled.img`
  width: 25px;
  height: 17px;
`;

