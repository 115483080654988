import styled from "styled-components";
import { useAdminRegistrationRoomState } from "../../../recoil/mansionManagement/adminRegistrationRoomStateAtom";
import { Fragment } from "react/jsx-runtime";
import addIcon from "../../../assets/images/mansionManagement/add.png";
import deleteIcon from "../../../assets/images/mansionManagement/delete.png";

function RoomRegistrationMonthlyExpense({ roomIndex }: { roomIndex: number }) {
  const { adminRegistrationRoomState, handleUpdateRoom } =
    useAdminRegistrationRoomState();

  const formatInput = (input: string) => {
    const cleaned = input.replace(/\D/g, ""); // 숫자가 아닌 모든 문자를 제거

    return cleaned;
  };

  const roomInfo = adminRegistrationRoomState[roomIndex];

  const handleAddExpense = () => {
    handleUpdateRoom(roomIndex, {
      ...adminRegistrationRoomState[roomIndex],
      moreFixedCost: [...roomInfo.moreFixedCost, { key: "", value: "" }],
    });
  };

  const deleteMoreExpense = (index: number) => {
    const updatedMoreInfo = roomInfo.moreFixedCost.filter(
      (_, i) => i !== index
    );
    handleUpdateRoom(roomIndex, {
      ...adminRegistrationRoomState[roomIndex],
      moreFixedCost: updatedMoreInfo,
    });
  };

  const handleMoreExpenseChange = (
    index: number,
    field: "key" | "value",
    value: string
  ) => {
    const updatedMoreExpense = roomInfo.moreFixedCost.map((info, i) =>
      i === index ? { ...info, [field]: value } : info
    );
    handleUpdateRoom(roomIndex, {
      ...adminRegistrationRoomState[roomIndex],
      moreFixedCost: updatedMoreExpense,
    });
  };

  const sumOfMoreExpense = () => {
    let sum = 0;
    roomInfo.moreFixedCost.map((item, index) => {
      sum += Number(item.value);
    });
    return sum;
  };

  const formatNumberWithCommas = (input: string) => {
    return input.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const removeCommas = (input: string) => {
    return input.replace(/,/g, "");
  };

  return (
    <ExpenseWrapper>
      <ExpenseTitle>
        월고정비용{" "}
        <AddItemButton onClick={handleAddExpense} src={addIcon}></AddItemButton>
      </ExpenseTitle>
      <ExpenseItemWrapper>
        <ExpenseItem>
          월세
          <ExpenseInput
            value={formatNumberWithCommas(
              adminRegistrationRoomState[roomIndex].rent
            )}
            onChange={(e) => {
              const format = formatInput(removeCommas(e.target.value));

              handleUpdateRoom(roomIndex, {
                ...adminRegistrationRoomState[roomIndex],
                rent: format,
              });
            }}
          ></ExpenseInput>
        </ExpenseItem>
        <ExpenseItem>
          관리비
          <ExpenseInput
            value={formatNumberWithCommas(
              adminRegistrationRoomState[roomIndex].managementFee
            )}
            onChange={(e) => {
              const format = formatInput(removeCommas(e.target.value));

              handleUpdateRoom(roomIndex, {
                ...adminRegistrationRoomState[roomIndex],
                managementFee: format,
              });
            }}
          ></ExpenseInput>
        </ExpenseItem>
        {roomInfo.moreFixedCost.map((item, index) => (
          <MoreExpenseWrapper key={index}>
            <MoreExpenseNameInput
              value={item.key}
              onChange={(e) =>
                handleMoreExpenseChange(index, "key", e.target.value)
              }
            />
            <MoreExpenseValueInput
              value={formatNumberWithCommas(item.value)}
              onChange={(e) => {
                const format = formatInput(removeCommas(e.target.value));

                handleMoreExpenseChange(index, "value", format);
              }}
            />
            <DeleteMoreExpenseButton
              src={deleteIcon}
              onClick={() => deleteMoreExpense(index)}
            ></DeleteMoreExpenseButton>
          </MoreExpenseWrapper>
        ))}
        <ExpenseItem>
          합계
          <InfoBox>
            {(
              Number(roomInfo.rent) +
              Number(roomInfo.managementFee) +
              sumOfMoreExpense()
            ).toLocaleString()}
          </InfoBox>
        </ExpenseItem>
      </ExpenseItemWrapper>
    </ExpenseWrapper>
  );
}

export default RoomRegistrationMonthlyExpense;

const ExpenseWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding-left: 23px;
  padding: 84px 0;
  border-bottom: 2px solid #e6e6e6;
  justify-content: space-between;
`;

const ExpenseTitle = styled.div`
  display: flex;
  font-size: 25px;
  font-weight: bold;
  margin-top: 8px;
  letter-spacing: -2px;
  gap: 10.5px;
  font-weight: 600;
  padding-left: 23px;
`;

const AddItemButton = styled.img`
  width: 27px;
  height: 27px;
  cursor: pointer;
`;

const ExpenseItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 904px;
  gap: 33px;
`;

const ExpenseItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 313.5px;
  font-size: 20px;
  font-weight: 500;
`;

const ExpenseInput = styled.input`
  width: 190px;
  height: 60px;
  font-size: 18px;
  background-color: #f7f8f9;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 15px;
  font-family: "Noto Sans KR";
  font-weight: 500;
`;

const InfoBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 190px;
  height: 60px;
  font-size: 18px;
  background-color: #f7f8f9;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 15px;
`;

const MoreExpenseWrapper = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  width: 313.5px;
  font-size: 20px;
  gap: 20px;
`;

const MoreExpenseNameInput = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  font-size: 20px;
  font-family: "Noto Sans KR";
  font-weight: 500;
`;
const MoreExpenseValueInput = styled.input`
  display: flex;
  align-items: center;
  width: 190px;
  height: 60px;
  font-size: 18px;
  background-color: #f7f8f9;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 15px;
  font-family: "Noto Sans KR";
  font-weight: 500;
`;

const DeleteMoreExpenseButton = styled.img`
  width: 27px;
  height: 27px;
  position: absolute;
  right: -50px;
  cursor: pointer;
`;
