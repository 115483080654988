import React from 'react';
import { createPortal } from 'react-dom';
import { styled } from 'styled-components';
import ResponsiveWrapper from './ResponsiveWrapper';

const PortalWrapper = ({ children }: { children: React.ReactElement }) => {
  const root = document.getElementById('choiceArea');
  return (
    root &&
    createPortal(
      <Wrapper>
        <ResponsiveWrapper>{children}</ResponsiveWrapper>
      </Wrapper>,
      root
    )
  );
};

export default PortalWrapper;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999999;
  font-family: 'Noto Sans KR';
`;
