import styled from "styled-components";
// import ResponsiveWrapper from "../../ResponsiveWrapper";
import { useNavigate } from "react-router-dom";
import adersLogo from "../../../assets/images/mainPage/adersLogo.png";
import PrivacyPolicyContent from "../../PrivacyPolicyContent";
import TermsAgreeContent from "../../TermsAgreeContent";
import {useState} from "react";
import Modal from "../../Modal";

function MobileFooter() {
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  const openPrivacyModal = () => setIsPrivacyModalOpen(true);
  const closePrivacyModal = () => setIsPrivacyModalOpen(false);
  const openTermsModal = () => setIsTermsModalOpen(true);
  const closeTermsModal = () => setIsTermsModalOpen(false);

  const navigate = useNavigate();

  return (
    <Wrapper>
      <FooterTop>
        <ResponsiveWrapper>
          <FooterTopInner>
            <MobileNavigationArea>
              <div onClick={openPrivacyModal}>개인정보처리방침</div>
              <div onClick={openTermsModal}>이용약관</div>
              <div onClick={() => navigate("/qna/home")}>Q＆A</div>
            </MobileNavigationArea>
            <MobileButtonArea>
              <MobileButton
                onClick={() => {
                  window.open("https://pf.kakao.com/_qTEPj");
                }}
              >
                카톡 상담
              </MobileButton>
              <MobileButton
                onClick={() => {
                  navigator.clipboard.writeText(
                    "property@aders-international.com"
                  );
                  alert(
                    `이메일이 클립보드에 복사되었습니다. property@aders-international.com`
                  );
                }}
              >
                이메일 문의
              </MobileButton>
            </MobileButtonArea>
            <MobileCustomerServiceArea>
              <div style={{ fontWeight: "bold" }}>고객센터 {">"}</div>
              <div style={{ fontWeight: "bold" }}>+81-6-6655-0328</div>
              <div>평일 10:00~19:00</div>
            </MobileCustomerServiceArea>
          </FooterTopInner>
        </ResponsiveWrapper>
      </FooterTop>
      <FooterBottom>
        <ResponsiveWrapper>
          <FooterBottomInner>
            <MobileFooterProfile>
              <MobileFooterProfileImage src={adersLogo} />
              株式会社エイダス
            </MobileFooterProfile>
            <MobileInformaionArea>
              <MobileInformation>代表者：李　知炯</MobileInformation>
              <MobileInformation>
                住所：大阪府大阪市中央区松屋町住吉6-8
              </MobileInformation>
              <MobileInformation>
                宅地建物取引業免許番号：大阪府知事(1)第6236 1号
              </MobileInformation>
              <MobileInformation>
                行政書士登録番号：24261578
              </MobileInformation>
            </MobileInformaionArea>
          </FooterBottomInner>
        </ResponsiveWrapper>
      </FooterBottom>
      <Modal isOpen={isPrivacyModalOpen} onClose={closePrivacyModal}>
        <PrivacyPolicyContent />
      </Modal>

      {/* 이용약관 모달 */}
      <Modal isOpen={isTermsModalOpen} onClose={closeTermsModal}>
        <TermsAgreeContent />
      </Modal>
    </Wrapper>
  );
}

export default MobileFooter;

const ResponsiveWrapper = styled.div`
  /* position: relative; */
  display: flex;
  flex-direction: column;
  /* border: 1px solid black; */
  @media screen and (min-width: 1280px) {
    width: 1280px;
    padding: 0 60px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 1024px;
    padding: 0 45px;
  }
  @media screen and (max-width: 1023px) {
    width: 425px;
    padding: 0 20px;
  }
`;

const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FooterTop = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 176px;
  border-bottom: 1px solid #c9c9c9;
  padding-top: 36.5px;
`;

const FooterTopInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const MobileNavigationArea = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 33px;
`;

const MobileButtonArea = styled.div`
  display: flex;
  /* position: relative; */
  margin-top: 18px;
  height: 34px;
  justify-content: space-between;
  width: 100%;
`;

const MobileButton = styled.button`
  width: 185px;
  height: 100%;
  border: 1px solid #c9c9c9;
  border-radius: 7px;
  background-color: white;
  font-size: 14px;
  font-family: "Noto Sans KR";
  color: #333333;
`;

const MobileCustomerServiceArea = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 16.5px;
`;
const FooterBottom = styled.div`
  display: flex;
  justify-content: center;
  height: 218px;
  width: 100%;
`;

const FooterBottomInner = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 30.5px;
  display: flex;
  flex-direction: column;
`;

const MobileFooterProfile = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
  font-family: "Noto Sans JP";
`;

const MobileFooterProfileImage = styled.img`
  width: 41px;
  height: 41px;
`;

const MobileInformaionArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 27.5px;
  gap: 13px;
  font-family: "Noto Sans JP";
`;

const MobileInformation = styled.div``;
