import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import ResponsiveWrapper from "../../../components/ResponsiveWrapper";
import { useScrapCount } from "../../../context/ScrapCountContext";
import axiosInstance from "../../../services/axiosConfig";

// 공통 스타일
const commonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #eee;
`;

const SubTabsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 65px 0px 25px 0px;

  @media screen and (max-width: 1023px) {
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 24px 0px;
  }
`;

interface TabProps {
  active?: boolean;
}

const SubTab = styled(Link)<TabProps>`
  white-space: nowrap;
  display: flex;
  justify-content: center;
  flex-direction: row;
  text-decoration: none;
  font-size: 23px;
  font-weight: bold;
  color: ${(props) => (props.active ? "#99393a" : "#000")};
  padding-right: 48px;
  cursor: pointer;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
    padding: 0px;
  }
`;

const Count = styled.div`
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

const MobileCount = styled.div`
  display: none;
  font-weight: 500;

  @media screen and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    padding: 21px 0;
    font-size: 15px;
  }
`;

interface TABProps {
  tab: number;
  setTab: (index: number) => void;
}

const Line = styled.div`
  @media screen and (max-width: 1023px) {
    display: flex;
    width: 100%;
    height: 1px;
    background-color: #ddd;
  }
`;

const SubTabs: React.FC<TABProps> = ({ tab, setTab }) => {
  const location = useLocation();
  const { counts, setCounts } = useScrapCount();

  useEffect(() => {
    if (location.pathname === "/mypage/scrab/all") setTab(0);
    else if (location.pathname.startsWith("/mypage/scrab/property")) setTab(1);
    else if (location.pathname === "/mypage/scrab/review") setTab(2);
  }, [location.pathname, setTab]);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const response = await axiosInstance.get("/mypage/count");

        const data = response.data;
        // console.log(data);

        setCounts({
          all: data.totalScrapCount,
          review: data.reviewScrapCount,
          room: data.roomScrapCount,
        });
      } catch (error) {
        // console.error("Failed to fetch counts:", error);
      }
    };

    fetchCounts();
  }, [setCounts]);

  const getCurrentCount = () => {
    switch (tab) {
      case 0:
        return counts.all;
      case 1:
        return counts.room;
      case 2:
        return counts.review;
      default:
        return 0;
    }
  };

  return (
    <>
      <ResponsiveWrapper>
        <SubTabsContainer>
          <SubTab
            to="/mypage/scrab/all"
            active={tab === 0}
            onClick={() => setTab(0)}
          >
            모두 <Count>({counts.all})</Count>
          </SubTab>
          <SubTab
            to="/mypage/scrab/property"
            active={tab === 1}
            onClick={() => setTab(1)}
          >
            맨션 <Count>({counts.room})</Count>
          </SubTab>
          <SubTab
            to="/mypage/scrab/review"
            active={tab === 2}
            onClick={() => setTab(2)}
          >
            입주후기 <Count>({counts.review})</Count>
          </SubTab>
        </SubTabsContainer>
      </ResponsiveWrapper>
      <Line />
      <ResponsiveWrapper>
        <MobileCount>{getCurrentCount()}건</MobileCount>
      </ResponsiveWrapper>
    </>
  );
};

export default SubTabs;
