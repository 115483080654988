// src/ScrabBook.tsx
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import SubTabs from "./components/SubTabs";
import { ScrapCountProvider } from "../../context/ScrapCountContext";

const ScrabBook: React.FC = () => {
  const [tab, setTab] = useState(0);

  return (
    <ScrapCountProvider>
      <SubTabs tab={tab} setTab={setTab} />
      <Outlet />
    </ScrapCountProvider>
  );
};

export default ScrabBook;
