import { atom } from "recoil";

export type logoDataType = {
  id: number | null;
  fileUrl?: string;
  file?: File | null;
};

export const logoStateAtom = atom<logoDataType>({
  key: "logoState",
  default: { id: null, fileUrl: "", file: null },
});
