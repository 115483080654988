import React from 'react'
import type { roomInfoI } from './MapPinDetail'
import { ListScroll, ListWrap } from './Map.style'
import RoomContentsItem from './RoomContentsItem'

interface props {
  roomList: roomInfoI[]|[]
}

export default function MapPinContentsList({roomList}:props) {
  return (
    <ListWrap>
      <ListScroll>
        {roomList.map((item)=>(
          <RoomContentsItem key={item.id}room={item}/>
        ))}
      </ListScroll>
    </ListWrap>
  )
}
