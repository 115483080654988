import styled from "styled-components";

function NotFound() {
  return <Wrapper>존재하지 않는 페이지입니다.</Wrapper>;
}

export default NotFound;

const Wrapper = styled.div`
  width: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 30px;
`;
