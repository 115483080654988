import { atom, useRecoilState } from "recoil";

export const mobileSearchStateAtom = atom<boolean>({
  key: "mobileSearchState",
  default: false,
});

export const useMobileSearchState = () => {
  const [mobileSearchState, setMobileSearchState] = useRecoilState(
    mobileSearchStateAtom
  );
  const modifyMobileSearchState = () =>
    setMobileSearchState(!mobileSearchState);
  return { mobileSearchState, modifyMobileSearchState, setMobileSearchState };
};
