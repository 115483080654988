import styled from "styled-components";
import logo from "../../../assets/images/adminMainDesign/logo-white.png";
import coloredLogo from "../../../assets/images/adminMainDesign/logo-colored.png";
import main from "../../../assets/images/adminMainDesign/main-white.png";
import coloredMain from "../../../assets/images/adminMainDesign/main-colored.png";
import { sidebarStateType } from "./MainDesignSidebar";

function SideBarHeader({
  sidebarState,
  getSidebarState,
}: {
  sidebarState: sidebarStateType;
  getSidebarState: (newState: sidebarStateType) => void;
}) {
  return (
    <SideBarHeaderWrpper>
      <ButtonItem>
        <StyledImage
          src={sidebarState === "logo" ? coloredLogo : logo}
          onClick={() => getSidebarState("logo")}
        />
        로고
      </ButtonItem>
      <ButtonItem>
        <StyledImage
          src={sidebarState === "main" ? coloredMain : main}
          onClick={() => getSidebarState("main")}
        />
        메인 페이지
      </ButtonItem>
    </SideBarHeaderWrpper>
  );
}

export default SideBarHeader;

const SideBarHeaderWrpper = styled.div`
  display: flex;
  width: 100%;
  height: 179px;
  border-bottom: 3px solid #e6e6e6;
  padding: 48px 49px;
  gap: 64px;
`;

const ButtonItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  font-size: 15px;
  font-weight: bold;
`;

const StyledImage = styled.img`
  width: 78px;
  height: 78px;
  cursor: pointer;
`;
