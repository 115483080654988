import styled from "styled-components";
import CloneMain from "./MainPreview/CloneMain";

function MainDesignPreview() {
  return (
    <MainDesignPreviewWrapper>
      <CloneMain />
    </MainDesignPreviewWrapper>
  );
}

export default MainDesignPreview;

const MainDesignPreviewWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;
`;
