// import styled from "styled-components";
import styled from "styled-components";
import scrap from "../../../assets/images/mainPage/scrap.png";
import scrapColord from "../../../assets/images/mainPage/scrap-colored.png";
import useDeviceSize from "../../../hooks/useDeviceSize";
import {
  popularMansionDataType,
  popularMansionItemType,
} from "../PopularMansion";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../services/axiosConfig";
import usePopularMansionData from "../../../recoil/mainPge/popularMansionDataStateAtom";

function PopularMansionItem({
  roomInfo,
}: {
  roomInfo?: popularMansionItemType;
}) {
  const scrapState = roomInfo && roomInfo?.isScrapbook;

  const navigate = useNavigate();
  const onClickThumbnail = () => {
    navigate(`/detail?roomId=${roomInfo?.id}`);
  };

  const { setPopularMansionDataState } = usePopularMansionData();

  const fetchPopularMansion = async () => {
    const response: { data: popularMansionDataType } = await axiosInstance.get(
      "/normal/home/detailBest"
    );
    setPopularMansionDataState(response.data);
  };

  const onClickScrap = async (e: React.MouseEvent) => {
    const token = window.sessionStorage.getItem("Authorization");

    e.stopPropagation();
    if (token) {
      try {
        const response = await axiosInstance.post(`/normal/user/scrap`, {
          category: "room",
          entityId: roomInfo?.id,
        });

        if (response.status === 200) {
          if (scrapState) {
            alert("스크랩 취소 되었습니다.");
            fetchPopularMansion();
          } else {
            alert("스크랩 되었습니다.");
            fetchPopularMansion();
          }
        }
      } catch (error) {
        alert("스크랩에 실패했습니다");
        console.log(error);
      }
    } else {
      alert("로그인 후 이용할 수 있는 서비스 입니다.");
      navigate("/login");
    }
  };

  const tagsString =
    roomInfo?.recommendPoints
      ?.split("$")
      .filter((item) => item !== "")
      .map((item) => `#${item}`)
      .join(" ") || "";

  const { isMobile } = useDeviceSize();
  return (
    <ItemWrapper>
      <ImageDiv onClick={onClickThumbnail}>
        <StyledImage src={roomInfo && roomInfo?.thumbnailUrl}></StyledImage>
        {roomInfo?.isScrapbook !== undefined && (
          <ScrapButton
            src={roomInfo?.isScrapbook ? scrapColord : scrap}
            onClick={onClickScrap}
          />
        )}
      </ImageDiv>
      {isMobile ? (
        <TitleWrapper>
          <Title>{roomInfo && roomInfo?.name}</Title>
          <Tag>{tagsString}</Tag>
        </TitleWrapper>
      ) : (
        <>
          <Title>{roomInfo && roomInfo?.name}</Title>
          <Tag>{tagsString}</Tag>
        </>
      )}
    </ItemWrapper>
  );
}

export default PopularMansionItem;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 1280px) {
    max-width: 364px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    max-width: 301px;
  }
  @media screen and (max-width: 1023px) {
    max-width: 186px;
    align-items: baseline;
  }
`;

const ImageDiv = styled.div`
  cursor: pointer;
  border-radius: 9px;
  overflow: hidden;
  position: relative;
  @media screen and (min-width: 1280px) {
    width: 364px;
    height: 242px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 301px;
    height: 200px;
  }
  @media screen and (max-width: 1023px) {
    width: 186px;
    height: 186px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 12px;
  row-gap: 7px;
  column-gap: 3px;
`;

const Title = styled.div`
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media screen and (min-width: 1280px) {
    max-width: 364px;
    font-size: 19px;
    margin-top: 14px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    max-width: 301px;
    font-size: 17px;
    margin-top: 15.5px;
  }
  @media screen and (max-width: 1023px) {
    max-width: 186px;
    font-size: 12px;
  }
`;

const Tag = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @media screen and (min-width: 1280px) {
    max-width: 364px;
    font-size: 16px;
    margin-top: 7.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    max-width: 301px;
    font-size: 15px;
    margin-top: 4.5px;
  }
  @media screen and (max-width: 1023px) {
    max-width: 186px;
    font-size: 12px;
  }
`;

const ScrapButton = styled.img`
  display: block;
  position: absolute;
  @media screen and (min-width: 1280px) {
    width: 17.5px;
    height: 17.5px;
    right: 17px;
    bottom: 17px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 19.5px;
    height: 19.5px;
    right: 15px;
    bottom: 15px;
  }
  @media screen and (max-width: 1023px) {
    width: 19.5px;
    height: 19.5px;
    right: 15px;
    bottom: 15px;
  }
`;
