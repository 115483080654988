import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled,{keyframes} from "styled-components";
import axios from "axios";

const GoogleOauth: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const code = new URL(window.location.href).searchParams.get("code");
    if (code) {
      const fetchData = async () => {
        try {
          const response = await getPrintCode(code, "google");

          const jwtToken = response.headers["authorization"];
          sessionStorage.setItem("Authorization", jwtToken);
          const refreshToken =  response.headers["refreshtoken"];
          sessionStorage.setItem("RefreshToken", refreshToken);

          if (response.status === 201) {
             navigate('/login/moreInfo');
          } else if(response.status===200) {
            navigate('/');
          }
        } catch (error) {
        }
      };

      fetchData();
    } else {
      navigate("/login");
    }
  }, []);

  async function getPrintCode(code: string, provider: string) {
    const res = await axios.post(`https://houberapp.com/auth/oauth`, {
      code,
      provider,
    });
    return res;
  }

  return (
    <SpinnerWrapper>
    <StyledSpinner />
  </SpinnerWrapper>
  );
};

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledSpinner = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid rgba(150, 150, 150, 0.3);
  border-top: 5px solid #99393a; 
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 

`;


export default GoogleOauth;
