import { useEffect, useState } from "react";
import styled from "styled-components";
import ResponsiveWrapper from "../../../../components/ResponsiveWrapper";
import { useRecoilValue } from "recoil";
import { categoryIconStateAtom } from "../../../../recoil/adminMainDesign/categoryIconStateAtom";

function CloneCategories({
  iconResponses,
}: {
  iconResponses?: {
    categoryName: string;
    fileId: number;
    fileType: "Icon";
    link: string;
    url: string;
  }[];
}) {
  const categoryIconState = useRecoilValue(categoryIconStateAtom);

  return (
    <ResponsiveWrapper>
      <Wrapper>
        <CategoriesBox>
          {categoryIconState &&
            categoryIconState.map((item, index) => (
              <CategoryItem key={index}>
                <CategoryImgWrapper>
                  <StyledImage src={item.fileUrl} alt={item.name}></StyledImage>
                </CategoryImgWrapper>
                {item.name}
              </CategoryItem>
            ))}
        </CategoriesBox>
      </Wrapper>
    </ResponsiveWrapper>
  );
}

export default CloneCategories;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 329px;
`;

const CategoriesBox = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const CategoryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  gap: 23px;
  font-size: 16px;
`;

const CategoryImgWrapper = styled.div`
  overflow: hidden;
  width: 92.5px;
  height: 92.5px;
`;

const StyledImage = styled.img`
  width: 100%;
`;
