import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CategoryModal from "./CategoryModal";
import searchIcon from "../../../assets/images/header/search-black.png";
import { useAdminMansionPropertyState } from "../../../recoil/mansionManagement/adminMansionPropertyStateAtom";
import AdminChoiceArea from "./AdminChoiceArea";
import arrowIcon from "../../../assets/images/ResidentReview/DownIcon.png";

function MansionManagememtHeader() {
  const navigate = useNavigate();
  const { adminMansionPropertyState, setAdminMansionPropertyState } =
    useAdminMansionPropertyState();
  const [categoryModalState, setCategoryModalState] = useState<boolean>(false);
  const [selectedCategoryState, setSelectedCategoryState] = useState<number[]>(
    []
  );
  const [searchInputState, setSearchInputState] = useState<string>("");

  const [choiceAreaModalState, setChoiceAreaModalState] =
    useState<boolean>(false);

  const getCategoryModalStae = (newState: boolean) => {
    setCategoryModalState(newState);
  };

  const getSelectedCategoryState = (newState: number[]) => {
    setSelectedCategoryState(newState);
  };

  const getChoiceAreaModalState = (newState: boolean) => {
    setChoiceAreaModalState(newState);
  };

  const handleSearch = () => {
    setAdminMansionPropertyState({
      ...adminMansionPropertyState,
      keyword: searchInputState,
      page: 1,
    });
  };

  return (
    <HeaderWrapper>
      {categoryModalState && (
        <CategoryModal
          getCategoryModalState={getCategoryModalStae}
          selectedCategoryState={selectedCategoryState}
          getSelectedCategoryState={getSelectedCategoryState}
        />
      )}
      {choiceAreaModalState && (
        <AdminChoiceArea
          getChoiceAreaModalState={getChoiceAreaModalState}
        ></AdminChoiceArea>
      )}
      <HeaderLeft>
        <HambergerButton onClick={() => setCategoryModalState(true)}>
          <CenterLine />
        </HambergerButton>
        <HeaderTitle>매물관리</HeaderTitle>
        <ChoiceAreaButton onClick={() => setChoiceAreaModalState(true)}>
          지역 선택 <ArrowIcon src={arrowIcon}></ArrowIcon>
        </ChoiceAreaButton>
      </HeaderLeft>
      <HeaderRight>
        <SearchDiv>
          <SearchInput
            value={searchInputState}
            onChange={(e) => setSearchInputState(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <SearchIcon
            src={searchIcon}
            onClick={() => {
              handleSearch();
            }}
          />
        </SearchDiv>
        <RegistrationMansion
          onClick={() => navigate("/admin/registration?type=add")}
        >
          + 매물 등록하기
        </RegistrationMansion>
      </HeaderRight>
    </HeaderWrapper>
  );
}

export default MansionManagememtHeader;

const HeaderWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 192px;
  align-items: center;
  justify-content: space-between;
  margin-top: 13px;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  padding-left: 21px;
`;

const HambergerButton = styled.div`
  display: flex;
  align-items: center;
  width: 32px;
  height: 20px;
  border-top: 2px solid #8e8e8e;
  border-bottom: 2px solid #8e8e8e;
  cursor: pointer;
`;
const CenterLine = styled.div`
  width: 100%;
  height: 1px;
  border-top: 2px solid #8e8e8e;
`;

const HeaderTitle = styled.div`
  margin-left: 23.35px;
  width: 184px;
  height: 61px;
  font-size: 50px;
  font-weight: bold;
  letter-spacing: -4px;
`;

const ChoiceAreaButton = styled.button`
  display: flex;
  align-items: center;
  margin-left: 10.65px;
  min-width: 126px;
  height: 36px;
  padding: 0 17px;
  border: 1px solid black;
  font-size: 21px;
  background-color: white;
  border-radius: 5px;
  font-family: "Noto Sans KR";
  cursor: pointer;
  gap: 6.5px;
  z-index: 1;
  font-weight: 500;
`;

const ArrowIcon = styled.img`
  width: 16px;
  height: 8.5px;
  transform: rotate(-90deg);
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

const SearchDiv = styled.div`
  display: flex;
  align-items: center;
  width: 371px;
  height: 50px;
  border: 2px solid #cdcdcd;
  border-radius: 25px;
  padding: 0 20px;
  gap: 20px;
`;

const SearchInput = styled.input`
  width: 287px;
  font-size: 21px;
  font-family: "Noto Sans KR";
  border: none;
  outline: none;
`;

const SearchIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const RegistrationMansion = styled.button`
  margin-left: 21px;
  width: 289px;
  height: 55px;
  background-color: #99393a;
  font-size: 25px;
  font-weight: 600;
  font-family: "Noto Sans KR";
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;
