import { useMediaQuery } from "react-responsive";

export default function useDeviceSize() {
  const isPC = useMediaQuery({ query: "(min-width: 1280px)" });
  const isLaptop = useMediaQuery({
    query: "(min-width:1024px) and (max-width:1279px)",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });

  return { isPC, isLaptop, isMobile };
}
