import styled from "styled-components";
import ResponsiveWrapper from "../../../components/ResponsiveWrapper";
import ContentsHeader from "./ContentsHeader";
import MainItem from "./MainItem";
import useDeviceSize from "../../../hooks/useDeviceSize";
import MoreInfoButton from "./MoreInfoButton";
import { useEffect, useState } from "react";
import Navigation, { getMaxPage } from "../../../components/Navigation";
import { reviewDataType } from "../Main";

function MansionReviews({
  latestReviews,
}: {
  latestReviews?: reviewDataType[];
}) {
  const { isPC, isLaptop, isMobile } = useDeviceSize();
  const [contentsPageState, setContentsPageState] = useState(0);
  const [currentPageReviews, setCurrentPageReviews] = useState<
    reviewDataType[]
  >([]);

  useEffect(() => {
    if (latestReviews) {
      const itemsPerPage = isMobile ? 2 : 3;
      const startIndex = contentsPageState * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      latestReviews &&
        setCurrentPageReviews(latestReviews.slice(startIndex, endIndex));
    }
  }, [contentsPageState, latestReviews, isMobile]);

  return (
    <ResponsiveWrapper>
      <ContentsHeader
        title="입주후기"
        subtitle="입주자들의 생생한 후기를 확인해보세요!"
        moreInfo="/residentreview"
      />
      <ContentsWrapper>
        {!latestReviews || (latestReviews && latestReviews?.length < 1) ? (
          <NoItemArea>조건에 해당하는 후기가 없습니다.</NoItemArea>
        ) : (
          <>
            <Navigation
              onClickNext={() => {
                setContentsPageState(contentsPageState + 1);
              }}
              onClickPrev={() => {
                setContentsPageState(contentsPageState - 1);
              }}
              page={contentsPageState}
              maxPage={getMaxPage(3, latestReviews?.length)}
              translateY={-13}
            />
            {!isMobile
              ? currentPageReviews &&
                currentPageReviews.map((item, index) => (
                  <MainItem
                    key={index}
                    width={isPC ? 361.5 : 299.5}
                    height={isPC ? 242.5 : 200.5}
                    itemTitleMargin={17}
                    category="review"
                    reviewInfo={item}
                  ></MainItem>
                ))
              : currentPageReviews &&
                currentPageReviews.map((item, index) => (
                  <MainItem
                    key={index}
                    width={187}
                    height={137}
                    itemTitleMargin={10}
                    category="review"
                    reviewInfo={item}
                  ></MainItem>
                ))}
          </>
        )}
      </ContentsWrapper>
      {isMobile && (
        <ButtonWrapper>
          <MoreInfoButton
            moreInfoText="입주후기 더보기"
            linkTo="/residentreview"
          ></MoreInfoButton>
        </ButtonWrapper>
      )}
    </ResponsiveWrapper>
  );
}

export default MansionReviews;

const ContentsWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  @media screen and (min-width: 1280px) {
    height: 269.5px;
    gap: 37px;
    margin: 23px 0;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    height: 227.5px;
    gap: 19px;
    margin: 20px 0;
  }
  @media screen and (max-width: 1023px) {
    height: 162px;
    justify-content: space-between;
    margin: 28px 0;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding-bottom: 70px;
`;

const NoItemArea = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #837474;
  @media screen and (min-width: 1280px) {
    font-size: 27px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 24px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 18px;
  }
`;
