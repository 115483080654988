import { useEffect, useState } from "react";
import styled from "styled-components";
import RegistrationHeader from "./components/RegistrationHeader";
import RoomRegistrationImage, {
  fileDataType,
} from "./components/RoomRegistrationImage";
import RoomRegistrationRoomNumber from "./components/RoomRegistrationRoomNumber";
import RoomRegistrationInfo from "./components/RoomRegistrationInfo";
import RoomRegistrationMonthlyExpense from "./components/RoomRegistrationMonthlyExpense";
import RoomRegistrationInitialCost from "./components/RoomRegistrationInitialCost";
import {
  registrationRoomDataType,
  useAdminRegistrationRoomState,
} from "../../recoil/mansionManagement/adminRegistrationRoomStateAtom";
import axiosInstance from "../../services/axiosConfig";
import { useLocation, useNavigate } from "react-router-dom";

type floorPlanImage = {
  fileType: "floorPlan";
  id?: number;
  url: string;
  file: File | null;
};

type roomImage = {
  fileType: "Room" | "floorPlan";
  id: number;
  url: string;
  file: File | null;
};

type roomDataType = {
  area: number;
  brokerageFee: number;
  contractTerm: number;
  direction: string;
  fireInsuranceCost: number;
  floorPlan: floorPlanImage;
  gratuity: number;
  guaranteeReviewFee: number;
  id: number;
  keyExchangeFee: number;
  managementFee: number;
  moreFixedCost?: string;
  moreInfo?: string;
  moreInitialCost?: string;
  numOfFloors: number;
  rent: number;
  roomImg: roomImage[];
  roomNum: number;
  roomType: string;
  status: string;
  structure: string;
  deposit: string;
  penaltyOption: string;
  penaltyAmount: string;
};

function RegistrationRoom() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const mansionId = queryParams.get("mansionId");
  const type = queryParams.get("type");
  const roomId = queryParams.get("roomId");

  const [roomIndexState, setRoomIndexState] = useState(0);

  const {
    adminRegistrationRoomState,
    handleRemoveRoom,
    handleAddRoom,
    handleResetRoom,
    handleUpdateRoom,
  } = useAdminRegistrationRoomState();

  const validateRoomData = () => {
    let invalidatePage: number[] = [];

    adminRegistrationRoomState.forEach((item, index) => {
      const pageIndex = index + 1;

      const addToInvalidatePage = () => {
        if (!invalidatePage.includes(pageIndex)) {
          invalidatePage = [...invalidatePage, pageIndex];
        }
      };

      if (
        !item.roomNumber ||
        (!item.roomImg && !item.addRoomImg) ||
        item.roomImg.length + item.addRoomImg.length < 1 ||
        (!item.floorPlan && type === "add") ||
        (!item.floorPlan.file && type === "add") ||
        !item.numOfFloors ||
        !item.area
      ) {
        addToInvalidatePage();
      }

      if (item.moreFixedCost && item.moreFixedCost.length > 0) {
        item.moreFixedCost.forEach((cost) => {
          if (!cost.value || !cost.key) {
            addToInvalidatePage();
          }
        });
      }

      if (item.moreInitialCost && item.moreInitialCost.length > 0) {
        item.moreInitialCost.forEach((cost) => {
          if (!cost.value || !cost.key) {
            addToInvalidatePage();
          }
        });
      }

      if (item.moreInfo && item.moreInfo.length > 0) {
        item.moreInfo.forEach((info) => {
          if (!info.value || !info.key) {
            addToInvalidatePage();
          }
        });
      }
    });

    return invalidatePage.join(",");
  };

  const convertedMoreInfo = (array: { key: string; value: string }[]) => {
    let convertedString = "";
    array.map((item, index) => {
      convertedString += "$" + item.key;
      convertedString += "@" + item.value;
    });

    return convertedString;
  };

  const reverseConvertedMoreInfo = (
    convertedString?: string
  ): { key: string; value: string }[] => {
    const resultArray: { key: string; value: string }[] = [];

    const items = convertedString && convertedString.split("$");

    items &&
      items.forEach((item) => {
        if (item) {
          const [key, value] = item.split("@");
          if (key && value !== undefined) {
            resultArray.push({ key, value });
          }
        }
      });

    return resultArray;
  };

  const convertToFormData = (
    data: registrationRoomDataType,
    status: "Temp" | "Public"
  ): FormData => {
    const formData = new FormData();

    data.roomNumber !== "" && formData.append(`roomNum`, data.roomNumber);
    formData.append("status", status);
    formData.append(`roomType`, data.roomType);
    data.area !== "" && formData.append(`area`, data.area.toString());
    data.numOfFloors !== "" && formData.append(`numOfFloors`, data.numOfFloors);
    formData.append(`contractTerm`, data.contractTerm.toString());
    formData.append(`structure`, data.structure);
    formData.append(`penaltyOption`, data.penaltyOption);
    formData.append(`penaltyAmount`, data.penaltyAmount);
    formData.append(`rent`, data.rent === "" ? "0" : data.rent);
    formData.append(`direction`, data.direction);
    formData.append(
      `managementFee`,
      data.managementFee === "" ? "0" : data.managementFee
    );
    formData.append(`deposit`, data.deposit === "" ? "0" : data.deposit);
    formData.append(`gratuity`, data.gratuity === "" ? "0" : data.gratuity);
    formData.append(
      `keyExchangeFee`,
      data.keyExchangeFee === "" ? "0" : data.keyExchangeFee
    );
    formData.append(
      `fireInsuranceCost`,
      data.fireInsuranceCost === "" ? "0" : data.fireInsuranceCost
    );
    formData.append(
      `guaranteeReviewFee`,
      data.guaranteeReviewFee === "" ? "0" : data.guaranteeReviewFee
    );
    formData.append(
      `brokerageFee`,
      data.brokerageFee === "" ? "0" : data.brokerageFee
    );
    formData.append(`moreFixedCost`, convertedMoreInfo(data.moreFixedCost));
    formData.append(`moreInitialCost`, convertedMoreInfo(data.moreInitialCost));
    formData.append(`moreInfo`, convertedMoreInfo(data.moreInfo));

    if (data.floorPlan.file) {
      formData.append(`floorPlan`, data.floorPlan.file);
    }

    data.deletedTargetImgId.forEach((id, imgIndex) => {
      if (id) {
        formData.append(`deletedTargetImgId[${imgIndex}]`, id.toString());
      }
    });
    data.addRoomImg.forEach((img, imgIndex) => {
      if (img.file) {
        formData.append(`addRoomImg[${imgIndex}]`, img.file);
      }
    });

    return formData;
  };

  const convertToPostFormData = (
    data: registrationRoomDataType,
    status: "Temp" | "Public"
  ): FormData => {
    const formData = new FormData();

    data.roomNumber !== "" && formData.append(`roomNum`, data.roomNumber);
    formData.append("status", status);
    formData.append(`roomType`, data.roomType);
    data.area !== "" && formData.append(`area`, data.area.toString());
    data.numOfFloors !== "" && formData.append(`numOfFloors`, data.numOfFloors);
    formData.append(`contractTerm`, data.contractTerm.toString());
    formData.append(`structure`, data.structure);
    formData.append(`penaltyOption`, data.penaltyOption);
    formData.append(`penaltyAmount`, data.penaltyAmount);
    formData.append(`rent`, data.rent === "" ? "0" : data.rent);
    formData.append(`direction`, data.direction);
    formData.append(
      `managementFee`,
      data.managementFee === "" ? "0" : data.managementFee
    );
    formData.append(`deposit`, data.deposit === "" ? "0" : data.deposit);
    formData.append(`gratuity`, data.gratuity === "" ? "0" : data.gratuity);
    formData.append(
      `keyExchangeFee`,
      data.keyExchangeFee === "" ? "0" : data.keyExchangeFee
    );
    formData.append(
      `fireInsuranceCost`,
      data.fireInsuranceCost === "" ? "0" : data.fireInsuranceCost
    );
    formData.append(
      `guaranteeReviewFee`,
      data.guaranteeReviewFee === "" ? "0" : data.guaranteeReviewFee
    );
    formData.append(
      `brokerageFee`,
      data.brokerageFee === "" ? "0" : data.brokerageFee
    );
    formData.append(`moreFixedCost`, convertedMoreInfo(data.moreFixedCost));
    formData.append(`moreInitialCost`, convertedMoreInfo(data.moreInitialCost));
    formData.append(`moreInfo`, convertedMoreInfo(data.moreInfo));

    if (data.floorPlan.file) {
      formData.append(`floorPlan`, data.floorPlan.file);
    }

    data.addRoomImg.forEach((img, imgIndex) => {
      if (img.file) {
        formData.append(`roomImg[${imgIndex}]`, img.file);
      }
    });

    return formData;
  };

  const handleSaveRoom = async () => {
    if (type === "add") {
      try {
        const promises = adminRegistrationRoomState.map((item, index) => {
          const formData = convertToPostFormData(
            adminRegistrationRoomState[index],
            "Temp"
          );
          return axiosInstance.post(
            `/admin/property/${mansionId}/room`,
            formData
          );
        });

        await Promise.all(promises);
        alert("방 등록에 성공했습니다.");
        navigate("/admin/mansionManagement");
      } catch (error) {
        console.error("Error in adding rooms:", error);
      }
    } else if (type === "modify" && mansionId) {
      try {
        const formData = convertToFormData(
          adminRegistrationRoomState[0],
          "Temp"
        );
        const response = await axiosInstance.put(
          `/admin/property/room/${roomId}`,
          formData
        );

        if (response.status === 200) {
          alert("호실 정보가 수정되었습니다.");
          navigate("/admin/mansionManagement");
        }
      } catch (error) {
        console.log(error);
        alert("호실 정보 수정에 실패했습니다.");
      }
    }
  };

  const handlePostRoom = async () => {
    if (validateRoomData().length < 1) {
      if (type === "add") {
        try {
          const promises = adminRegistrationRoomState.map((item, index) => {
            const formData = convertToPostFormData(
              adminRegistrationRoomState[index],
              "Public"
            );
            return axiosInstance.post(
              `/admin/property/${mansionId}/room`,
              formData
            );
          });

          await Promise.all(promises);
          alert("방 등록에 성공했습니다.");
          navigate("/admin/mansionManagement");
        } catch (error) {
          alert("방 등록에 실패했습니다.");
        }
      } else if (type === "modify" && roomId) {
        try {
          const formData = convertToFormData(
            adminRegistrationRoomState[0],
            "Public"
          );
          const response = await axiosInstance.put(
            `/admin/property/room/${roomId}`,
            formData
          );

          if (response.status === 200) {
            alert("호실 정보가 수정되었습니다.");
            navigate("/admin/mansionManagement");
          }
        } catch (error) {
          console.log(error);
          alert("호실 정보 수정에 실패했습니다.");
        }
      }
    } else {
      alert(`빈 입력값이 있습니다. [${validateRoomData()}]페이지`);
    }
  };

  const fetchPrevRoomData = async (roomId: string) => {
    try {
      const response: { data: roomDataType } = await axiosInstance.get(
        `/admin/property/room/${roomId}`
      );
      const data = response.data;

      const filteredRoomImg: fileDataType[] = response.data.roomImg
        .filter((img) => img.fileType !== "floorPlan")
        .map((img) => ({
          id: img.id,
          fileUrl: img.url,
          file: null,
        }));

      handleUpdateRoom(roomIndexState, {
        area: data.area.toString(),
        status: data.status as "Temp" | "Public",
        roomNumber: data.roomNum.toString(),
        structure: data.structure as
          | "woodcarving"
          | "rebar"
          | "ironFrame"
          | "other",
        brokerageFee: data.brokerageFee.toString(),
        contractTerm: data.contractTerm,
        deposit: data.deposit.toString(),
        direction: data.direction as "동향" | "서향" | "남향" | "북향",
        fireInsuranceCost: data.fireInsuranceCost.toString(),
        floorPlan: data.floorPlan
          ? {
              fileUrl: data.floorPlan.url || "",
              id: data.floorPlan.id,
            }
          : { fileUrl: "", id: undefined },
        gratuity: data.gratuity.toString(),
        roomType: data.roomType,
        roomImg: filteredRoomImg,
        addRoomImg: [],
        deletedTargetImgId: [],
        guaranteeReviewFee: data.guaranteeReviewFee.toString(),
        keyExchangeFee: data.keyExchangeFee.toString(),
        penaltyOption: data.penaltyOption as
          | "6개월 이내"
          | "1년 이내"
          | "1년 6개월 이내"
          | "2년 이내"
          | "2년 6개월 이내"
          | "3년 이내",
        penaltyAmount: data.penaltyAmount as
          | "1개월"
          | "2개월"
          | "3개월"
          | "4개월"
          | "5개월"
          | "6개월",
        managementFee: data.managementFee.toString(),
        numOfFloors: data.numOfFloors.toString(),
        rent: data.rent.toString(),
        moreFixedCost: reverseConvertedMoreInfo(data.moreFixedCost),
        moreInfo: reverseConvertedMoreInfo(data.moreInfo),
        moreInitialCost: reverseConvertedMoreInfo(data.moreInitialCost),
      });
    } catch (error) {
      console.log(error);

      alert("저장된 데이터를 불러오는데 실패했습니다.");
    }
  };

  useEffect(() => {
    if (!type || !mansionId) {
      navigate("/admin/mansionManagement");
    }

    if (type === "modify" && roomId) {
      fetchPrevRoomData(roomId);
    }
    return () => {
      handleResetRoom();
    };
  }, []);

  return (
    <Wrapper>
      <RoomRegistrationWrapper>
        <RegistrationHeader title={"호실등록"} path={"매물관리 > 호실등록"} />
        {type === "add" && (
          <ButtonDiv>
            <Button
              onClick={() => {
                handleAddRoom();
                setRoomIndexState(adminRegistrationRoomState.length);
              }}
            >
              방 추가
            </Button>
            <Button
              onClick={() => {
                if (adminRegistrationRoomState.length > 1) {
                  handleRemoveRoom(roomIndexState);
                  roomIndexState !== 0 && setRoomIndexState(roomIndexState - 1);
                } else {
                  alert("최소 1개의 방이 존재해야 합니다.");
                }
              }}
            >
              현재 방 삭제
            </Button>
            <>현재{roomIndexState + 1}페이지</>
            {roomIndexState > 0 && (
              <Button onClick={() => setRoomIndexState(roomIndexState - 1)}>
                {"<"}
              </Button>
            )}
            {roomIndexState < adminRegistrationRoomState.length - 1 && (
              <Button onClick={() => setRoomIndexState(roomIndexState + 1)}>
                {">"}
              </Button>
            )}
          </ButtonDiv>
        )}

        <RoomRegistrationRoomNumber
          roomIndex={roomIndexState}
        ></RoomRegistrationRoomNumber>
        <RoomRegistrationImage
          roomIndex={roomIndexState}
        ></RoomRegistrationImage>
        <RoomRegistrationInfo roomIndex={roomIndexState}></RoomRegistrationInfo>
        <RoomRegistrationMonthlyExpense
          roomIndex={roomIndexState}
        ></RoomRegistrationMonthlyExpense>
        <RoomRegistrationInitialCost
          roomIndex={roomIndexState}
        ></RoomRegistrationInitialCost>
      </RoomRegistrationWrapper>
      <ButtonArea>
        <SaveButton onClick={handleSaveRoom}>임시저장</SaveButton>
        <RegistrationButton onClick={handlePostRoom}>
          등록하기
        </RegistrationButton>
      </ButtonArea>
    </Wrapper>
  );
}

export default RegistrationRoom;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 1920px;
  display: flex;
  align-items: center;
`;

const ButtonArea = styled.div`
  display: flex;
  gap: 28px;
  margin-top: 58px;
  padding-bottom: 260px;
`;

const SaveButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  width: 269px;
  height: 55px;
  border: 3px solid #99393a;
  color: #99393a;
  border-radius: 8px;
  background-color: white;
  cursor: pointer;
`;

const RegistrationButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 600;
  width: 269px;
  height: 55px;
  background-color: #99393a;
  color: white;
  border-radius: 8px;
  cursor: pointer;
`;

const RoomRegistrationWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 1323px;
  padding: 0 43px;
  background-color: white;
  margin-top: 107px;
  border-radius: 5px;
`;

const ButtonDiv = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  display: flex;
  gap: 10px;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: "Noto Sans KR";
  min-width: 40px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #e6e6e6;
  color: #333333;
  cursor: pointer;
  padding: 0 10px;
`;
