// src/pages/PrivacyPolicyContent.tsx
import React from "react";
import styled from "styled-components";

const TermsAgreeContent: React.FC = () => {
  return (
    <>
      <Title>利用規約</Title>
      <TermsContent>
        <p>
          株式会社エイダス「（以下、「当社」といいます）」が提供する総合不動産情報サイト「ハウバー（英字名：Houber）
          （以下、「本サービス」といいます）」の利用に関して、本サービス登録前にこの利用規約「（以下「本規約」といいます）」を必ずご確認ください
          。また、ユーザーは、本サービスへの登録を行ったことで、本規約の記載内容について同意したものとみなします。
        </p>
        <h3>第１条（目的）</h3>
        <p>
          1.
          本サービスは、ユーザーが、本サービスを通じて提供される、物件のリアルタイムの不動産情報の提供を受けることで、
          <br />
          ユーザーの物件情報の取得を円滑に行うことを目的とします。
          <br />
          2.
          本規約は、当社が提供する本サービスを、ユーザーが利用する際の一切の行為に適用されます。
          <br />
          3. ユーザーは、本規約に従い本サービスを利用するものとします。
          <br />
          ４）本サービスに関連するサービス及び情報更新の通知・連絡のため
          <br />
          ５）当社の事業活動及び市場調査に関する分析・統計のため
          <br />
          ６）ユーザーが本サービスを利用している間におけるユーザーとの各種連絡のため
          <br />
          ７）その他、本サービスの目的および当社のプライバシーポリシーに記載した目的のため
        </p>

        <h3>第２条（諸規程）</h3>
        <p>
          当社は、ユーザーに対し、必要に応じて、本サービスの利用に関する諸規程を作成し、本サービスの利用を制約する権利を有しています。
        </p>

        <h3>第３条（ＩＤ登録）</h3>
        <p>
          1.
          ユーザーは、本サービスを利用するため、当社所定の書式にユーザーの情報（以下「登録情報」といいます。）を入力して、当社に送信する方法で、ＩＤ登録を行うものとします
          <br />
          2.
          ユーザーは、入力した登録情報に変更があった場合には、速やかに登録情報の内容を修正するものとします。
          <br />
          3.
          当社は、ユーザーが入力した登録情報が真実に反すると判断した場合には、当該ユーザーのＩＤの削除、本サービスの利用停止、その他当社が相当と判断した処分を行うものとします。
        </p>
        <h3>第４条（個人情報の取扱）</h3>
        <p>
          1.
          当社は、ユーザーから提供される個人情報を、当社の「個人情報保護方針」に基づき、適切に取り扱うものとします。
          <br />
          2.
          ユーザーは、以下の各号に定める場合に、ユーザーの個人情報その他登録情報が第三者に提供されることに同意します。
        </p>
        <PIndent>
          1.
          情報提供者が提供する情報など、当社以外の会社が提供する情報に関するお問い合わせをユーザーから当社が受けた場合で、
          <br />
          お問い合わせに対する回答を当該情報提供者から直接行うことが適切であると当社が判断した場合、お問い合わせの内容およびメー
          <br />
          ルアドレスなどの回答先情報を当該情報提供者に開示すること。
          <br />
          2.
          他のユーザーや第三者に損害を発生させるなど、本規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとされるユーザーのご利用をお断りするために、ＩＤ、ご利用態様、氏名や住所など個人を特定するための情報を情報提供者に開示すること。
        </PIndent>

        <h3>第５条（ユーザーの責任）</h3>
        <p>
          1.
          ユーザーは、自己の責任において本サービスを利用するものとし、本サービスを利用してなされた一切の行為およびその結果について一切の責任を負い、当社が何らの責任を負わないことを承諾します。
          <br />
          2.
          ユーザーが本サービスを通じて何らかのコンテンツ（情報、データ、文書、ソフトウェア、写真、画像、伝言、文字などの一切を含みます。）を掲載した場合、掲載したコンテンツに関する責任はユーザーが負うものとし、当社は何らの責任を負わないことを承諾します。
          <br />
          3.
          ユーザーは、自己の責任において、ＩＤならびにパスワードを管理するものとします。
          <br />
          4.
          当社は、ＩＤとパスワードの組み合わせが登録情報と一致してログインされた場合には、そのＩＤを登録しているユーザー自身による利用とみなします。
          <br />
          5.
          ＩＤならびにパスワードを利用して行われた行為の責任は当該ＩＤを保有しているユーザーの責任とみなします。
          <br />
          ＩＤ及びパスワードが第三者によって使用されたことによって生じた損害について、当社は一切の責任を負わないものとします。ユーザーは、許可なく自分のＩＤが利用された場合、またはＩＤならびにパスワードが第三者に漏洩してしまった場合にはただちに当社に連絡するものとします。
        </p>
        <h3>第６条（本サービスを通じて掲載されたコンテンツの権利）</h3>
        <p>
          1.
          本サービスを通じて掲載、開示、提供等されたコンテンツ（情報、データ、文書、ソフトウェア、写真、画像、伝言、文字などの一切を含みます。）の著作権、著作者人格権その他一切の知的財産権は、掲載したユーザーおよび当該ユーザーから正当にその権利の譲渡や使用等の権利の許諾を受けた権利者に帰属します
          <br />
          2.
          ユーザーは、当社に対し、ユーザーが本サービスを通じて掲載等したコンテンツを、当社が本サービスの提供をするのに必要な範囲で、非独占的に使用することを許諾します。
          <br />
          3.
          ユーザーは、当社に対し、本サービスを通じて掲載等するコンテンツが、第三者の権利を侵害していないことを保証するものとし、第三者との間で紛争が生じた場合には、当該ユーザーの責任と費用において問題を解決するとともに、当社に対して何らの迷惑または損害を与えないものとします。当該クレームや請求への対応に関連して当社が損害賠償その他金銭を支払った場合、ユーザーは、当社に対し、当該費用および賠償金等（当社が支払った弁護士費用を含みます。）を支払う義務を負うものとします。
          <br />
          4.
          ユーザーは、知的財産権を侵害する行為を発見した場合には、当社に対し次の情報をご提供するものとします。なお、これにより、当社が何らかの行為をすることを保証するものではありません。
        </p>
        <PIndent>
          1. 申告された方の氏名、住所、電話番号、メールアドレス
          <br />
          2. 権利者の氏名、住所、電話番号、メールアドレス
          <br />
          3.
          申告された方が著作権者でない場合には、権利者を代理する法律上の権限を有していることの根拠の説明
          <br />
          4. 知的財産権が侵害されたと主張される物についての説明
          <br />
          5. 侵害していると主張される物が掲載されている場所
          <br />
          6.
          問題となっている物の使用等が権利者に無断で使用されていると主張される法律的な根拠についての説明
        </PIndent>
        <p>
          本サービスまたは本規約に関連して当社とユーザーの間で生じた紛争については、
          日本法を準拠法とし、
          <br />
          東京地方裁判所を第一審の専属的合意管轄裁判所とします。
        </p>
      </TermsContent>
    </>
  );
};

export default TermsAgreeContent;

// Styled Components
const Title = styled.h1`
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
`;

const TermsContent = styled.div`
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.5;

  h3 {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  &::-webkit-scrollbar {
    width: 4px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: none;
  }
`;
// p 태그에 들여쓰기 스타일을 적용한 컴포넌트
const PIndent = styled.p`
  margin-left: 20px; /* 문단 들여쓰기 */
`;
