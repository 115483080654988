import React from 'react'
import { PinThumbnailWrap, Text } from './Map.style'

interface pinInfoI{
  id: number
  propertyName: string
  propertyThumbnailUrl: string
}
  
  export default function PinThumbnail({id, propertyName, propertyThumbnailUrl}: pinInfoI) {
    
    return (
      <PinThumbnailWrap $bgUrl={propertyThumbnailUrl}>
        <Text $fontSize='16px' $fontWeight='bold' $textAlign='center'>{propertyName}</Text> 
      </PinThumbnailWrap>
    )
  }
  