import { Outlet } from "react-router-dom";
import styled from "styled-components";

function AdminBackground() {
  return (
    <Wrapper>
      <Outlet />
    </Wrapper>
  );
}

export default AdminBackground;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  background-color: #edeef1;
`;
