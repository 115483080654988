import React, { useState,useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

interface ImageUploadProps {
  onImageSelect: (file: File | null) => void;
  tumbnail: string|null;
}


const ImageUpload: React.FC<ImageUploadProps> = ({ onImageSelect,tumbnail}) => {
  const [preview, setPreview] = useState<string | null>(null);
  

  useEffect(() => {
    if (tumbnail) {
      setPreview(tumbnail);
    } else {
      setPreview(null);
    }
  }, [tumbnail]);


  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      onImageSelect(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div style={{display:'flex',flexDirection:'column', alignItems:'center'}}>
    <Container >
      <Input type="file" accept=".jpg,.jpeg,.png,.gif" onChange={handleImageChange} />
      {!preview ? (
        <Placeholder>
          <p>드래그 앤 드롭으로 썸네일 이미지 추가</p>
          <div >+이미지 선택</div>
        </Placeholder>
      ) : <Image src={preview}/>}
    </Container>
  </div>
  );
};

export default ImageUpload;


const Container = styled.div`
  width: 100%;
  height: 500px;
  overflow: hidden;
  position: relative;
  background-color: #a7a7a7;
`;

const Input = styled.input`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  color: white;

  div{
    width: 100px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:5px;
  }
`;
