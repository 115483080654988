import styled from "styled-components";
import BannerArrowLeft from "../assets/images/mainPage/bannerarrow-left.png";
import BannerArrowRight from "../assets/images/mainPage/bannerarrow-right.png";
import useDeviceSize from "../hooks/useDeviceSize";

function Navigation({
  onClickPrev,
  onClickNext,
  page,
  maxPage,
  translateY,
  mobile,
}: {
  onClickPrev: () => void;
  onClickNext: () => void;
  page: number;
  maxPage?: number;
  translateY: number;
  mobile?: boolean;
}) {
  const { isPC, isMobile } = useDeviceSize();

  return (
    <NavigationButtonDiv $translateY={translateY} mobile={mobile}>
      <NavigationButton
        $translateX={isPC ? -33 : -21}
        onClick={onClickPrev}
        $isFirst={page === 0}
      >
        <StyledArrowImage src={BannerArrowLeft} />
      </NavigationButton>
      <NavigationButton
        $translateX={isPC ? 33 : 21}
        onClick={onClickNext}
        $isLast={page === maxPage}
      >
        <StyledArrowImage src={BannerArrowRight} />
      </NavigationButton>
    </NavigationButtonDiv>
  );
}

export default Navigation;

export const getMaxPage = (pagePerItems: number, listLength?: number) => {
  if (listLength !== 0 || listLength !== undefined) {
    const maxPage = listLength && Math.ceil(listLength / pagePerItems) - 1;
    return maxPage;
  } else {
    return 0;
  }
};

const NavigationButtonDiv = styled.div<{
  $translateY: number;
  mobile?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  z-index: 10;
  pointer-events: none;
  transform: translate(
    0px,
    ${(props) => props.$translateY && `${props.$translateY}px`}
  );
  @media screen and (max-width: 1023px) {
    display: ${(props) => (props.mobile ? "flex" : "none")};
  }
`;

const NavigationButton = styled.div<{
  $translateX: number;
  $isFirst?: boolean;
  $isLast?: boolean;
}>`
  pointer-events: auto;
  visibility: ${(props) => (props.$isFirst || props.$isLast ? "hidden" : "")};
  border-radius: 50px;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.1);
  transform: translate(
    ${(props) => props.$translateX && `${props.$translateX}px`}
  );
  cursor: pointer;
  @media screen and (min-width: 1280px) {
    width: 52px;
    height: 52px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 50px;
    height: 50px;
  }
  @media screen and (max-width: 1023px) {
    width: 33px;
    height: 33px;
  }
`;

const StyledArrowImage = styled.img`
  @media screen and (min-width: 1280px) {
    width: 52px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 50px;
  }
  @media screen and (max-width: 1023px) {
    width: 33px;
  }
`;
