import { useEffect, useState } from "react";
import styled from "styled-components";

function RegistrationHeader({ title, path }: { title: string; path: string }) {
  return (
    <HeaderWrapper>
      <Path>{path}</Path>
      <Title>{title}</Title>
    </HeaderWrapper>
  );
}

export default RegistrationHeader;

const HeaderWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 226px;
  padding-top: 52px;
  display: flex;
  padding-left: 12px;
  gap: 20px;
`;

const Path = styled.div`
  font-size: 20px;
  letter-spacing: -3px;
  font-weight: 500;
`;

const Title = styled.div`
  font-size: 50px;
  font-weight: bold;
  letter-spacing: -4px;
`;
