import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaArrowRight } from "react-icons/fa";

const ModalContainer = styled.div<{ x: number; y: number }>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 459px;
  height: 167px;
  background: #fff;
  padding: 30px 36px;
  border-radius: 10px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const HeaderButton = styled.button`
  font-size: 16px;
  font-weight: bold;
  padding: 8px 16px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const DateRangeContainer = styled.div`
  display: flex;
  align-items: center;
  width: 459px;
  height: 167px;
`;

const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    width: 130px;
  }
  .react-datepicker__input-container input {
    font-size: 18px;
    width: 130px;
    padding: 12px 0px;
    border: 1px solid #ccc;
    border-radius: 50px;
    text-align: center;
  }
`;

const Dash = styled.span`
  width: 14.5px;
  height: 2px;
  background-color: #000;
  border-radius: 1px;
  margin: 0 16px;
  display: inline-block;
  font-weight: bold;
`;

const SearchButton = styled.button`
  padding: 8px 16px;
  background-color: #e6e6e6;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-left: 12px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const Wrapper = styled.div`
  position: relative; /* 상대 위치 설정 */
  display: flex;
  align-items: center;
`;

interface DateRangeSearchProps {
  fetchByDate: (startDate: string, endDate: string, page: number) => void;
}

const DateRangeSearch: React.FC<DateRangeSearchProps> = ({ fetchByDate }) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const modalRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const openModal = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setModalPosition({ x: rect.right + 10, y: rect.top + window.scrollY });
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      closeModal();
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);

  const handleSearch = () => {
    if (startDate && endDate) {
      const formattedStartDate = startDate.toISOString().split("T")[0];
      const formattedEndDate = endDate.toISOString().split("T")[0];
      fetchByDate(formattedStartDate, formattedEndDate, 1);
      closeModal();
    }
  };

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };

  return (
    <Wrapper>
      <HeaderButton ref={buttonRef} onClick={openModal}>
        날짜로 조회하기
        <FaArrowRight />
      </HeaderButton>
      {isModalOpen && (
        <ModalContainer ref={modalRef} x={modalPosition.x} y={modalPosition.y}>
          <Header>날짜로 조회하기</Header>
          <DateRangeContainer>
            <DatePickerWrapper>
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                dateFormat="yy.MM.dd"
              />
            </DatePickerWrapper>
            <Dash />
            <DatePickerWrapper>
              <DatePicker
                selected={endDate}
                onChange={handleEndDateChange}
                dateFormat="yy.MM.dd"
              />
            </DatePickerWrapper>
            <SearchButton onClick={handleSearch}>조회</SearchButton>
          </DateRangeContainer>
        </ModalContainer>
      )}
    </Wrapper>
  );
};

export default DateRangeSearch;
