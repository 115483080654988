import { atom, useRecoilState } from "recoil";
export const adminPendingDeleteMansionDataStateAtom = atom<number[]>({
  key: "adminPendingDeleteMansionDataState",
  default: [],
});

export const useAdminPendingDeleteMansionState = () => {
  const [adminPendingDeleteMansionState, setAdminPendingDeleteMansionState] =
    useRecoilState(adminPendingDeleteMansionDataStateAtom);
  return { adminPendingDeleteMansionState, setAdminPendingDeleteMansionState };
};
