import { useLocation, Link } from 'react-router-dom';
import styled from 'styled-components';
import LOGO from "../../../assets/images/ResidentReview/LOGO.png"
const ShowAccount = () => {
  const location = useLocation();
  const data = location.state?.data;

  // data가 빈 문자열인지 확인
  const isPasswordChange = data === '';

  return (
    <Container>
    <LogoBox>
    <img src={LOGO} style={{width:'100%', height:'100%'}}/>
  </LogoBox>
      <StyledDiv />
      <InfoDiv>
        <InfoText>
          {isPasswordChange ? '비밀번호 찾기' : '아이디 찾기'}
        </InfoText>
        <InfoText className="secondInfo">
          {isPasswordChange ? (
            '고객님의 비밀번호가 변경되었습니다.'
          ) : (
            <>
              고객님의 아이디는 <InfoText className="IDsection">{data}</InfoText> 입니다.
            </>
          )}
        </InfoText>
        <InfoText className="last">
          {isPasswordChange
            ? '비밀번호 변경이 성공적으로 이루어졌습니다. 이용해주셔서 감사합니다.'
            : '아이디 찾기가 성공적으로 이루어졌습니다. 이용해주셔서 감사합니다.'}
        </InfoText>

        <Link to="/login" style={{ textDecoration: 'none', display: 'flex', justifyContent: 'center' }}>
          <Button>로그인하러 가기</Button>
        </Link>
      </InfoDiv>
    </Container>
  );
};

export default ShowAccount;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoBox = styled.div`
  text-align: center;
  margin-top: 90px;
  width: 165px;
  height: 165px;


  @media (max-width: 1024px) {
    margin-top: 70px;
    width: 140px;
    height: 140px;
  }

  @media (max-width: 500px) {
    width: 100px;
    height: 100px;
    margin-top: 50px;
  }
`;

const StyledDiv = styled.div`
  background-color: rgba(187, 185, 185, 0.5);
  width: 100%;
  padding: 1px;
  margin-top: 65px;

  @media (max-width: 1024px) {
    margin-top: 50px;
  }

  @media (max-width: 500px) {
    margin-top: 30px;
  }
`;

const InfoDiv = styled.div`
  padding-top: 50px;
  text-align: center;
  width: 545px;

  @media (max-width: 1024px) {
    width: 65%;
    padding-top: 40px;
  }

  @media (max-width: 500px) {
    width: 70%;
    padding-top: 30px;
  }
`;

const InfoText = styled.p`
  font-size: 25px;
  font-weight: 700;
  line-height: 1.5;
  &.secondInfo {
    margin-top: 50px;
    font-size: 23px;
    font-weight: 400;

    @media (max-width: 1024px) {
      font-size: 20px;
    }

    @media (max-width: 500px) {
      margin-top: 30px;
      font-size: 18px;
    }
  }

  &.IDsection {
    display: contents;
    font-size: 30px;
    font-weight: 700;

    @media (max-width: 1024px) {
      font-size: 24px;
    }

    @media (max-width: 500px) {
      font-size: 20px;
    }
  }

  &.last {
    color: #777777;
    font-size: 18px;
    font-weight: 400;

    @media (max-width: 1024px) {
      font-size: 16px;
    }

    @media (max-width: 500px) {
      font-size: 9px;
    }
  }
`;

const Button = styled.div`
  width: 100%;
  margin-top: 50px;
  height: 70px;
  align-items: center;
  justify-content: center;
  background-color: #99393a;
  color: white;
  font-size: 23px;
  border: none;
  display: flex;
  border-radius: 5px;

  @media (max-width: 1024px) {
    width: 475px;
    height: 50px;
    font-size: 18px;
  }

  @media (max-width: 500px) {
    height: 40px;
    font-size: 14px;
  }
`;
