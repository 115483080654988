import { atom, useRecoilState } from "recoil";
import { mainMansionDataType, reviewDataType } from "../../pages/mainPage/Main"; // 정확한 경로로 수정해주세요.

export type searchResultDataType = {
  propertySearched: {
    houber: mainMansionDataType[];
    houberCount: number;
    purchase: mainMansionDataType[];
    purchaseCount: number;
    rent: mainMansionDataType[];
    rentCount: number;
    store: mainMansionDataType[];
    storeCount: number;
  };
  qnaSearched: {
    answerStatus: false;
    content: number;
    createAt: string;
    id: number;
    keywords: string[];
    title: string;
    userNickName: string;
    userProfileImage: string;
  }[];
  reviewSearched: reviewDataType[];
};

export const searchResultDataStateAtom = atom<searchResultDataType>({
  key: "searchResultDataState",
  default: {
    propertySearched: {
      houber: [],
      houberCount: 0,
      purchase: [],
      purchaseCount: 0,
      rent: [],
      rentCount: 0,
      store: [],
      storeCount: 0,
    },
    qnaSearched: [],
    reviewSearched: [],
  },
});

export const useSearchResultData = () => {
  const [searchResultDatastate, setSearchResultDataState] =
    useRecoilState<searchResultDataType>(searchResultDataStateAtom);

  return { searchResultDatastate, setSearchResultDataState };
};
