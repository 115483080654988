import React, { useEffect, useState } from "react";
import useDeviceSize from "../../hooks/useDeviceSize";
import axiosInstance from "../../services/axiosConfig";
import useCheckItems from "../../store/modal/checkItems";
import ChoiceAreaOption from "./ChoiceAreaOption";
import ChoiceSubwayOption from "./ChoiceSubwayOption";

export type List = { id: number; name: string; propertyCount: number };
interface PrefetcherType {
  id: number;
  name: string;
  prefectureList: List[];
}

const DataOptionBox = ({ ...props }) => {
  const { isPC, isLaptop } = useDeviceSize();
  const [prefetcher, setPrefetcher] = useState<PrefetcherType[] | null>(null);
  const { init } = useCheckItems();

  useEffect(() => {
    const fetch = async () => {
      const res = await axiosInstance(`/map/subway/prefecture`);
      setPrefetcher(res.data);
    };
    init();
    if (props.dataType === "subway") {
      fetch();
    }
  }, []);

  if (props.dataType === "location") {
    return (
      <>
        <ChoiceAreaOption
          title="간사이"
          detail={["오사카 | 효고 | 교토", "시가 | 나라 | 와카야마"]}
          top={isPC ? 78 : 75}
          left={isPC ? 33 : 33}
          id={1}
          setRegionInner={props.setRegionInner}
          setStep={props.setStep}
        />
        <ChoiceAreaOption
          title="칸토"
          detail={[
            "도쿄 | 카나가와 | 치바",
            "사이타마 | 이바라키",
            "도치기 | 군마 ",
          ]}
          top={isPC ? 53 : 53}
          left={isPC ? 70 : isLaptop ? 70 : 60}
          id={2}
          setRegionInner={props.setRegionInner}
          setStep={props.setStep}
        />
        <ChoiceAreaOption
          title="규슈 오키나와"
          detail={[
            "후쿠오카 | 사가 | 나가사키",
            "구마모토 | 오이타 | 미야자키",
            "가고시마 | 오키나와",
          ]}
          top={isPC ? 55 : 55}
          left={isPC ? 0 : 0}
          id={3}
          setRegionInner={props.setRegionInner}
          setStep={props.setStep}
        />
      </>
    );
  } else if (props.dataType === "subway") {
    return (
      <>
        {prefetcher &&
          prefetcher.map((item) => {
            return (
              <ChoiceSubwayOption
                key={item.id}
                id={item.id}
                detail={item.prefectureList}
                setRegionLineInner={props.setRegionLineInner}
                setStep={props.setStep}
              />
            );
          })}
      </>
    );
  } else {
    return <></>;
  }
};

export default DataOptionBox;
