import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

// 드롭다운 컨테이너 스타일
const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

// 드롭다운 버튼 스타일
const DropdownButton = styled.button`
  padding: 8px 16px;
  background-color: #ffffff;
  border: 0px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center; /* 가운데 정렬 */
  position: relative;
  color: #333333;
  border: solid 2px #cccccc;
  width: 128px;
  height: 50px;
  white-space: nowrap;
  font-weight: 600;
  
  &::after {
    content: "";
    display: inline-block;
    margin-left: 8px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #828c94; /* 화살표 색상 */
    transform: translateY(0px); /* 화살표 위치 조정 */
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
  }

  @media screen and (max-width: 1023px) {
    font-size: 15px;
    font-weight: normal;
    white-space: nowrap;

    width: 86px;
    height: 35px;

    &::after {
      content: "";
      display: inline-block;
      margin-left: 8px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #828c94; /* 화살표 색상 */
      transform: translateY(0px); /* 화살표 위치 조정 */
    }
  }
`;

// 드롭다운 메뉴 스타일
const DropdownMenu = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? "block" : "none")};
  position: absolute;
  top: 60px;
  left: 0px;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 1;
  min-width: 100px;
  width: 128px;
  color: #333333;
  overflow: hidden;

  @media screen and (max-width: 1023px) {
    font-size: 15px;

    min-width: 80px;

    width: 86px;
    top: 40px;
  }
`;

// 드롭다운 항목 스타일
const DropdownItem = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  font-size: 23px;
  text-align: center; /* 글자 가운데 정렬 */
  background-color: ${(props) => (props.selected ? "#fce8d8" : "#ffffff")};
  &:hover {
    background-color: #f1f1f1;
  }
  &:first-child {
    border-radius: 10px 10px 0 0; /* 첫 번째 항목 모서리 둥글게 */
  }
  &:last-child {
    border-radius: 0 0 10px 10px; /* 마지막 항목 모서리 둥글게 */
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;

    width: 86px;
    height: 24px;

    padding: 0px;
    &:first-child {
      border-radius: 5px 5px 0 0; /* 첫 번째 항목 모서리 둥글게 */
    }
    &:last-child {
      border-radius: 0 0 5px 5px; /* 마지막 항목 모서리 둥글게 */
    }
  }
`;

const options = [
  { id: 1, label: "추천순" },
  { id: 2, label: "최신순" },
];

interface DropdownProps {
  selectedOption: (typeof options)[0];
  onOptionSelect: (option: (typeof options)[0]) => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  selectedOption,
  onOptionSelect,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setShowMenu((prev) => !prev);

  const handleOptionClick = (option: (typeof options)[0]) => {
    onOptionSelect(option);
    setShowMenu(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownButton onClick={toggleDropdown}>
        {selectedOption.label}
      </DropdownButton>
      <DropdownMenu show={showMenu}>
        {options.map((option) => (
          <DropdownItem
            key={option.id}
            selected={option.id === selectedOption.id}
            onClick={() => handleOptionClick(option)}
          >
            {option.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </DropdownContainer>
  );
};

export default Dropdown;
