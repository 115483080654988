import styled from "styled-components";

function CloneMoreInfoButton() {
  return <Button>더보기</Button>;
}

export default CloneMoreInfoButton;

const Button = styled.div`
  color: #99393a;
  font-weight: bold;
  cursor: pointer;
  @media screen and (min-width: 1280px) {
    font-size: 16px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 15px;
  }
`;
