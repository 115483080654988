import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useDeviceSize from "../../../hooks/useDeviceSize";
import usePropertyState from "../../../hooks/usePropertyState";
import { propertyOptionDataType } from "../../../recoil/mansionPage/propertyOptionsAtom";
import DownArrow from "../../../assets/images/ResidentReview/DownIcon.png";

function MansionSidebarbottom() {
  const { isPC } = useDeviceSize();
  const { filters, updateFilters, propertyOptions, updatePropertyOptions } =
    usePropertyState();

  const [roomTypePartState, setRoomTypePartState] = useState<{
    numberOfRoomType: string;
    categoryOfRoomType: string;
  }>({ numberOfRoomType: "", categoryOfRoomType: "" });

  const [dropdownState, setDropdownState] = useState<
    "" | "subwayTimeConsumed" | "withInYear"
  >("");

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handlePropertyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    updatePropertyOptions(name, checked);
  };

  const convertedWithInYear = (withInYear: number | null) => {
    switch (withInYear) {
      case 3:
        return "신축";
      case 5:
        return "5년 이내";
      case 10:
        return "10년 이내";
      case 15:
        return "15년 이내";
      case 20:
        return "20년 이내";
      case 0:
        return "상관없음";
    }
  };

  const OPTIONS = [
    { name: "인터넷 무료", propertyName: "freeInternet" },
    { name: "2인 이상 입주", propertyName: "morePeople" },
    { name: "복층 맨션", propertyName: "duplexMention" },
    { name: "인덕션", propertyName: "induction" },
    { name: "올 전기 맨션", propertyName: "allElectricMansion" },
    { name: "실내 세탁판", propertyName: "indoorWashingBoard" },
    { name: "비데", propertyName: "bidet" },
    { name: "유닛베스", propertyName: "unitBath" },
    { name: "냉난방", propertyName: "airConditioning" },
    { name: "24시 쓰레기 배출", propertyName: "trashDisposal24H" },
    { name: "인터폰", propertyName: "intercom" },
    { name: "자동차 주차장", propertyName: "carParking" },
    { name: "자전거 주차장", propertyName: "bicycleParking" },
    { name: "여성 전용 맨션", propertyName: "womenOnly" },
    { name: "오토록", propertyName: "autoLock" },
    { name: "외국인 가능", propertyName: "foreignerAllowed" },
    { name: "가구옵션", propertyName: "furnitureOptions" },
    { name: "펫가능", propertyName: "petsAllowed" },
    { name: "디자이너 맨션", propertyName: "designerMention" },
    { name: "역세권", propertyName: "closeToStation" },
    { name: "가스레인지", propertyName: "gasRange" },
    { name: "도시가스", propertyName: "cityGas" },
    { name: "실외 세탁판", propertyName: "outdoorWashingBoard" },
    { name: "독립형 화장실", propertyName: "standAloneToilet" },
    { name: "욕실 건조기", propertyName: "bathroomDryer" },
    { name: "수납공간", propertyName: "storageSpace" },
    { name: "쓰레기 보관소", propertyName: "garbageStorage" },
    { name: "택배박스", propertyName: "deliveryBox" },
    { name: "엘레베이터", propertyName: "elevator" },
    { name: "바이크 주차장", propertyName: "bikeParking" },
    { name: "남성 전용 맨션", propertyName: "mensOnly" },
    { name: "CCTV", propertyName: "cctv" },
    { name: "해외심사 가능", propertyName: "overseasScreeningAvailable" },
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownState("");
      }
    };

    if (dropdownState) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownState]);

  return (
    <>
      <ContentsDiv $marginTop={isPC ? 37 : 28}>
        <RoomTypeDiv>
          <Title>방 타입</Title>
          <SelectRoomTypeDiv>
            <SelectRoomType>
              {["1", "2", "3", "4", "5"].map((item, index) => (
                <SelectRoomTypeItem
                  key={item}
                  $isSelected={item === roomTypePartState.numberOfRoomType}
                  onClick={() => {
                    if (roomTypePartState.categoryOfRoomType) {
                      if (
                        !filters.roomTypeList.includes(
                          roomTypePartState.categoryOfRoomType + item
                        )
                      ) {
                        updateFilters({
                          roomTypeList: [
                            ...filters.roomTypeList,
                            roomTypePartState.categoryOfRoomType + item,
                          ],
                        });
                        setRoomTypePartState({
                          categoryOfRoomType: "",
                          numberOfRoomType: "",
                        });
                      }
                    } else {
                      setRoomTypePartState({
                        ...roomTypePartState,
                        numberOfRoomType: item,
                      });
                    }
                  }}
                >
                  {item}
                </SelectRoomTypeItem>
              ))}
            </SelectRoomType>
            <SelectRoomType>
              {["r", "k", "dk", "ldk"].map((item, index) => (
                <SelectRoomTypeItem
                  key={item}
                  $isSelected={item === roomTypePartState.categoryOfRoomType}
                  onClick={() => {
                    if (roomTypePartState.numberOfRoomType) {
                      if (
                        !filters.roomTypeList.includes(
                          item + roomTypePartState.numberOfRoomType
                        )
                      ) {
                        updateFilters({
                          roomTypeList: [
                            ...filters.roomTypeList,
                            item + roomTypePartState.numberOfRoomType,
                          ],
                        });
                        setRoomTypePartState({
                          categoryOfRoomType: "",
                          numberOfRoomType: "",
                        });
                      }
                    } else {
                      setRoomTypePartState({
                        ...roomTypePartState,
                        categoryOfRoomType: item,
                      });
                    }
                  }}
                >
                  {item.toUpperCase()}
                </SelectRoomTypeItem>
              ))}
            </SelectRoomType>
          </SelectRoomTypeDiv>
          <CombinedRoomTypeWrapper>
            {filters.roomTypeList.map((item, index) => (
              <CombinedRoomType
                key={index}
                onClick={() => {
                  const newRoomType = [...filters.roomTypeList];
                  const index = newRoomType.indexOf(item);
                  if (index > -1) {
                    newRoomType.splice(index, 1);
                  }
                  updateFilters({ roomTypeList: newRoomType });
                }}
              >
                {item.slice(-1, item.length)}
                {item.slice(0, -1).toUpperCase()}
              </CombinedRoomType>
            ))}
          </CombinedRoomTypeWrapper>
        </RoomTypeDiv>
      </ContentsDiv>
      <ContentsDiv $marginTop={isPC ? 36 : 27}>
        <Title>면적</Title>
        <AreaDetailWrapper>
          <AreaDetail>
            <StyledInput
              value={filters.minArea}
              onChange={(e) => updateFilters({ minArea: e.target.value })}
            />
            ㎡
          </AreaDetail>
          <Bar></Bar>
          <AreaDetail>
            <StyledInput
              value={filters.maxArea}
              onChange={(e) => updateFilters({ maxArea: e.target.value })}
            />
            ㎡
          </AreaDetail>
        </AreaDetailWrapper>
      </ContentsDiv>
      <ContentsDiv $marginTop={isPC ? 40 : 32}>
        <Title>옵션</Title>
        <OptionCheckboxDiv>
          <OptionCheckboxLeft>
            {OPTIONS.slice(0, Math.ceil(OPTIONS.length / 2)).map(
              (item, index) => (
                <CheckBoxWrapper key={item.name}>
                  <CheckBox
                    type="checkbox"
                    name={item.propertyName}
                    checked={
                      propertyOptions[
                        item.propertyName as keyof propertyOptionDataType
                      ]
                    }
                    onChange={handlePropertyChange}
                  />
                  {item.name}
                </CheckBoxWrapper>
              )
            )}
          </OptionCheckboxLeft>
          <OptionCheckBoxRight>
            {OPTIONS.slice(Math.ceil(OPTIONS.length / 2), OPTIONS.length).map(
              (item, index) => (
                <CheckBoxWrapper key={index}>
                  <CheckBox
                    type="checkbox"
                    name={item.propertyName}
                    checked={
                      propertyOptions[
                        item.propertyName as keyof propertyOptionDataType
                      ]
                    }
                    onChange={handlePropertyChange}
                  />
                  {item.name}
                </CheckBoxWrapper>
              )
            )}
          </OptionCheckBoxRight>
        </OptionCheckboxDiv>
      </ContentsDiv>
      <ContentsDiv $marginTop={isPC ? 40 : 37}>
        <Title>건축년도</Title>
        <SelectButton onClick={() => setDropdownState("withInYear")}>
          <SelectButtonInner>
            {filters.withInYear !== null
              ? convertedWithInYear(filters.withInYear)
              : "상관없음"}
            <StyledDownIcon src={DownArrow} />
          </SelectButtonInner>
          {dropdownState === "withInYear" && (
            <DropDownWrapper ref={dropdownRef}>
              {[3, 5, 10, 15, 20, 0].map((item, index) => (
                <DropDownItem
                  key={index}
                  onClick={(e) => {
                    const value = item === 0 ? null : item;
                    e.stopPropagation();
                    updateFilters({ withInYear: value });
                    setDropdownState("");
                  }}
                >
                  {convertedWithInYear(item)}
                </DropDownItem>
              ))}
            </DropDownWrapper>
          )}
        </SelectButton>
      </ContentsDiv>
      <ContentsDiv $marginTop={isPC ? 44 : 37}>
        <Title>역까지</Title>
        <SelectButton onClick={() => setDropdownState("subwayTimeConsumed")}>
          <SelectButtonInner>
            {filters.subwayTimeConsumed === null
              ? `상관없음`
              : `도보 ${filters.subwayTimeConsumed}분`}

            <StyledDownIcon src={DownArrow} />
          </SelectButtonInner>
          {dropdownState === "subwayTimeConsumed" && (
            <DropDownWrapper ref={dropdownRef}>
              {[5, 10, 15, 20, 25, 30, 0].map((item, index) => (
                <DropDownItem
                  key={index}
                  onClick={(e) => {
                    const value = item === 0 ? null : item;
                    e.stopPropagation();
                    updateFilters({ subwayTimeConsumed: value });
                    setDropdownState("");
                  }}
                >
                  {item === 0 ? `상관없음` : `도보 ${item}분`}
                </DropDownItem>
              ))}
            </DropDownWrapper>
          )}
        </SelectButton>
      </ContentsDiv>
      <ContentsDiv $marginTop={isPC ? 55 : 46}>
        <Title>층수</Title>
        <FloorDiv>
          <CheckBoxWrapper>
            <CheckBox
              type="checkbox"
              name="higherThen2F"
              checked={filters.higherThen2F}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { name, checked } = e.target;
                updateFilters({ [name]: checked });
                console.log(filters);
              }}
            />
            2층 이상
          </CheckBoxWrapper>
        </FloorDiv>
      </ContentsDiv>
    </>
  );
}

export default MansionSidebarbottom;

const Title = styled.div`
  font-weight: 600;
  @media screen and (min-width: 1280px) {
    font-size: 17px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 14px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 17px;
  }
`;

const ContentsDiv = styled.div<{ $marginTop: number }>`
  margin-top: ${(props) => `${props.$marginTop}px`};
  width: 100%;

  @media screen and (min-width: 1280px) {
    font-size: 16px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 13px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 16px;
  }
`;

const RoomTypeDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SelectRoomTypeDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1280px) {
    margin-top: 20px;
    gap: 11px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 17px;
    gap: 8px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 20px;
    gap: 11px;
  }
`;

const SelectRoomType = styled.div`
  display: flex;
  @media screen and (min-width: 1280px) {
    gap: 11px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 9px;
  }
  @media screen and (max-width: 1023px) {
    gap: 11px;
  }
`;

const SelectRoomTypeItem = styled.div<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.$isSelected ? "#99393a" : "white")};
  color: ${(props) => (props.$isSelected ? "white" : "black")};
  border: 1px solid;
  border-color: ${(props) => (props.$isSelected ? "#99393a" : "#c0c0c0")};
  border-radius: 50px;
  cursor: pointer;
  font-weight: 500;
  @media screen and (min-width: 1280px) {
    font-size: 18px;
    padding: 0 12px;
    height: 35.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 13px;
    padding: 0 11px;
    height: 29.5px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 18px;
    padding: 0 12px;
    height: 35.5px;
  }
`;

const CombinedRoomTypeWrapper = styled.div`
  display: flex;
  gap: 7px;
  flex-wrap: wrap;
  margin-top: 15px;
`;
const CombinedRoomType = styled.div`
  color: #99393a;
  border: 1px solid #99393a;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  @media screen and (min-width: 1280px) {
    padding: 4px 10px;
    font-size: 16px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 6px 10px;
    font-size: 13px;
  }
  @media screen and (max-width: 1023px) {
    padding: 4px 10px;
    font-size: 16px;
  }
`;

const AreaDetailWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`;
const Bar = styled.div`
  height: 1px;
  border: 1px solid black;
  @media screen and (min-width: 1280px) {
    width: 15px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 12px;
  }
  @media screen and (max-width: 1023px) {
    width: 15px;
  }
`;
const AreaDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c0c0c0;
  background-color: white;
  @media screen and (min-width: 1280px) {
    width: 107px;
    height: 49px;
    font-size: 16px;
    border-radius: 22px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 89px;
    height: 41px;
    font-size: 13px;
    border-radius: 17px;
  }
  @media screen and (max-width: 1023px) {
    min-width: 130px;
    min-height: 50px;
    font-size: 16px;
    border-radius: 22px;
  }
`;

const OptionCheckboxDiv = styled.div`
  display: flex;
  @media screen and (min-width: 1280px) {
    margin-top: 23px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 21px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 23px;
  }
`;
const OptionCheckboxLeft = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1280px) {
    width: 140px;
    gap: 19px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 117px;
    gap: 12px;
  }
  @media screen and (max-width: 1023px) {
    width: 170px;
    gap: 19px;
  }
`;
const OptionCheckBoxRight = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  @media screen and (min-width: 1280px) {
    gap: 19px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 12px;
  }
  @media screen and (max-width: 1023px) {
    gap: 19px;
  }
`;

const SelectButton = styled.div`
  display: flex;
  position: relative;
  border: 1px solid #c0c0c0;
  background-color: white;
  cursor: pointer;
  @media screen and (min-width: 1280px) {
    width: 142px;
    height: 49px;
    font-size: 16px;
    border-radius: 24.5px;
    margin-top: 14px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 118px;
    height: 41px;
    font-size: 13px;
    border-radius: 19px;
    margin-top: 14px;
  }
  @media screen and (max-width: 1023px) {
    width: 142px;
    height: 49px;
    font-size: 16px;
    border-radius: 24.5px;
    margin-top: 14px;
  }
`;

const SelectButtonInner = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: 1280px) {
    padding: 0 13px 0 25px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 0 10px 0 22px;
  }
  @media screen and (max-width: 1023px) {
    padding: 0 13px 0 25px;
  }
`;

const DropDownWrapper = styled.div`
  box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.1);
  position: absolute;
  background-color: white;
  display: flex;
  flex-direction: column;
  z-index: 10;
  border-radius: 10px;
  @media screen and (min-width: 1280px) {
    transform: translate(0px, 49px);
    padding: 10px 0 10px 25px;
    width: 142px;
    font-size: 16px;
    gap: 19.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    transform: translate(0px, 41px);
    padding: 10px 0 10px 22px;
    width: 118px;
    font-size: 13px;
    gap: 28px;
  }
  @media screen and (max-width: 1023px) {
    transform: translate(0px, 49px);
    padding: 10px 0 10px 25px;
    width: 142px;
    font-size: 16px;
    gap: 19.5px;
  }
`;

const DropDownItem = styled.div`
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
`;

const FloorDiv = styled.div`
  @media screen and (min-width: 1280px) {
    margin-top: 24px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 17px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 24px;
  }
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  @media screen and (min-width: 1280px) {
    font-size: 15px;
    gap: 6px;
    letter-spacing: -1px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 12px;
    gap: 5px;
    letter-spacing: -1px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
    gap: 6px;
    letter-spacing: -1px;
  }
`;

const CheckBox = styled.input`
  display: flex;
  accent-color: #99393a;
  @media screen and (min-width: 1280px) {
    width: 19.5px;
    height: 19.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 20px;
    height: 20px;
  }
  @media screen and (max-width: 1023px) {
    width: 19.5px;
    height: 19.5px;
  }
`;

const StyledDownIcon = styled.img`
  @media screen and (min-width: 1280px) {
    width: 12.5px;
    height: 6.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 10.5px;
    height: 5.5px;
  }
  @media screen and (max-width: 1023px) {
    width: 12.5px;
    height: 6.5px;
  }
`;

const StyledInput = styled.input`
  padding: 0 2px;
  font-family: "inter";
  border: none;
  outline: none;
  text-align: right;
  @media screen and (min-width: 1280px) {
    width: 50px;
    font-size: 16px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 40px;
    font-size: 13px;
  }
  @media screen and (max-width: 1023px) {
    width: 50px;
    font-size: 16px;
  }
`;
