import { atom, useRecoilState } from "recoil";

export const adminSelectedRoomDataStateAtom = atom<number[]>({
  key: "adminSelectedRoomDataState",
  default: [],
});

export const useAdminSelectedRoomState = () => {
  const [adminSelectedRoomState, setAdminSelectedRoomState] = useRecoilState(
    adminSelectedRoomDataStateAtom
  );
  return { adminSelectedRoomState, setAdminSelectedRoomState };
};
