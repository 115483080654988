import React,{useState, useEffect} from "react";
import styled from "styled-components";
import axios from "axios";
import Share from "../../../assets/images/ResidentReview/Share.png";
import { useNavigate } from "react-router-dom";
import { copyToClipboard } from "../../../utils/clibboard";
import axiosInstance from "../../../services/axiosConfig";
interface FooterProps {
  createAt: string | undefined;
  id?:string;
  isScrapped: boolean | undefined;
}

const Footer: React.FC<FooterProps> = ({createAt,id,isScrapped}) => {
  const [messageState, setMessageState] = useState(false);
  const [CPYmessageState, setCPYMessageState] = useState(false);
  const [scrapped, setScrapped] = useState<boolean>();

  useEffect(()=>{
    setScrapped(isScrapped);
  },[isScrapped])


  const handleScrap = async () => {

    try {
      const data = {
        category: "review",
        entityId: id,
      };
      const res = await axiosInstance.post(
        "https://houberapp.com/normal/user/scrap",
        data
      );
      if(res.data === "스크랩 추가"){
        setScrapped(true);
      }else{
        setScrapped(false);
      }
    } catch (error) {
    }

  };

  const handleOffMessage = () => {
    setMessageState(!messageState);
  };
  const handleOffMessage2 = () => {
    setCPYMessageState(!CPYmessageState);
  };
  const handleShareClick = () => {
    const currentUrl = window.location.href;
    copyToClipboard(currentUrl);
    setCPYMessageState(true);
  };

  return (
    <>
      {messageState && (
        <AlertMessage>
          <div
            onClick={handleOffMessage}
            style={{
              position: "fixed",
              right: "10px",
              top: "5px",
              cursor: "pointer",
            }}
          >
            x
          </div>
          스크랩했습니다.
        </AlertMessage>
      )}
        {CPYmessageState && (
        <AlertMessage>
          <div
            onClick={handleOffMessage2}
            style={{
              position: "fixed",
              right: "10px",
              top: "5px",
              cursor: "pointer",
            }}
          >
            x
          </div>
          클립보드에 복사되었습니다.
        </AlertMessage>
      )}
      <StyledDiv />
      <FooterContainer>
        <div style={{color:'#9BA2AE', fontWeight:'500'}}>{createAt}</div>
        <ShareDiv>
          <img onClick={handleShareClick} src={Share} />
          <StyledBTN onClick={handleScrap} scrapped={scrapped}>스크랩</StyledBTN>
        </ShareDiv>
      </FooterContainer>
      <StyledDiv className="second" />
    </>
  );
};

export default Footer;



const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 780px;
  height: 75px;
  align-items: center;

  div{
    @media screen and (max-width: 1023px) {
 font-size: 10px;
  }
  }

  @media screen and (max-width: 1023px) {
    width: 386px;
  height: 50px;
  }
`;

const StyledDiv = styled.div`
  width: 100%;
  height: 1px;
  background-color: #a7a7a7;

  &.second {
    margin-bottom: 200px;
  }
`;

const ShareDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 25px;
    height: 25px;
    @media screen and (max-width: 1023px) {
        width: 22px;
        height: 22px;
  }
  }
`;

const StyledBTN = styled.button<{scrapped?:boolean}>`
  width: 83px;
  height: 40px;
  background-color: ${({ scrapped }) => (scrapped ? "#99393a" : "#d3d3d3")};
  border: none;
  border-radius: 5px;
  font-size: 16px;
  color: white;
  font-weight: bold;
  margin-left: 18px;
font-family: "Noto Sans KR";
  @media screen and (max-width: 1023px) {
    width: 64px;
  height: 28px;
  font-size: 12px;
  }
  @media screen and (max-width: 500px) {
    margin-left: 13.5px;
  }
`;

const AlertMessage = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 476px;
  height: 136px;
  transform: translate(-50%, -50%);
  border: 2px solid rgba(187, 185, 185, 0.5);
  background-color: #efefef;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 5000;
  @media screen and (max-width: 1023px) {
    width: 385px;
    height: 133px;
    font-size: 18px;
  }
`;