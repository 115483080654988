import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

interface AutoLoginCheckboxProps {
  autoLogin: boolean;
  handleAutoLoginChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const AutoLoginCheckbox: React.FC<AutoLoginCheckboxProps> = ({ autoLogin, handleAutoLoginChange }) => (
  <CheckboxContainer>
    <StyledCheckbox
      id="AutoLogin"
      checked={autoLogin}
      onChange={handleAutoLoginChange}
    />
    <div>아이디 저장</div>
  </CheckboxContainer>
);

export default AutoLoginCheckbox;

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
`;

const StyledCheckbox = styled.input.attrs({ type: "checkbox" })`
  width: 20px;
  height: 20px;
  appearance: none;
  background-color: white;
  border: 2px solid rgba(187, 185, 185, 0.5);
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  margin-right: 8px;
  transition: all 0.2s ease-in-out;
  margin: 0px;
  margin-right: 8px;
  &:before {
    content: "✔";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: gray;
    font-size: 16px;
  }

  &:checked {
    background-color: #99393a;
    border: #99393a;

    &:before {
      color: white;
      display: block;
    }
  }
  @media (max-width: 425px) {
    width: 17px;
    height: 17px;
  }
`;
