import styled from "styled-components";
import ResponsiveWrapper from "../../../components/ResponsiveWrapper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";

import BannerArrowLeft from "./../../../assets/images/mainPage/bannerarrow-left.png";
import BannerArrowRight from "./../../../assets/images/mainPage/bannerarrow-right.png";
import useDeviceSize from "../../../hooks/useDeviceSize";
import { useNavigate } from "react-router-dom";

function MainBanner({
  mainBannerUrls,
}: {
  mainBannerUrls?: {
    fileUrl: string;
    id: number;
    redirectUrl: string;
  }[];
}) {
  const { isPC } = useDeviceSize();
  const navigate = useNavigate();

  const displayBannerUrls =
    !mainBannerUrls || mainBannerUrls.length === 0
      ? []
      : mainBannerUrls.length === 1
      ? [
          ...mainBannerUrls,
          ...mainBannerUrls,
          ...mainBannerUrls,
          ...mainBannerUrls,
          ...mainBannerUrls,
          ...mainBannerUrls,
        ]
      : mainBannerUrls.length < 4
      ? [...mainBannerUrls, ...mainBannerUrls, ...mainBannerUrls]
      : mainBannerUrls;

  const handleBannerClick = (redirectUrl: string) => {
    // 외부 링크 여부 확인
    if (
      redirectUrl.startsWith("http://") ||
      redirectUrl.startsWith("https://")
    ) {
      window.open(redirectUrl, "_blank"); // 외부 링크는 새 탭에서 열기
    } else {
      navigate(redirectUrl); // 내부 링크는 navigate로 이동
    }
  };

  return (
    <ResponsiveWrapper>
      <Wrapper>
        {displayBannerUrls.length > 0 && (
          <>
            <NavigationButtonDiv>
              <NavigationButton
                $translateX={isPC ? -33 : -21}
                className="main-prev-button"
              >
                <StyledArrowImage src={BannerArrowLeft} />
              </NavigationButton>
              <NavigationButton
                $translateX={isPC ? 33 : 21}
                className="main-next-button"
              >
                <StyledArrowImage src={BannerArrowRight} />
              </NavigationButton>
            </NavigationButtonDiv>
          </>
        )}

        {displayBannerUrls.length > 0 && (
          <StyledSwiper
            loop={displayBannerUrls.length === 1 ? false : true}
            centeredSlides
            centeredSlidesBounds
            modules={[Navigation, Autoplay]}
            autoplay={{ delay: 5000 }}
            navigation={{
              prevEl: ".main-prev-button",
              nextEl: ".main-next-button",
            }}
            breakpoints={{
              1024: {
                slidesPerView: 2.2216,
                spaceBetween: 15,
              },
              1280: {
                slidesPerView: 2.28,
                spaceBetween: 20,
              },
            }}
          >
            {displayBannerUrls.map((item, index) => (
              <StyledSwiperSlide
                key={index}
                onClick={() => handleBannerClick(item.redirectUrl)}
              >
                <StyledImage src={item.fileUrl} key={index}></StyledImage>
              </StyledSwiperSlide>
            ))}
          </StyledSwiper>
        )}
      </Wrapper>
    </ResponsiveWrapper>
  );
}

export default MainBanner;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: 1280px) {
    height: 709px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    height: 574px;
  }
  @media screen and (max-width: 1023px) {
    height: 400px;
  }
`;

const StyledSwiper = styled(Swiper)`
  border-radius: 10px;
  @media screen and (max-width: 1023px) {
    border-radius: 0;
  }
`;

const StyledSwiperSlide = styled(SwiperSlide)`
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  @media screen and (min-width: 1280px) {
    height: 507px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    height: 419.5px;
  }
  @media screen and (max-width: 1023px) {
      height: 400px;
    border-radius: 0;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  @media screen and (max-width: 1023px) {
    border-radius: 0;
    width: auto;
  }
`;

const NavigationButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  z-index: 10;
  pointer-events: none;
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

const NavigationButton = styled.div<{ $translateX: number }>`
  cursor: pointer;
  border-radius: 50px;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.1);
  pointer-events: auto;
  transform: translate(
    ${(props) => props.$translateX && `${props.$translateX}px`}
  );
  @media screen and (min-width: 1280px) {
    width: 52px;
    height: 52px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 50px;
    height: 50px;
  }
`;

const StyledArrowImage = styled.img`
  @media screen and (min-width: 1280px) {
    width: 50px;
    height: 50px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 48px;
    height: 48px;
  }
`;
