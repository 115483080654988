import styled from "styled-components";
import CloneTitle from "./CloneTitle";
import CloneSubtitle from "./CloneSubtitle";
import CloneMoreInfoButton from "./CloneMoreInfoButton";

function CloneContentsHeader({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) {
  return (
    <ContentsHeaderWrapper>
      <CloneTitle>{title}</CloneTitle>
      <HeaderBottom>
        <CloneSubtitle>{subtitle}</CloneSubtitle>
        <CloneMoreInfoButton></CloneMoreInfoButton>
      </HeaderBottom>
    </ContentsHeaderWrapper>
  );
}

export default CloneContentsHeader;

const ContentsHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  height: 60px;
`;
const HeaderBottom = styled.div`
  display: flex;
  justify-content: space-between;
`;
