import { atom } from "recoil";

export type subBannerDataType = {
  id: number | null;
  fileUrl?: string;
  redirectUrl: string;
  file?: File;
};

export const subBannerStateAtom = atom<subBannerDataType>({
  key: "subBannerState",
  default: { id: null, fileUrl: "", redirectUrl: "" },
});
