import styled from "styled-components";
import ResponsiveWrapper from "../ResponsiveWrapper";
import useDeviceSize from "../../hooks/useDeviceSize";
import FooterLeft from "./components/FooterLeft";
import FooterMiddle from "./components/FooterMiddle";
import FooterRight from "./components/FooterRight";
import MobileFooter from "./components/MobileFooter";

function Footer() {
  const { isMobile } = useDeviceSize();
  return (
    <FooterLayout>
      {!isMobile ? (
        <ResponsiveWrapper>
          <Wrapper>
            <FooterLeft></FooterLeft>
            <FooterMiddle></FooterMiddle>
            <FooterRight></FooterRight>
          </Wrapper>
        </ResponsiveWrapper>
      ) : (
        <Wrapper>
          <MobileFooter />
        </Wrapper>
      )}
    </FooterLayout>
  );
}

export default Footer;

const FooterLayout = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #f7f9fa;
  @media screen and (min-width: 1280px) {
    margin-top: 100px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 80px;
  }
  @media screen and (max-width: 1023px) {
    height: 394px;
    flex-direction: column;
    font-size: 14px;
  }
`;

const Wrapper = styled.footer`
  display: flex;
  align-items: center;
  width: 100%;

  color: #333333;
  @media screen and (min-width: 1280px) {
    height: 315px;
    padding: 44px 0 38px;
    font-size: 16px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    height: 254px;
    padding: 46px 0 41px;
    font-size: 15px;
  }
  @media screen and (max-width: 1023px) {
    height: 394px;
    flex-direction: column;
    font-size: 14px;
  }
`;
