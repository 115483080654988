import styled from "styled-components";
import checkIcon from "../../../assets/images/mansionManagement/check.png";
import arrowIcon from "../../../assets/images/ResidentReview/DownIcon.png";
import { useAdminMansionPropertyState } from "../../../recoil/mansionManagement/adminMansionPropertyStateAtom";
import { useEffect, useRef } from "react";

function CategoryModal({
  getCategoryModalState,
  selectedCategoryState,
  getSelectedCategoryState,
}: {
  getCategoryModalState: (newState: boolean) => void;
  selectedCategoryState: number[];
  getSelectedCategoryState: (newState: number[]) => void;
}) {
  const modalRef = useRef<HTMLDivElement>(null);

  const { adminMansionPropertyState, setAdminMansionPropertyState } =
    useAdminMansionPropertyState();

  const handleSelectedCategory = (id: number) => {
    if (!selectedCategoryState.includes(id)) {
      getSelectedCategoryState([...selectedCategoryState, id]);
    } else {
      const newState = [...selectedCategoryState];
      const deleteIndex = newState.indexOf(id);
      if (deleteIndex > -1) newState.splice(deleteIndex, 1);
      getSelectedCategoryState(newState);
    }
  };

  const handleSelectAll = () => {
    if (selectedCategoryState.length < 14) {
      getSelectedCategoryState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]);
    } else {
      getSelectedCategoryState([]);
    }
  };

  const convertedCategoryId = (id: number) => {
    switch (id) {
      case 1:
        return "맨션";
      case 2:
        return "주택";
      case 3:
        return "단층맨션";
      case 4:
        return "기타";
      case 5:
        return "토지";
      case 6:
        return "맨션";
      case 7:
        return "점포";
      case 8:
        return "주택";
      case 9:
        return "기타";
      case 10:
        return "사무실";
      case 11:
        return "창고";
      case 12:
        return "점포";
      case 13:
        return "기타";
      case 14:
        return "하우버맨션";
    }
  };
  return (
    <CategoryModalWrapper>
      <ModalTop>
        <ModalButton style={{ width: 92 }} onClick={() => handleSelectAll()}>
          전체선택
        </ModalButton>
        <ModalButton
          style={{ width: 68 }}
          onClick={() => {
            setAdminMansionPropertyState({
              ...adminMansionPropertyState,
              categoryIdList: selectedCategoryState,
            });
            getCategoryModalState(false);
          }}
        >
          조회
        </ModalButton>
      </ModalTop>
      <ModalCategoryArea>
        <ModalCategoryInner>
          임대
          {[1, 2, 3, 4].map((item, index) => (
            <CategoryItem
              key={index}
              onClick={() => handleSelectedCategory(item)}
            >
              <ArrowIcon src={arrowIcon} />
              {convertedCategoryId(item)}
              {selectedCategoryState.includes(item) && (
                <CheckIcon src={checkIcon} />
              )}
            </CategoryItem>
          ))}
        </ModalCategoryInner>
        <ModalCategoryInner>
          매매
          {[5, 6, 7, 8, 9].map((item, index) => (
            <CategoryItem
              key={index}
              onClick={() => handleSelectedCategory(item)}
            >
              <ArrowIcon src={arrowIcon} />
              {convertedCategoryId(item)}
              {selectedCategoryState.includes(item) && (
                <CheckIcon src={checkIcon} />
              )}
            </CategoryItem>
          ))}
        </ModalCategoryInner>
        <ModalCategoryInner>
          점포{" "}
          {[10, 11, 12, 13].map((item, index) => (
            <CategoryItem
              key={index}
              onClick={() => handleSelectedCategory(item)}
            >
              <ArrowIcon src={arrowIcon} />
              {convertedCategoryId(item)}
              {selectedCategoryState.includes(item) && (
                <CheckIcon src={checkIcon} />
              )}
            </CategoryItem>
          ))}
        </ModalCategoryInner>
        <ModalCategoryInner>
          하우버 맨션
          <CategoryItem onClick={() => handleSelectedCategory(14)}>
            <ArrowIcon src={arrowIcon} />
            {convertedCategoryId(14)}
            {selectedCategoryState.includes(14) && (
              <CheckIcon src={checkIcon} />
            )}
          </CategoryItem>
        </ModalCategoryInner>
      </ModalCategoryArea>
    </CategoryModalWrapper>
  );
}

export default CategoryModal;

const CategoryModalWrapper = styled.div`
  font-weight: 500;
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 35px;
  width: 747px;
  height: 435px;
  box-sizing: border-box;
  top: 67px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

const ModalTop = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 40px;
`;

const ModalButton = styled.button`
  border: none;
  font-size: 20px;
  border-radius: 5px;
  background-color: #e6e6e6;
  height: 100%;
  cursor: pointer;
  font-family: "Noto Sans KR";
  font-weight: 500;
`;

const ModalCategoryArea = styled.div`
  width: 100%;
  display: flex;
  margin-top: 28px;
`;

const ModalCategoryInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  font-size: 20px;
  width: 162px;
`;

const CategoryItem = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  cursor: pointer;
`;

const ArrowIcon = styled.img`
  height: 8px;
  width: 15px;
  transform: rotate(270deg);
`;

const CheckIcon = styled.img`
  width: 25px;
  height: 17px;
`;
