import { atom, useRecoilState } from "recoil";
import { popularMansionDataType } from "../../pages/popularMansionPage/PopularMansion";


export const popularMansionDataStateAtom = atom<popularMansionDataType>({
  key: "popularMansionState",
  default: {
    bestRooms: [],
  },
});


const usePopularMansionData = () => {
  const [popularMansionDataState, setPopularMansionDataState] =
    useRecoilState<popularMansionDataType>(popularMansionDataStateAtom);

  return { popularMansionDataState, setPopularMansionDataState };
};

export default usePopularMansionData;
