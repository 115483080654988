import React, { useCallback, useEffect, useState } from 'react'
import {MapPinDetailWrap, ContentsListWrap, SidebarPinDetailWrapper, MapPinContentsTop, PinListTitle, MobileComponents, ArrowIconWrap, MapPinContentsListWrap, WebArrowIcon, WebArrowIconWrap } from './Map.style';
import axiosInstance from '../../../services/axiosConfig';
import PinThumbnail from './PinThumbnail';
import arrow from '../../../assets/images/mapPage/arrow.png'
import arrowLeftRight from '../../../assets/images/mapPage/arrowLeftRight.png'
import MapPinContentsList from './MapPinContentsList';

interface props {
  setPinDetail: React.Dispatch<React.SetStateAction<number>>
  pinDetail: number
  pinGetKey: string
  showFilterList: boolean
}

export interface googlePinInfoI {
  id:number
  propertyThumbnailUrl: string
  propertyName: string
  roomList: roomInfoI[] | []
}
export interface roomInfoI{
  id: number
  floorPlanUrl: string
  scrapbook:	boolean
  rent: number
  managementFee: number
  deposit	: number
  gratuity: number
  roomType: string
  area: number
  numOfFloors: number
}

export default function MapPinDetail({setPinDetail, pinDetail, pinGetKey, showFilterList}: props) {
  // /map/google/detail/{id} 에서 받아온 데이터 저장
  const [pinInfo, setPiinInfo] = useState<googlePinInfoI>({
    id: 0,
    propertyName: "",
    propertyThumbnailUrl: "",
    roomList: []
  })
  // 모바일용 up down || web on off
  const [show, setShow] = useState<boolean>(true);

  const fetchPinId = async ()=>{
    try {
      const response: { data: googlePinInfoI } = await axiosInstance.get(
        `/map/google/detail/${pinDetail}?key=${pinGetKey}`
      );
      setPiinInfo(response.data)
    } catch (error) {
      console.log("error", error)
    }
    setShow(true);
  };
  
  useEffect(()=>{
    fetchPinId()
  },[pinDetail, pinGetKey])
  
  return (
    <MapPinDetailWrap $show={show}>
        <ContentsListWrap $show={show}>
          <SidebarPinDetailWrapper $show={show}>
            <MapPinContentsTop $show={show}>
                {show &&
                      <ArrowIconWrap 
                      onClick={()=>{setShow(false)}}
                      src={arrow}
                      $rotateValue={180}
                      $width='32px'
                      $margin='0 0 8px 0'
                      $height='8px'/>
                }
                {!show &&
                      <ArrowIconWrap 
                      onClick={()=>{setShow(true)}}
                      src={arrow}
                      $width='32px'
                      $margin='0 0 8px 0'
                      $height='8px'/>
                }
              
              <PinListTitle >매물</PinListTitle>
              
            </MapPinContentsTop>
            {show &&(
                      <WebArrowIconWrap onClick={()=>{setShow(false)}}>
                        <WebArrowIcon src={arrowLeftRight} $height='16px' $width='8px'/>
                      </WebArrowIconWrap> 
                    )
                }
            {!show &&(
                    <WebArrowIconWrap onClick={()=>{setShow(true)}} $hidden={true} $showFilterList={showFilterList}>
                      <WebArrowIcon src={arrowLeftRight} $rotateValue={-180} $height='16px' $width='8px'/>
                    </WebArrowIconWrap> 
                    )
            }
            <MapPinContentsListWrap $show={show}>
              <PinThumbnail 
                id={pinInfo.id} 
                propertyName={pinInfo.propertyName}
                propertyThumbnailUrl={pinInfo.propertyThumbnailUrl}/>
              <MapPinContentsList roomList={pinInfo.roomList}/>
            </MapPinContentsListWrap>
          </SidebarPinDetailWrapper>
        </ContentsListWrap>
        
      </MapPinDetailWrap>
  )
}
