import styled from "styled-components";
import ResponsiveWrapper from "../../../components/ResponsiveWrapper";
import ContentsHeader from "./ContentsHeader";
import MainItem from "./MainItem";
import useDeviceSize from "../../../hooks/useDeviceSize";
import MoreInfoButton from "./MoreInfoButton";
import Navigation, { getMaxPage } from "../../../components/Navigation";
import { useEffect, useState } from "react";
import { mainMansionDataType, mainPageDataType } from "../Main";

function PopularMansions({ bestList }: { bestList?: mainMansionDataType[] }) {
  const { isPC, isLaptop, isMobile } = useDeviceSize();

  const [contentsPageState, setContentsPageState] = useState(0);
  const [currentPageMansions, setCurrentPageMansions] = useState<
    mainMansionDataType[]
  >([]);

  useEffect(() => {
    if (bestList) {
      const itemsPerPage = isMobile ? 2 : isPC ? 4 : 3;
      const startIndex = contentsPageState * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setCurrentPageMansions(bestList.slice(startIndex, endIndex));
    }
  }, [contentsPageState, bestList, isMobile, isLaptop, isPC]);

  console.log(currentPageMansions);
  return (
    <Wrapper>
      <ResponsiveWrapper>
        <ContentsHeader
          title="최신 등록 매물"
          subtitle="가장 최신 등록된 매물을 소개해드릴게요"
          moreInfo="/popularMansion"
        />
        <ContentsWrapper>
          {!bestList || (bestList && bestList.length < 1) ? (
            <NoItemArea>조건에 해당하는 매물이 없습니다.</NoItemArea>
          ) : (
            <>
              <Navigation
                onClickNext={() => {
                  setContentsPageState(contentsPageState + 1);
                }}
                onClickPrev={() => {
                  setContentsPageState(contentsPageState - 1);
                }}
                page={contentsPageState}
                maxPage={getMaxPage(isPC ? 4 : 3, bestList?.length)}
                translateY={-13}
              />
              {isPC &&
                currentPageMansions &&
                currentPageMansions.map((item, index) => (
                  <MainItem
                    key={index}
                    width={269}
                    height={177}
                    itemTitleMargin={18}
                    roomInfo={item}
                    category="room"
                    pageState={contentsPageState}
                  />
                ))}
              {isLaptop &&
                currentPageMansions &&
                currentPageMansions.map((item, index) => (
                  <MainItem
                    key={index}
                    width={297}
                    height={196}
                    itemTitleMargin={13}
                    roomInfo={item}
                    category="room"
                    pageState={contentsPageState}
                  />
                ))}
              {isMobile &&
                currentPageMansions &&
                currentPageMansions.map((item, index) => (
                  <MainItem
                    key={index}
                    width={187}
                    height={137}
                    itemTitleMargin={10}
                    roomInfo={item}
                    category="room"
                    pageState={contentsPageState}
                  />
                ))}
            </>
          )}
        </ContentsWrapper>
        {isMobile && (
          <MoreInfoButton
            moreInfoText="최신 등록 매물 더보기"
            linkTo="/popularMansion"
          ></MoreInfoButton>
        )}
      </ResponsiveWrapper>
    </Wrapper>
  );
}

export default PopularMansions;

const Wrapper = styled.div`
  @media screen and (max-width: 1023px) {
    margin-bottom: 78px;
  }
`;

const ContentsWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  @media screen and (min-width: 1280px) {
    margin: 21px 0;
    height: 209px;
    gap: 28px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin: 18px 0;
    height: 223px;
    gap: 21px;
  }
  @media screen and (max-width: 1023px) {
    margin: 23px 0;
    height: 162px;
    justify-content: space-between;
  }
`;

const NoItemArea = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #837474;
  @media screen and (min-width: 1280px) {
    font-size: 27px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 24px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 18px;
  }
`;
