import { Dispatch, SetStateAction, useEffect } from "react";
import styled from "styled-components";
import axiosInstance from "../../services/axiosConfig";
import { List } from "./DataOptionBox";

interface Props {
  detail: List[];
  id: number;
  setStep: Dispatch<SetStateAction<number>>;
  setRegionLineInner: Dispatch<SetStateAction<any>>;
}

function ChoiceSubwayOption({
  detail,
  id,
  setStep,
  setRegionLineInner,
}: Props) {
  const onClick = async (id: number) => {
    try {
      const res = await axiosInstance.get(`/map/subway/prefecture/${id}`);
      setRegionLineInner({ regionLineData: res.data });
      setStep((prev) => (prev += 1));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <OverMap>
      <ChoiceAreaOptionWrapper
        $top={id === 1 ? 70 : id === 2 ? 55 : 45}
        $left={id === 1 ? 42 : id === 2 ? 70 : 0}
      >
        <OptionDetailDiv>
          {detail.map((item, index) => (
            <OptionDetail
              key={index}
              onClick={() => {
                onClick(item.id);
              }}
            >
              {item.name}
              {" (" + item.propertyCount + ")"}
            </OptionDetail>
          ))}
        </OptionDetailDiv>
      </ChoiceAreaOptionWrapper>
    </OverMap>
  );
}

export default ChoiceSubwayOption;

const OverMap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 10px;
  @media screen and (min-width: 1280px) {
    width: 664px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 522px;
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;

const ChoiceAreaOptionWrapper = styled.div<{ $top: number; $left: number }>`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  border: 1px solid #00000050;
  z-index: 20;
  color: #000000a0;
  top: ${(props) => `${props.$top}%`};
  left: ${(props) => `${props.$left}%`};
  @media screen and (min-width: 1280px) {
    padding: 5px 0px;
    max-width: 145px;
    gap: 10px;
    border-radius: 10px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 4px 0px;
    max-width: 135px;
    gap: 7px;
    border-radius: 7px;
  }
  @media screen and (max-width: 1023px) {
    padding: 4px 0px;
    max-width: 105px;
    gap: 3px;
    border-radius: 7px;
  }
`;

const OptionDetailDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1280px) {
    gap: 7px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 5px;
  }
  @media screen and (max-width: 1023px) {
    gap: 3px;
  }
`;

const OptionDetail = styled.div`
  display: flex;
  font-weight: 600;
  padding: 8px 0px 8px 15px;
  color: #000000a0;
  &:hover {
    background-color: rgb(153, 57, 58, 0.7);
    cursor: pointer;
  }
  @media screen and (min-width: 1280px) {
    font-size: 14px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 11px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 9px;
  }
`;
