import checkIcon from "../../../assets/images/mansionManagement/check.png";
import styled from "styled-components";
import { useAdminRegistrationState } from "../../../recoil/mansionManagement/adminRegistrationStateAtom";

function RegistrationPoint() {
  const { registrationDataState, setRegistrationDataState } =
    useAdminRegistrationState();
  return (
    <PointWrapper>
      <PointInputArea>
        <PointTitle>추천 포인트</PointTitle>
        <OptionsItemWrapper>
          <PointItem>
            <PointIcon src={checkIcon} />
            <PointInput
              placeholder="이 맨션의 추천 포인트를 적어주세요"
              value={registrationDataState.point1}
              onChange={(e) =>
                setRegistrationDataState({
                  ...registrationDataState,
                  point1: e.target.value,
                })
              }
            ></PointInput>
          </PointItem>
          <PointItem>
            <PointIcon src={checkIcon} />
            <PointInput
              placeholder="이 맨션의 추천 포인트를 적어주세요"
              value={registrationDataState.point2}
              onChange={(e) =>
                setRegistrationDataState({
                  ...registrationDataState,
                  point2: e.target.value,
                })
              }
            ></PointInput>
          </PointItem>
          <PointItem>
            <PointIcon src={checkIcon} />
            <PointInput
              placeholder="이 맨션의 추천 포인트를 적어주세요"
              value={registrationDataState.point3}
              onChange={(e) =>
                setRegistrationDataState({
                  ...registrationDataState,
                  point3: e.target.value,
                })
              }
            ></PointInput>
          </PointItem>
        </OptionsItemWrapper>
      </PointInputArea>
    </PointWrapper>
  );
}

export default RegistrationPoint;

const PointWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 399px;
  padding-left: 23px;
  border-bottom: 2px solid #e6e6e6;
`;

const PointTitle = styled.div`
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -2px;
`;

const PointInputArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OptionsItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

const PointItem = styled.div`
  display: flex;
  align-items: center;
  padding: 17px 19px;
  width: 904px;
  height: 60px;
  border: 1px dotted #a7a7a7;
  border-radius: 5px;
  background-color: #f7f8f9;
  box-sizing: border-box;
  font-size: 18px;
  letter-spacing: -1px;
  padding: 0 20px;
`;

const PointIcon = styled.img`
  width: 25px;
  height: 17px;
`;

const PointInput = styled.input`
  flex: 1;
  margin-left: 13.5px;
  height: 95%;
  border: none;
  background-color: #f7f8f9;
  font-size: 18px;
  outline: none;
`;
