import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ResponsiveWrapper from "../../components/ResponsiveWrapper";
import axiosInstance from "../../services/axiosConfig";
import FileIcon from "../../assets/images/qna/dd.png";
import CheckImg from "../../assets/images/qna/check.png";
import Like from "../../assets/images/qna/Group 4.png";
import LikeMobile from "../../assets/images/qna/like.png";
import LikeMobileClick from "../../assets/images/qna/likeClick.png";
import UnLike from "../../assets/images/qna/Group 3.png";
import { useParams, useNavigate } from "react-router-dom";
import face from "../../../src/assets/images/mypage/face.png";

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 104px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 90px;
  }

  @media screen and (max-width: 1023px) {
    align-items: flex-start;
    margin-top: 80px;
  }
`;

const Title = styled.div`
  font-size: 26px;
  color: #999999;
  text-align: left;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 24px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 12px;
  }
`;

const QuestionTitle = styled.div`
  font-size: 38px;
  font-weight: 800;
  margin-top: 19px;
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 36px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 18px;
  font-weight: 700;

  }
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 37px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 35px;
  }

  @media screen and (max-width: 1023px) {
    border-bottom: solid 1.5px #cccccc;
    padding-bottom: 25px;
    align-items: flex-start;
    margin-top: 30px;
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-right: 15px;
  }
`;

const UserIcon = styled.img`
  width: 75px;
  height: 75px;
  color: grey;
  margin-right: 19px;
  border-radius: 50%;
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 65px;
    height: 65px;
  }

  @media screen and (max-width: 1023px) {
    width: 35px;
    height: 35px;
  }
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserName = styled.div`
  font-weight: 800;
  font-size: 19px;
  color: #333333;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 18px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 13px;
  font-weight: 700;

  }
`;

const TimeInfo = styled.div`
  color: #999999;
  font-size: 19px;
  margin-top: 6px;
  font-weight: 500;


  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 18px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 13px;
    font-weight: 500;
  }
`;

const QuestionStatus = styled.div`
  margin-left: auto;
  color: #999999;
  font-size: 26px;
  text-align: right;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 24px;
    margin-top: 16px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 12px;
  }
`;

const RecommendButton = styled.div<{ recommended: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 1023px) {
    flex-direction: row;
    padding: 7px 15px;
    border-radius: 10px;
    background-color: ${(props) =>
      props.recommended ? "#99393a" : "transparent"};
    border: 1.5px solid
      ${(props) => (props.recommended ? "transparent" : "#bebebe")};
  }

  img {
    width: 40px;
    height: 40px;

    @media screen and (max-width: 1279px) and (min-width: 1024px) {
      width: 35px;
      height: 35px;
    }

    @media screen and (max-width: 1023px) {
      width: 20px;
      height: 20px;
    }
  }
`;

const RecommendIcon = styled.div`
  margin-bottom: 5px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-bottom: 4px;
  }

  @media screen and (max-width: 1023px) {
    margin-bottom: 3px;
    margin-right: 8px;
  }
`;

const Recommend = styled.div<{ recommended: boolean }>`
  color: #555555;
  font-size: 19px;
  font-weight: 600;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 17px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 15px;
  font-weight: 700;

  }

  @media screen and (max-width: 1023px) {
    color: ${(props) => (props.recommended ? "#ffffff" : "#555555")};
  }
`;

const QuestionContent = styled.div`
  font-size: 23px;
  margin: 62px 0 58px 0;
  color: #555555;
  white-space: pre-line;
  line-height: 1.7;
  font-weight: 500;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
    margin: 60px 0 55px 0;
  }

  @media screen and (max-width: 1023px) {
    font-size: 20px;
    margin: 50px 0 50px 0;
  }
`;

const UploadedItemLinkContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-height: 600px;
  border: 0px solid #ccc;
  border-radius: 10px;
  margin-bottom: 16px;
  overflow: hidden;
  padding: 1px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-bottom: 14px;
  }

  @media screen and (max-width: 1023px) {
    margin-bottom: 12px;
  }
`;

const LinkStyled = styled.a`
  color: blue; /* 기본 링크 색상 */
  text-decoration: underline; /* 밑줄 추가 */
  &:hover {
    color: darkblue; /* 호버 시 색상 */
  }
  font-size: 23px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 20px;
  }
`;

const UploadedItemContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 600px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 16px;
  overflow: hidden;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-bottom: 14px;
  }

  @media screen and (max-width: 1023px) {
    margin-bottom: 12px;
  }
`;

const UploadedItemFileContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-height: 600px;
  border: 0px solid #ccc;
  border-radius: 10px;
  margin-bottom: 16px;
  overflow: hidden;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-bottom: 14px;
  }

  @media screen and (max-width: 1023px) {
    margin-bottom: 12px;
  }
`;

const UploadedImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* 이미지가 컨테이너에 맞게 조정되도록 설정 */
  border-radius: 10px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    border-radius: 8px;
  }

  @media screen and (max-width: 1023px) {
    border-radius: 5px;
  }
`;

const UploadedFile = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 30px 42px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 16px;
  height: 93px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 25px 36px;

    height: 85px;
  }

  @media screen and (max-width: 1023px) {
    padding: 20px 30px;

    height: 75px;
  }
`;

const FileIconImg = styled.img`
  width: 34px;
  margin-right: 22px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 30px;
    margin-right: 18px;
  }

  @media screen and (max-width: 1023px) {
    width: 26px;
    margin-right: 14px;
  }
`;

const Check = styled.img`
  width: 32px;
  margin-left: 22px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 28px;
    margin-left: 18px;
  }

  @media screen and (max-width: 1023px) {
    width: 24px;
    margin-left: 14px;
  }
`;

const QuestionImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin-bottom: 20px;
  object-fit: contain; /* 이미지 전체를 보여주기 위해 contain으로 변경 */
  cursor: pointer; /* 클릭 가능하게 커서 모양 변경 */

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-bottom: 18px;
  }

  @media screen and (max-width: 1023px) {
    margin-bottom: 16px;
  }
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 67px 0;
  padding-bottom: 102px;
  border-bottom: solid 1.5px #cccccc;
  font-weight: 500;


  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin: 60px 0;
    padding-bottom: 90px;
  }

  @media screen and (max-width: 1023px) {
    margin: 27px 0;
    padding-bottom: 22px;
  }
`;

const Tag = styled.div`
  color: #6c90c3;
  font-size: 23px;
  margin-right: 36px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 21px;
    margin-right: 32px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 15px;
    margin-right: 28px;
  }
`;

const AnswerContainer = styled.div`
  padding-bottom: 80px;
`;

const AnswerContent = styled.div`
  font-size: 23px;
  color: #555555;
  font-weight: 500;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 21px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 19px;
  }
`;

const AnswerImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* 자식 요소를 여러 줄로 배치 */
  overflow-x: scroll;
  margin-top: 20px;
  gap: 10px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 18px;
  }

  @media screen and (max-width: 1023px) {
    margin-top: 16px;
  }
`;

const AnswerImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer; /* 클릭 가능하게 커서 모양 변경 */
`;

const EditButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 320px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-bottom: 280px;
  }

  @media screen and (max-width: 1023px) {
    margin-bottom: 240px;
  }
`;

const EditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 50px;
  font-size: 23px;
  background-color: transparent;
  color: #333333;
  border: 2px solid #bfbfbf;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  min-width: 180px;
  height: 50px;
  font-weight: 500;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 10px 45px;
    font-size: 21px;
    height: 45px;
  }

  @media screen and (max-width: 1023px) {
    padding: 8px 40px;
    font-size: 19px;
    height: 40px;
  }
`;

const DetailQna: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [recommended, setRecommended] = useState(false);
  const [questionData, setQuestionData] = useState<any>(null);
  const [link, setLink] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/qna/detail/${id}`);
        setQuestionData(response.data);
        setRecommended(response.data.recommend);
        setLink(response.data.link);
        // console.log(response);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const handleRecommendClick = async () => {
    try {
      await axiosInstance.patch(`/qna/recommend/${id}`, {});
      setRecommended((prev) => !prev);
    } catch (error) {
      // console.error("Error updating recommendation:", error);
    }
  };

  const handleImageClick = (url: string) => {
    window.open(url, "_blank");
  };

  const getStatusText = (saveStatus: number, answerStatus: boolean) => {
    if (saveStatus === 0) return "임시저장";
    if (answerStatus) return "답변완료";
    return "미답변";
  };

  return (
    <ResponsiveWrapper>
      {questionData ? (
        <>
          <Header>
            <Title>Q&A</Title>
            <QuestionStatus>
              {getStatusText(
                questionData.saveStatus,
                questionData.answerStatus
              )}
            </QuestionStatus>
          </Header>
          <QuestionTitle>{questionData.title}</QuestionTitle>
          <Box>
            <UserInfo>
              <UserIcon src={questionData?.userProfileImage || face} />
              <UserDetails>
                <UserName>{questionData.nickName}</UserName>
                <TimeInfo>{questionData.createDate}</TimeInfo>
              </UserDetails>
            </UserInfo>
            <RecommendButton
              onClick={handleRecommendClick}
              recommended={recommended}
            >
              {isMobile ? (
                <RecommendIcon
                  as="img"
                  src={recommended ? LikeMobileClick : LikeMobile}
                />
              ) : (
                <RecommendIcon as="img" src={recommended ? Like : UnLike} />
              )}
              <Recommend recommended={recommended}>추천하기</Recommend>
            </RecommendButton>
          </Box>

          <QuestionContent>{questionData.content}</QuestionContent>
          {link && (
            <UploadedItemLinkContainer>
              <LinkStyled href={link} target="_blank" rel="noopener noreferrer">
                {link}
              </LinkStyled>
            </UploadedItemLinkContainer>
          )}
          {questionData.imageAndFile
            .filter((file: any) => file.fileName.endsWith(".pdf"))
            .map((file: any, index: number) => (
              <UploadedItemFileContainer key={index}>
                <UploadedFile
                   onClick={() => handleImageClick(file.url)}>
                  <FileIconImg src={FileIcon} />
                  <div>{file.fileName}</div>
                  <Check src={CheckImg} />
                </UploadedFile>
              </UploadedItemFileContainer>
            ))}
          {questionData.imageAndFile
            .filter(
              (file: any) =>
                file.fileName.endsWith(".jpeg") ||
                file.fileName.endsWith(".jpg") ||
                file.fileName.endsWith(".png")
            )
            .map((file: any, index: number) => (
              <UploadedItemContainer key={index}>
                <QuestionImage
                  src={file.url}
                  alt={`uploaded ${index}`}
                  onClick={() => handleImageClick(file.url)}
                />
              </UploadedItemContainer>
            ))}

          <Tags>
            {questionData.keywords.map((keyword: string, index: number) => (
              <Tag key={index}>#{keyword}</Tag>
            ))}
          </Tags>
          {questionData.saveStatus === 0 && (
            <EditButtonContainer>
              <EditButton onClick={() => navigate(`/qnaform/${id}`)}>
                수정하기
              </EditButton>
            </EditButtonContainer>
          )}
          {questionData.answers && questionData.answers.length > 0 && (
            <AnswerContainer>
              <AnswerContent>{questionData.answers[0].content}</AnswerContent>
              <AnswerImagesContainer>
                {questionData.answers[0].images
                  .filter(
                    (file: string) =>
                      file.endsWith(".jpeg") ||
                      file.endsWith(".jpg") ||
                      file.endsWith(".png")
                  )
                  .map((file: string, index: number) => (
                    <AnswerImage
                      key={index}
                      src={file}
                      alt={`answer ${index}`}
                      onClick={() => handleImageClick(file)}
                    />
                  ))}
              </AnswerImagesContainer>
            </AnswerContainer>
          )}
        </>
      ) : (
        <div>존재하지 않는 페이지 입니다</div>
      )}
    </ResponsiveWrapper>
  );
};

export default DetailQna;
