import { useEffect, useState } from "react";
import styled from "styled-components";
import MansionManagementHeader from "./components/MansionManagementHeader";
import MansionManagememtContents from "./components/MansionManagementContents";
import axiosInstance from "../../services/axiosConfig";
import { useAdminMansionPropertyState } from "../../recoil/mansionManagement/adminMansionPropertyStateAtom";

export type adminMansionListType = {
  totalPages: number;
  totalElements: number;
  size: number;
  content: {
    id: number;
    thumbnailImg: {
      id: number;
      fileType: string;
      url: string;
    };
    name: string;
    agency: string;
    status: "Public" | "Private" | "Temp";
  }[];
  number: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  pageable: {
    offset: number;
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
  };
  numberOfElements: number;
  first: boolean;
  last: boolean;
  empty: boolean;
};

function AdminMansionManagememt() {
  const [mansionListState, setMansionListState] =
    useState<adminMansionListType>();

  const { adminMansionPropertyState, setAdminMansionPropertyState } =
    useAdminMansionPropertyState();

  const fetchMansions = async () => {
    try {
      const response: { data: adminMansionListType } = await axiosInstance.get(
        "/admin/property",
        {
          params: {
            regionId: adminMansionPropertyState.regionId,
            page: adminMansionPropertyState.page,
            categoryIdList:
              adminMansionPropertyState.categoryIdList &&
              adminMansionPropertyState.categoryIdList.join(","),
          },
        }
      );

      setMansionListState(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSearchMansions = async () => {
    try {
      const response: { data: adminMansionListType } = await axiosInstance.get(
        "/admin/property/search",
        {
          params: {
            regionId: adminMansionPropertyState.regionId,
            page: adminMansionPropertyState.page,
            keyword: adminMansionPropertyState.keyword,
          },
        }
      );

      setMansionListState(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      adminMansionPropertyState.keyword &&
      adminMansionPropertyState.keyword.length > 0
    ) {
      fetchSearchMansions();
    } else {
      fetchMansions();
    }
  }, [adminMansionPropertyState]);

  return (
    <Wrapper>
      <ContentsWrapper>
        <MansionManagementHeader></MansionManagementHeader>
        <MansionManagememtContents
          mansionList={mansionListState}
        ></MansionManagememtContents>
      </ContentsWrapper>
    </Wrapper>
  );
}

export default AdminMansionManagememt;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 1920px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 105px 0;
`;

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1323px;
  padding: 0 43px;
  background-color: white;
`;
