import React, { useEffect, useState } from "react";
import useDeviceSize from "../../../hooks/useDeviceSize";
import AdminChoiceAreaOption from "./AdminChoiceAreaOption";
import { useAdminMansionPropertyState } from "../../../recoil/mansionManagement/adminMansionPropertyStateAtom";
export type List = { id: number; name: string; propertyCount: number };

const AdminChoiceAreaDataOptionBox = ({
  getChoiceAreaModalState,
}: {
  getChoiceAreaModalState: (newState: boolean) => void;
}) => {
  const { isPC } = useDeviceSize();
  const { setAdminMansionPropertyState, adminMansionPropertyState } =
    useAdminMansionPropertyState();
  return (
    <>
      <AdminChoiceAreaOption
        title="간사이"
        detail={["오사카 | 효고 | 교토", "시가 | 나라 | 와카야마"]}
        top={isPC ? 78 : 75}
        left={isPC ? 33 : 33}
        id={1}
        onClickIem={() => {
          setAdminMansionPropertyState({
            ...adminMansionPropertyState,
            regionId: 1,
          });
          getChoiceAreaModalState(false);
        }}
      />
      <AdminChoiceAreaOption
        title="칸토"
        detail={[
          "도쿄 | 카나가와 | 치바",
          "사이타마 | 이바라키",
          "도치기 | 군마 ",
        ]}
        top={isPC ? 53 : 53}
        left={isPC ? 70 : 60}
        id={2}
        onClickIem={() => {
          setAdminMansionPropertyState({
            ...adminMansionPropertyState,
            regionId: 2,
          });
          getChoiceAreaModalState(false);
        }}
      />
      <AdminChoiceAreaOption
        title="규슈 오키나와"
        detail={[
          "후쿠오카 | 사가 | 나가사키",
          "구마모토 | 오이타 | 미야자키",
          "가고시마 | 오키나와",
        ]}
        top={isPC ? 55 : 55}
        left={isPC ? 0 : 0}
        id={3}
        onClickIem={() => {
          setAdminMansionPropertyState({
            ...adminMansionPropertyState,
            regionId: 3,
          });
          getChoiceAreaModalState(false);
        }}
      />
    </>
  );
};

export default AdminChoiceAreaDataOptionBox;


