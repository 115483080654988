import styled from "styled-components";
import ResponsiveWrapper from "../../../../components/ResponsiveWrapper";
import CloneContentsHeader from "./CloneContentsHeader";
import CloneMainItem from "./CloneMainItem";
import { useRecoilValue } from "recoil";
import { subBannerStateAtom } from "../../../../recoil/adminMainDesign/subBannerStateAtom";

function CloneHouberMansions() {
  const subBanner = useRecoilValue(subBannerStateAtom);

  return (
    <ResponsiveWrapper>
      <ContentsOuter>
        <CloneContentsHeader
          title="하우버 맨션"
          subtitle="하우버 전용 맨션을 소개해드릴게요"
        />
        <ContentsWrapper>
          {(subBanner && subBanner.fileUrl) || (subBanner && subBanner.file) ? (
            <CloneMainItem
              width={557}
              height={453}
              thumbnailUrl={subBanner.fileUrl}
            ></CloneMainItem>
          ) : (
            <CloneMainItem width={557} height={453}></CloneMainItem>
          )}
          <SmallItemWrapper>
            {[1, 2, 3, 4].map((item, index) => (
              <CloneMainItem key={index} width={268} height={177} />
            ))}
          </SmallItemWrapper>
        </ContentsWrapper>
      </ContentsOuter>
    </ResponsiveWrapper>
  );
}

export default CloneHouberMansions;

const ContentsOuter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const ContentsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  margin-bottom: 119px;
`;

const SmallItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-left: 30px;
`;

const SubBannerWrapper = styled.div`
  width: 557px;
  height: 453px;
  border-radius: 10px;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;
