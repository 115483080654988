import { atom } from "recoil";

export type propertyOptionDataType = {
  freeInternet: boolean;
  petsAllowed: boolean;
  morePeople: boolean;
  designerMention: boolean;
  duplexMention: boolean;
  closeToStation: boolean;
  induction: boolean;
  gasRange: boolean;
  allElectricMansion: boolean;
  cityGas: boolean;
  indoorWashingBoard: boolean;
  outdoorWashingBoard: boolean;
  bidet: boolean;
  standAloneToilet: boolean;
  unitBath: boolean;
  bathroomDryer: boolean;
  airConditioning: boolean;
  storageSpace: boolean;
  trashDisposal24H: boolean;
  garbageStorage: boolean;
  intercom: boolean;
  deliveryBox: boolean;
  carParking: boolean;
  elevator: boolean;
  bicycleParking: boolean;
  bikeParking: boolean;
  womenOnly: boolean;
  mensOnly: boolean;
  autoLock: boolean;
  cctv: boolean;
  foreignerAllowed: boolean;
  overseasScreeningAvailable: boolean;
  furnitureOptions: boolean;
};

export const propertyOptionDataStateAtom = atom<propertyOptionDataType>({
  key: "propertyOptionDataState",
  default: {
    freeInternet: false,
    petsAllowed: false,
    morePeople: false,
    designerMention: false,
    duplexMention: false,
    closeToStation: false,
    induction: false,
    gasRange: false,
    allElectricMansion: false,
    cityGas: false,
    indoorWashingBoard: false,
    outdoorWashingBoard: false,
    bidet: false,
    standAloneToilet: false,
    unitBath: false,
    bathroomDryer: false,
    airConditioning: false,
    storageSpace: false,
    trashDisposal24H: false,
    garbageStorage: false,
    intercom: false,
    deliveryBox: false,
    carParking: false,
    elevator: false,
    bicycleParking: false,
    bikeParking: false,
    womenOnly: false,
    mensOnly: false,
    autoLock: false,
    cctv: false,
    foreignerAllowed: false,
    overseasScreeningAvailable: false,
    furnitureOptions: false,
  },
});
