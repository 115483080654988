import styled from "styled-components";
import useDeviceSize from "../../hooks/useDeviceSize";
import HouberMansions from "./components/HouberMansions";
import Categories from "./components/Categories";
import MainBanner from "./components/MainBanner";
import PopularMansions from "./components/PopularMansions";
import MansionReviews from "./components/MansionReviews";
import { useEffect } from "react";
import axiosInstance from "../../services/axiosConfig";
import { useMainPageData } from "../../recoil/mainPge/mainPageDataState";
import counselingKakao from "../../assets/images/mainPage/counseling-kakao.png";
import upIcon from "../../assets/images/mainPage/arrow-up.png";
import { selectedOptionType } from "../../recoil/adminMainDesign/categoryIconStateAtom";

export type mainMansionDataType = {
  name: string;
  thumbnailUrl: string;
  scrapbook: boolean;
  id: number;
};

export type reviewDataType = {
  scrapbook: boolean;
  id: number;
  thumbnailUrl: string;
  name: string;
};

export type mainPageDataType = {
  bestList: mainMansionDataType[];
  bestBrooklynMansions: mainMansionDataType[];
  latestReviews: reviewDataType[];
  mainImageResponse: {
    iconResponseList: {
      fileUrl: string;
      id: number;
      name: string;
      selectedOption: selectedOptionType;
    }[];
    logoResponse: {
      fileUrl: string;
      id: number;
    };
    mainBannerList: {
      fileUrl: string;
      id: number;
      redirectUrl: string;
    }[];
    subBanner: {
      fileUrl: string;
      id: number;
      redirectUrl: string;
    };
  };
};

type mainPageResponseType = {
  data: mainPageDataType;
};

// 메인 페이지
function Main({ admin }: { admin?: boolean }) {
  const { isLaptop, isMobile } = useDeviceSize();

  useEffect(() => {}, [isMobile, isLaptop]);

  const { mainPageDataState, setMainPageDataState } = useMainPageData();

  const fetchMain = async () => {
    const response: mainPageResponseType = await axiosInstance.get(
      "/normal/home/main"
    );

    setMainPageDataState(response.data);
  };

  useEffect(() => {
    if (admin !== true) {
      fetchMain();
    }
  }, []);

  return (
    <MainWrapper>
      <RemoteControllerWrapper>
        <RemoteController>
          <StyledImage
            src={counselingKakao}
            onClick={() => {
              window.open("https://pf.kakao.com/_qTEPj");
            }}
          ></StyledImage>
          <StyledImage
            src={upIcon}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          ></StyledImage>
        </RemoteController>
      </RemoteControllerWrapper>
      <MainBanner
        mainBannerUrls={mainPageDataState?.mainImageResponse.mainBannerList}
      />
      {isMobile && (
        <Categories
          iconResponses={mainPageDataState?.mainImageResponse.iconResponseList}
        />
      )}
      <PopularMansions bestList={mainPageDataState?.bestList} />
      {!isMobile && (
        <Categories
          iconResponses={mainPageDataState?.mainImageResponse.iconResponseList}
        />
      )}
      <HouberMansions
        bestBrooklynMansions={mainPageDataState?.bestBrooklynMansions}
        subBanner={mainPageDataState?.mainImageResponse.subBanner}
      />
      <MansionReviews latestReviews={mainPageDataState?.latestReviews} />
    </MainWrapper>
  );
}

export default Main;

const MainWrapper = styled.div`
  position: relative;
  @media screen and (min-width: 1280px) {
    background-color: white;
    border-radius: 5px 5px 0 0;
  }
`;

const RemoteControllerWrapper = styled.div`
  position: fixed;
  height: 70vh;
  z-index: 1000;
  pointer-events: none;
  @media screen and (min-width: 1280px) {
    width: 1280px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 1024px;
  }
  @media screen and (max-width: 1023px) {
    width: 425px;
  }
`;

const RemoteController = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  bottom: 10%;
  pointer-events: auto;
  @media screen and (min-width: 1280px) {
    right: 60px;
    width: 45px;
    height: 95px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    right: 45px;
    width: 52px;
    height: 110px;
  }
  @media screen and (max-width: 1023px) {
    right: 20px;
    width: 37px;
    height: 83px;
  }
`;

const StyledImage = styled.img`
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  cursor: pointer;
  @media screen and (min-width: 1280px) {
    width: 45px;
    height: 45px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 52px;
    height: 52px;
  }
  @media screen and (max-width: 1023px) {
    width: 37px;
    height: 37px;
  }
`;
