import { atom } from "recoil";

export type mainBannerDataType = {
  id?: number;
  fileUrl?: string;
  redirectUrl: string;
  file?: File;
};

export const mainBannerStateAtom = atom<mainBannerDataType[]>({
  key: "mainBannerState",
  default: [{ id: 0, redirectUrl: "", fileUrl: "", file: undefined }],
});
