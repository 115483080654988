// import styled from "styled-components";
import styled from "styled-components";
import ResponsiveWrapper from "./ResponsiveWrapper";
import Title from "./Title";
import Subtitle from "./Subtitle";
import useDeviceSize from "../hooks/useDeviceSize";
import arrowIcon from "../assets/images/ResidentReview/DownIcon.png";
import filterIcon from "../assets/images/mypage/settings.png";
import useCheckItems from "../store/modal/checkItems";
import useChoiceAreaModalState from "../store/modal/choiceArea";
import ChoiceArea from "./ChoiceArea";

function PageHeader({
  src,
  title,
  subtitle,
  selectArea,
}: {
  src: string;
  title: string;
  subtitle?: string;
  selectArea?: boolean;
}) {
  const { isMobile } = useDeviceSize();
  const { open, choiceAreaOpen } = useChoiceAreaModalState();

  return (
    <>
      {choiceAreaOpen && <ChoiceArea />}
      <ResponsiveWrapper>
        <PageHeaderWrapper>
          <HeaderTop>
            <TitleDiv>
              <HeaderIcon src={src} />
              <Title>{title}</Title>
            </TitleDiv>
            {selectArea && (
              <>
                {isMobile ? (
                  <ChoiceAreaIcon
                    src={filterIcon}
                    onClick={() => {
                      open("location");
                    }}
                  ></ChoiceAreaIcon>
                ) : (
                  <ChoiceAreaButton
                    onClick={() => {
                      open("location");
                    }}
                  >
                    지역선택 <ArrowIcon src={arrowIcon}></ArrowIcon>
                  </ChoiceAreaButton>
                )}
              </>
            )}
          </HeaderTop>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </PageHeaderWrapper>
      </ResponsiveWrapper>
    </>
  );
}

export default PageHeader;

const PageHeaderWrapper = styled.div`
  @media screen and (min-width: 1280px) {
    margin-top: 62px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 62px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 37px;
  }
`;

const HeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: 1280px) {
    margin-bottom: 8.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-bottom: 6.5px;
  }
  @media screen and (max-width: 1023px) {
    margin-bottom: 5.5px;
  }
`;

const TitleDiv = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderIcon = styled.img`
  @media screen and (min-width: 1024px) {
    width: 30px;
    height: 30px;
    margin-right: 4px;
  }
  @media screen and (max-width: 1023px) {
    width: 22px;
    height: 22px;
    margin-right: 2px;
  }
`;

const ChoiceAreaButton = styled.button`
  display: flex;
  font-family: "Noto Sans KR";
  border: 2px solid #bfbfbf;
  border-radius: 10px;
  background-color: white;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  @media screen and (min-width: 1280px) {
    gap: 11px;
    font-size: 19px;
    width: 129px;
    height: 53px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 7px;
    font-size: 16px;
    width: 110px;
    height: 40px;
  }
`;

const ArrowIcon = styled.img`
  @media screen and (min-width: 1280px) {
    width: 14.5px;
    height: 7.5px;
    margin-top: 3px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 11px;
    height: 6px;
  }
`;

const ChoiceAreaIcon = styled.img`
  width: 16px;
  height: 22px;
  transform: rotate(90deg) scaleX(-1);
`;
