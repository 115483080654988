import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Main from "./pages/mainPage/Main";
import MyPage from "./pages/MyPage/MyPage";
import Header from "./components/Header/Header";
import styled, { createGlobalStyle } from "styled-components";
import HomeView from "./pages/MyPage/HomeView";
import ScrabBook from "./pages/MyPage/ScrabBook";
import Property from "./pages/MyPage/Property";
import All from "./pages/MyPage/All";
import Review from "./pages/MyPage/Review";
import Settings from "./pages/MyPage/Settings";
import WithDrawal from "./pages/MyPage/WithDrawal";
import Login from "./pages/loginPage/Login";
import HouberMansion from "./pages/houberMansionPage/HouberMansion";
import Counseling from "./pages/counseling/Counseling";
import PopularMansion from "./pages/popularMansionPage/PopularMansion";
import FindId from "./pages/FindIdPwPage/FindID";
import CounselingAdmin from "./pages/counseling/CounselingAdmin";
import FormPage from "./pages/counseling/FormPage";
import SignUp from "./pages/SingUpPage/SignUp";
import KakaoOauth from "./pages/loginPage/components/KakaoOauth";
import MoreInfo from "./pages/SingUpPage/components/MoreInfo";
import GoogleOauth from "./pages/loginPage/components/GoogleOauth";
import { useEffect, useState } from "react";
import Detail from "./pages/detailPage/Detail";
import ShowAccount from "./pages/FindIdPwPage/components/ShowAcoount";
import Search from "./pages/searchPage/Search";
import AdminMainDesign from "./pages/adminMainDesignPage/AdminMainDesign";
import Welcome from "./pages/SingUpPage/components/Welcome";
import AdminLogin from "./pages/loginPage/AdminLogin/AdminLogin";
import ResidentReview from "./pages/ResidentReviewPage/ResidentReview";
import ReviewDetail from "./pages/ResidentReviewPage/ReviewDetail";
import QnA from "./pages/qna/QnA";
import MyQnA from "./pages/qna/MyQnA";
import Home from "./pages/qna/Home";
import DetailQna from "./pages/qna/DetailQna";
import QnaForm from "./pages/qna/QnaForm";
import ClientLayout from "./components/ClientLayout";
import AdminLayout from "./components/AdminLayout";
import AdminReview from "./pages/ResidentReviewPage/AdminReview/AdminReview";
import PostReview from "./pages/ResidentReviewPage/AdminReview/PostReview";
import AdminBackground from "./components/AdminBackground";
import Mansion from "./pages/mansionPage/Mansion";
import Map from "./pages/mapPage/Map";
import QnaAdmin from "./pages/qna/QnaAdmin";
import QuestionDetail from "./pages/qna/QuestionDetail";
import QnaFormUpdate from "./pages/qna/QnaFormUpdate";
import AdminMansionManagememt from "./pages/adminMansionManagement/AdminMansionManagement";
import Registration from "./pages/adminMansionRegistration/Registration";
import BridgePage from "./services/BridgePage";
import RegistrationRoom from "./pages/adminMansionRegistration/RegistrationRoom";
import NotFound from "./components/NotFound";

function App() {
  const [isLogin, setIsLogin] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsLogin(location.pathname.includes("/login"));
  }, [location]);

  return (
    <div className="App">
      <Layout>
        <GlobalStyle />
        <Routes>
          {/* 헤더를 포함하는 페이지는 ClientLayout Route 안으로 */}
          <Route element={<ClientLayout />}>
            <Route path="/bridge" element={<BridgePage />} />
            <Route index element={<Main />} />
            <Route path="/houberMansion" element={<HouberMansion />} />
            <Route path="/popularMansion" element={<PopularMansion />} />
            <Route path="/detail" element={<Detail />} />
            <Route path="/search" element={<Search />} />
            <Route path="/mansion" element={<Mansion />} />
            <Route path="/map" element={<Map />}/>
            <Route path="/*" element={<NotFound />} />
            <Route path="/mypage" element={<MyPage />}>
              <Route path="home" element={<HomeView />} />
              <Route path="scrab" element={<ScrabBook />}>
                <Route path="all" element={<All />} />
                <Route path="Property" element={<Property />} />
                <Route path="review" element={<Review />} />
              </Route>
            </Route>
            <Route path="/mypage/settings" element={<Settings />} />
            <Route
              path="/mypage/settings/withdrawal"
              element={<WithDrawal />}
            />
            <Route path="/counseling" element={<Counseling />} />
            <Route path="/qna" element={<QnA />}>
              <Route path="home" element={<Home />} />
              <Route path="myqna" element={<MyQnA />} />
            </Route>
            <Route path="/qna/detail/:id" element={<DetailQna />} />
            <Route path="/residentreview">
              <Route index element={<ResidentReview />} />
              <Route path="detail/:id" element={<ReviewDetail />} />
            </Route>
          </Route>
          <Route path="/qnaform" element={<QnaForm />} />
          <Route path="/qnaform/:id" element={<QnaFormUpdate />} />
          <Route path="/login">
            <Route index element={<Login />} />
            <Route path="findidpw" element={<FindId />} />
            <Route path="signUp" element={<SignUp />} />
            <Route path="KakaoOauth" element={<KakaoOauth />} />
            <Route path="GoogleOauth" element={<GoogleOauth />} />
            <Route path="moreInfo" element={<MoreInfo />} />
            <Route path="showaccount" element={<ShowAccount />} />
            <Route path="welcome" element={<Welcome />} />
          </Route>
          <Route path="admin" element={<AdminBackground />}>
            {/* Admin 헤더를 포함하는 페이지는 AdminLayout Route 안으로 */}
            <Route element={<AdminLayout />}>
              <Route path="form/:id" element={<FormPage />} />
              <Route path="counseling" element={<CounselingAdmin />} />
              <Route path="review" element={<AdminReview />} />
              <Route path="postreview/:id" element={<PostReview />} />
              <Route path="postreview" element={<PostReview />} />
              <Route path="qna" element={<QnaAdmin />} />
              <Route path="qnaform/:id" element={<QuestionDetail />} />
              <Route
                path="mansionManagement"
                element={<AdminMansionManagememt />}
              />
              <Route path="registration" element={<Registration />} />
              <Route path="registrationRoom" element={<RegistrationRoom />} />
            </Route>
            <Route path="mainDesign" element={<AdminMainDesign />} />
            <Route path="login" element={<AdminLogin />} />
          </Route>
        </Routes>
      </Layout>
    </div>
  );
}

export default App;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  @media screen and (min-width: 1280px) {
    min-width: 1280px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    min-width: 1024px;
  }
  @media screen and (max-width: 1023px) and (min-width: 426px) {
    min-width: 425px;
  }
  @media screen and (max-width: 425px) {
    width: 425px;
  }
`;

const GlobalStyle = createGlobalStyle`
 body, h1, h2, h3, h4, h5, h6, p, span, div, a, li {
    letter-spacing: -0.04em;
  }
`;
