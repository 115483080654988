import { atom } from "recoil";

export type filterDataType = {
  subwayIdList: number[];
  districtIdList: number[];
  minPrice: number;
  maxPrice: number;
  includeManageFee: boolean;
  nonDeposit: boolean;
  nonGratuity: boolean;
  roomStructureList: string[];
  higherThen2F: boolean;
  roomTypeList: string[];
  minArea: number;
  maxArea: number;
  withInYear: number | null;
  subwayTimeConsumed: number | null;
};

export const filterDataStateAtom = atom<filterDataType>({
  key: "filterDataState",
  default: {
    subwayIdList: [],
    districtIdList: [],
    minPrice: 0,
    maxPrice: 1000000,
    includeManageFee: false,
    nonDeposit: false,
    nonGratuity: false,
    roomStructureList: [],
    higherThen2F: false,
    roomTypeList: [],
    minArea: 0,
    maxArea: 100,
    withInYear: null,
    subwayTimeConsumed: null,
  },
});
