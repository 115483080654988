import { atom, useRecoilState } from "recoil";

export const selectedDistrictListStateAtom = atom<
  { id: number; name: string }[]
>({
  key: "selectedDistrictListState",
  default: [],
});

export const selectedSubwayListStateAtom = atom<{ id: number; name: string }[]>(
  {
    key: "selectedSubwayListState",
    default: [],
  }
);

export const useSelectedAreas = () => {
  const [selectedDistrictListState, setSelectedDistrictListState] =
    useRecoilState(selectedDistrictListStateAtom);
  const [selectedSubwayListState, setSelectedSubwayListState] = useRecoilState(
    selectedSubwayListStateAtom
  );

  const clearDistrictListState = () => setSelectedDistrictListState([]);
  const clearSubwayListState = () => setSelectedSubwayListState([]);

  return {
    selectedDistrictListState,
    setSelectedDistrictListState,
    clearDistrictListState,
    selectedSubwayListState,
    setSelectedSubwayListState,
    clearSubwayListState,
  };
};
