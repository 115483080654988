import styled from "styled-components";
import SidebarHeader from "./SidebarComponents/SidebarHeader";
import SidebarNavigation from "./SidebarComponents/SidebarNavigaion";
import SidebarFooter from "./SidebarComponents/SidebarFooter";

function Sidebar() {
  return (
    <Wrapper>
      <SidebarHeader></SidebarHeader>
      <SidebarNavigation></SidebarNavigation>
      <SidebarFooter></SidebarFooter>
    </Wrapper>
  );
}

export default Sidebar;

const Wrapper = styled.header`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  top: 46px;
  z-index: 10000;
`;
