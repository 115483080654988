import styled from "styled-components";
import MansionContentsHeader from "./MansionContentsHeader";
import MansionItem from "./MansionItem";
import MansionContentsBottom from "./MansionContentsBottom";
import { mansionListDataType } from "../Mansion";

function MansionContents({
  mansionState,
}: {
  mansionState?: mansionListDataType;
}) {
  return (
    <MansionContentsWrapper>
      <MansionContentsHeader
        mansionState={mansionState}
      ></MansionContentsHeader>
      <MansionItemWrapper>
        {mansionState?.content.length ? (
          <>
            {mansionState?.content.map((item, index) => (
              <MansionItem key={index} mansionInfo={item}></MansionItem>
            ))}
          </>
        ) : (
          <NoItem>조건에 해당하는 매물이 없습니다.</NoItem>
        )}
      </MansionItemWrapper>
      <MansionContentsBottom
        mansionState={mansionState}
      ></MansionContentsBottom>
    </MansionContentsWrapper>
  );
}

export default MansionContents;

const MansionContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1280px) {
    width: 770px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 640px;
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
    min-height: 50vh;
  }
`;

const MansionItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  @media screen and (min-width: 1280px) {
    margin-top: 23px;
    gap: 55px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 19px;
    gap: 54px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 11px;
    gap: 43px;
  }
`;

const NoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  flex: 1;
  color: #837474;
  @media screen and (max-width: 1023px) {
    font-size: 20px;
  }
`;
