import { atom, useRecoilState } from "recoil";
export const adminSelectedMansionDataStateAtom = atom<number[]>({
  key: "adminSelectedMansionDataState",
  default: [],
});

export const useAdminSelectedMansionState = () => {
  const [adminSelectedMansionState, setAdminSelectedMansionState] =
    useRecoilState(adminSelectedMansionDataStateAtom);
  return { adminSelectedMansionState, setAdminSelectedMansionState };
};
