import styled from "styled-components";
import axios from "axios";
import axiosInstance from "../../../services/axiosConfig";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useAdminRegistrationState } from "../../../recoil/mansionManagement/adminRegistrationStateAtom";

function RegistrationLocation() {
  const { registrationDataState, setRegistrationDataState } =
    useAdminRegistrationState();

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const center = {
    lat: Number(registrationDataState.latitude),
    lng: Number(registrationDataState.longitude),
  };
  const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

  // const API_URL = "https://translation.googleapis.com/language/translate/v2";

  // const translateText = async (text: string, targetLanguage: "ko") => {
  //   const response = await axios.post(`${API_URL}?key=${API_KEY}`, {
  //     q: text,
  //     target: targetLanguage,
  //   });

  //   return response.data.data.translations[0].translatedText;
  // };

  const fetchAddressByZipcode = async () => {
    try {
      const response = await axios.get(`https://api.zipaddress.net`, {
        params: { zipcode: registrationDataState.zipCode },
      });

      if (response.data.code === 200) {
        return response.data.data.fullAddress;
      } else if (response.data.code === 404) {
        alert("주소 검색 결과가 없습니다.");
      }
    } catch (error) {
      alert("주소 검색에 실패했습니다.");
    }
  };

  const fetchGiocodeByAddress = async () => {
    try {
      const response = await axiosInstance.get(`/map/google/address`, {
        params: {
          address: `${registrationDataState.zipCode}${registrationDataState.address} ${registrationDataState.additionalAddress}`,
        },
      });

      if (response.status === 200) {
        setRegistrationDataState({
          ...registrationDataState,
          latitude: response.data[0],
          longitude: response.data[1],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnClickLocationSearch = async () => {
    const address = await fetchAddressByZipcode();
    if (address) {
      setRegistrationDataState({
        ...registrationDataState,
        address: address,
      });
    }
  };

  return (
    <LocationWrapper>
      <LocationTop>
        <LocationTitle>위치</LocationTitle>
        <LocationInputArea>
          <LocationInputIiem>
            <LocationInputName>우편번호</LocationInputName>
            <LocationZipcode>
              〒
              <LocationZipcodeInput
                value={registrationDataState.zipCode}
                onChange={(e) =>
                  setRegistrationDataState({
                    ...registrationDataState,
                    zipCode: e.target.value,
                  })
                }
              ></LocationZipcodeInput>
            </LocationZipcode>

            <SearchButton onClick={handleOnClickLocationSearch}>
              검색
            </SearchButton>
          </LocationInputIiem>
          <LocationInputIiem>
            <LocationInputName>주소</LocationInputName>
            <LocationInput
              width={633}
              value={registrationDataState.address}
              onChange={(e) =>
                setRegistrationDataState({
                  ...registrationDataState,
                  address: e.target.value,
                })
              }
            ></LocationInput>
          </LocationInputIiem>
          <LocationInputIiem>
            <LocationInputName>추가 주소</LocationInputName>
            <LocationInput
              width={633}
              value={registrationDataState.additionalAddress}
              onChange={(e) =>
                setRegistrationDataState({
                  ...registrationDataState,
                  additionalAddress: e.target.value,
                })
              }
            ></LocationInput>
            <ConfirmButton onClick={fetchGiocodeByAddress}>확인</ConfirmButton>
          </LocationInputIiem>
        </LocationInputArea>
      </LocationTop>
      <GoogleMapDiv id="map">
        <LoadScript googleMapsApiKey={`${API_KEY}`}>
          <GoogleMap
            center={center}
            zoom={19}
            mapContainerStyle={containerStyle}
            options={{ disableDefaultUI: true, zoomControl: true }}
          >
            <Marker position={center} />
          </GoogleMap>
        </LoadScript>
      </GoogleMapDiv>
    </LocationWrapper>
  );
}

export default RegistrationLocation;

const LocationWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 756px;
  padding-left: 23px;
  border-bottom: 2px solid #e6e6e6;
  gap: 41px;
`;

const LocationTop = styled.div`
  display: flex;
  gap: 260px;
`;

const LocationInputArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const LocationTitle = styled.div`
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -2px;
  margin-top: 17px;
`;

const LocationInputIiem = styled.div`
  display: flex;
  align-items: center;
`;

const LocationInputName = styled.div`
  width: 122.5px;
  font-size: 20px;
  letter-spacing: -1px;
  font-weight: 500;
`;

const LocationZipcode = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 5px;
  width: 198px;
  height: 60px;
  font-size: 18px;
  background-color: #f7f8f9;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
  padding: 0 15px;
`;

const LocationZipcodeInput = styled.input`
  display: flex;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  font-size: 18px;
  background: none;
`;

const LocationInput = styled.input<{ width: number }>`
  display: flex;
  align-items: center;
  width: ${(props) => `${props.width}px`};
  height: 60px;
  font-size: 18px;
  background-color: #f7f8f9;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 15px;
  outline: none;
`;

const SearchButton = styled.button`
  margin-left: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 110px;
  height: 60px;
  background-color: #f7f8f9;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
  letter-spacing: -2px;
  cursor: pointer;
  font-family: "Noto Sans KR";
  font-weight: 500;
`;

const ConfirmButton = styled.div`
  margin-left: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 110px;
  height: 60px;
  letter-spacing: -2px;
  cursor: pointer;
  font-weight: 500;
`;

const GoogleMapDiv = styled.div`
  width: 100%;
  height: 308px;
  border-radius: 5px;
  background-color: #edeef1;
`;
