import React from 'react'
import { SidebarOptionsWrapper, WebFilterList, SearchMansionButton, Option, FilterListWrap, Box, IconWrap } from './Map.style';
import MansionSidebarTop from './MansionSidebarTop';
import MansionSidebarbottom from './MansionSidebarbottom';
import { propertyOptionDataStateAtom } from '../../../recoil/mansionPage/propertyOptionsAtom';
import { filterDataStateAtom } from '../../../recoil/mansionPage/filterStateAtom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { mansionPropertyDataStateAtom } from '../../../recoil/mansionPage/mansionPropertyState';
import exit from  '../../../assets/images/mapPage/exitIcon.png'

interface props {
  setShowFilterList: React.Dispatch<React.SetStateAction<boolean>>
  showFilterList: boolean
  setSearch: React.Dispatch<React.SetStateAction<boolean>>
}

export default function MapFliterList({setSearch, setShowFilterList, showFilterList}: props) {
  const propertyStateValue = useRecoilValue(propertyOptionDataStateAtom);
  const filterStateValue = useRecoilValue(filterDataStateAtom);
  const [propertyState, setPropertyState] = useRecoilState(
    mansionPropertyDataStateAtom
  );
  
  
  return (
    <WebFilterList $show={showFilterList}>
        <FilterListWrap>
          <SidebarOptionsWrapper>
            <Box $justifyContent='space-between' $alignItems='center'>
              <Option>조건</Option>
              <IconWrap 
                onClick={() => setShowFilterList(false)}
                width='18px'
                height='18px'
                src={exit}
                alt='exit button'/>
            </Box>
            <MansionSidebarTop />
            <MansionSidebarbottom />
          </SidebarOptionsWrapper>
          <SearchMansionButton
            onClick={() => {
              setShowFilterList(prev => !prev);
              setPropertyState({
                ...propertyState,
                ...filterStateValue,
                propertyOptionRequest: propertyStateValue,
              });
              setSearch(true);
            }}
          >
            매물검색
          </SearchMansionButton>
        </FilterListWrap>
        
      </WebFilterList>
  )
}
