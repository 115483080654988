import styled from "styled-components";
import {
  logoDataType,
  logoStateAtom,
} from "../../../recoil/adminMainDesign/logoStateAtom";
import { useRecoilState } from "recoil";

function SetLogo() {
  const [logoState, setLogoState] = useRecoilState<logoDataType>(logoStateAtom);

  const handleImageDrag = (event: React.DragEvent) => {
    // 이미지 드래그&드롭 시 새 창에 이미지가 뜨지 않도록 제어
    event.preventDefault();
  };

  const handleImageUpload = (
    event: React.ChangeEvent<HTMLInputElement> | React.DragEvent
  ) => {
    event.preventDefault();
    let file: File | null = null;

    if (event.type === "drop") {
      const dragEvent = event as React.DragEvent;
      file = dragEvent.dataTransfer.files[0];
    } else if (event.type === "change") {
      const changeEvent = event as React.ChangeEvent<HTMLInputElement>;
      file = changeEvent.target.files && changeEvent.target.files[0];
    }

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          setLogoState({ ...logoState, fileUrl: reader.result, file: file });
        }
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <SidebarContentsItem>
      로고 이미지 업로드
      {logoState.fileUrl && logoState.fileUrl ? (
        <UploadedImageView>
          <PreviewImage
            src={logoState.fileUrl && logoState.fileUrl}
            alt="uploadedlogoImage"
            onClick={() => {
              setLogoState({ ...logoState, file: undefined, fileUrl: "" });
            }}
          />
        </UploadedImageView>
      ) : (
        <UploadImageWrapper
          onDragOver={handleImageDrag}
          onDrop={handleImageUpload}
        >
          드래그 앤 드롭으로 이미지 추가
          <ChooseImage>
            <StyledFileInput type="file" onChange={handleImageUpload} />+ 이미지
            선택
          </ChooseImage>
        </UploadImageWrapper>
      )}
    </SidebarContentsItem>
  );
}

export default SetLogo;

const SidebarContentsItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
`;

const UploadImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 240px;
  border-radius: 5px;
  background-color: #f7f8f9;
  border: 1px dotted #a7a7a7;
  font-size: 15px;
  gap: 15px;
`;

const StyledFileInput = styled.input`
  display: none;
`;

const ChooseImage = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 131px;
  height: 28px;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
`;

const UploadedImageView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 240px;
  border-radius: 5px;
  background-color: #f7f8f9;
  border: 1px solid black;
  font-size: 15px;
  gap: 15px;
  overflow: hidden;
`;

const PreviewImage = styled.img`
  width: 100%;
  cursor: pointer;
`;
