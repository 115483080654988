import styled from "styled-components";
import SidebarNavigation from "./SidebarNavigaion";
import {
  mainBannerDataType,
  mainBannerStateAtom,
} from "../../../recoil/adminMainDesign/mainBannerStateAtom";
import { useRecoilState } from "recoil";
import { useState } from "react";

function SetMainBanner() {
  const [navigationState, setNavigationState] = useState<number>(0);

  const [mainBannerState, setMainBannerState] =
    useRecoilState(mainBannerStateAtom);

  const getNavigationState = (newState: number) => {
    setNavigationState(newState);
  };

  const handleImageDrag = (event: React.DragEvent) => {
    // 이미지 드래그&드롭 시 새 창에 이미지가 뜨지 않도록 제어
    event.preventDefault();
  };

  const updateItemAtIndex = (index: number, newItem: mainBannerDataType) => {
    const newState = [...mainBannerState];
    newState[index] = newItem;
    setMainBannerState(newState);
  };

  const handleImageDrop = (event: React.DragEvent) => {
    // 이미지 드래그&드롭 시 새 창에 이미지가 뜨지 않도록 제어
    event.preventDefault();
    const file = event.dataTransfer.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          updateItemAtIndex(navigationState, {
            ...mainBannerState[navigationState],
            fileUrl: reader.result,
            file: file,
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageUpload = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const file = target.files && target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          updateItemAtIndex(navigationState, {
            ...mainBannerState[navigationState],
            fileUrl: reader.result,
            file: file,
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOnChangeInput = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    updateItemAtIndex(navigationState, {
      ...mainBannerState[navigationState],
      redirectUrl: event.target.value,
    });

  return (
    <SetMainBannerWrapper>
      <SidebarContentsItem>
        <ContentsItemTop>
          메인 슬라이드 배너 {navigationState + 1}
          <SidebarNavigation
            navigationState={navigationState}
            getNavitaionState={getNavigationState}
            onClickAddButton={() => {
              setMainBannerState([
                ...mainBannerState,
                { fileUrl: "", redirectUrl: "", file: undefined },
              ]);
              setNavigationState(mainBannerState.length);
            }}
            maxPage={mainBannerState.length - 1}
          ></SidebarNavigation>
        </ContentsItemTop>
        {mainBannerState[navigationState].fileUrl ? (
          <UploadedImageView>
            <PreviewImage
              src={mainBannerState[navigationState].fileUrl}
              alt="uploadedMainBannerImage"
              onClick={() => {
                updateItemAtIndex(navigationState, {
                  ...mainBannerState[navigationState],
                  file: undefined,
                  fileUrl: "",
                });
              }}
            />
          </UploadedImageView>
        ) : (
          <UploadImageWrapper
            onDragOver={handleImageDrag}
            onDrop={handleImageDrop}
          >
            드래그 앤 드롭으로 이미지 추가
            <ChooseImage>
              <StyledFileInput type="file" onChange={handleImageUpload} />+
              이미지 선택
            </ChooseImage>
          </UploadImageWrapper>
        )}
      </SidebarContentsItem>
      <SidebarContentsItem>
        메인 슬라이드 배너 {navigationState + 1} 링크
        <TextInputDiv>
          <TextInput
            value={mainBannerState[navigationState].redirectUrl}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
              handleOnChangeInput(event)
            }
            placeholder="/url 형식으로 링크를 지정해주세요"
          ></TextInput>
        </TextInputDiv>
      </SidebarContentsItem>
    </SetMainBannerWrapper>
  );
}

export default SetMainBanner;

const SetMainBannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 534px;
  gap: 50px;
  border-bottom: 3px solid #e6e6e6;
  font-weight: 500;
`;

const SidebarContentsItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  font-size: 20px;
`;

const TextInputDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

const TextInput = styled.textarea`
  width: 100%;
  height: 110px;
  padding: 20px 19px;
  border: 1px dotted #a7a7a7;
  font-family: "Noto Sans KR";
  border-radius: 5px;
  resize: none;
  outline: none;
  background-color: #f7f8f9;
  box-sizing: border-box;
`;

const ContentsItemTop = styled.div`
  display: flex;
  justify-content: space-between;
`;

const UploadImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 240px;
  border-radius: 5px;
  background-color: #f7f8f9;
  border: 1px dotted #a7a7a7;
  font-size: 15px;
  gap: 15px;
`;

const StyledFileInput = styled.input`
  display: none;
`;

const ChooseImage = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 131px;
  height: 28px;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
`;

const UploadedImageView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 240px;
  border-radius: 5px;
  background-color: #f7f8f9;
  border: 1px solid black;
  font-size: 15px;
  gap: 15px;
  overflow: hidden;
`;

const PreviewImage = styled.img`
  width: 100%;
  cursor: pointer;
`;
