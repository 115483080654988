import styled from "styled-components";
import global from "../../../../assets/images/header/global.png";

function CloneHeaderTop() {
  return (
    <HeaderTopWrapper>
      <HeaderTopInner>
        <div></div>
        <UtilityBox>
          <Logout>로그아웃</Logout>
          <LanguageSelectButton>
            <StyledImage src={global} width={13}></StyledImage>
            한국어
          </LanguageSelectButton>
        </UtilityBox>
      </HeaderTopInner>
    </HeaderTopWrapper>
  );
}

export default CloneHeaderTop;

const HeaderTopWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  border-bottom: 1px solid #a4a4a4;
  height: 53px;
`;

const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  width: 1280px;
  padding: 0 73px;
`;

const HeaderTopInner = styled(HeaderBox)`
  justify-content: space-between;
`;

const RechoiceArea = styled.a`
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;
`;

const UtilityBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 180px;
`;

const Logout = styled.a`
  cursor: pointer;
  font-size: 16px;
`;

const LanguageSelectButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #ffffff;
  font-family: "Noto Sans KR";
  cursor: pointer;
  width: 103px;
  height: 31px;
  font-size: 16px;
`;

const StyledImage = styled.img`
  display: block;
  margin-right: 6px;
`;
