import styled from "styled-components";
import MansionSidebarTop from "./MansionSidebarTop";
import MansionSidebarbottom from "./MansionSidebarbottom";
import { useRecoilValue, useRecoilState } from "recoil";

import { propertyOptionDataStateAtom } from "../../../recoil/mansionPage/propertyOptionsAtom";
import { filterDataStateAtom } from "../../../recoil/mansionPage/filterStateAtom";
import { mansionPropertyDataStateAtom } from "../../../recoil/mansionPage/mansionPropertyState";
import mansionPageMapIcon from "../../../assets/images/mansionPage/mansionPageMapIcon.png";
import { useNavigate } from "react-router-dom";

function MansionOptionBar({ closeModal }: { closeModal: () => void }) {
  const [mansionPropertyState, setMansionPropertyState] = useRecoilState(
    mansionPropertyDataStateAtom
  );
  const propertyStateValue = useRecoilValue(propertyOptionDataStateAtom);
  const filterStateValue = useRecoilValue(filterDataStateAtom);
  const navigate = useNavigate();

  return (
    <MansionSidebarWrapper>
      <SidebarOptionsWrapper>
        <Option>조건</Option>
        <MansionSidebarTop />
        <MansionSidebarbottom />
      </SidebarOptionsWrapper>
      <ButtonDiv>
        <SearchMansionButton
          onClick={() => {
            closeModal();
            setMansionPropertyState({
              ...mansionPropertyState,
              ...filterStateValue,
              propertyOptionRequest: propertyStateValue,
            });
          }}
        >
          매물검색
        </SearchMansionButton>
        <ViewOnMapButton
          onClick={() => {
            setMansionPropertyState({
              ...mansionPropertyState,
              ...filterStateValue,
              propertyOptionRequest: propertyStateValue,
            });
            closeModal();
            navigate("/map");
          }}
        >
          <StyledMapIcon src={mansionPageMapIcon} />
          지도로 보기
        </ViewOnMapButton>
      </ButtonDiv>
    </MansionSidebarWrapper>
  );
}

export default MansionOptionBar;

const MansionSidebarWrapper = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  box-sizing: border-box;
  z-index: 100000;
  margin-top: 8px;
  gap: 17px;
`;

const SidebarOptionsWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #eeeeee;
  background-color: #f5f5f5;
  border-radius: 5px;
  overflow-y: auto;
  padding: 29px 25px 29px;
  flex: 1;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: none;
  }
`;

const Option = styled.div`
  font-weight: bold;
  font-size: 23px;
`;

const SearchMansionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 10px;
  color: white;
  background-color: #99393a;
  font-family: "Noto Sans KR";
  font-weight: bold;
  cursor: pointer;
  box-sizing: border-box;
  width: 187.5px;
  height: 71px;
  font-size: 23px;
  border: none;
`;

const ViewOnMapButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-radius: 10px;
  color: #99393a;
  border: 1px solid #99393a;
  font-family: "Noto Sans KR";
  font-weight: bold;
  background-color: white;
  cursor: pointer;
  height: 71px;
  width: 187.5px;
  box-sizing: border-box;
  font-size: 23px;
  gap: 8px;
`;

const StyledMapIcon = styled.img`
  width: 32px;
  height: 28px;
`;

const ButtonDiv = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;
