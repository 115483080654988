import { atom, useRecoilState } from "recoil";
export const adminPendingDeleteRoomDataStateAtom = atom<number[]>({
  key: "adminPendingDeleteRoomDataState",
  default: [],
});

export const useAdminPendingDeleteRoomState = () => {
  const [adminPendingDeleteRoomState, setAdminPendingDeleteRoomState] =
    useRecoilState(adminPendingDeleteRoomDataStateAtom);
  return { adminPendingDeleteRoomState, setAdminPendingDeleteRoomState };
};
