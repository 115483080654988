import styled from "styled-components";
import scrap from "../../../../assets/images/mainPage/scrap.png";
import scrapColord from "../../../../assets/images/mainPage/scrap-colored.png";

function CloneMainItem({
  width,
  height,
  thumbnailUrl,
}: {
  width: number;
  height: number;
  thumbnailUrl?: string;
}) {
  return (
    <MainItemWrapper $maxWidth={width}>
      <ImageWrapper width={width} height={height} $thumbnailUrl={thumbnailUrl}>
        <StyledImage src={thumbnailUrl} />
      </ImageWrapper>
    </MainItemWrapper>
  );
}

export default CloneMainItem;

const MainItemWrapper = styled.div<{ $maxWidth: number }>`
  max-width: ${(props) => `${props.$maxWidth}px`};
  @media screen and (min-width: 1280px) {
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
  }
  @media screen and (max-width: 1023px) {
  }
`;

const ImageWrapper = styled.div<{
  width: number;
  height: number;
  $thumbnailUrl?: string;
}>`
  position: relative;
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  background-color: ${(props) => `${!props.$thumbnailUrl && "#e6e6e6"}`};
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
`;
const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

const ScrapButton = styled.img`
  display: block;
  position: absolute;
  @media screen and (min-width: 1280px) {
    width: 17.5px;
    height: 17.5px;
    right: 17px;
    bottom: 17px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 19.5px;
    height: 19.5px;
    right: 15px;
    bottom: 15px;
  }
  @media screen and (max-width: 1023px) {
    width: 19.5px;
    height: 19.5px;
    right: 15px;
    bottom: 15px;
  }
`;
