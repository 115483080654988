import React from "react";
import { styled } from "styled-components";
import { RegionLineInner } from "../";
import axiosInstance from "../../../services/axiosConfig";
import useCheckItems from "../../../store/modal/checkItems";
import useDetailChoiceOpenModalState from "../../../store/modal/detailChoice";
import CheckBox from "./CheckBox";

const LineCheckBoxSet = ({ ...props }: RegionLineInner) => {
  const { open, close, setDetailData } = useDetailChoiceOpenModalState();
  const { add, returnItems, del } = useCheckItems();

  return (
    <Container>
      <CheckBox
        text={props.name}
        id={props.id}
        checkItemHandler={async ({ target }) => {
          if (target.checked) {
            const res = await axiosInstance(
              `/map/subway/check/lineType/${props.id}`
            );

            const result = res.data.flatMap((item: any) => {
              return item.stationList.map((station: any) => ({
                top: props.name,
                parentId: item.id,
                parent: item.name,
                id: station.id,
                name: station.name,
              }));
            });
            add(result);
          } else {
            del(props.name, "top");
          }
        }}
        inputPosition="right"
        size="medium"
        isChecked={returnItems.find((inner) => inner.top === props.name)}
      />
      <List>
        {props.lineList.map((item) => (
          <CheckBox
            key={item.id}
            text={item.name}
            id={item.id}
            checkItemHandler={async ({ target }) => {
              if (target.checked) {
                const res = await axiosInstance(`/map/subway/line/${item.id}`);

                const result = res.data.map((station: any) => {
                  return {
                    top: props.name,
                    parentId: item.id,
                    parent: item.name,
                    id: station.id,
                    name: station.name,
                  };
                });
                setDetailData(result);
                add(result);
                open();
              } else {
                close();
                del(item.name, "parent");
              }
            }}
            size="small"
            isChecked={returnItems.find((inner) => inner.parent === item.name)}
          />
        ))}
      </List>
    </Container>
  );
};

export default LineCheckBoxSet;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 530px;
  gap: 12px 0px;
  width: 100%;
`;
