import { useEffect, useState } from "react";
import styled from "styled-components";
import searchIcon from "../../../assets/images/header/search-black.png";
import axiosInstance from "../../../services/axiosConfig";
import SelectDistrictItem from "./SelectDistrictItem";
import xIcon from "../../../assets/images/mainPage/x-icon.png";
import { useAdminRegistrationState } from "../../../recoil/mansionManagement/adminRegistrationStateAtom";

type districtInfoType = {
  districtId: number;
  districtKName: string;
};

function RegistrationDistrict() {
  const { registrationDataState, setRegistrationDataState } =
    useAdminRegistrationState();

  const [selectDistrictAreaState, setSelectDistrictAreaState] =
    useState<boolean>(false);

  const [selectDistrictInputState, setSelectDistrictInputState] =
    useState<string>("");

  const [searchDistrictResultState, setSearchDistrictResultState] =
    useState<districtInfoType[]>();

  const fetchSearchDistrict = async () => {
    try {
      const response = await axiosInstance.get(`/map/search/district`, {
        params: {
          key: selectDistrictInputState,
        },
      });

      setSearchDistrictResultState(response.data);
    } catch (error) {
      alert("지역 검색에 실패했습니다.");
    }
  };

  return (
    <DistrictWrapper>
      {selectDistrictAreaState ? (
        <SelectDistrictArea>
          <CloseButton onClick={() => setSelectDistrictAreaState(false)}>
            X
          </CloseButton>
          <SearchDistrictTop>
            <SearchDistrictInputDiv>
              <SearchDistrictInput
                placeholder="지역 이름을 입력해주세요"
                value={selectDistrictInputState}
                onChange={(e) => setSelectDistrictInputState(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    fetchSearchDistrict();
                  }
                }}
              ></SearchDistrictInput>
              <SearchIcon
                src={searchIcon}
                onClick={() => fetchSearchDistrict()}
              />
            </SearchDistrictInputDiv>
          </SearchDistrictTop>
          <DistrictItemArea>
            {searchDistrictResultState &&
              searchDistrictResultState.map((item, index) => (
                <SelectDistrictItem
                  districtInfo={item}
                  index={index}
                  close={() => setSelectDistrictAreaState(false)}
                ></SelectDistrictItem>
              ))}
          </DistrictItemArea>
        </SelectDistrictArea>
      ) : (
        <DistrictInputArea>
          <DistrictTitle>지역 선택</DistrictTitle>
          <DistrictInputWrapper>
            {registrationDataState.districtKName !== "" ? (
              <SelectedItem>
                {registrationDataState.districtKName}
                <DeletSelectButton
                  src={xIcon}
                  onClick={() =>
                    setRegistrationDataState({
                      ...registrationDataState,
                      districtId: 0,
                      districtKName: "",
                    })
                  }
                />
              </SelectedItem>
            ) : (
              <>
                지역을 선택해주세요
                <ChooseDistrict
                  onClick={() => setSelectDistrictAreaState(true)}
                >
                  + 지역 선택
                </ChooseDistrict>
              </>
            )}
          </DistrictInputWrapper>
        </DistrictInputArea>
      )}
    </DistrictWrapper>
  );
}

export default RegistrationDistrict;

const DistrictWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-left: 23px;
  border-bottom: 2px solid #e6e6e6;
  padding: 100px 0;
`;

const DistrictTitle = styled.div`
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -2px;
`;

const DistrictInputArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DistrictInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 904px;
  height: 204px;
  border-radius: 5px;
  background-color: #f7f8f9;
  border: 1px dotted #a7a7a7;
  font-size: 15px;
  gap: 15px;
`;

const ChooseDistrict = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 131px;
  height: 28px;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
`;

const SelectDistrictArea = styled.div`
  position: relative;
  width: 100%;
  height: 620px;
  overflow: auto;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  padding: 0 50px;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 9.5px;
  right: 32px;
  color: #c1c1c1;
  font-size: 30px;
  cursor: pointer;
`;

const SearchDistrictTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
  width: 100%;
`;

const SearchDistrictInputDiv = styled.div`
  display: flex;
  align-items: center;
  width: 371px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid #cdcdcd;
  padding: 0 13px;
  gap: 10px;
`;

const SearchDistrictInput = styled.input`
  display: flex;
  flex: 1;
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 50px;
  font-family: "Noto Sans KR";
  font-weight: 500;
`;

const SearchIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const DistrictItemArea = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  height: 353px;
  &::-webkit-scrollbar {
    width: 4px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: none;
  }
`;

const SelectedItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
`;

const DeletSelectButton = styled.img`
  width: 10px;
  height: 10px;
  cursor: pointer;
`;
