import styled from "styled-components";

function Subtitle({ children }: { children: string }) {
  return <SubtitleWrapper>{children}</SubtitleWrapper>;
}

export default Subtitle;

const SubtitleWrapper = styled.div`
  @media screen and (min-width: 1280px) {
    font-size: 16px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 15px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 12px;
  }
`;
