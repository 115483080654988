import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Face from "../../assets/images/mypage/face.png";
import axiosInstance from "../../services/axiosConfig";
import back from "../../assets/images/qna/back_admin.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  min-height: 100vh;
  width: 100%;
`;

const FormContainer = styled.div`
  width: 1323px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding-bottom: 150px;
`;

const FormWrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 0 150px;
`;

const BackButtonContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 47px 62px;
  border: none;
  background: none;
  align-items: center;
  font-size: 30px;
  color: #333333;
  font-weight: 500;
`;

const BackButton = styled.img`
  display: flex;
  height: 30px;
  font-size: 18px;
  border: none;
  background: none;
  cursor: pointer;
  align-items: center;
  margin-right: 12px;
`;

const HeaderSection = styled.div`
  width: 100%;
  padding: 0 59px;
  margin-bottom: 20px;
`;

const Header = styled.h1`
  font-size: 50px;
  font-weight: bold;
  margin-top: 76px;
  margin-bottom: 40px;
`;

const SubHeader = styled.h2`
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 24px;
  color:#333333;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.div<{ src: string }>`
  width: 56px;
  height: 56px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 20px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
`;

const ProfileInfo = styled.div`
  font-size: 18px;
  color: #999;
  margin-bottom: 5px;
`;

const Date = styled.div`
  font-size: 18px;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #ccc;
  margin: 0 43px;
`;

const Label = styled.label`
  font-size: 28px;
  margin-top: 67px;
  margin-bottom: 14px;
  font-weight: 500;
`;

const ValueContainer = styled.div`
  padding: 20px 50px;
  font-size: 25px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 10px;
  width: 100%;
  font-weight: 500;
  color:#333333;
`;

const ValueContainerSmall = styled(ValueContainer)`
  width: 467px;
`;

const TextAreaContainer = styled.div`
  padding: 20px 50px;
  font-size: 25px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 10px;
  height: 250px;
  overflow-y: auto;
  white-space: pre-line;
  line-height: 1.5;
`;

const EmailContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  justify-content: space-between;
`;

const AtSymbol = styled.span`
  font-size: 25px;
  margin: 0 32.5px;
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 83px;
`;

const Button = styled.button`
  padding: 12px 56px;
  font-size: 25px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  max-width: 269px;
  height: 55px;
  white-space: nowrap;
`;

const DefaultButton = styled(Button)`
  background-color: #99393a;
  color: white;
  margin-left: 12px;
  display: flex;
    margin-right: 12px;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
font-weight: bold;
`;

const OutlineButton = styled(Button)`
  display: flex;
    background-color: #ffffff;
    color: #99393a;
    border: 2px solid #99393a;
    margin-right: 12px;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    font-weight: bold;

`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const ListContainer = styled.div`
  padding: 20px 50px;
  height: 250px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow-y: auto;
  margin-bottom: 10px;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled(Link)`
  display: block;
  padding: 10px;
  font-size: 25px;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
`;

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DateButton = styled.button`
  padding: 12px 60px;
  font-size: 25px;
  background-color: #99393a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  justify-content: center;
  align-items: baseline;
  width: 100%;
  max-width: 467px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
    max-width: 500px;
  }
`;

const SubContainer2 = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 18px;
  align-items: baseline;
  justify-content: space-between;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    margin-bottom: 0px;
  }
`;

interface NotificationProps {
  show: boolean;
}

const Notification = styled.div<NotificationProps>`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  display: ${(props) => (props.show ? "block" : "none")};
`;

const FormPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: 0,
    createAt: "",
    userName: "",
    userProfileImageUrl: "",
    userEngName: "",
    phoneNumber: "",
    email: "",
    kakaoId: "",
    gender: "",
    birth: "",
    currentCountry: "",
    visaTypeAndDuration: "",
    jpSchoolAdmission: "",
    guarantorOrContact: "",
    desiredMoveInDate: "",
    desiredMoveInArea: "",
    desiredRentCost: "",
    otherPreferences: "",
    roomScrapbook: [] as { roomId: number; propertyName: string }[],
    userNickName: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/admin/consult/detail/${id}`);
        // console.log(response);
        setFormData(response.data);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  const handleCopy = () => {
    const textToCopy = `
  이름: ${formData.userName}
  영문: ${formData.userEngName}
  연락처: ${formData.phoneNumber}
  메일 주소: ${formData.email}
  카카오톡 아이디: ${formData.kakaoId}
  성별: ${formData.gender}
  생년월일: ${formData.birth}
  현재 거주국: ${formData.currentCountry}
  비자 종류 및 기간: ${formData.visaTypeAndDuration}
  일본어 학교 입학 여부: ${formData.jpSchoolAdmission}
  일본인 보증인 및 긴급 연락처용 단순 지인 유무: ${formData.guarantorOrContact}
  입주 희망일: ${formData.desiredMoveInDate}
  입주 희망 지역: ${formData.desiredMoveInArea}
  희망 야칭 및 초기비용: ${formData.desiredRentCost}
  기타 희망사항: ${formData.otherPreferences}
 스크랩한 맨션:
  ${formData.roomScrapbook
    .map(
      (room, index) =>
        `${index + 1}) <a href="https://www.houber-home.com//detail?roomId=${room.roomId}">${room.propertyName}</a> - <a href="https://www.houber-home.com//detail?roomId=${room.roomId}">https://www.houber-home.com//detail?roomId=${room.roomId}</a>`
    )
    .join("<br>")}
  `;

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = `<pre>${textToCopy}</pre>`;
    document.body.appendChild(tempDiv);

    const range = document.createRange();
    range.selectNodeContents(tempDiv);
    const selection = window.getSelection();

    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);

      try {
        document.execCommand("copy");
        alert("내용이 복사되었습니다.");
      } catch (err) {
        // console.error("Failed to copy text: ", err);
      }

      selection.removeAllRanges();
    }

    document.body.removeChild(tempDiv);
  };

  const handleStatusChange = async (newStatus: boolean) => {
    try {
      const response = await axiosInstance.patch(
        "/admin/consult/detail/check",
        {
          consultId: id,
          isChecked: newStatus,
        }
      );
      setFormData((prevData) => ({
        ...prevData,
        checked: newStatus,
      }));
    } catch (error) {
      // console.error("Error updating status:", error);
    }
  };

  const handleConfirm = () => {
    showNoti();
    handleStatusChange(true);
  };
  const handleUnconfirm = () => {
    showNoti();
    handleStatusChange(false);
  };

  const showNoti = () => {
    if (!notification) {
      setNotification(true);
      setTimeout(() => setNotification(false), 2000);
    }
  };
  const [notification, setNotification] = useState(false);

  return (
    <Container>
      <BackButtonContainer onClick={() => navigate(-1)}>
        <BackButton src={back} />
        뒤로가기
      </BackButtonContainer>
      <FormContainer>
        <HeaderSection>
          <Header>상담양식</Header>
          <SubHeader>{formData.userName} 님의 상담양식</SubHeader>
          <DateWrapper>
            <ProfileContainer>
              <Avatar src={formData.userProfileImageUrl || Face} />
              <div>
                <ProfileInfo>{formData.userNickName}</ProfileInfo>
                <Date>{formData.createAt.split("T")[0]}</Date>
              </div>
            </ProfileContainer>
            <DateButton onClick={handleCopy}>내용 복사하기</DateButton>
          </DateWrapper>
        </HeaderSection>
        <Divider />
        <FormWrapper>
          <Form>
            <Label>이름</Label>
            <ValueContainer>{formData.userName}</ValueContainer>
            <ValueContainer>{formData.userEngName}</ValueContainer>

            <Label>연락처</Label>
            <ValueContainer>{formData.phoneNumber}</ValueContainer>

            <Label>메일 주소</Label>
            <ValueContainer>{formData.email}</ValueContainer>

            <Label>카카오톡 아이디</Label>
            <ValueContainer>{formData.kakaoId}</ValueContainer>
            <SubContainer2>
              <SubContainer>
                <Label>성별</Label>
                <ValueContainer>{formData.gender}</ValueContainer>
              </SubContainer>
              <SubContainer>
                <Label>생년월일</Label>
                <ValueContainer>{formData.birth}</ValueContainer>
              </SubContainer>
            </SubContainer2>
            <Label>현재 거주국</Label>
            <ValueContainer>{formData.currentCountry}</ValueContainer>

            <Label>비자 종류 및 기간</Label>
            <ValueContainer>{formData.visaTypeAndDuration}</ValueContainer>

            <Label>일본어 학교 입학 여부</Label>
            <ValueContainer>{formData.jpSchoolAdmission}</ValueContainer>

            <Label>일본인 보증인 및 긴급 연락처용 단순 지인 유무</Label>
            <ValueContainer>{formData.guarantorOrContact}</ValueContainer>

            <Label>입주 희망일</Label>
            <ValueContainer>{formData.desiredMoveInDate}</ValueContainer>

            <Label>입주 희망 지역</Label>
            <ValueContainer>{formData.desiredMoveInArea}</ValueContainer>

            <Label>희망 야칭 및 초기비용</Label>
            <ValueContainer>{formData.desiredRentCost}</ValueContainer>

            <Label>기타 희망사항</Label>
            <TextAreaContainer>{formData.otherPreferences}</TextAreaContainer>

            <Label>스크랩한 맨션</Label>
            <ListContainer>
              <List>
                {formData.roomScrapbook.map((property) => (
                  <ListItem
                    to={`/detail?roomId=${property.roomId}`}
                    key={property.roomId}
                  >
                    {property.propertyName}
                  </ListItem>
                ))}
              </List>
            </ListContainer>

            <ButtonContainer>
              <OutlineButton type="button" onClick={handleUnconfirm}>
                미확인으로 변경
              </OutlineButton>
              <DefaultButton type="button" onClick={handleConfirm}>
                확인하기
              </DefaultButton>
            </ButtonContainer>
          </Form>
        </FormWrapper>
      </FormContainer>
      <Notification show={notification}>
        변경사항이 저장 되었습니다.
      </Notification>
    </Container>
  );
};
export default FormPage;
