import styled from "styled-components";
import ResponsiveWrapper from "../../components/ResponsiveWrapper";
import MansionSidebar from "./components/MansionSidebar";
import MansionContents from "./components/MansionContents";
import { useEffect, useState } from "react";
import axiosInstance from "../../services/axiosConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { propertyOptionDataType } from "../../recoil/mansionPage/propertyOptionsAtom";
import { paginationDataType } from "../../recoil/mansionPage/paginationStateAtom";
import { filterDataType } from "../../recoil/mansionPage/filterStateAtom";
import usePropertyState from "../../hooks/usePropertyState";
import { useRecoilState } from "recoil";
import { mansionPropertyDataStateAtom } from "../../recoil/mansionPage/mansionPropertyState";

export type mansionPropertyType = {
  propertyOptionRequest: propertyOptionDataType;
} & filterDataType &
  paginationDataType;

export type searchedRoomType = {
  id: number;
  floorPlanUrl: string;
  roomNum: number;
  numOfFloor: number;
  roomType: string;
  area: number;
  rent: number;
  managementFee: number;
  deposit: number;
  gratuity: number;
};

export type mansionDataType = {
  address: string;
  constructedDate: string;
  name: string;
  searchedRoom: searchedRoomType[];
  stationInfo: string[];
  thumbnailUrl: string;
};

export type mansionListDataType = {
  content: mansionDataType[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
  };
  size: number;
  totalElements: number;
  totalPages: number;
};

function Mansion() {
  const navigate = useNavigate();
  const location = useLocation();
  const detailCategoryId = location.state;
  const [mansionState, setMansionState] = useState<mansionListDataType>();
  const [propertyState, setPropertyState] = useRecoilState(
    mansionPropertyDataStateAtom
  );

  const { updatePagination, pagination, updatePropertyOptions } =
    usePropertyState();

  const fetchMansion = async (property: mansionPropertyType) => {
    const response: { data: mansionListDataType } = await axiosInstance.post(
      "property",
      { ...property, detailCategoryId: pagination.detailCategoryId }
    );

    console.log(response.data);
    
    setMansionState(response.data);
  };
  useEffect(() => {
    fetchMansion(propertyState);
  }, [propertyState, pagination]);

  useEffect(() => {
    if (!detailCategoryId) {
      navigate("/");
    }
    updatePagination({ detailCategoryId: detailCategoryId });
  }, []);

  return (
    <ResponsiveWrapper>
      <MansionWrapper>
        <MansionSidebar />
        <MansionContents mansionState={mansionState} />
      </MansionWrapper>
    </ResponsiveWrapper>
  );
}

export default Mansion;

const MansionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media screen and (min-width: 1280px) {
    margin-top: 41px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 51px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 29px;
  }
`;
