import styled from "styled-components";
import scrap from "../../../assets/images/mainPage/scrap.png";
import scrapColord from "../../../assets/images/mainPage/scrap-colored.png";

import { mainMansionDataType, mainPageDataType, reviewDataType } from "../Main";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../services/axiosConfig";

import { useMainPageData } from "../../../recoil/mainPge/mainPageDataState";

function MainItem({
  width,
  height,
  itemTitleSize,
  itemTitleMargin,
  category,
  roomInfo,
  reviewInfo,
  itemTitle,
  pageState,
  fetchData,
}: {
  width: number;
  height: number;
  itemTitleSize?: number;
  itemTitleMargin?: number;
  category?: "review" | "room";
  roomInfo?: mainMansionDataType;
  reviewInfo?: reviewDataType;
  itemTitle?: string;
  pageState?: number;
  fetchData?: () => void;
}) {
  const navigate = useNavigate();
  const { setMainPageDataState } = useMainPageData();

  const scrapState =
    category === "review" ? reviewInfo?.scrapbook : roomInfo?.scrapbook;

  const onClickThumbnail = () => {
    if (category === "room") {
      navigate(`/detail?roomId=${roomInfo?.id}`);
    } else if (category === "review") {
      navigate(`/residentreview/detail/${reviewInfo && reviewInfo.id}`);
    }
  };

  const fetchMain = async () => {
    const response: { data: mainPageDataType } = await axiosInstance.get(
      "/normal/home/main"
    );

    setMainPageDataState(response.data);
  };

  const onClickScrap = async (e: React.MouseEvent) => {
    const token = window.sessionStorage.getItem("Authorization");

    e.stopPropagation();
    if (token) {
      try {
        const response = await axiosInstance.post(`/normal/user/scrap`, {
          category: category,
          entityId: category === "room" ? roomInfo?.id : reviewInfo?.id,
        });

        if (response.status === 200) {
          if (scrapState) {
            alert("스크랩 취소 되었습니다.");
            fetchMain();
          } else {
            alert("스크랩 되었습니다.");
            fetchMain();
          }
        }
      } catch (error) {
        console.log(error);
        alert("스크랩에 실패했습니다");
      }
    } else {
      alert("로그인 후 이용할 수 있는 서비스 입니다.");
      navigate("/login");
    }
  };

  return (
    <MainItemWrapper $maxWidth={width}>
      <ImageWrapper width={width} height={height}>
        <StyledImage
          src={
            category === "room"
              ? roomInfo && roomInfo.thumbnailUrl
              : reviewInfo && reviewInfo.thumbnailUrl
          }
          onClick={onClickThumbnail}
        />
        {scrapState !== undefined && (
          <ScrapButton
            src={scrapState ? scrapColord : scrap}
            onClick={(event) => onClickScrap(event)}
          />
        )}
      </ImageWrapper>
      {roomInfo || reviewInfo ? (
        <ItemTitle
          itemTitleSize={itemTitleSize}
          $itemTitleMargin={itemTitleMargin}
        >
          {category === "room"
            ? roomInfo && roomInfo.name
            : reviewInfo && reviewInfo?.name}
        </ItemTitle>
      ) : (
        itemTitle && (
          <ItemTitle
            itemTitleSize={itemTitleSize}
            $itemTitleMargin={itemTitleMargin}
          >
            {itemTitle}
          </ItemTitle>
        )
      )}
    </MainItemWrapper>
  );
}

export default MainItem;

const MainItemWrapper = styled.div<{ $maxWidth: number }>`
  max-width: ${(props) => `${props.$maxWidth}px`};
  @media screen and (min-width: 1280px) {
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
  }
  @media screen and (max-width: 1023px) {
  }
`;

const ImageWrapper = styled.div<{ width: number; height: number }>`
  position: relative;
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 5px;
`;

const ItemTitle = styled.div<{
  itemTitleSize?: number;
  $itemTitleMargin?: number;
}>`
  color: #555555;
  font-size: ${(props) => `${props.itemTitleSize}px`};
  margin-top: ${(props) => `${props.$itemTitleMargin}px`};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ScrapButton = styled.img`
  display: block;
  position: absolute;
  @media screen and (min-width: 1280px) {
    width: 17.5px;
    height: 17.5px;
    right: 17px;
    bottom: 17px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 19.5px;
    height: 19.5px;
    right: 15px;
    bottom: 15px;
  }
  @media screen and (max-width: 1023px) {
    width: 19.5px;
    height: 19.5px;
    right: 15px;
    bottom: 15px;
  }
`;
