import { useState } from "react";
import { useRecoilState } from "recoil";
import { filterDataStateAtom } from "../recoil/mansionPage/filterStateAtom";
import { paginationDataStateAtom } from "../recoil/mansionPage/paginationStateAtom";
import {
  propertyOptionDataStateAtom,
  propertyOptionDataType,
} from "../recoil/mansionPage/propertyOptionsAtom";

const usePropertyState = () => {
  const [pagination, setPagination] = useRecoilState(paginationDataStateAtom);
  const [filters, setFilters] = useRecoilState(filterDataStateAtom);
  const [propertyOptions, setPropertyOptions] =
    useRecoilState<propertyOptionDataType>(propertyOptionDataStateAtom);

  const updatePropertyOptions = (optionName: string, value: boolean) => {
    setPropertyOptions((prev) => ({
      ...prev,
      [optionName]: value,
    }));
  };

  const selectOnePropertyOptions = (optionName: string, value: boolean) => {
    setPropertyOptions((prev) => ({
      ...{
        freeInternet: false,
        petsAllowed: false,
        morePeople: false,
        designerMention: false,
        duplexMention: false,
        closeToStation: false,
        induction: false,
        gasRange: false,
        allElectricMansion: false,
        cityGas: false,
        indoorWashingBoard: false,
        outdoorWashingBoard: false,
        bidet: false,
        standAloneToilet: false,
        unitBath: false,
        bathroomDryer: false,
        airConditioning: false,
        storageSpace: false,
        trashDisposal24H: false,
        garbageStorage: false,
        intercom: false,
        deliveryBox: false,
        carParking: false,
        elevator: false,
        bicycleParking: false,
        bikeParking: false,
        womenOnly: false,
        mensOnly: false,
        autoLock: false,
        cctv: false,
        foreignerAllowed: false,
        overseasScreeningAvailable: false,
        furnitureOptions: false,
      },
      [optionName]: value,
    }));
  };

  return {
    pagination,
    filters,
    propertyOptions,
    updatePagination: (newPagination: any) =>
      setPagination((prev) => ({ ...prev, ...newPagination })),
    updateFilters: (newFilters: any) =>
      setFilters((prev) => ({ ...prev, ...newFilters })),
    updatePropertyOptions,
    selectOnePropertyOptions,
  };
};

export default usePropertyState;
