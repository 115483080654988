import styled from "styled-components";
import { useAdminRegistrationState } from "../../../recoil/mansionManagement/adminRegistrationStateAtom";

function RegistrationExplain() {
  const { registrationDataState, setRegistrationDataState } =
    useAdminRegistrationState();

  return (
    <ExplainWrapper>
      <ExplainInputArea>
        <ExplainTitle>맨션 설명</ExplainTitle>
        <ExplainInput
          placeholder="맨션에 대한 설명을 적어주세요"
          value={registrationDataState.mansionExplain}
          onChange={(e) =>
            setRegistrationDataState({
              ...registrationDataState,
              mansionExplain: e.target.value,
            })
          }
        ></ExplainInput>
      </ExplainInputArea>
    </ExplainWrapper>
  );
}

export default RegistrationExplain;

const ExplainWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 408px;
  padding-left: 23px;
  border-bottom: 2px solid #e6e6e6;
`;

const ExplainTitle = styled.div`
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -2px;
`;

const ExplainInputArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ExplainInput = styled.textarea`
  padding: 17px 19px;
  width: 904px;
  height: 204px;
  border: 1px dotted #a7a7a7;
  font-family: "Noto Sans KR";
  border-radius: 5px;
  resize: none;
  outline: none;
  background-color: #f7f8f9;
  box-sizing: border-box;
  font-size: 18px;
  letter-spacing: -1px;
`;
