import { useEffect, useState } from "react";
import styled from "styled-components";
import MainDesignHeader from "./components/MainDesignHeader";
import MainDesignSidebar from "./components/MainDesignSidebar";
import MainDesignPreview from "./components/MainDesignPreview";
import axios from "axios";
import { subBannerStateAtom } from "../../recoil/adminMainDesign/subBannerStateAtom";
import { mainBannerStateAtom } from "../../recoil/adminMainDesign/mainBannerStateAtom";
import { logoStateAtom } from "../../recoil/adminMainDesign/logoStateAtom";
import { categoryIconStateAtom } from "../../recoil/adminMainDesign/categoryIconStateAtom";
import { useSetRecoilState } from "recoil";
import { mainBannerDataType } from "../../recoil/adminMainDesign/mainBannerStateAtom";
import { subBannerDataType } from "../../recoil/adminMainDesign/subBannerStateAtom";
import { logoDataType } from "../../recoil/adminMainDesign/logoStateAtom";
import { categoryIconDataType } from "../../recoil/adminMainDesign/categoryIconStateAtom";
import axiosInstance from "../../services/axiosConfig";

type mainResponseType = {
  data: mainDesignDataType;
};

export type mainDesignDataType = {
  logoResponse: logoDataType;
  mainBannerList: mainBannerDataType[];
  subBanner: subBannerDataType;
  iconResponseList: categoryIconDataType[];
};

function AdminMainDesign() {
  const [originalDataState, setOgriginalDataState] =
    useState<mainDesignDataType>();
  const setLogoState = useSetRecoilState(logoStateAtom);
  const setMainBannerState = useSetRecoilState(mainBannerStateAtom);
  const setSubBannerState = useSetRecoilState(subBannerStateAtom);
  const setCategoryIconState = useSetRecoilState(categoryIconStateAtom);

  useEffect(() => {
    const fetchAdminDesign = async () => {
      const response: mainResponseType = await axiosInstance.get("/admin/home");
      setOgriginalDataState(response.data);
      if (response.data.logoResponse) {
        setLogoState(response.data.logoResponse && response.data.logoResponse);
      }
      if (response.data.mainBannerList.length > 0) {
        setMainBannerState(
          response.data.mainBannerList && response.data.mainBannerList
        );
      }
      if (response.data.subBanner) {
        setSubBannerState(response.data.subBanner && response.data.subBanner);
      }
      if (response.data.iconResponseList.length > 0)
        setCategoryIconState(
          response.data.iconResponseList && response.data.iconResponseList
        );
    };

    fetchAdminDesign();
  }, []);

  return (
    <Wrapper>
      <MainDesignHeader originalDataState={originalDataState} />
      <ContentsWrapper>
        <MainDesignSidebar />
        <MainDesignPreview />
      </ContentsWrapper>
    </Wrapper>
  );
}

export default AdminMainDesign;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 1920px;
  height: 2800px;
`;

const ContentsWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 2607px;
`;
