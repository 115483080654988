import styled from "styled-components";
import arrowIcon from "../../../assets/images/ResidentReview/DownIcon.png";
import checkIcon from "../../../assets/images/mansionManagement/check.png";
import { useAdminRegistrationState } from "../../../recoil/mansionManagement/adminRegistrationStateAtom";

function RegistrationCategory() {
  const { registrationDataState, setRegistrationDataState } =
    useAdminRegistrationState();
  const convertedCategoryId = (id: number) => {
    switch (id) {
      case 1:
        return "맨션";
      case 2:
        return "주택";
      case 3:
        return "단층맨션";
      case 4:
        return "기타";
      case 5:
        return "토지";
      case 6:
        return "맨션";
      case 7:
        return "점포";
      case 8:
        return "주택";
      case 9:
        return "기타";
      case 10:
        return "사무실";
      case 11:
        return "창고";
      case 12:
        return "점포";
      case 13:
        return "기타";
      case 14:
        return "하우버 맨션";
    }
  };

  return (
    <CategoryWrapper>
      <CategoryTitle>카테고리</CategoryTitle>
      <SelectCategoryArea>
        <CategoryOptionWrapper>
          <CategoryMainOption>임대</CategoryMainOption>
          <CategoryOptionArea>
            {[1, 2, 3, 4].map((item, index) => (
              <CategoryOption
                key={index}
                onClick={() =>
                  setRegistrationDataState({
                    ...registrationDataState,
                    selectedCategory: item,
                  })
                }
              >
                <CategoryArrow src={arrowIcon} />
                {convertedCategoryId(item)}
                {registrationDataState.selectedCategory === item && (
                  <CheckIcon src={checkIcon} />
                )}
              </CategoryOption>
            ))}
          </CategoryOptionArea>
        </CategoryOptionWrapper>
        <CategoryOptionWrapper>
          <CategoryMainOption>매매</CategoryMainOption>
          <CategoryOptionArea>
            {[5, 6, 7, 8, 9].map((item, index) => (
              <CategoryOption
                key={index}
                onClick={() =>
                  setRegistrationDataState({
                    ...registrationDataState,
                    selectedCategory: item,
                  })
                }
              >
                <CategoryArrow src={arrowIcon} />
                {convertedCategoryId(item)}
                {registrationDataState.selectedCategory === item && (
                  <CheckIcon src={checkIcon} />
                )}
              </CategoryOption>
            ))}
          </CategoryOptionArea>
        </CategoryOptionWrapper>
        <CategoryOptionWrapper>
          <CategoryMainOption>점포</CategoryMainOption>
          <CategoryOptionArea>
            {[10, 11, 12, 13].map((item, index) => (
              <CategoryOption
                key={index}
                onClick={() =>
                  setRegistrationDataState({
                    ...registrationDataState,
                    selectedCategory: item,
                  })
                }
              >
                <CategoryArrow src={arrowIcon} />
                {convertedCategoryId(item)}
                {registrationDataState.selectedCategory === item && (
                  <CheckIcon src={checkIcon} />
                )}
              </CategoryOption>
            ))}
          </CategoryOptionArea>
        </CategoryOptionWrapper>
        <CategoryOptionWrapper>
          <CategoryMainOption>하우버 맨션</CategoryMainOption>
          <CategoryOptionArea>
            <CategoryOption
              onClick={() =>
                setRegistrationDataState({
                  ...registrationDataState,
                  selectedCategory: 14,
                })
              }
            >
              <CategoryArrow src={arrowIcon} />
              {convertedCategoryId(14)}
              {registrationDataState.selectedCategory === 14 && (
                <CheckIcon src={checkIcon} />
              )}
            </CategoryOption>
          </CategoryOptionArea>
        </CategoryOptionWrapper>
      </SelectCategoryArea>
    </CategoryWrapper>
  );
}

export default RegistrationCategory;

const CategoryWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 409px;
  padding-left: 23px;
  border-bottom: 2px solid #e6e6e6;
`;

const CategoryTitle = styled.div`
  font-size: 25px;
  letter-spacing: -2px;
  font-weight: 600;
`;

const SelectCategoryArea = styled.div`
  display: flex;
  margin-left: 216.5px;
  gap: 74px;
`;

const CategoryOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25.5px;
  min-width: 133px;
`;

const CategoryMainOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  padding: 0 21px;
  border: 1px solid #a7a7a7;
  font-size: 25px;
  font-weight: bold;
  border-radius: 5px;
  letter-spacing: -3px;
`;

const CategoryOptionArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 31px;
`;

const CategoryOption = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  gap: 8px;
  font-weight: 500;
  cursor: pointer;
`;

const CategoryArrow = styled.img`
  width: 15px;
  height: 8px;
  transform: rotate(270deg);
`;

const CheckIcon = styled.img`
  width: 25px;
  height: 17px;
`;
