import { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { useAdminRegistrationState } from "../../../recoil/mansionManagement/adminRegistrationStateAtom";

type districtInfoType = {
  districtId: number;
  districtKName: string;
};

function SelectDistrictItem({
  districtInfo,
  index,
  close,
}: {
  districtInfo: districtInfoType;
  index: number;
  close: () => void;
}) {
  const { registrationDataState, setRegistrationDataState } =
    useAdminRegistrationState();

  return (
    <DistrictItem>
      <DistrictItemInfo>
        <DistrictItemInex>{index + 1}</DistrictItemInex>
        {districtInfo.districtKName}
      </DistrictItemInfo>
      <DistrictItemOption>
        <SelectButton
          onClick={() => {
            setRegistrationDataState({
              ...registrationDataState,
              districtId: districtInfo.districtId,
              districtKName: districtInfo.districtKName,
            });
            close();
          }}
        >
          선택
        </SelectButton>
      </DistrictItemOption>
    </DistrictItem>
  );
}

export default SelectDistrictItem;

const DistrictItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 15px;
  min-height: 90px;
  font-size: 25px;
  border-bottom: 1px solid #e6e6e6;
  gap: 20px;
  font-weight: 500;
`;

const DistrictItemInfo = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DistrictItemInex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 100%;
`;

const DistrictItemOption = styled.div`
  position: relative;
  display: flex;
  gap: 20.5px;
  font-size: 20px;
`;

const SelectButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding: 17.5px;
  border: 1px solid #8e8e8e;
  border-radius: 30px;
  cursor: pointer;
  &:hover {
    color: white;
    background-color: #99393a;
    border-color: #99393a;
  }
`;
