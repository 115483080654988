import React, { useState, ChangeEvent, useEffect } from "react";
import styled from "styled-components";
import FindIdSection from "./components/FindIdSection";
import PasswordChangeForm from "./components/PasswordChangeForm";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LOGO from "../../assets/images/ResidentReview/LOGO.png"
const FindId: React.FC = () => {
  const [newPW, setNewPW] = useState("");
  const [checkNewPW, setCheckNewPW] = useState("");
  const [passwordMismatch, setPasswordMismatch] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [showPasswordChangeForm, setShowPasswordChangeForm] = useState(false); // 추가
  const [isIDPhoneNumberValid, setIsIdPhoneNumberValid] = useState(false);
  const [isPwPhoneNumberValid, setIsPwPhoneNumberValid] = useState(false);
  const [IDverificationCodeValid, setIDverificationCodeValid] = useState(true);
  const [PWverificationCodeValid, setPWverificationCodeValid] = useState(true);
  const [localPwValid, setlocalPwValid] = useState(true);

  const [phoneNum, setPhoneNum] = useState('');
  const navigate = useNavigate();

  const handleIdFindConfirm =async (verificationCode: string, phoneNum:string, type:string ,countryCode:string) => {
    const ctrphonenum = `+${countryCode}${phoneNum}`;
    const queryParams = new URLSearchParams({
      phoneNum: ctrphonenum,
      type,
      code: verificationCode
    }).toString();

    try{
      const response = await axios.post( `https://houberapp.com/auth/verify/check?${queryParams}`)
      console.log(response);
       // 바디에 문자열 있으면 아이디 보여주는 페이지로 이동할 예정
      if(response.data !== ''){
        setIDverificationCodeValid(false);
        navigate('/login/showaccount', {state:{data: response.data}})
      // 비밀번호 바뀌었다는 알려주는 페이지로 이동
      }
    }catch(error){
      console.log(error);
    }
  };

  const handlePasswordChangeConfirm =async (verificationCode: string, phoneNum:string, type:string ,countryCode:string) => {
    const ctrphonenum = `+${countryCode}${phoneNum}`;
    setPhoneNum(ctrphonenum);
    const queryParams = new URLSearchParams({
      phoneNum: ctrphonenum,
      type,
      code: verificationCode
    }).toString();

    try{
      const response = await axios.post( `https://houberapp.com/auth/verify/check?${queryParams}`)
      console.log(response);
      if(response.status == 200){
        setShowPasswordChangeForm(true);
        setPWverificationCodeValid(false);
      }
    }catch(error){
      console.log(error);
    }
   
  };

  const handleNewPWChange = (e: ChangeEvent<HTMLInputElement>) => {
    const pw = e.target.value;
    setNewPW(pw);
    setPasswordValid(isPasswordValid(pw));
    if (checkNewPW !== "" && pw !== checkNewPW) {
      setPasswordMismatch(true);
    } else {
      setPasswordMismatch(false);
    }
  };

  const handleCheckNewPWChange = (e: ChangeEvent<HTMLInputElement>) => {
    const pw = e.target.value;
    setCheckNewPW(pw);
    if (pw !== newPW) {
      setPasswordMismatch(true);
    } else {
      setPasswordMismatch(false);
    }
  };

  useEffect(()=>{
    if(checkNewPW !=="" && newPW !==""){
    if(checkNewPW === newPW){
        setlocalPwValid(false);
    };
  }else
  setlocalPwValid(true)
  },[checkNewPW,newPW]);

  const handlePasswordChange = async() => {
    const queryParams = new URLSearchParams({
      phoneNum,
      userPw:checkNewPW
    }).toString();

    try{
      const response = await axios.post(`https://houberapp.com/auth/changePw?${queryParams}`)
      console.log(response);
      if(response.data === ''){
          navigate('/login/showaccount', {state:{data: response.data}})
      }
    }catch(error){
      console.log(error);
    }
  };

  const isPasswordValid = (pw: string): boolean => {
    const regex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}$/;
    return regex.test(pw);
  };

  return (
    <FindIdContainer>
      <LogoBox>
        <img src={LOGO} style={{width:'100%', height:'100%'}}/>
      </LogoBox>
      <StyledDiv />
      <FindIDBox>
        <TextDiv>아이디 비밀번호 찾기</TextDiv>

        <FindIdSection
          title="아이디 찾기"
          type= "FindAccount"
          onConfirmClick={handleIdFindConfirm}
          verificationCodeValid={IDverificationCodeValid}
        />
        {isIDPhoneNumberValid && (
          <AlertDiv>
            입력하신 번호를 찾을 수 없습니다
          </AlertDiv>
        )}
        <Seperator />

        <FindIdSection
          title="비밀번호 변경하기"
          type= "ChangePw"
          onConfirmClick={handlePasswordChangeConfirm}
          verificationCodeValid={PWverificationCodeValid}
        />
        {isPwPhoneNumberValid && (
          <AlertDiv>
            입력하신 번호를 찾을 수 없습니다
          </AlertDiv>
        )}
        {showPasswordChangeForm && (
          <>
            <InfoDiv>
              * 영문,숫자,특수기호를 포함한 8자 이상의 비밀번호를 입력해주세요.
            </InfoDiv>
            <PasswordChangeForm
              newPW={newPW}
              checkNewPW={checkNewPW}
              onNewPWChange={handleNewPWChange}
              onCheckNewPWChange={handleCheckNewPWChange}

              passwordValid={passwordValid}
              passwordMismatch={passwordMismatch}
            />
            <Button disabled={passwordMismatch || localPwValid} onClick={handlePasswordChange}>변경하기</Button>
          </>
        )}

        <div style={{ marginBottom: "200px" }} />
      </FindIDBox>
    </FindIdContainer>
  );
};
export default FindId;

const FindIdContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
`;

const LogoBox = styled.div`
  text-align: center;
  margin-top: 90px;
  width: 165px;
  height: 165px;
  @media (max-width: 1024px) {
    margin-top: 70px;
    width: 140px;
    height: 140px;
  }
  @media (max-width: 500px) {
    width: 100px;
    height: 100px;
    margin-top: 45px;
  }
`;

const StyledDiv = styled.div`
  background-color: rgba(187, 185, 185, 0.5);
  width: 100%;
  padding: 1px;
  margin-top: 65px;
  top: 298px;
  @media (max-width: 500px) {
    margin-top: 40px;
  }
`;

const TextDiv = styled.div`
  font-size: 26px;
  font-weight: bold;
  margin-top: 100px;
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    margin-top: 70px;
    font-size: 23px;
  }
  @media (max-width: 500px) {
    margin-top: 45px;
    font-size: 16px;
  }
`;

const FindIDBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 545px;
  @media (max-width: 1024px) {
    width: 450px;
  }
  @media (max-width: 500px) {
    width: 374px;
  }
`;

const Seperator = styled.div`
  margin-top: 50px;
  width: 100%;
  height: 1px;
  background-color: #cccccc;
`;

const InfoDiv = styled.div`
  padding-top: 50px;
  padding-bottom: 25px;
  color: #949494;

  @media (max-width: 500px) {
    font-size: 11px;
    padding-top: 25px;
    padding-bottom: 12px;
  }
`;

const Button = styled.button`
  background-color: ${({ disabled }) => (disabled ? "#ccc" : "#99393a")};
  color: ${({ disabled }) => (disabled ? "#858585" : "white")};
  font-size: 20px;
  border: none;
  width: auto;
  height: 70px;
  border-radius: 5px;
  font-weight: bold;
  margin-top: 5px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  transition: background-color 0.3s ease;

  &:disabled {
    cursor: not-allowed;
  }

  @media (max-width: 500px) {
    font-size: 11px;
    height: 34px;
  }
`;

const AlertDiv = styled.div`

padding-top: 5px;
 color: red;
margin-bottom: 5px;

@media (max-width: 500px) {
    font-size: 11px;
  }

`;