import styled from "styled-components";
import ArrowLeft from "../../../assets/images/mainPage/bannerarrow-left.png";
import ArrowRight from "../../../assets/images/mainPage/bannerarrow-right.png";

function SidebarNavigation({
  navigationState,
  getNavitaionState,
  onClickAddButton,
  maxPage,
}: {
  navigationState: number;
  getNavitaionState: (newState: number) => void;
  onClickAddButton: () => void;
  maxPage: number;
}) {
  return (
    <NavigationDiv>
      <NavigationButton
        onClick={() => getNavitaionState(navigationState - 1)}
        invisible={navigationState === 0}
      >
        <StyledImage src={ArrowLeft}></StyledImage>
      </NavigationButton>
      <NavigationButton onClick={onClickAddButton} invisible={false}>
        +
      </NavigationButton>
      <NavigationButton
        onClick={() => getNavitaionState(navigationState + 1)}
        invisible={navigationState === maxPage}
      >
        <StyledImage src={ArrowRight}></StyledImage>
      </NavigationButton>
    </NavigationDiv>
  );
}

export default SidebarNavigation;

const NavigationDiv = styled.div`
  width: 120px;
  display: flex;
  justify-content: space-between;
`;

const NavigationButton = styled.div<{ invisible: boolean }>`
  visibility: ${(props) => props.invisible && "hidden"};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 25px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;
