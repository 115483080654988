import styled from "styled-components";
import ResponsiveWrapper from "../../ResponsiveWrapper";
import xIcon from "../../../assets/images/mainPage/x-icon.png";
import { useMobileSearchState } from "../../../recoil/mainPge/mobileSearchStateAtom";

function MobileSearchHeader() {
  const { modifyMobileSearchState } = useMobileSearchState();
  return (
    <Wrapper>
      <ResponsiveWrapper>
        <InnerWrapper>
          검색{" "}
          <StyledCloseImage src={xIcon} onClick={modifyMobileSearchState} />
        </InnerWrapper>
      </ResponsiveWrapper>
    </Wrapper>
  );
}

export default MobileSearchHeader;

const Wrapper = styled.header`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 48px;
  font-size: 15px;
  font-weight: 600;
  border-bottom: 2px solid #f5f5f5;
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StyledCloseImage = styled.img`
  width: 17px;
  height: 17px;
`;
