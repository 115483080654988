import React from "react";
import styled from "styled-components";

interface Props {
  id: number;
  text: string;
  inputPosition?: "right" | "left";
  size: "medium" | "small" | "xsmall";
  checkItemHandler: ({ target }: any) => void;
  propertyCount?: number;
  isChecked?: boolean;
}

export default function CheckBox({
  id,
  text,
  inputPosition,
  checkItemHandler,
  propertyCount,
  size,
  isChecked,
}: Props) {
  const checkHandled = async ({ target }: any) => {
    checkItemHandler({ target });
  };
  if (inputPosition === "right") {
    return (
      <>
        <Container>
          <Text propertyCount={propertyCount} size={size}>
            {propertyCount && "(" + { propertyCount } + ")"}
            {text}
          </Text>
          <CustomButton
            id={id.toString() + "-" + size}
            type="checkbox"
            onChange={(e) => checkHandled(e)}
            value={id.toString() + "-" + size}
            checked={isChecked || false}
          />
          <Label htmlFor={id.toString() + "-" + size} size={size} />
        </Container>
      </>
    );
  } else
    return (
      <Container>
        <CustomButton
          id={id.toString() + "-" + size}
          type="checkbox"
          onChange={(e) => checkHandled(e)}
          value={id.toString() + "-" + size}
          checked={isChecked || false}
        />
        <Label htmlFor={id.toString() + "-" + size} size={size} />
        <Text propertyCount={propertyCount} size={size}>
          {text}
          {propertyCount && "(" + { propertyCount } + ")"}
        </Text>
      </Container>
    );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 165px;
  @media screen and (max-width: 1023px) {
    width: 150px;
  }
  span {
    position: relative;
    top: 2px;
  }
`;

const CustomButton = styled.input`
  display: none;
  &:checked + label {
    border: 1px solid #99393a;
    background-color: #99393a;
    background-size: 100% 100%;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="none"><path d="M1.52734 7.70605L7.87524 15.0381L19.4983 1.00098" stroke="%23FFFDF9" stroke-width="4" stroke-linecap="round"/></svg>');
  }
`;
const Label = styled.label<{ size: "medium" | "small" | "xsmall" }>`
  min-width: ${({ size }) =>
    size === "medium" ? "20px" : size === "small" ? "17px" : "15px"};
  min-height: ${({ size }) =>
    size === "medium" ? "20px" : size === "small" ? "17px" : "15px"};
  border: 1px solid #000000a0;
  cursor: pointer;
  border-radius: 4px;
  background-color: white;
  box-sizing: border-box;
`;

const Text = styled.span<{
  propertyCount?: number;
  size: "medium" | "small" | "xsmall";
}>`
  color: ${({ propertyCount }) =>
    propertyCount !== 0 ? "#000000a0" : "#00000050"};
  font-family: "Noto Sans KR";
  font-size: ${({ size }) =>
    size === "medium" ? "20px" : size === "small" ? "15px" : "13px"};
  font-weight: ${({ size }) => (size === "medium" ? "600" : "500")};
`;
