import React, { useState, useEffect, ChangeEvent } from "react";
import styled from "styled-components";
import KakaoLogin from "../loginPage/components/KakaoLogin";
import GoogleLogin from "../loginPage/components/GoogleLogin";
import GoogleIMG from "../loginPage/IMG/GoogleIMG.png";
import 카카오 from "../loginPage/IMG/카카오톡.png";
import CountrySelect from "../FindIdPwPage/components/ContrySelect";
import PasswordChangeForm from "../FindIdPwPage/components/PasswordChangeForm";
import ConfirmBox from "./components/ConfirmForm";
import TermsAgreement, { CheckboxesState } from "./components/TermsAgreement";
import NicknameInput from "./components/NicknameInput";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LOGO from "../../assets/images/ResidentReview/LOGO.png"
const SignUp: React.FC = () => {
  // 전화번호 부분 모음
  const [countryCode, setCountryCode] = useState("82");
  const [phoneNumCount, setphoneNumCount] = useState(false);
  const [phoneNum, setphoneNum] = useState("");
  const [isphoneNumValid, setIsphoneNumValid] = useState(true);
  // 비밀번호 부분 모음
  const [userPw, setNewPW] = useState("");
  const [checkNewPW, setCheckNewPW] = useState("");
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [passwordValid, setPasswordValid] = useState(true);
  //전화번호 인증 부분 모음
  const [verificationCode, setVerificationCode] = useState("");
  const [isverificationCodeValid, setIsverificationCodeValid] = useState(true);
  const [confirmationResult, setConfirmationResult] = useState(false);
  // 사용자 정보 부분 모음
  const [userName, setUserName] = useState("");
  const [isuserNameFilled, setIsuserNameFilled] = useState(false);
  const [nickName, setnickName] = useState("");
  const [isValidnickName, setIsValidnickName] = useState(false);
  const [birthDay, setbirthDay] = useState("");
  const [isbirthDayValid, setIsbirthDayValid] = useState(false);
  const [userId, setUserID] = useState("");
  const [isuserIDFilled, setIsuserIDFilled] = useState(false);
  const [isuserIDValid, setisuserIDValid] = useState(false);
  const [isUserNickNameUsed, setIsuserNickNameUsed] = useState(true);
  // 약관 부분 모음
  const [allRequiredChecked, setAllRequiredChecked] = useState(false);
  const [tosInfoList, setTosInfoList] = useState<CheckboxesState>({
    olderThen14Years: false,
    agreeToTermsOfUse: false,
    personalInformationCollectionAndUsageAgreement: false,
    agreementToUsePersonalInformationForMarketing: false,
    agreementToUseSMS: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (userId === "") return;

    const handler = setTimeout(() => {
      const checkUserId = async () => {
        try {
          const response = await axios.get(
            "https://houberapp.com/auth/register/check",
            {
              params: {
                key: "userId",
                value: userId,
              },
            }
          );

          setisuserIDValid(!response.data);
        } catch (error) {

        }
      };

      checkUserId();
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [userId]);

  // useEffect(() => {
  //   console.log("isuserIDValid 상태 변경:", isuserIDValid);
  // }, [isuserIDValid]);

  useEffect(() => {
    setphoneNumCount(phoneNum.length === 11 || phoneNum.length > 0);

  }, [phoneNum]);

  useEffect(() => {
    setIsbirthDayValid(birthDay.length === 10);

  }, [birthDay]);

  useEffect(() => {
    setIsuserNameFilled(userName.length > 1);

  }, [userName]);

  const handleAllRequiredChecked = (checked: boolean) => {
    setAllRequiredChecked(checked);
  };

  const handleUSerIDChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    if (value.includes("@")) {
      setUserID(value.replace("@", ""));
    } else {
      setUserID(value);
      setIsuserIDFilled(value.length >= 2);
    }
  };

  const handlebirthDayChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    let dateNumText = value.replace(/[^0-9]/g, "");
    let formattedDate = dateNumText;

    if (dateNumText.length >= 4 && dateNumText.length < 6) {
      formattedDate =
        dateNumText.substring(0, 4) + "-" + dateNumText.substring(4);
    } else if (dateNumText.length >= 6) {
      formattedDate =
        dateNumText.substring(0, 4) +
        "-" +
        dateNumText.substring(4, 6) +
        "-" +
        dateNumText.substring(6);
    }

    setbirthDay(formattedDate);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      const { selectionStart } = e.currentTarget;
      if (selectionStart && (selectionStart === 5 || selectionStart === 8)) {
        e.preventDefault();
        setbirthDay(
          (prev) =>
            prev.slice(0, selectionStart - 1) + prev.slice(selectionStart)
        );
      }
    }
  };

  const handleUserNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setUserName(name);
  };

  const handlephoneNumChange = (e: ChangeEvent<HTMLInputElement>) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    setphoneNum(onlyNums);

    if (onlyNums === "") {
      setIsphoneNumValid(true);
    }
  };

  const sendPhoneNumber = async () => {
    const ctrphonenum = `+${countryCode}${phoneNum}`;


    const queryParams = new URLSearchParams({
      phoneNum: ctrphonenum,
      type: "Register",
    }).toString();

    try {
      const response = await axios.post(
        `https://houberapp.com/auth/verify?${queryParams}`
      );

      setConfirmationResult(true);
    } catch (error) {

    }
  };

  const handleConfirmClick = async () => {
    const code = verificationCode;
    const ctrphonenum = `+${countryCode}${phoneNum}`;

    const queryParams = new URLSearchParams({
      code: code,
      phoneNum: ctrphonenum,
      type: "Register",
    }).toString();

    try {
      const response = await axios.post(
        `https://houberapp.com/auth/verify/check?${queryParams}`
      );

      if (response.status === 200) {
        setConfirmationResult(true);
        setIsverificationCodeValid(false)
      }
    } catch (error) {
      alert("잘못된 인증번호입니다.");

    }
  };

  const handleVerificationCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 6) {
      setVerificationCode(e.target.value);
    }
  };

  const handleNewPWChange = (e: ChangeEvent<HTMLInputElement>) => {
    const pw = e.target.value;
    setNewPW(pw);
    setPasswordValid(isPasswordValid(pw));
    if (checkNewPW !== "" && pw !== checkNewPW) {
      setPasswordMismatch(true);
    } else {
      setPasswordMismatch(false);
    }
  };

  const handleCheckNewPWChange = (e: ChangeEvent<HTMLInputElement>) => {
    const pw = e.target.value;
    setCheckNewPW(pw);
    if (pw !== userPw) {
      setPasswordMismatch(true);
    } else {
      setPasswordMismatch(false);
    }
  };



  const handlenickNameChange = (value: string, isValid: boolean, isIdUsed:boolean) => {
    setnickName(value);
    setIsValidnickName(isValid);
    setIsuserNickNameUsed(isIdUsed);

  };
  const isPasswordValid = (pw: string): boolean => {
    const regex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}$/;
    return regex.test(pw);
  };


  const handleCheckboxChange = (updatedCheckboxes: CheckboxesState) => {
    setTosInfoList(updatedCheckboxes);
  };

  const handleSignUpOnClick = async () => {
    const ctrphonenum = `+${countryCode}${phoneNum}`;
    try {
      const signUpData = {
        userName,
        birthDay,
        phoneNum:ctrphonenum,
        userId,
        userPw,
        nickName,
        tosInfoList,
      };


      const response = await axios.post(
        "https://houberapp.com/auth/register",
        signUpData
      );

      if (response.status === 200) {
        navigate('/login/welcome',{state:{data:nickName}})
      }
    } catch (error) {
      alert("회원가입 중 오류가 발생했습니다.");
    }
  };

  return (
    <SignUpContainer>
      <Link style={{textDecoration: 'none'}} to="/">      
      <LogoBox>
        <img src={LOGO} style={{width:'100%', height:'100%'}}/>
      </LogoBox>
      </Link>
      <TextDiv>SNS 계정으로 간편하게 회원가입</TextDiv>
      <SocialLoginContainer>
        <GoogleLogin icon={GoogleIMG} />
        <KakaoLogin icon={카카오} />
      </SocialLoginContainer>
      <StyledDiv />

      <LayoutDiv>
        <Stylediv className="name">회원가입</Stylediv>
        <Stylediv style={{fontWeight:'600'}}>이름</Stylediv>
        <DivInput>
          <InputField
            placeholder="이름"
            value={userName}
            onChange={handleUserNameChange}
          />
        </DivInput>

        <Stylediv style={{fontWeight:'600'}}>생년월일</Stylediv>
        <DivInput>
          <InputField
            placeholder="8자리 입력"
            maxLength={10}
            value={birthDay}
            onChange={handlebirthDayChange}
            onKeyDown={handleKeyDown}
          />
        </DivInput>

        <Stylediv style={{fontWeight:'600'}}>전화번호</Stylediv>
        <CountrySelect
          countryCode={countryCode}
          setCountryCode={setCountryCode}
        />
        <DivInput style={{ marginTop: "3px", marginBottom: "0px" }}>
          <InputField
            style={{ marginTop: "0px" }}
            placeholder="휴대전화 번호 입력"
            maxLength={13}
            type="text"
            value={phoneNum}
            onChange={handlephoneNumChange}
            disabled={confirmationResult}
          />
        </DivInput>
        <Button disabled={!phoneNumCount} onClick={sendPhoneNumber}>
          전화번호 인증하기
        </Button>
        {confirmationResult && (
          <ConfirmBox
            isverificationCodeValid={isverificationCodeValid}
            verificationCode={verificationCode}
            handleVerificationCodeChange={handleVerificationCodeChange}
            handleConfirmClick={handleConfirmClick}
            sendPhoneNumber={sendPhoneNumber}
          />
        )}
        <StyledDiv style={{ marginBottom: "40px" }} />

        <Stylediv style={{fontWeight:'600'}}>아이디</Stylediv>
        <DivInput className={`${isuserIDValid ? "IdInput" : ""}`}>
          <InputField
            maxLength={15}
            placeholder="아이디"
            value={userId}
            onChange={handleUSerIDChange}
          />
        </DivInput>
        {isuserIDValid && <StyledP>중복된 아이디 입니다.</StyledP>}
        <Stylediv style={{fontWeight:'600'}}>비밀번호</Stylediv>
        <InfoDiv>
          * 영문,숫자,특수기호를 포함한 8자 이상의 비밀번호를 입력해주세요.
        </InfoDiv>
        <PasswordChangeForm
          newPW={userPw}
          checkNewPW={checkNewPW}
          onNewPWChange={handleNewPWChange}
          onCheckNewPWChange={handleCheckNewPWChange}

          passwordValid={passwordValid}
          passwordMismatch={passwordMismatch}
        />

        <Stylediv style={{fontWeight:'600'}} className="nickName">닉네임</Stylediv>
        <InfoDiv>
          * 공백없이 한글,일본어,영어,숫자, 10글자 이내 입력 가능합니다.
        </InfoDiv>
        <NicknameInput onChange={handlenickNameChange} />
        <Stylediv  style={{paddingTop:'20px', fontWeight:'600'}}>약관동의</Stylediv>
        <MarginDIv/>
        <TermsAgreement
          onAllRequiredChecked={handleAllRequiredChecked}
          onCheckboxChange={handleCheckboxChange}
        />
        <Button
          disabled={
            !phoneNumCount ||
            !allRequiredChecked ||
            !confirmationResult ||
            !(userPw === checkNewPW) ||
            !isValidnickName ||
            !isbirthDayValid ||
            !isuserNameFilled ||
            !isuserIDFilled ||
            isuserIDValid ||
            isUserNickNameUsed
          }
          onClick={handleSignUpOnClick}
        >
          회원가입하기
        </Button>

        <Stylediv className="bottom">
          이미 아이디가 있으신가요?{" "}
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to="/login"
          >
            <StyledSpan>로그인</StyledSpan>
          </Link>
        </Stylediv>
      </LayoutDiv>

      <div style={{ marginBottom: "200px" }} />
    </SignUpContainer>
  );
};

export default SignUp;

const InfoDiv = styled.div`
font-family: "Noto Sans KR";
padding: 10px 0 10px;
  color: #949494;
  @media (max-width: 1024px) {
    font-size: 13px;
  }
  @media (max-width: 500px) {
    font-size: 11.5px;
    padding: 2px 0 2px;
  }
`;

const Stylediv = styled.div`
  font-family: "Noto Sans KR";
  margin-bottom: 5px;
  font-size: 23px;
  font-weight: 400;
  &.name {
    font-size: 26px;
    font-weight: bold;
    padding-top: 40px;
    padding-bottom: 40px;

    @media (max-width: 500px) {
      font-size: 16px;
      font-weight: 600;
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  &.nickName {
    margin-top: 30px;
  }
  @media (max-width: 500px) {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 400;
  }
  &.bottom {
    font-weight: 400;
    font-size: 21;
    text-align: center;
    @media (max-width: 1024px) {
      font-size: 15px;
    }
    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
`;

const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
`;

const TextDiv = styled.div`
  font-family: "Noto Sans KR";
  font-weight: 500;
  font-size: 23px;
  margin-top: 60px;
  @media (max-width: 500px) {
    font-size: 12px;
    margin-top: 40px;
  }
`;

const LogoBox = styled.div`
  text-align: center;
  margin-top: 90px;
  width: 165px;
  height: 165px;
  @media (max-width: 500px) {
    margin-top: 50px;
    width: 100px;
    height: 100px;
  }
`;


const StyledDiv = styled.div`
  background-color: rgba(187, 185, 185, 0.5);
  width: 100%;
  padding: 1px;
  margin-top: 30px;
`;

const SocialLoginContainer = styled.div`
  justify-content: space-between;
  display: flex;
  width: auto;
  height: auto;
  align-items: center;
  padding-top: 15px;
`;

const InputField = styled.input`
  border-radius: 10px;
  width: 100%;
  padding-left: 20px;
  font-family: "Noto Sans KR";
  font-weight: 500;
  font-size: 23px;
  border: 1px solid #ccc;
  height: 60px;
  outline: none;
  @media (max-width: 1024px) {
    font-size: 22px;

  }
  @media (max-width: 500px) {
    font-size: 12px;
    height: 34px;
  }
`;

const DivInput = styled.div`
  display: flex;
  border: none;
  border-radius: 5px;
  background-color: white;
  margin-top: 5px;
  margin-bottom: 30px;

  &.IdInput {
    margin-bottom: 15px;
    @media (max-width: 500px) {
    margin-bottom: 2px;
  }

  }

  @media (max-width: 500px) {
    margin-bottom: 15px;
  }
`;

const LayoutDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 528px;
  border: none;

  @media (max-width: 1024px) {
    width: 420px;
  }
  @media (max-width: 500px) {
    width: 85%;
  }
`;

const Button = styled.button`
  background-color: ${({ disabled }) => (disabled ? "#ccc" : "#99393a")};
  color: ${({ disabled }) => (disabled ? "#858585" : "white")};
  font-size: 20px;
  border: none;
  width: auto;
  height: 70px;
  border-radius: 5px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 20px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  transition: background-color 0.3s ease;

  &:disabled {
    cursor: not-allowed;
  }
  @media (max-width: 500px) {
    height: 35px;
    font-size: 15px;
  }
`;

const StyledSpan = styled.span`
  font-size: 27px;
  font-weight: bold;
  @media (max-width: 1024px) {
    font-size: 22px;
  }
  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

const StyledP = styled.div`
  color: red;
  margin-bottom: 15px;

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;


const MarginDIv = styled.div`
  margin-bottom  : 5px;

`;