// src/components/Modal.tsx
import React from "react";
import styled from "styled-components";

// 모달 열림/닫힘 상태와 닫기 기능을 props로 받아옴
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <Overlay>
      <ModalContainer>
        <ModalContent>
          {children} {/* 모달 내부에 표시할 내용 */}
        </ModalContent>
        <ButtonGroup>
          <Button onClick={onClose}>확인</Button> {/* 모달 닫기 버튼 */}
        </ButtonGroup>
      </ModalContainer>
    </Overlay>
  );
};

export default Modal;

// Styled Components (기존 CSS 변환)
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 어두운 배경 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* 모달이 다른 요소들 위에 보이도록 */
  font-family: "Noto Sans JP";
`;

const ModalContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 1000px;
  max-height: 90vh;
  overflow-y: auto;
`;

const ModalContent = styled.div`
  margin-bottom: 20px;
  &::-webkit-scrollbar {
    width: 4px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: none;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 10px 40px;
  background-color: #99393a;
  font-family: "Noto Sans KR";
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
`;
