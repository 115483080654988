import styled from "styled-components";
import { useAdminRegistrationState } from "../../../recoil/mansionManagement/adminRegistrationStateAtom";

function RegistrationInformation() {
  const { registrationDataState, setRegistrationDataState } =
    useAdminRegistrationState();

  const handleImageDrag = (event: React.DragEvent) => {
    // 이미지 드래그&드롭 시 새 창에 이미지가 뜨지 않도록 제어
    event.preventDefault();
  };

  const handleImageUpload = (
    event: React.ChangeEvent<HTMLInputElement> | React.DragEvent
  ) => {
    event.preventDefault();
    let file: File | null = null;

    if (event.type === "drop") {
      const dragEvent = event as React.DragEvent;
      file = dragEvent.dataTransfer.files[0];
    } else if (event.type === "change") {
      const changeEvent = event as React.ChangeEvent<HTMLInputElement>;
      file = changeEvent.target.files && changeEvent.target.files[0];
    }

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          setRegistrationDataState({
            ...registrationDataState,
            fileUrl: reader.result,
            thumbnailImage: file,
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const isValidDate = (year: number, month: number, day: number) => {
    const currentYear = new Date().getFullYear();
    if (year > currentYear) {
      return false;
    }

    const date = new Date(year, month - 1, day);
    return (
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
    );
  };

  const formatYearInput = (input: string) => {
    const cleaned = input.replace(/\D/g, "");
    const match = cleaned.match(/(\d{0,4})(\d{0,2})(\d{0,2})/);

    if (match) {
      let formatted = match[1];
      if (match[2]) {
        formatted += `-${match[2]}`;
      }
      if (match[3]) {
        formatted += `-${match[3]}`;
      }

      if (
        match[1].length === 4 &&
        match[2].length === 2 &&
        match[3].length === 2
      ) {
        const year = parseInt(match[1], 10);
        const month = parseInt(match[2], 10);
        const day = parseInt(match[3], 10);

        if (isValidDate(year, month, day)) {
          return formatted;
        } else {
          alert("유효한 날짜를 입력해주세요.");
          return "";
        }
      }

      return formatted;
    }
    return input;
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedYear = formatYearInput(e.target.value);
    setRegistrationDataState({
      ...registrationDataState,
      structedYear: formattedYear,
    });
  };
  const formatInput = (input: string) => {
    const cleaned = input.replace(/\D/g, ""); // 숫자가 아닌 모든 문자를 제거

    return cleaned;
  };

  return (
    <InformationWrapper>
      <InformationInputArea>
        <InformationItem>
          <InformationTitle>매물명</InformationTitle>
          <InformationInput
            placeholder="매물명"
            value={registrationDataState.name}
            onChange={(e) => {
              setRegistrationDataState({
                ...registrationDataState,
                name: e.target.value,
              });
            }}
          />
        </InformationItem>
        <InformationItem>
          <InformationTitle>관리회사명</InformationTitle>
          <InformationInput
            placeholder="관리회사명"
            value={registrationDataState.agency}
            onChange={(e) => {
              setRegistrationDataState({
                ...registrationDataState,
                agency: e.target.value,
              });
            }}
          />
        </InformationItem>
        <InformationItem>
          <InformationTitle>건축년도</InformationTitle>
          <InformationInput
            placeholder="YYYY-MM-DD"
            value={registrationDataState.structedYear}
            onChange={(e) => handleYearChange(e)}
          />
        </InformationItem>
        <InformationItem>
          <InformationTitle>층수</InformationTitle>
          <InfoInputArea>
            <InfoInput
              placeholder="건물 층수 입력"
              value={registrationDataState.totalFloors}
              onChange={(e) => {
                const format = formatInput(e.target.value);
                setRegistrationDataState({
                  ...registrationDataState,
                  totalFloors: format,
                });
              }}
            />
          </InfoInputArea>
        </InformationItem>
      </InformationInputArea>

      <ImageInputArea>
        <InformationTitle>썸네일 이미지</InformationTitle>
        {registrationDataState && registrationDataState.fileUrl ? (
          <UploadedImageView>
            <PreviewImage
              src={registrationDataState.fileUrl}
              alt="uploadedfileImage"
              onClick={() => {
                setRegistrationDataState({
                  ...registrationDataState,
                  thumbnailImage: null,
                  fileUrl: "",
                });
              }}
            />
          </UploadedImageView>
        ) : (
          <UploadImageWrapper
            onDragOver={handleImageDrag}
            onDrop={handleImageUpload}
          >
            드래그 앤 드롭으로 이미지 추가
            <ChooseImage>
              <StyledFileInput type="file" onChange={handleImageUpload} />+
              이미지 선택
            </ChooseImage>
          </UploadImageWrapper>
        )}
      </ImageInputArea>
      <NotificationArea>
        <Notification>
          <div>※ 이미지는 1장만 업로드 해주세요.</div>
          <div>※ 권장 크기는 1280px * 1280px 입니다.</div>
          <div>※ 10MB 이내의 이미지 파일을 준비해주세요.</div>
          <div>※ 대응 파일 : JPG, PNG, GIF </div>
        </Notification>
      </NotificationArea>
    </InformationWrapper>
  );
}

export default RegistrationInformation;

const InformationWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 824px;
  padding-left: 23px;
  border-bottom: 2px solid #e6e6e6;
  padding-top: 76px;
`;

const InformationInputArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

const InformationItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InformationTitle = styled.div`
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -2px;
`;

const InformationInput = styled.input`
  display: flex;
  align-items: center;
  font-size: 18px;
  width: 904px;
  height: 60px;
  background-color: #f7f8f9;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 15px;
  outline: none;
`;

const ImageInputArea = styled.div`
  margin-top: 67px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
`;

const UploadImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 904px;
  height: 204px;
  border-radius: 5px;
  background-color: #f7f8f9;
  border: 1px dotted #a7a7a7;
  font-size: 15px;
  gap: 15px;
`;

const StyledFileInput = styled.input`
  display: none;
`;

const ChooseImage = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 131px;
  height: 28px;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
`;

const UploadedImageView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 904px;
  height: 204px;
  border-radius: 5px;
  background-color: #f7f8f9;
  border: 1px solid black;
  font-size: 15px;
  gap: 15px;
  overflow: hidden;
`;

const PreviewImage = styled.img`
  width: 100%;
  cursor: pointer;
`;

const NotificationArea = styled.div`
  margin-top: 18px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-weight: 500;
`;

const Notification = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  padding-left: 24.5px;
  width: 904px;
  height: 159px;
  gap: 11px;
  font-size: 18px;
  background-color: #edeef1;
  border: 1px solid #a7a7a7;
`;

const InfoInputArea = styled.div`
  width: 904px;
`;

const InfoInput = styled.input`
  width: 190px;
  height: 60px;
  font-size: 18px;
  background-color: #f7f8f9;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 15px;
  outline: none;
`;
