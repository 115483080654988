import { useState } from "react";
import styled from "styled-components";
import useDeviceSize from "../../../hooks/useDeviceSize";
import SearchContentsHeader from "./SerachContentsHeader";
import ViewMoreButton from "./ViewMoreButton";
import { useNavigate } from "react-router-dom";

function SearchQna({
  qnaList,
  count,
}: {
  qnaList: {
    answerStatus: false;
    content: number;
    createAt: string;
    id: number;
    keywords: string[];
    title: string;
    userNickName: string;
    userProfileImage: string;
  }[];
  count: number;
}) {
  const { isMobile } = useDeviceSize();
  const [viewMoreButtonState, setViewMoreButtonState] =
    useState<boolean>(false);
  const navigate = useNavigate();
  return (
    <ContentsWrapper>
      <SearchContentsHeader
        title="Q&A"
        itemNumber={count}
      ></SearchContentsHeader>
      <ItemWrapper>
        {qnaList
          .slice(
            0,
            viewMoreButtonState
              ? qnaList.length
              : qnaList.length >= 1
              ? 1
              : qnaList.length
          )
          .map((item, index) => (
            <QnaItem key={index}>
              <ItemTop>
                {isMobile ? (
                  <ItemState></ItemState>
                ) : (
                  <ItemState>
                    {item.answerStatus ? "답변완료" : "답변대기중"}
                  </ItemState>
                )}
                <ItemTitle onClick={() => navigate(`/qna/detail/${item.id}`)}>
                  Q. {item.title}
                </ItemTitle>
              </ItemTop>
              {!isMobile && (
                <ItemContents
                  onClick={() => navigate(`/qna/detail/${item.id}`)}
                >
                  {item.content}
                </ItemContents>
              )}
              <ItemInformation>
                <UserInfo>
                  <UserIcon src={item.userProfileImage} />
                  {item.userNickName}
                </UserInfo>
                <Time>{item.createAt.split("T")[0]}</Time>
              </ItemInformation>
              <TagDiv>
                {item.keywords.map((item, index) => (
                  <Tag key={index}>
                    <HashIcon>#</HashIcon>
                    {item}
                  </Tag>
                ))}
              </TagDiv>
            </QnaItem>
          ))}
      </ItemWrapper>

      <ContentsBottom>
        {count > 1 && (
          <ViewMoreButton
            buttonState={viewMoreButtonState}
            onClickButton={() => setViewMoreButtonState(!viewMoreButtonState)}
          ></ViewMoreButton>
        )}
      </ContentsBottom>
    </ContentsWrapper>
  );
}

export default SearchQna;

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #e6e6e6;
  @media screen and (min-width: 1280px) {
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
  }
  @media screen and (max-width: 1023px) {
    border: none;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: none;
  }
  @media screen and (min-width: 1280px) {
    margin-top: 20px;
    gap: 10px;
    max-height: 862.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 15px;
    gap: 10px;
    max-height: 763.5px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 10px;
    margin-bottom: 16px;
    gap: 10px;
    max-height: 423px;
  }
`;

const QnaItem = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e6e6e6;
  overflow: hidden;
  position: relative;
  @media screen and (min-width: 1280px) {
    padding: 20px 0;
    min-height: 247.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 15px 0;
    min-height: 224.5px;
  }
  @media screen and (max-width: 1023px) {
    padding: 10px 0;
    max-height: 121px;
  }
`;

const ItemTop = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
`;

const ItemState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #99393a;
  color: #ffffff;
  font-weight: bold;
  @media screen and (min-width: 1280px) {
    border-radius: 17.15px;
    width: 110px;
    height: 34px;
    font-size: 19px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    border-radius: 17.15px;
    width: 110px;
    height: 34px;
  }
  @media screen and (max-width: 1023px) {
    border-radius: 10px;
    width: 20px;
    height: 20px;
  }
`;

const ItemTitle = styled.div`
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (min-width: 1280px) {
    font-size: 23px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
  }
`;

const ItemContents = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (min-width: 1280px) {
    font-size: 19px;
    margin-top: 21px;
    line-height: 26px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 15px;
    margin-top: 24px;
    line-height: 24px;
  }
`;

const ItemInformation = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  @media screen and (min-width: 1280px) {
    font-size: 16px;
    margin-top: 29px;
    gap: 26px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 15px;
    margin-top: 22px;
    gap: 32px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 12px;
    margin-top: 14px;
    gap: 12px;
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  max-width: 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (min-width: 1280px) {
    gap: 6px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 6px;
  }
  @media screen and (max-width: 1023px) {
    gap: 7px;
  }
`;

const UserIcon = styled.img`
  border-radius: 50px;
  @media screen and (min-width: 1280px) {
    width: 25px;
    height: 25px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 25px;
    height: 25px;
  }
  @media screen and (max-width: 1023px) {
    width: 15px;
    height: 15px;
  }
`;

const Time = styled.div``;

const TagDiv = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  @media screen and (min-width: 1280px) {
    margin-top: 23px;
    gap: 10px;
    margin-bottom: 15px;
    max-height: 78px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 19px;
    gap: 9px;
    margin-bottom: 15px;
    max-height: 70px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 20px;
    gap: 7px;
    margin-bottom: 10px;
    max-height: 49px;
  }
`;

const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555555;
  border: 1px solid #cccccc;
  @media screen and (min-width: 1280px) {
    padding: 0 10px;
    height: 33.5px;
    border-radius: 17px;
    font-size: 16px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 0 10px;
    height: 30px;
    border-radius: 15px;
    font-size: 15px;
  }
  @media screen and (max-width: 1023px) {
    padding: 0 7px;
    height: 21px;
    border-radius: 10px;
    font-size: 12px;
  }
`;

const HashIcon = styled.div`
  color: #999999;
`;

const ContentsBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 1280px) {
    margin-top: 69px;
    margin-bottom: 81px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 39px;
    margin-bottom: 65px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 18px;
    margin-bottom: 35px;
  }
`;
