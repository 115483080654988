import styled from "styled-components";

function Title({ children }: { children: string }) {
  return <TitleWrapper>{children}</TitleWrapper>;
}

export default Title;

const TitleWrapper = styled.div`
  color: #333333;
  @media screen and (min-width: 1280px) {
    font-size: 26px;
    font-weight: bold;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
    font-weight: bold;
  }
  @media screen and (max-width: 1023px) {
    font-size: 22px;
    font-weight: bold;
  }
`;
