import React from "react";
import styled from "styled-components";
import x from "../../../assets/images/mainPage/x-icon.png"
// Media query variables
const mediaQueries = {
  tablet: "@media screen and (max-width: 1279px) and (min-width: 1024px)",
  mobile: "@media screen and (max-width: 1023px)",
};

// Styled components
const Home = styled.div`
  display: flex;
  flex-direction: column;
  margin: 70px 0;
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 뒷배경 어둡게 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  position: relative; /* 자식 요소의 절대 위치를 기준으로 설정 */
`;

const CloseButton = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  width: 18px;
  height: 18px;

  ${mediaQueries.mobile} {
    width: 16px;
    height: 16px;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
`;

const ModalContent = styled.div`
  font-weight: 400;
  font-size: 23px;
  margin: 70px 270px;

  ${mediaQueries.tablet} {
    font-size: 23px;
  }
  ${mediaQueries.mobile} {
    font-size: 18px;
    margin: 55px 95px;
  }
`;

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <ModalBackground>
      <ModalContainer>
        <ModalHeader>
          <CloseButton src={x} alt="닫기" onClick={onClose} />
        </ModalHeader>
        <ModalContent>클립보드에 복사되었습니다</ModalContent>
      </ModalContainer>
    </ModalBackground>
  );
};

export default Modal;
