import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import ResponsiveWrapper from "../../../components/ResponsiveWrapper";

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 48px 0px;

  font-size: 38px;
  font-weight: bold;
  margin-left: 8px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 34px;
    padding: 51px 0px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 20px;
    padding: 32px 0px;
  }
`;

const Tabs = styled.div`
  display: flex;
`;

interface TabProps {
  active?: boolean;
}
// const Tab = styled(Link)<TabProps>`

//   text-decoration: none;
//   font-weight: bold;
//   justify-content: center;
//   padding: 18px 24px;
//   cursor: pointer;
//   font-size: 23px;

//   @media screen and (max-width: 1279px) and (min-width: 1024px) {
//     padding: 18px 24px;

//     font-size: 22px;
//   }
//   @media screen and (max-width: 1023px) {
//     font-size: 18px;
//     padding: 18px 24px;
//   }
//   ${(props) =>
//     props.active &&
//     css`
//       border-bottom: 3px solid #99393a;
//     `}
//   color: ${(props) => (props.active ? "#99393a" : "#000")};
// `;

const Tab = styled(Link)<TabProps>`
  text-decoration: none;
  font-weight: bold;
  justify-content: center;
  padding: 18px 8px;
  margin-right: 48px;
  cursor: pointer;
  font-size: 23px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 18px 8px;
    margin-right: 52px;
    font-size: 22px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 18px;
    margin-right: 26px;
    padding: 18px 0px;
  }

  ${(props) =>
    props.active &&
    css`
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -3px; /* 아래로 2px 떨어뜨림 */
        width: 100%;
        height: 3px;
        background-color: #99393a;
      }
    `}

  color: ${(props) => (props.active ? "#99393a" : "#000")};
`;

interface TABProps {
  tab: number;
  setTab: (index: number) => void;
}

const Line = styled.div`
  width: 100%;
  height: 1px;
`;

const TAB: React.FC<TABProps> = ({ tab, setTab }) => {
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname === "/mypage/home") setTab(0);
    else if (location.pathname.startsWith("/mypage/scrab")) setTab(1);
    else if (location.pathname === "/mypage/shopping") setTab(2);
  }, [location.pathname, setTab]);

  return (
    <>
      <ResponsiveWrapper>
        <Header>마이페이지</Header>
        <Tabs>
          <Tab to="/mypage/home" active={tab === 0} onClick={() => setTab(0)}>
            홈 화면
          </Tab>
          <Tab
            to="/mypage/scrab/all"
            active={tab === 1}
            onClick={() => setTab(1)}
          >
            스크랩북
          </Tab>
          {/* <Tab
            to="/mypage/shopping"
            active={tab === 2}
            onClick={() => setTab(2)}
          >
            나의 쇼핑
          </Tab> */}
        </Tabs>
      </ResponsiveWrapper>
      <Line />
    </>
  );
};

export default TAB;
