import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";

export type recentSearchDataType = string[];

const { persistAtom } = recoilPersist({
  key: "recentSearchSessionStorage",
  storage: sessionStorage,
});

export const recentSearchStateAtom = atom<recentSearchDataType>({
  key: "recentSearchState",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const useRecentSearchDataState = () => {
  const [recentSearchDataState, setRecentSearchDataState] = useRecoilState<
    string[]
  >(recentSearchStateAtom);

  const updateRecentSearchData = (newState: string) => {
    if (recentSearchDataState.includes(newState)) return;
    if (recentSearchDataState.length < 5) {
      setRecentSearchDataState([...recentSearchDataState, newState]);
    } else {
      setRecentSearchDataState([...recentSearchDataState.slice(1), newState]);
    }
  };

  const removeSearchData = (index: number) => {
    setRecentSearchDataState(
      recentSearchDataState.filter((_, i) => i !== index)
    );
  };

  return {
    recentSearchDataState,
    updateRecentSearchData,
    setRecentSearchDataState,
    removeSearchData,
  };
};
