import { useState } from "react";
import styled from "styled-components";
import SearchMainItem from "./SearchMainItem"; 
import useDeviceSize from "../../../hooks/useDeviceSize";
import SearchContentsHeader from "./SerachContentsHeader";
import ViewMoreButton from "./ViewMoreButton";
import { reviewDataType } from "../../mainPage/Main";

function SearchMansionReviews({
  reviewList,
  count,
}: {
  reviewList: reviewDataType[];
  count: number;
}) {
  const { isPC, isLaptop, isMobile } = useDeviceSize();
  const [viewMoreButtonState, setViewMoreButtonState] =
    useState<boolean>(false);

  return (
    <ContentsWrapper>
      <SearchContentsHeader
        title="입주후기"
        itemNumber={count}
      ></SearchContentsHeader>
      <ItemWrapper>
        {isPC &&
          reviewList
            .slice(
              0,
              viewMoreButtonState
                ? reviewList.length
                : reviewList.length >= 3
                ? 3
                : reviewList.length
            )
            .map((item, index) => (
              <SearchMainItem
                key={index}
                width={361.5}
                height={242}
                category="review"
                reviewInfo={item}
                itemTitleMargin={16}
              ></SearchMainItem>
            ))}
        {isLaptop &&
          reviewList
            .slice(
              0,
              viewMoreButtonState
                ? reviewList.length
                : reviewList.length >= 3
                ? 3
                : reviewList.length
            )
            .map((item, index) => (
              <SearchMainItem
                key={index}
                width={295}
                height={196}
                category="review"
                reviewInfo={item}
                itemTitleMargin={11}
              ></SearchMainItem>
            ))}
        {isMobile &&
          reviewList
            .slice(
              0,
              viewMoreButtonState
                ? reviewList.length
                : reviewList.length >= 2
                ? 2
                : reviewList.length
            )
            .map((item, index) => (
              <SearchMainItem
                key={index}
                width={185}
                height={137}
                category="review"
                reviewInfo={item}
                itemTitleMargin={9}
              ></SearchMainItem>
            ))}
      </ItemWrapper>
      <ContentsBottom>
        {((isMobile && count > 2) ||
          (isLaptop && count > 3) ||
          (isPC && count > 3)) && (
          <ViewMoreButton
            buttonState={viewMoreButtonState}
            onClickButton={() => setViewMoreButtonState(!viewMoreButtonState)}
          ></ViewMoreButton>
        )}
      </ContentsBottom>
    </ContentsWrapper>
  );
}

export default SearchMansionReviews;

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #e6e6e6;
  @media screen and (min-width: 1280px) {
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
  }
  @media screen and (max-width: 1023px) {
    border: none;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  margin-top: 21px;
  flex-wrap: wrap;
  overflow-y: auto;
  @media screen and (min-width: 1280px) {
    column-gap: 23px;
    row-gap: 21px;
    max-height: 670px;
    min-height: 278px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    column-gap: 20px;
    row-gap: 24px;
    max-height: 733px;
    min-height: 227px;
  }
  @media screen and (max-width: 1023px) {
    column-gap: 7px;
    row-gap: 19px;
    max-height: 525px;
    min-height: 166px;
  }
  &::-webkit-scrollbar {
    width: 4px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: none;
  }
`;

const ContentsBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 1280px) {
    margin-top: 69px;
    margin-bottom: 81px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 39px;
    margin-bottom: 65px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 18px;
    margin-bottom: 35px;
  }
`;
