import { useNavigate } from 'react-router-dom';
import { WebComponents,MobileComponents, Box, Text, IconWrap, FilterBtn, TopWrap, } from './Map.style';
import settings from '../../../assets/images/mapPage/settings.png';
import settingsGray from '../../../assets/images/mypage/settings.png'
import exit from  '../../../assets/images/mapPage/exitIcon.png'
import { useState } from 'react';
import usePropertyState from '../../../hooks/usePropertyState';

interface props {
  setShowFilterList: React.Dispatch<React.SetStateAction<boolean>>
  showFilterList: boolean
}

export default function MapTop({setShowFilterList, showFilterList}: props) {
  const navigate = useNavigate();
  const {pagination } = usePropertyState();
  return (
    <TopWrap>
      <WebComponents>
        <Text 
          onClick={() => navigate("/mansion", { state: pagination.detailCategoryId })}
          $color='#999999'
          $fontSize='15px'
          $fontWeight='600'
          $margin='0 4px 0 0'
          $cursor='pointer'
        >
          닫기 X
        </Text>
        <Box $alignItems='center' $justifyContent='center'>
          <FilterBtn 
            $show={!showFilterList}
            onClick={()=>setShowFilterList(prev=>!prev)}>
            조건
            <IconWrap 
              $width='16px'
              $height='16px'
              $padding='2px 0 0 2px'
              src={settings}
              alt='exit button'/>
          </FilterBtn>
        </Box>
      </WebComponents>
      <MobileComponents>
        <Box
          $width='100%'
          $height='60px'
          $padding='20px 20px'
          $justifyContent='space-between'
          $alignItems='center'>
          <Box $alignItems='center'>
            <Text 
              $margin='0 4px 0 0'
              $fontSize='20px'
              $fontWeight='bold'>
              지도로 보기
            </Text>
            <IconWrap 
              onClick={()=>setShowFilterList(prev=>!prev)}
              $width='16px' 
              $height='18px'
              src={settingsGray}
              $rotate={true}
              $padding='0 2px 0 0'
              $pointer={true}
              alt='filter'/>
          </Box>
          <IconWrap 
            onClick={() => navigate("/mansion", { state: pagination.detailCategoryId })}
            width='16px'
            height='16px'
            src={exit}
            alt='exit button'/>
        </Box>
      </MobileComponents>
    </TopWrap>
  )
}




