import { create } from 'zustand';

type DataType = {
  id: number;
  name: string;
};

interface Prop {
  detailChoiceOpen: boolean;
  detailData: DataType[] | null;
  open: () => void;
  close: () => void;
  setDetailData: (data: DataType[]) => void;
}

const useDetailChoiceOpenModalState = create<Prop>((set) => ({
  detailChoiceOpen: false,
  detailData: null,
  open: () => set(() => ({ detailChoiceOpen: true })),
  close: () => set(() => ({ detailChoiceOpen: false, detailData: null })),
  setDetailData: (data: DataType[]) => set(() => ({ detailData: data })),
}));

export default useDetailChoiceOpenModalState;
