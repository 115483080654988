// import styled from "styled-components";
import CloneHouberMansions from "./CloneHouberMansions";
import CloneCategories from "./CloneCategories";
import CloneMainBanner from "./CloneMainBanner";
import ClonePopularMansions from "./ClonePopularMansions";
import CloneMansionReviews from "./CloneMansionReviews";
import axios from "axios";
import { useEffect, useState } from "react";
import styled from "styled-components";
import CloneHeader from "./CloneHeader";

// 메인 페이지
function CloneMain({ admin }: { admin?: boolean }) {
  return (
    <MainWrapper>
      <CloneHeader />
      <CloneMainBanner />
      <ClonePopularMansions />
      <CloneCategories />
      <CloneHouberMansions />
      <CloneMansionReviews />
    </MainWrapper>
  );
}

export default CloneMain;

const MainWrapper = styled.div`
  @media screen and (min-width: 1280px) {
    background-color: white;
    border-radius: 5px 5px 0 0;
  }
`;
