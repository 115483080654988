import { atom } from "recoil";

export type selectedOptionType =
  | "freeInternet"
  | "morePeople"
  | "duplexMention"
  | "induction"
  | "allElectricMansion"
  | "indoorWashingBoard"
  | "bidet"
  | "unitBath"
  | "airConditioning"
  | "trashDisposal24H"
  | "intercom"
  | "carParking"
  | "bicycleParking"
  | "womenOnly"
  | "autoLock"
  | "foreignerAllowed"
  | "furnitureOptions"
  | "petsAllowed"
  | "designerMention"
  | "closeToStation"
  | "gasRange"
  | "cityGas"
  | "outdoorWashingBoard"
  | "standAloneToilet"
  | "bathroomDryer"
  | "storageSpace"
  | "garbageStorage"
  | "deliveryBox"
  | "elevator"
  | "bikeParking"
  | "mensOnly"
  | "cctv"
  | "overseasScreeningAvailable";

export type categoryIconDataType = {
  id?: number;
  fileUrl?: string;
  selectedOption?: selectedOptionType;
  name: string;
  file?: File;
};

export const categoryIconStateAtom = atom<categoryIconDataType[]>({
  key: "categoryIconState",
  default: [
    { id: 0, fileUrl: "", selectedOption: undefined, name: "", file: undefined },
  ],
});
