import { atom, useRecoilState } from "recoil";
import { mainMansionDataType, reviewDataType } from "../../pages/mainPage/Main";
import { selectedOptionType } from "../adminMainDesign/categoryIconStateAtom";

export type mainPageDataType = {
  bestList: mainMansionDataType[];
  bestBrooklynMansions: mainMansionDataType[];
  latestReviews: reviewDataType[];
  mainImageResponse: {
    iconResponseList: {
      fileUrl: string;
      id: number;
      name: string;
      selectedOption: selectedOptionType;
    }[];
    logoResponse: {
      fileUrl: string;
      id: number;
    };
    mainBannerList: {
      fileUrl: string;
      id: number;
      redirectUrl: string;
    }[];
    subBanner: {
      fileUrl: string;
      id: number;
      redirectUrl: string;
    };
  };
};

export const mainPageDataStateAtom = atom<mainPageDataType>({
  key: "mainPageDataState",
  default: {
    bestList: [],
    bestBrooklynMansions: [],
    latestReviews: [],
    mainImageResponse: {
      iconResponseList: [],
      logoResponse: {
        fileUrl: "",
        id: 0,
      },
      mainBannerList: [],
      subBanner: {
        fileUrl: "",
        id: 0,
        redirectUrl: "",
      },
    },
  },
});

export const useMainPageData = () => {
  const [mainPageDataState, setMainPageDataState] =
    useRecoilState<mainPageDataType>(mainPageDataStateAtom);

  return { mainPageDataState, setMainPageDataState };
};
