import styled from "styled-components";
import { useAdminRegistrationRoomState } from "../../../recoil/mansionManagement/adminRegistrationRoomStateAtom";
import addIcon from "../../../assets/images/mansionManagement/add.png";
import deleteIcon from "../../../assets/images/mansionManagement/delete.png";
import { Fragment } from "react/jsx-runtime";

function RoomRegistrationInitialCost({ roomIndex }: { roomIndex: number }) {
  const { adminRegistrationRoomState, handleUpdateRoom } =
    useAdminRegistrationRoomState();

  const roomInfo = adminRegistrationRoomState[roomIndex];

  const formatInput = (input: string) => {
    const cleaned = input.replace(/\D/g, ""); // 숫자가 아닌 모든 문자를 제거

    return cleaned;
  };

  const handleAddInitialCost = () => {
    handleUpdateRoom(roomIndex, {
      ...adminRegistrationRoomState[roomIndex],
      moreInitialCost: [...roomInfo.moreInitialCost, { key: "", value: "" }],
    });
  };

  const deleteMoreInitialCost = (index: number) => {
    const updatedMoreInfo = roomInfo.moreInitialCost.filter(
      (_, i) => i !== index
    );
    handleUpdateRoom(roomIndex, {
      ...adminRegistrationRoomState[roomIndex],
      moreInitialCost: updatedMoreInfo,
    });
  };

  const handleMoreInitialCostChange = (
    index: number,
    field: "key" | "value",
    value: string
  ) => {
    const updatedMoreInitialCost = roomInfo.moreInitialCost.map((info, i) =>
      i === index ? { ...info, [field]: value } : info
    );
    handleUpdateRoom(roomIndex, {
      ...adminRegistrationRoomState[roomIndex],
      moreInitialCost: updatedMoreInitialCost,
    });
  };

  const sumOfMoreInitialCost = () => {
    let sum = 0;
    roomInfo.moreInitialCost.map((item, index) => {
      sum += Number(item.value);
    });
    return sum;
  };

  const formatNumberWithCommas = (input: string) => {
    return input.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const removeCommas = (input: string) => {
    return input.replace(/,/g, "");
  };

  return (
    <InfoWrapper>
      <InfoTitle>
        초기비용{" "}
        <AddItemButton
          onClick={handleAddInitialCost}
          src={addIcon}
        ></AddItemButton>
      </InfoTitle>
      <InfoItemWrapper>
        <InfoWrapperLeft>
          <InfoItem>
            시키킹
            <InfoInput
              value={formatNumberWithCommas(roomInfo.deposit)}
              onChange={(e) => {
                const format = formatInput(removeCommas(e.target.value));

                handleUpdateRoom(roomIndex, {
                  ...adminRegistrationRoomState[roomIndex],
                  deposit: format,
                });
              }}
            ></InfoInput>
          </InfoItem>
          <InfoItem>
            열쇠교환비
            <InfoInput
              value={formatNumberWithCommas(roomInfo.keyExchangeFee)}
              onChange={(e) => {
                const format = formatInput(removeCommas(e.target.value));

                handleUpdateRoom(roomIndex, {
                  ...adminRegistrationRoomState[roomIndex],
                  keyExchangeFee: format,
                });
              }}
            ></InfoInput>
          </InfoItem>
          <InfoItem>
            보증심사료
            <InfoInput
              value={formatNumberWithCommas(roomInfo.guaranteeReviewFee)}
              onChange={(e) => {
                const format = formatInput(removeCommas(e.target.value));

                handleUpdateRoom(roomIndex, {
                  ...adminRegistrationRoomState[roomIndex],
                  guaranteeReviewFee: format,
                });
              }}
            ></InfoInput>
          </InfoItem>
          {roomInfo.moreInitialCost.map((item, index) => (
            <Fragment key={index}>
              {index % 2 === 0 && (
                <MoreInitialCostWrapper>
                  <MoreInitialCostNameInput
                    value={item.key}
                    onChange={(e) =>
                      handleMoreInitialCostChange(index, "key", e.target.value)
                    }
                  />
                  <MoreInitialCostValueInput
                    value={formatNumberWithCommas(item.value)}
                    onChange={(e) => {
                      const format = formatInput(removeCommas(e.target.value));
                      handleMoreInitialCostChange(index, "value", format);
                    }}
                  />
                  <DeleteMoreInitialCostButton
                    src={deleteIcon}
                    onClick={() => deleteMoreInitialCost(index)}
                  ></DeleteMoreInitialCostButton>
                </MoreInitialCostWrapper>
              )}
            </Fragment>
          ))}
          <InfoItem>
            합계
            <InfoBox>
              {(
                Number(roomInfo.deposit) +
                Number(roomInfo.gratuity) +
                Number(roomInfo.keyExchangeFee) +
                Number(roomInfo.fireInsuranceCost) +
                Number(roomInfo.guaranteeReviewFee) +
                Number(roomInfo.brokerageFee) +
                sumOfMoreInitialCost()
              ).toLocaleString()}
            </InfoBox>
          </InfoItem>
        </InfoWrapperLeft>
        <InfoWrapperRight>
          <InfoItem>
            레이킹
            <InfoInput
              value={formatNumberWithCommas(roomInfo.gratuity)}
              onChange={(e) => {
                const format = formatInput(removeCommas(e.target.value));

                handleUpdateRoom(roomIndex, {
                  ...adminRegistrationRoomState[roomIndex],
                  gratuity: format,
                });
              }}
            ></InfoInput>
          </InfoItem>
          <InfoItem>
            화재보험비
            <InfoInput
              value={formatNumberWithCommas(roomInfo.fireInsuranceCost)}
              onChange={(e) => {
                const format = formatInput(removeCommas(e.target.value));

                handleUpdateRoom(roomIndex, {
                  ...adminRegistrationRoomState[roomIndex],
                  fireInsuranceCost: format,
                });
              }}
            ></InfoInput>
          </InfoItem>
          <InfoItem>
            중개수수료
            <InfoInput
              value={formatNumberWithCommas(roomInfo.brokerageFee)}
              onChange={(e) => {
                const format = formatInput(removeCommas(e.target.value));

                handleUpdateRoom(roomIndex, {
                  ...adminRegistrationRoomState[roomIndex],
                  brokerageFee: format,
                });
              }}
            ></InfoInput>
          </InfoItem>
          {roomInfo.moreInitialCost.map((item, index) => (
            <Fragment key={index}>
              {index % 2 === 1 && (
                <MoreInitialCostWrapper>
                  <MoreInitialCostNameInput
                    value={item.key}
                    onChange={(e) =>
                      handleMoreInitialCostChange(index, "key", e.target.value)
                    }
                  />
                  <MoreInitialCostValueInput
                    value={formatNumberWithCommas(item.value)}
                    onChange={(e) => {
                      const format = formatInput(removeCommas(e.target.value));
                      handleMoreInitialCostChange(index, "value", format);
                    }}
                  />
                  <DeleteMoreInitialCostButton
                    src={deleteIcon}
                    onClick={() => deleteMoreInitialCost(index)}
                  ></DeleteMoreInitialCostButton>
                </MoreInitialCostWrapper>
              )}
            </Fragment>
          ))}
        </InfoWrapperRight>
      </InfoItemWrapper>
    </InfoWrapper>
  );
}

export default RoomRegistrationInitialCost;

const InfoWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 23px;
  padding: 84px 0;
  gap: 179.5px;
`;

const InfoTitle = styled.div`
  display: flex;
  font-size: 25px;
  font-weight: bold;
  margin-top: 8px;
  letter-spacing: -2px;
  gap: 10.5px;
  font-weight: 600;
  padding-left: 23px;
`;

const AddItemButton = styled.img`
  width: 27px;
  height: 27px;
  cursor: pointer;
`;

const InfoItemWrapper = styled.div`
  display: flex;
  gap: 30px;
  width: 904px;
`;

const InfoWrapperLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 33px;
  height: 100%;
  padding-right: 50px;
`;
const InfoWrapperRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 33px;
  height: 100%;
  padding-right: 50px;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 313.5px;
  font-size: 20px;
  font-weight: 500;
`;

const InfoInput = styled.input`
  width: 190px;
  height: 60px;
  font-size: 18px;
  background-color: #f7f8f9;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 15px;
  outline: none;
  font-family: "Noto Sans KR";
  font-weight: 500;
`;

const InfoBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 190px;
  height: 60px;
  font-size: 18px;
  background-color: #f7f8f9;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 15px;
`;

const MoreInitialCostWrapper = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  width: 313.5px;
  font-size: 20px;
  gap: 20px;
`;

const MoreInitialCostNameInput = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  font-size: 20px;
  font-family: "Noto Sans KR";
  font-weight: 500;
`;
const MoreInitialCostValueInput = styled.input`
  display: flex;
  align-items: center;
  width: 190px;
  height: 60px;
  font-size: 18px;
  background-color: #f7f8f9;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 15px;
  font-family: "Noto Sans KR";
  font-weight: 500;
`;

const DeleteMoreInitialCostButton = styled.img`
  width: 27px;
  height: 27px;
  position: absolute;
  right: -50px;
  cursor: pointer;
`;
