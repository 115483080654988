import styled from "styled-components";
import MobileSearchHeader from "./components/MobileSearchHeader";
import MobileSearchArea from "./components/MobileSearchArea";

function MobileSearch() {
  return (
    <Wrapper>
      <MobileSearchContentsWrapper>
        <MobileSearchHeader />
        <MobileSearchArea />
      </MobileSearchContentsWrapper>
    </Wrapper>
  );
}

export default MobileSearch;

const Wrapper = styled.header`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  z-index: 100000;
`;

const MobileSearchContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 706px;
  background-color: white;
`;
