import React, { useState } from 'react'
import { IconWrap, MylocationWrap } from './Map.style'
import onMyLocation from '../../../assets/images/mapPage/onMyLocation.png'
import offMyLocation from '../../../assets/images/mapPage/offMyLocation.png'
import { locationI } from '../Map'
import ToastMessage from './ToastMessage'


interface props {
  onLocationFound: React.Dispatch<React.SetStateAction<locationI>> // 위치 정보 콜백
  pinDetail: number
}

export default function MyLocation({ onLocationFound,pinDetail }: props) {
  const [onLocation, setOnLocation] = useState<boolean>(false);
  const [notFindLocation, setNotFindLocation] = useState<boolean>(false);
  
  //accuracy

  const handleClick = () => {
    if(!onLocation){
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            
            if(position.coords.accuracy>=1000){
              setNotFindLocation(true)
              setTimeout(()=>setNotFindLocation(false), 5000)
              setOnLocation(false);
            }else{
              const { latitude, longitude } = position.coords;
              onLocationFound({ lat: latitude, lng: longitude });
              setOnLocation(true);
            }
          },
          (error) => {
            switch (error.code) {
              case error.PERMISSION_DENIED:
                alert('사용자가 위치 정보 제공을 거부했습니다.');
                break;
              case error.POSITION_UNAVAILABLE:
                alert('위치 정보를 사용할 수 없습니다.');
                break;
              case error.TIMEOUT:
                alert('위치 정보 요청이 시간 초과되었습니다.');
                break;
              default:
                alert('알 수 없는 오류가 발생했습니다.');
                break;
            }
            setOnLocation(false);
          },
        {
          enableHighAccuracy: true,
        })
      } else {
        alert('이 브라우저는 위치 정보를 지원하지 않습니다.');
        setOnLocation(false);
      }
    }else{
      onLocationFound({ lat: 0, lng: 0 });
      setOnLocation(false);
    }
    
  };

  return (
    <MylocationWrap $mobilePinShow={pinDetail !== -1} onClick={handleClick}>
      {onLocation && <IconWrap src={onMyLocation} $height='28px' $width='28px'/>}
      {onLocation && <ToastMessage messageString='실제 위치와 반경 1km 정도 차이날 수 있습니다.' key={'toast'} timer={5000}/>}
      {notFindLocation && <ToastMessage messageString='내 정확한 위치를 찾을 수 없습니다.' key={'toast'} timer={5000}/>}
      {!onLocation && <IconWrap src={offMyLocation} $height='28px' $width='28px'/>}
    </MylocationWrap>
  )
}
