import styled from "styled-components";
import ResponsiveWrapper from "../../ResponsiveWrapper";
import loginIcon from "../../../assets/images/mainPage/sign-in.png";
import { useNavigate } from "react-router-dom";
import SidebarNavigationItem from "./SidebarNavigationItem";

function SidebarNavigation() {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <ResponsiveWrapper>
        <NavigationWrapper style={{ borderBottom: "1px solid #C9C9C9" }}>
          <SidebarNavigationItem categoryIdList={[1, 2, 3, 4]}>
            임대
          </SidebarNavigationItem>
          <SidebarNavigationItem categoryIdList={[5, 6, 7, 8, 9]}>
            매매
          </SidebarNavigationItem>
          <SidebarNavigationItem categoryIdList={[10, 11, 12, 13]}>
            점포
          </SidebarNavigationItem>
          <SidebarNavigationItem categoryIdList={[14]}>
            하우버 맨션
          </SidebarNavigationItem>
        </NavigationWrapper>
        <NavigationWrapper>
          <SidebarNavigationItem path={"/residentreview"}>
            입주후기
          </SidebarNavigationItem>
          <SidebarNavigationItem path={"/qna/home/"}>
            Q & A
          </SidebarNavigationItem>
        </NavigationWrapper>
      </ResponsiveWrapper>
    </Wrapper>
  );
}

export default SidebarNavigation;

const Wrapper = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  width: 100%;
  background-color: white;
`;

const NavigationWrapper = styled.div`
  display: flex;
  padding: 54px 0;
  flex-direction: column;
`;
