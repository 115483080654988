import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import searchblack from "../../../assets/images/header/search-black.png";
import scrapgray from "../../../assets/images/header/scrap-gray.png";
import userIcon from "../../../assets/images/header/usericon.png";
import userIconGuest from "../../../assets/images/header/usericon-guest.png";
import counseling from "../../../assets/images/header/counseling.png";
import { useSetRecoilState } from "recoil";
import { mainLogoStateAtom } from "../../../recoil/mainPge/mainLogoStateAtom";
import useDeviceSize from "../../../hooks/useDeviceSize";
import { useSidebarState } from "../../../recoil/mainPge/sidebarStateAtom";
import { useMobileSearchState } from "../../../recoil/mainPge/mobileSearchStateAtom";
import axiosInstance from "../../../services/axiosConfig";
import React, { useEffect, useState } from "react";
import { useDebounceSearchvValue } from "../../../hooks/useDebounceSearchValue";
import { useRecentSearchDataState } from "../../../recoil/mainPge/recentSearchStateAtom";
import { logoStateAtom } from "../../../recoil/adminMainDesign/logoStateAtom";

function HeaderContents() {
  const navigate = useNavigate();
  const { isMobile } = useDeviceSize();
  const { modifySidebarState, setSidebarState } = useSidebarState();
  const { modifyMobileSearchState } = useMobileSearchState();
  const { updateRecentSearchData } = useRecentSearchDataState();
  const [logoState, setLogoState] = useState<string>("");
  const setRecoilLogoState = useSetRecoilState(logoStateAtom);
  const [userImageState, setUserImageState] = useState<string>("");
  const [dropdownState, setDropdownState] = useState<boolean>(false);
  const [searchInputState, setSearchInputState] = useState<string>("");
  const debouncedInputValue = useDebounceSearchvValue(searchInputState, 500);
  const [recommendListState, setRecommendListState] = useState<
    {
      id: number;
      keyword: string;
    }[]
  >([]);

  const fetchUserImage = async () => {
    try {
      const response: { data: string } = await axiosInstance.get(
        "https://houberapp.com/normal/home/userImg"
      );

      setUserImageState(response.data && response.data);
    } catch (error) {}
  };
  const fetchLogoImage = async () => {
    try {
      const response: { data: string } = await axiosInstance.get(
        "https://houberapp.com/normal/home/logo"
      );

      setLogoState(response.data && response.data);
      setRecoilLogoState({ id: 0, fileUrl: response.data && response.data });
    } catch (error) {}
  };
  useEffect(() => {
    fetchUserImage();
    fetchLogoImage();
  }, []);

  const getImage = () => {
    if (window.sessionStorage.getItem("Authorization")) {
      if (userImageState === "") {
        return userIcon;
      } else {
        return userImageState;
      }
    } else {
      return userIconGuest;
    }
  };
  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0) {
      setSearchInputState("");
      setRecommendListState([]);
    }
    setSearchInputState(e.target.value);
  };

  const fetchRecommendKeyword = async (keyword: string) => {
    const response: { data: { id: number; keyword: string }[] } =
      await axiosInstance.get("normal/home/search/keyword", {
        params: { keyword: keyword },
      });
    setRecommendListState(response.data);
  };

  useEffect(() => {
    if (debouncedInputValue && debouncedInputValue.length > 1) {
      fetchRecommendKeyword(debouncedInputValue);
    }
  }, [debouncedInputValue]);

  return (
    <HeaderContentsWrapper>
      <HeaderContentsInner>
        <ContentsLeft>
          <HamburgerButton onClick={modifySidebarState}>
            <ButtonHorizon></ButtonHorizon>
          </HamburgerButton>
          {logoState && logoState.length > 0 ? (
            <Logo
              onClick={() => {
                navigate("/");
                setSidebarState(false);
              }}
            >
              <StyledImage src={logoState}></StyledImage>
            </Logo>
          ) : (
            <Logo
              onClick={() => {
                navigate("/");
                setSidebarState(false);
              }}
            >
              LOGO
            </Logo>
          )}
        </ContentsLeft>
        <ContentsRight>
          {isMobile ? (
            <>
              <SearchIcon
                src={searchblack}
                onClick={() => {
                  setSidebarState(false);
                  modifyMobileSearchState();
                }}
              />
              <IconButton
                src={counseling}
                onClick={() => navigate("/counseling")}
              />
            </>
          ) : (
            <>
              <SearchDiv>
                <SearchIcon
                  src={searchblack}
                  onClick={() => {
                    if (searchInputState.length > 0) {
                      navigate(`/search?keyword=${searchInputState}`, {
                        state: { keyword: searchInputState },
                      });
                      setRecommendListState([]);
                      updateRecentSearchData(searchInputState);
                    }
                  }}
                />
                <Horizon />
                <SearchInput
                  placeholder="통합검색"
                  value={searchInputState}
                  onChange={(e) => handleOnChangeInput(e)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (searchInputState.length > 1) {
                        navigate(`/search?keyword=${searchInputState}`, {
                          state: { keyword: searchInputState },
                        });
                        setRecommendListState([]);
                        updateRecentSearchData(searchInputState);
                      } else {
                        alert("검색어를 2글자 이상 입력해주세요");
                      }
                    }
                  }}
                ></SearchInput>
                {recommendListState.length > 0 && (
                  <RecommendSearchArea>
                    {recommendListState.map((item, index) => (
                      <RecommendSearchItem
                        key={index}
                        onClick={() => {
                          navigate(`/search?keyword=${item.keyword}`, {
                            state: { id: item.id },
                          });
                          setSearchInputState("");
                          setRecommendListState([]);
                          updateRecentSearchData(item.keyword);
                        }}
                      >
                        <RecommandedSearchIcon src={searchblack} />
                        {item.keyword}
                      </RecommendSearchItem>
                    ))}
                  </RecommendSearchArea>
                )}
              </SearchDiv>
              <ButtonDiv>
                <IconButton
                  src={scrapgray}
                  onClick={() => {
                    if (window.sessionStorage.getItem("Authorization")) {
                      navigate("/mypage/scrab/all");
                    } else {
                      alert("로그인 후 이용할 수 있습니다.");
                      navigate("/login");
                    }
                  }}
                />
                <IconButtonWrapper
                  onMouseOver={() => setDropdownState(true)}
                  onMouseOut={() => setDropdownState(false)}
                >
                  <IconButton
                    style={{ borderRadius: 25 }}
                    src={getImage()}
                    onClick={() => {
                      if (!window.sessionStorage.getItem("Authorization")) {
                        alert("로그인 후 이용할 수 있습니다.");
                        navigate("/login");
                      }
                    }}
                  />
                  {window.sessionStorage.getItem("Authorization") &&
                    dropdownState && (
                      <HeaderDropdown>
                        <HeaderDropdownItem
                          onClick={() => navigate("/mypage/home")}
                        >
                          마이페이지
                        </HeaderDropdownItem>
                        <HeaderDropdownItem
                          onClick={() => {
                            let confirmed =
                              window.confirm("로그아웃하시겠습니까?");
                            if (confirmed) {
                              window.sessionStorage.removeItem("Authorization");
                              window.sessionStorage.removeItem("RefreshToken");
                              alert("로그아웃 되었습니다.");
                              navigate("/");
                            }
                          }}
                        >
                          로그아웃
                        </HeaderDropdownItem>
                      </HeaderDropdown>
                    )}
                </IconButtonWrapper>
              </ButtonDiv>
            </>
          )}
        </ContentsRight>
      </HeaderContentsInner>
    </HeaderContentsWrapper>
  );
}

export default HeaderContents;

const HeaderContentsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (min-width: 1280px) {
    height: 133px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    height: 111px;
  }
  @media screen and (max-width: 1023px) {
    height: 46px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  }
`;

const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  @media screen and (min-width: 1280px) {
    width: 1280px;
    padding: 0 60px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 1024px;
    padding: 0 45px;
  }
  @media screen and (max-width: 1023px) {
    width: 425px;
    padding: 0 20px;
  }
`;

const HeaderContentsInner = styled(HeaderBox)`
  justify-content: space-between;
  @media screen and (max-width: 1023px) {
    /* padding: 0 20px; */
  }
`;

const ContentsLeft = styled.div`
  @media screen and (max-width: 1023px) {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 14px;
  }
`;

const HamburgerButton = styled.div`
  display: flex;
  align-items: center;
  display: none;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  width: 25px;
  height: 17px;
  @media screen and (max-width: 1023px) {
    display: flex;
  }
`;

const ButtonHorizon = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid black;
`;

const Logo = styled.div`
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  @media screen and (min-width: 1280px) {
    max-width: 200px;
    max-height: 80px;
    font-size: 40px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    max-width: 150px;
    max-height: 60px;
    font-size: 25px;
  }
  @media screen and (max-width: 1023px) {
    max-width: 90px;
    max-height: 36px;
  }
`;

const ContentsRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  @media screen and (min-width: 1280px) {
    width: 875px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 731px;
  }
  @media screen and (max-width: 1023px) {
    width: 58px;
  }
`;

const SearchDiv = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid #eeeeee;
  border-radius: 50px;
  position: relative;
  @media screen and (min-width: 1280px) {
    width: 542px;
    height: 53px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 452px;
    height: 44px;
  }
  @media screen and (max-width: 1023px) {
    /* display: none; */
  }
`;

const SearchIcon = styled.img`
  display: block;
  cursor: pointer;
  @media screen and (min-width: 1280px) {
    width: 25px;
    margin: 0 8px 0 14.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 20px;
    margin: 0 6.5px 0 11.5px;
  }
  @media screen and (max-width: 1023px) {
    width: 20px;
  }
`;

const Horizon = styled.div`
  border-right: 0.55px solid #828c94;

  @media screen and (min-width: 1280px) {
    width: 1px;
    height: 24px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 1px;
    height: 20px;
  }
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  border: none;
  font-family: "Noto Sans KR";
  outline: none;
  @media screen and (min-width: 1280px) {
    margin: 0 18px;
    height: 28px;
    font-size: 23px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin: 0 15px;
    height: 28px;
    font-size: 19px;
  }
  @media screen and (max-width: 1023px) {
    display: none;
  }
  ::placeholder {
    color: #bbbbbb;
  }
`;

const RecommendSearchArea = styled.div`
  width: 100%;
  border-radius: 25px;
  background-color: white;
  position: absolute;
  z-index: 100000;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  overflow: auto;
  @media screen and (min-width: 1280px) {
    padding: 20px 0;
    top: 53px;
    max-height: 240px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 20px 0;
    top: 44px;
    max-height: 190px;
  }
`;

const RecommendSearchItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (min-width: 1280px) {
    padding: 0 40px;
    gap: 8px;
    min-height: 40px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 0 30px;
    gap: 6px;
    min-height: 30px;
  }
  &:hover {
    background-color: #f3f3f3;
  }
`;

const RecommandedSearchIcon = styled.img`
  @media screen and (min-width: 1280px) {
    width: 20px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 15px;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  @media screen and (min-width: 1280px) {
    gap: 30px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 25px;
  }
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

const IconButtonWrapper = styled.div`
  position: relative;
`;

const IconButton = styled.img`
  cursor: pointer;

  @media screen and (min-width: 1280px) {
    width: 35px;
    height: 35px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 30px;
    height: 30px;
  }
  @media screen and (max-width: 1023px) {
    width: 23px;
    height: 23px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

const HeaderDropdown = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
  @media screen and (min-width: 1280px) {
    width: 101px;
    padding: 8px 0;
    gap: 9px;
    border-radius: 10px;
    left: -30px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 101px;
    padding: 8px 0;
    gap: 9px;
    border-radius: 10px;
    left: -35px;
  }
`;

const HeaderDropdownItem = styled.div`
  cursor: pointer;
  @media screen and (min-width: 1280px) {
    font-size: 18px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 15px;
  }
`;
