import { Outlet } from "react-router-dom";
import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";
import useDeviceSize from "../hooks/useDeviceSize";
import { useSidebarState } from "../recoil/mainPge/sidebarStateAtom";
import { useMobileSearchState } from "../recoil/mainPge/mobileSearchStateAtom";
import MobileSearch from "./MobileSearch/MobileSearch";
import Footer from "./Footer/Footer";

function ClientLayout() {
  const { isMobile } = useDeviceSize();
  const { sidebarState } = useSidebarState();
  const { mobileSearchState } = useMobileSearchState();

  return (
    <>
      <Header />
      {isMobile && sidebarState && <Sidebar />}
      {isMobile && mobileSearchState && <MobileSearch />}
      <Outlet />
      <Footer />
    </>
  );
}

export default ClientLayout;
