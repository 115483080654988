import React, { useState, KeyboardEvent, useEffect, useRef } from "react";
import styled from "styled-components";
import Mension from "../../../../assets/images/ResidentReview/Mension.png";
import PersonIcon from "../../../../assets/images/ResidentReview/PersonIcon.png";
import Onek from "../../../../assets/images/ResidentReview/1k.png";
import LocationIcon from "../../../../assets/images/ResidentReview/LocationIcon.png";
import useChoiceAreaModalState from "../../../../store/modal/choiceArea";
import useCheckItems from "../../../../store/modal/checkItems";
import ChoiceArea from "../../../../components/ChoiceArea";
import axiosInstance from "../../../../services/axiosConfig";
interface CategoryContainerProps {
  buildingType: string;
  setBuildingType: React.Dispatch<React.SetStateAction<string>>;
  residentsCount: string;
  setResidentsCount: React.Dispatch<React.SetStateAction<string>>;
  roomType: string;
  setRoomType: React.Dispatch<React.SetStateAction<string>>;
  districtId: number | null;
  setDistrictId: React.Dispatch<React.SetStateAction<number | null>>;
  keyword: string;
  setKeyword: React.Dispatch<React.SetStateAction<string>>;
  keywordArray: string[];
  hashtags: string[];
  setHashtags: React.Dispatch<React.SetStateAction<string[]>>;
}

const CategoryContainer: React.FC<CategoryContainerProps> = ({
  buildingType,
  setBuildingType,
  residentsCount,
  setResidentsCount,
  roomType,
  setRoomType,
  districtId,
  setDistrictId,
  hashtags,
  setKeyword,
  keywordArray,
  setHashtags,
}) => {
  const [inputValue, setInputValue] = useState("");
  const { open, choiceAreaOpen,close } = useChoiceAreaModalState();
  const { items,init } = useCheckItems();
  const [showRoomTypeOptions, setShowRoomTypeOptions] = useState(false);
  const [Rcount, setRcount] = useState<string>("");
  const [dname, setDname] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const roomTypeOptions = [
    "r1", "r2", "r3", "r4", "r5",
    "k1", "k2", "k3", "k4", "k5",
    "dk1", "dk2", "dk3", "dk4", "dk5",
    "ldk1", "ldk2", "ldk3", "ldk4", "ldk5",
    "s1", "s2", "s3", "s4", "s5"
  ];

useEffect(()=>{
  if(items.length >=2){
    init();
    alert('하나의 지역만 선택해주세요.')
  }else if(items.length ===1){
    setDistrictId(items[0].id);
    init();
  }
},[items])


const fetchDistrict = async (id: number) => {
  try {
    const res = await axiosInstance.get(`/map/district/${id}`);
    setDname(`${res.data.districtKName}`);
  } catch (error) {
  }
};

useEffect(() => {
  if (districtId) {
    fetchDistrict(districtId);
  }
}, [districtId]);
useEffect(() => {
  if (!districtId && items.length === 1 && items[0].id) {
    fetchDistrict(items[0].id);
  }
}, [items]);



  useEffect(() => {
    setHashtags(keywordArray.map((keyword) => `#${keyword}`));
  }, [keywordArray]);

  useEffect(() => {
    const keywordString = hashtags
      .map((hashtag, index) =>
        index === 0 ? hashtag.slice(1) : hashtag.replace(/#/g, "$")
      )
      .join("");
    setKeyword(keywordString);
  }, [hashtags, setKeyword]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= 15) {
      setInputValue(value);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === " " || e.key === "Enter") {
      const HashValue = `#${inputValue}`;
      if (HashValue.trim() !== "#") {
        setHashtags([...hashtags, HashValue]);
        setInputValue("");
      }
      e.preventDefault();
    }
  };
  const toggleRoomTypeOptions = () => {
    setShowRoomTypeOptions(!showRoomTypeOptions);
  };

  const handleHashtagClick = (index: number) => {
    setHashtags(hashtags.filter((_, i) => i !== index));
  };

  const handleRoomTypeClick = (option: string) => {
    setRoomType(option);
    setShowRoomTypeOptions(false);
  };

  const handleRCInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setRcount(value ? `${value}인 입주` : " 인 입주");
    setResidentsCount(value);
  };

  const handleInputFocus = () => {
    if (inputRef.current) {
      const value = residentsCount || "";
      inputRef.current.value = value;
      setResidentsCount(value);
      inputRef.current.setSelectionRange(0, 0); // 커서를 맨 앞에 위치시키기
    }
  };

  const handleInputBlur = () => {
    if (inputRef.current) {
      const value = residentsCount || "";
      inputRef.current.value = value ? `${value} 인 입주` : "";
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      const value = residentsCount || "";
      inputRef.current.value = value ? `${value} 인 입주` : "";
    }
  }, [residentsCount]);

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <StyledCategoryContainer>
        <StyledCategoryDiv>
          <StyledIconTextWrapper>
            <StyledIconText>
              <img src={Mension} alt="Mension" />
              <InputWrapper>
                <StyledInput
                  placeholder=""
                  value={buildingType}
                  onChange={(e) => setBuildingType(e.target.value)}
                />
                <Placeholder>
                  <div style={{ textAlign: "left", fontSize: "20px" }}>
                    건물타입
                  </div>
                  <div style={{ width: "140px", textAlign: "left" }}>
                    ex) 멘션, 점포, 사무실
                  </div>
                </Placeholder>
              </InputWrapper>
              <Seperator />
            </StyledIconText>
            <StyledIconText>
              <img src={PersonIcon} alt="PersonIcon" />
              <InputWrapper>
                <StyledInput
                  placeholder=""
                  value={
                    residentsCount !== ""
                      ? `${residentsCount}인 입주`
                      : `${Rcount}`
                  }
                  onChange={handleRCInputChange}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                />
                <Placeholder>
                  <div style={{ textAlign: "left", fontSize: "20px" }}>
                    입주인원
                  </div>
                  <div style={{ width: "140px", textAlign: "left" }}>
                    ex) 1인 입주, 2인 입주
                  </div>
                </Placeholder>
              </InputWrapper>
              <Seperator />
            </StyledIconText>
            <StyledIconText>
              <img src={Onek} alt="Onek" />
              <InputWrapper>
                <StyledInput
                  placeholder=""
                  value={roomType}
                  onClick={toggleRoomTypeOptions}
                  readOnly
                />
                <Placeholder onClick={toggleRoomTypeOptions}>
                  <div style={{ textAlign: "left", fontSize: "20px" }}>
                    방타입
                  </div>
                  <div>ex) 1k, 1LDK</div>
                </Placeholder>
                {showRoomTypeOptions && (
                  <OptionsList>
                    {roomTypeOptions.map((option, index) => (
                      <OptionItem
                        key={index}
                        onClick={() => handleRoomTypeClick(option)}
                      >
                        {option}
                      </OptionItem>
                    ))}
                  </OptionsList>
                )}
              </InputWrapper>
              <Seperator />
            </StyledIconText>
            <StyledIconText>
              <img src={LocationIcon} alt="LocationIcon" />
              <InputWrapper>
                <StyledInput
                className="Dname"
                  placeholder=""
                  // value={districtId || ""}
                  value={dname}
                  // onChange={(e) => setDistrictId(Number(e.target.value))}
                  onClick={() => open('location')}
                /> {choiceAreaOpen && <ChoiceArea />}
                <Placeholder>
                  <div style={{ textAlign: "left", fontSize: "20px" }}>
                    지역
                  </div>
                  <div>ex) 오사카 추오구</div>
                </Placeholder>
              </InputWrapper>
            </StyledIconText>
          </StyledIconTextWrapper>
        </StyledCategoryDiv>

        <StyledSeperator />

        <StyledCategoryDiv>
          <div style={{ padding: "20px" }}>
            <StyledHashtags>
              {hashtags.map((hashtag, index) => (
                <HashtagInputWrapper key={index}>
                  <StyledHashtag onClick={() => handleHashtagClick(index)}>
                    {hashtag}
                  </StyledHashtag>
                </HashtagInputWrapper>
              ))}
              <InputWrapper>
                <div style={{ color: "#888" }}>#</div>
                <StyledInput
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  maxLength={15}
                  className="Hash"
                  placeholder=""
                />
                <Placeholder style={{ width: "230px" }}>
                  <div style={{ width: "100%" }}>
                    #키워드를 5개 이상 입력해주세요
                  </div>
                </Placeholder>
              </InputWrapper>
            </StyledHashtags>
          </div>
        </StyledCategoryDiv>
      </StyledCategoryContainer>
    </div>
  );
};

export default CategoryContainer;

const Seperator = styled.div`
  background-color: #a7a7a7;
  width: 2px;
  height: 30px;
  margin-left: 46px;
`;

const StyledCategoryContainer = styled.div`
  background-color: #f7f9fa;
  width: 1068px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  margin-top: 20px;
`;

const StyledCategoryDiv = styled.div`
  width: 100%;
  min-height: 93.5px;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  overflow-x: hidden;
  input {
    justify-content: flex-start;
  }
`;

const StyledIconTextWrapper = styled.div`
  display: flex;
  gap: 30px;
`;

const StyledIconText = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  text-align: center;
  width: auto;
  img {
    width: 47px;
    height: 47px;
    margin-right: 15px;
  }
`;

const StyledInput = styled.input`
  font-family: "Noto Sans KR";
  border: none;
  outline: none;
  font-size: 20px;
  line-height: 1.5;
  width: 118px;
  background-color: transparent;
  text-align: left;
  border-bottom: 1px solid transparent;
  transition: border-color 0.3s;

  &.Dname {
font-size: 16px;
  }
  &.Hash {
    font-size: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 118px;
  }

  &:focus {
    border-bottom: 1px solid #555555;
  }

  &:focus + div,
  &:not(:placeholder-shown) + div {
    display: none;
  }
`;

const StyledHashtags = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 948px;
  gap: 15px;
  background-color: transparent;
`;

const StyledSeperator = styled.div`
  width: 1035px;
  height: 1px;
  background-color: #c9c9c9;
`;

const Placeholder = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
  font-size: 14px;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const HashtagInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledHashtag = styled.div`
  font-family: "Noto Sans KR";
  font-size: 16px;
  padding: 5px 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
`;

const OptionsList = styled.ul`
  position: absolute;
  width: 100%;
  background-color: #f7f9fa;
  border: 1px solid #ddd;
  padding: 0;
  margin: 0;
  max-height: 85px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const OptionItem = styled.li`
  padding: 1px;
  font-size: 16.5px;
  cursor: pointer;
  &:hover {
    background-color: #c0b887;
  }
`;
