import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import BannerArrowLeft from "../../../../assets/images/mainPage/bannerarrow-left.png";
import BannerArrowRight from "../../../../assets/images/mainPage/bannerarrow-right.png";
import ResponsiveWrapper from "../../../../components/ResponsiveWrapper";
import { useRecoilValue } from "recoil";
import { mainBannerStateAtom } from "../../../../recoil/adminMainDesign/mainBannerStateAtom";

function CloneMainBanner() {
  const mainBannerUrls = useRecoilValue(mainBannerStateAtom);

  // 메인 배너 슬라이드의 경우 슬라이드 개수가 4개 미만일 경우 loop 옵션 사용 시 이상하게 작동함
  // 때문에 슬라이드 수가 3개 이하일 때 슬라이드 개수를 2배로 복제하여
  const displayBannerUrls =
    mainBannerUrls && mainBannerUrls.length === 1
      ? [
          ...mainBannerUrls,
          ...mainBannerUrls,
          ...mainBannerUrls,
          ...mainBannerUrls,
          ...mainBannerUrls,
        ]
      : mainBannerUrls && mainBannerUrls.length < 4
      ? [...mainBannerUrls, ...mainBannerUrls]
      : mainBannerUrls;


  return (
    <ResponsiveWrapper>
      <Wrapper>
        {displayBannerUrls && displayBannerUrls?.length > 0 && (
          <NavigationButtonDiv>
            <NavigationButton $translateX={-33} className="main-prev-button">
              <StyledArrowImage src={BannerArrowLeft} />
            </NavigationButton>
            <NavigationButton $translateX={33} className="main-next-button">
              <StyledArrowImage src={BannerArrowRight} />
            </NavigationButton>
          </NavigationButtonDiv>
        )}
        <Swiper
          loop={displayBannerUrls?.length === 1 ? false : true}
          centeredSlides
          modules={[Navigation]}
          slidesPerView={2.28}
          spaceBetween={20}
          navigation={{
            prevEl: ".main-prev-button",
            nextEl: ".main-next-button",
          }}
        >
          {displayBannerUrls &&
            displayBannerUrls.map((item, index) => (
              <StyledSwiperSlide key={index}>
                <StyledImage src={item.fileUrl}></StyledImage>
              </StyledSwiperSlide>
            ))}
        </Swiper>
      </Wrapper>
    </ResponsiveWrapper>
  );
}

export default CloneMainBanner;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 709px;
`;

const StyledSwiperSlide = styled(SwiperSlide)`
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  height: 507px;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

const NavigationButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  z-index: 10;
  transform: translate(0, -50px);
  pointer-events: none;
`;

const NavigationButton = styled.div<{ $translateX: number }>`
  cursor: pointer;
  border-radius: 50px;
  pointer-events: all;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.1);
  transform: translate(
    ${(props) => props.$translateX && `${props.$translateX}px`}
  );
  width: 52px;
  height: 52px;
`;

const StyledArrowImage = styled.img`
  width: 50px;
  height: 50px;
`;
