import styled from "styled-components";
import design from "../../../assets/images/adminMainDesign/design.png";
import device from "../../../assets/images/adminMainDesign/device.png";
import { mainDesignDataType } from "../AdminMainDesign";
import { useRecoilValue } from "recoil";
import { subBannerStateAtom } from "../../../recoil/adminMainDesign/subBannerStateAtom";
import { mainBannerStateAtom } from "../../../recoil/adminMainDesign/mainBannerStateAtom";
import { logoStateAtom } from "../../../recoil/adminMainDesign/logoStateAtom";
import { categoryIconStateAtom } from "../../../recoil/adminMainDesign/categoryIconStateAtom";
import { mainBannerDataType } from "../../../recoil/adminMainDesign/mainBannerStateAtom";
import { subBannerDataType } from "../../../recoil/adminMainDesign/subBannerStateAtom";
import { logoDataType } from "../../../recoil/adminMainDesign/logoStateAtom";
import { categoryIconDataType } from "../../../recoil/adminMainDesign/categoryIconStateAtom";
import axiosInstance from "../../../services/axiosConfig";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function MainDesignHeader({
  originalDataState,
}: {
  originalDataState?: mainDesignDataType;
}) {
  const navigate = useNavigate();

  const logoState = useRecoilValue(logoStateAtom);
  const mainBannerState = useRecoilValue(mainBannerStateAtom);
  const subBannerState = useRecoilValue(subBannerStateAtom);
  const categoryIconState = useRecoilValue(categoryIconStateAtom);

  const onClickSave = async () => {
    try {
      await Promise.all([
        compareAndFetchLogo(),
        compareAndFetchMainBanner(),
        compareAndFetchCategoryIcon(),
        compareAndFetchSubBanner(),
      ]);

      alert("디자인 수정에 성공했습니다.");
      window.location.reload();
    } catch (error) {
      console.log(error);
      alert(`수정 사항 혹은 수정 사항중 일부 갱신 실패 ${error}`);
    }
  };

  // 로고 api
  const compareAndFetchLogo = async () => {
    if (originalDataState?.logoResponse !== logoState && logoState.file) {
      const formData = new FormData();
      formData.append("file", logoState.file);
      if (
        !originalDataState?.logoResponse ||
        (originalDataState.logoResponse.id === null &&
          originalDataState.logoResponse.fileUrl === "")
      ) {
        await axiosInstance.post(`/admin/home/logo/${logoState.id}`, formData);
      } else {
        await axiosInstance.put(`/admin/home/logo/${logoState.id}`, formData);
      }
    }
  };

  // 메인 배너 api
  const compareAndFetchMainBanner = async () => {
    const changedMainBanner: mainBannerDataType[] = [];
    const deletedMainBanner: (number | undefined)[] = [];
    const createdMainBanner: mainBannerDataType[] = [];

    if (
      originalDataState?.mainBannerList !== mainBannerState &&
      mainBannerState
    ) {
      originalDataState?.mainBannerList.forEach((item, index) => {
        if (item !== mainBannerState[index]) {
          if (
            !mainBannerState[index].file &&
            item.fileUrl !== mainBannerState[index].fileUrl
          ) {
            deletedMainBanner.push(item.id);
          } else if (mainBannerState[index].redirectUrl) {
            changedMainBanner.push(mainBannerState[index]);
          }
        }
      });

      if (originalDataState?.mainBannerList.length !== mainBannerState.length) {
        const additionalList = mainBannerState.slice(
          (originalDataState?.mainBannerList?.length ?? 0) -
            mainBannerState.length
        );
        additionalList.forEach((item) => {
          if (item.file && item.redirectUrl !== "") {
            createdMainBanner.push(item);
          } else {
            throw new Error("메인 배너 추가 시 이미지와 URL 모두 필요합니다.");
          }
        });
      }
    }

    if (changedMainBanner.length) await patchMainBanner(changedMainBanner);
    if (createdMainBanner.length) await postMainBanner(createdMainBanner);
    if (deletedMainBanner.length) await deleteMainBanner(deletedMainBanner);
  };

  const patchMainBanner = (changedMainBanner: mainBannerDataType[]) => {
    const formData = new FormData();
    changedMainBanner.forEach((item, index) => {
      if (item.id)
        formData.append(`bannerList[${index}].id`, item.id.toString());
      if (item.file) formData.append(`bannerList[${index}].file`, item.file);
      if (item.redirectUrl)
        formData.append(`bannerList[${index}].redirectUrl`, item.redirectUrl);
    });

    return axiosInstance.patch(`/admin/home/mainBanner`, formData);
  };

  const postMainBanner = (createdMainBanner: mainBannerDataType[]) => {
    const formData = new FormData();
    createdMainBanner.forEach((item, index) => {
      if (item.id)
        formData.append(`bannerList[${index}].id`, item.id.toString());
      if (item.file) formData.append(`bannerList[${index}].file`, item.file);
      if (item.redirectUrl)
        formData.append(`bannerList[${index}].redirectUrl`, item.redirectUrl);
    });

    return axiosInstance.post(`/admin/home/mainBanner`, formData);
  };

  const deleteMainBanner = (deletedMainBanner: (number | undefined)[]) => {
    const queryString = deletedMainBanner.map((id) => `ids=${id}`).join("&");

    return axiosInstance.delete(`/admin/home/?${queryString}`);
  };

  // 카테고리 api
  const compareAndFetchCategoryIcon = async () => {
    const changedCategoryIcon: categoryIconDataType[] = [];
    const deletedCategoryIcon: (number | undefined)[] = [];
    const createdCategoryIcon: categoryIconDataType[] = [];

    if (
      originalDataState?.iconResponseList !== categoryIconState &&
      categoryIconState
    ) {
      originalDataState?.iconResponseList.forEach((item, index) => {
        if (item !== categoryIconState[index]) {
          if (
            !categoryIconState[index].file &&
            item.fileUrl !== categoryIconState[index].fileUrl
          ) {
            deletedCategoryIcon.push(item.id);
          } else if (
            categoryIconState[index].selectedOption &&
            categoryIconState[index].name
          ) {
            changedCategoryIcon.push(categoryIconState[index]);
          }
        }
      });

      if (
        originalDataState?.iconResponseList.length !== categoryIconState.length
      ) {
        const additionalList = categoryIconState.slice(
          (originalDataState?.iconResponseList?.length ?? 0) -
            categoryIconState.length
        );
        additionalList.forEach((item) => {
          if (item.file && item.name && item.selectedOption !== undefined) {
            createdCategoryIcon.push(item);
          } else {
            throw new Error(
              "카테고리 추가 시 이미지, 이름, 옵션이 모두 필요합니다."
            );
          }
        });
      }
    }

    if (changedCategoryIcon.length)
      await patchCategoryIcon(changedCategoryIcon);
    if (createdCategoryIcon.length) await postCategoryIcon(createdCategoryIcon);
    if (deletedCategoryIcon.length)
      await deleteCategoryIcon(deletedCategoryIcon);
  };

  const patchCategoryIcon = (changedCategoryIcon: categoryIconDataType[]) => {
    const formData = new FormData();
    changedCategoryIcon.forEach((item, index) => {
      if (item.id) formData.append(`iconList[${index}].id`, item.id.toString());
      if (item.file) formData.append(`iconList[${index}].file`, item.file);
      if (item.selectedOption)
        formData.append(
          `iconList[${index}].selectedOption`,
          item.selectedOption
        );
      if (item.name) formData.append(`iconList[${index}].name`, item.name);
    });

    return axiosInstance.patch(`/admin/home/icon`, formData);
  };

  const postCategoryIcon = (createdCategoryIcon: categoryIconDataType[]) => {
    const formData = new FormData();
    createdCategoryIcon.forEach((item, index) => {
      if (item.id) formData.append(`iconList[${index}].id`, item.id.toString());
      if (item.file) formData.append(`iconList[${index}].file`, item.file);
      if (item.selectedOption)
        formData.append(
          `iconList[${index}].selectedOption`,
          item.selectedOption
        );
      if (item.name) formData.append(`iconList[${index}].name`, item.name);
    });

    return axiosInstance.post(`/admin/home/icon`, formData);
  };

  const deleteCategoryIcon = (deletedCategoryIcon: (number | undefined)[]) => {
    const queryString = deletedCategoryIcon.map((id) => `ids=${id}`).join("&");

    return axiosInstance.delete(`/admin/home/?${queryString}`);
  };

  // 서브배너 api
  const compareAndFetchSubBanner = async () => {
    if (
      (originalDataState?.subBanner !== subBannerState &&
        subBannerState.file) ||
      (originalDataState?.subBanner !== subBannerState &&
        subBannerState.redirectUrl !== originalDataState?.subBanner.redirectUrl)
    ) {
      const formData = new FormData();
      if (subBannerState.id) {
        formData.append(`id`, subBannerState.id.toString());
      }
      if (subBannerState.file) formData.append(`file`, subBannerState.file);
      formData.append(`redirectUrl`, subBannerState.redirectUrl);

      if (
        !originalDataState?.subBanner ||
        (originalDataState?.subBanner.fileUrl === "" &&
          originalDataState.subBanner.id === null &&
          originalDataState.subBanner.redirectUrl === "")
      ) {
        return axiosInstance.post(`/admin/home/subBanner`, formData);
      } else {
        return axiosInstance.patch(`/admin/home/subBanner`, formData);
      }
    }
  };

  return (
    <Wrapper>
      <HeaderLeft>
        <FinishButton onClick={() => navigate("/admin/mansionManagement")}>
          <Xicon>x</Xicon>
          끝내기
        </FinishButton>
        <DesignDiv>
          <DesignImage src={design} alt="design"></DesignImage>
          디자인
        </DesignDiv>
      </HeaderLeft>
      <HeaderRight>
        {/* <DeviceImage src={device} alt="device"></DeviceImage> */}
        <SaveButton onClick={onClickSave}>저장하기</SaveButton>
      </HeaderRight>
    </Wrapper>
  );
}

export default MainDesignHeader;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 153px;
  width: 100%;
  background-color: #ffffff;
`;

const HeaderLeft = styled.div`
  display: flex;
  margin-top: 36px;
`;

const FinishButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 241px;
  height: 85px;
  border-right: 2px solid #e6e6e6;
  font-size: 35px;
  font-weight: bold;
  color: #cacaca;
  gap: 10px;
  cursor: pointer;
`;

const Xicon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 45px;
  font-weight: 400;
  transform: translate(0, -4px);
`;

const DesignDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 85px;
  font-size: 20px;
  gap: 8px;
  font-weight: 500;
`;

const DesignImage = styled.img`
  width: 34px;
`;

const HeaderRight = styled.div`
  display: flex;
  margin-top: 50px;
  margin-right: 58px;
  gap: 14px;
`;

const DeviceImage = styled.img`
  width: 119px;
  height: 55px;
`;

const SaveButton = styled.button`
  width: 131px;
  height: 55px;
  background-color: #99393a;
  font-family: "Noto Sans KR";
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 25px;
  font-weight: bold;
  cursor: pointer;
`;
