import React from 'react';
import { styled } from 'styled-components';
import { RegionLineInnerType } from '..';
import useDetailChoiceOpenModalState from '../../../store/modal/detailChoice';
import DetailChoiceModal from '../DetailChoiceModal';
import LineCheckBoxSet from './LineCheckBoxSet';

const LineCheckBoxList = ({ regionLineData }: RegionLineInnerType) => {
  const { detailChoiceOpen, detailData } = useDetailChoiceOpenModalState();

  return (
    <Container>
      <BoxList>
        {regionLineData.map((item) => (
          <LineCheckBoxSet {...item} key={item.id} />
        ))}
      </BoxList>
      {detailChoiceOpen && detailData && (
        <DetailChoiceModal data={detailData} />
      )}
    </Container>
  );
};

export default LineCheckBoxList;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  position: relative;
`;
const BoxList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
