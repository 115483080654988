import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const BridgePage: React.FC = () => {
  const navigate = useNavigate();
  const redirectPath = sessionStorage.getItem('RedirectAfterBridge') || '/';
  useEffect(() => {
    const refreshToken = sessionStorage.getItem('RefreshToken');

    const fetchNewToken = async () => {
      if(refreshToken){
      try {
        const response = await axios.get('https://houberapp.com/auth/refresh', {
          headers: {
            refreshToken: refreshToken || null,
          },
        });

        if (response.status === 200) {
          const newToken = response.headers['authorization']; 
          sessionStorage.setItem('Authorization', newToken);


          if(redirectPath === "/bridge"){
            navigate('/');
          }else{
          navigate(redirectPath);
          // sessionStorage.removeItem('RedirectAfterBridge');
          }
        } else {
          redirectToLogin();
        }
      } catch (error) {
        sessionStorage.removeItem('Authorization');
        sessionStorage.removeItem('RefreshToken');
        redirectToLogin();
      }
    }else{
      redirectToLogin();
    }
  };

    const redirectToLogin = () => {
      if (redirectPath.includes('/admin')) {
        navigate('/admin/login');
      } else {
        navigate('/login');
      }
    };

    fetchNewToken();
  }, [navigate]);

  return <div></div>;
};

export default BridgePage;






// import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';

// const BridgePage: React.FC = () => {
//   const navigate = useNavigate();

//   useEffect(() => {
//     const refreshToken = sessionStorage.getItem('RefreshToken');

//     const fetchNewToken = async () => {
//       try {
//         const response = await axios.get(
//           'https://houberapp.com/auth/refresh',
//           {
//             headers: {
//               refreshToken: refreshToken ?? '',
//             },
//           }
//         );

//         if (response.status === 200) {
//           const newToken = response.headers['authorization']; 
//           sessionStorage.setItem('Authorization', newToken);
//           navigate(-1);
//         } else {
//           redirectToLogin();
//         }
//       } catch (error) {
//         sessionStorage.removeItem('Authorization');
//         sessionStorage.removeItem('RefreshToken');
//         redirectToLogin();
//       }
//     };

//     const redirectToLogin = () => {
//       if (window.location.pathname.includes('/admin')) {
//         navigate('/admin/login');
//       } else {
//         navigate('/login');
//       }
//     };

//     fetchNewToken();
//   }, [navigate]);

//   return <div></div>;
// };

// export default BridgePage;







// import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';

// const BridgePage: React.FC = () => {
//   const navigate = useNavigate();

//   useEffect(() => {
//     const refreshToken = sessionStorage.getItem('RefreshToken');

//     const fetchNewToken = async () => {
//       try {
//         const response = await axios.get(
//           'https://houberapp.com/auth/refresh',
//           {
//             headers: {
//               refreshToken: refreshToken ?? '',
//             },
//           }
//         );

//         if (response.status === 200) {
//           const newToken = response.headers['authorization']; 
//           sessionStorage.setItem('Authorization', newToken);
//           navigate(-1);
//         } else {
//           navigate('/login'); 
//         }
//       } catch (error) {
//         sessionStorage.removeItem('Authorization');
//         sessionStorage.removeItem('RefreshToken');
//         navigate('/login'); 
//       }
//     };

//     fetchNewToken();
//   }, [navigate]);

//   return <div></div>;
// };

// export default BridgePage;
