import React from "react";
import styled from "styled-components";
interface SocialBTNProps {
  icon: string;
}

const GoogleLogin: React.FC<SocialBTNProps> = ({ icon }) => {
 const REST_API_KEY = process.env.REACT_APP_GOOGLE;
 const REDIRECT_URI = 'https://houber-home.com/login/GoogleOauth';
 
 const link = `https://accounts.google.com/o/oauth2/auth?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=openid email profile`

  const loginHandler = () => {
    window.location.href = link;
  };
  return <Button icon={icon} onClick={loginHandler} />;
};

export default GoogleLogin;

const Button = styled.button<SocialBTNProps>`
  width: 58px;
  height: 58px;
  background: url(${(props) => props.icon}) no-repeat center center;
  background-size: contain;
  border: none;
  cursor: pointer;
  margin: 0px 13px;
  padding: 0px;

  @media (max-width: 500px) {
    width: 46px;
    height: 46px;
  }
`;
