import React, { useState, useRef, useEffect, ChangeEvent } from 'react';
import styled from 'styled-components';

interface TextAreaProps {
    value: string;
    onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  }

const TextArea: React.FC<TextAreaProps> = ({ value, onChange }) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);



    useEffect(() => {
        const textArea = textAreaRef.current;
        if (textArea) {
            textArea.style.height = 'auto';
            textArea.style.height = `${textArea.scrollHeight}px`;
        }
    }, [value]);

    return (
        <StyledTextArea
            ref={textAreaRef}
            value={value}
            onChange={onChange}
            placeholder='내용을 입력해주세요.'
        />
    );
};

export default TextArea;

const StyledTextArea = styled.textarea`
    border: none;
    resize: none;
    width: 1207px;
    box-sizing: border-box;
    overflow-y: hidden;
    min-height: 500px;
    font-size: 22px;
    font-family: "Noto Sans KR";
    line-height: 30pt;
`;
