import styled from "styled-components";
import SidebarNavigation from "./SidebarNavigaion";
import { useRecoilState } from "recoil";
import { useState } from "react";
import {
  categoryIconStateAtom,
  selectedOptionType,
} from "../../../recoil/adminMainDesign/categoryIconStateAtom";
import { categoryIconDataType } from "../../../recoil/adminMainDesign/categoryIconStateAtom";
import selected from "../../../assets/images/detail/selected.png";
import xIcon from "../../../assets/images/mainPage/x-icon.png";

function SetIconCategory() {
  const [navigationState, setNavigationState] = useState<number>(0);

  const [categoryIconState, setCategoryIconState] = useRecoilState(
    categoryIconStateAtom
  );

  const [optionSelectModalState, setOptionSelectModalState] =
    useState<boolean>(false);

  const getNavigationState = (newState: number) => {
    setNavigationState(newState);
  };

  const updateItemAtIndex = (index: number, newItem: categoryIconDataType) => {
    const newState = [...categoryIconState];
    newState[index] = newItem;
    setCategoryIconState(newState);
  };

  const OPTIONS = [
    { name: "인터넷 무료", propertyName: "freeInternet" },
    { name: "2인 이상 입주", propertyName: "morePeople" },
    { name: "복층 맨션", propertyName: "duplexMention" },
    { name: "인덕션", propertyName: "induction" },
    { name: "올 전기 맨션", propertyName: "allElectricMansion" },
    { name: "실내 세탁판", propertyName: "indoorWashingBoard" },
    { name: "비데", propertyName: "bidet" },
    { name: "유닛베스", propertyName: "unitBath" },
    { name: "냉난방", propertyName: "airConditioning" },
    { name: "24시 쓰레기 배출", propertyName: "trashDisposal24H" },
    { name: "인터폰", propertyName: "intercom" },
    { name: "자동차 주차장", propertyName: "carParking" },
    { name: "자전거 주차장", propertyName: "bicycleParking" },
    { name: "여성 전용 맨션", propertyName: "womenOnly" },
    { name: "오토록", propertyName: "autoLock" },
    { name: "외국인 가능", propertyName: "foreignerAllowed" },
    { name: "가구옵션", propertyName: "furnitureOptions" },
    { name: "펫가능", propertyName: "petsAllowed" },
    { name: "디자이너 맨션", propertyName: "designerMention" },
    { name: "역세권", propertyName: "closeToStation" },
    { name: "가스레인지", propertyName: "gasRange" },
    { name: "도시가스", propertyName: "cityGas" },
    { name: "실외 세탁판", propertyName: "outdoorWashingBoard" },
    { name: "독립형 화장실", propertyName: "standAloneToilet" },
    { name: "욕실 건조기", propertyName: "bathroomDryer" },
    { name: "수납공간", propertyName: "storageSpace" },
    { name: "쓰레기 보관소", propertyName: "garbageStorage" },
    { name: "택배박스", propertyName: "deliveryBox" },
    { name: "엘레베이터", propertyName: "elevator" },
    { name: "바이크 주차장", propertyName: "bikeParking" },
    { name: "남성 전용 맨션", propertyName: "mensOnly" },
    { name: "CCTV", propertyName: "cctv" },
    { name: "해외심사 가능", propertyName: "overseasScreeningAvailable" },
  ];

  const handleImageDrag = (event: React.DragEvent) => {
    // 이미지 드래그&드롭 시 새 창에 이미지가 뜨지 않도록 제어
    event.preventDefault();
  };

  const handleImageDrop = (event: React.DragEvent) => {
    // 이미지 드래그&드롭 시 새 창에 이미지가 뜨지 않도록 제어
    event.preventDefault();
    const file = event.dataTransfer.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          updateItemAtIndex(navigationState, {
            ...categoryIconState[navigationState],
            fileUrl: reader.result,
            file: file,
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageUpload = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const file = target.files && target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          updateItemAtIndex(navigationState, {
            ...categoryIconState[navigationState],
            fileUrl: reader.result,
            file: file,
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSelectOption = (option: selectedOptionType) => {
    updateItemAtIndex(navigationState, {
      ...categoryIconState[navigationState],
      selectedOption: option,
    });
    setOptionSelectModalState(false);
  };

  const handleOnChangeNameInput = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) =>
    updateItemAtIndex(navigationState, {
      ...categoryIconState[navigationState],
      name: event.target.value,
    });

  const convertedIndex = (page: number) => {
    return { start: 11 * (page - 1), end: 11 * page };
  };

  const selectedOption = OPTIONS.find(
    (option) =>
      option.propertyName === categoryIconState[navigationState].selectedOption
  );
  return (
    <SetIconCategoryWrapper>
      <SidebarContentsItem>
        <ContentsItemTop>
          아이콘 카테고리 {navigationState + 1}
          <SidebarNavigation
            navigationState={navigationState}
            getNavitaionState={getNavigationState}
            onClickAddButton={() => {
              setCategoryIconState([
                ...categoryIconState,
                {
                  fileUrl: "",
                  selectedOption: undefined,
                  file: undefined,
                  name: "",
                },
              ]);
              setNavigationState(categoryIconState.length);
            }}
            maxPage={categoryIconState.length - 1}
          />
        </ContentsItemTop>
        {categoryIconState[navigationState].fileUrl ? (
          <UploadedImageView>
            <PreviewImage
              src={categoryIconState[navigationState].fileUrl}
              alt="uploadedCategoryIconImage"
              onClick={() => {
                updateItemAtIndex(navigationState, {
                  ...categoryIconState[navigationState],
                  file: undefined,
                  fileUrl: "",
                });
              }}
            />
          </UploadedImageView>
        ) : (
          <UploadImageWrapper
            onDragOver={handleImageDrag}
            onDrop={handleImageDrop}
          >
            드래그 앤 드롭으로 이미지 추가
            <ChooseImage>
              <StyledFileInput type="file" onChange={handleImageUpload} />+
              이미지 선택
            </ChooseImage>
          </UploadImageWrapper>
        )}
      </SidebarContentsItem>
      <SidebarContentsItem>
        아이콘 카테고리 {navigationState + 1} 옵션
        <TextInputDiv>
          <OptionInputArea>
            {selectedOption ? (
              <SelectedOption>
                {selectedOption.name}
                <StyledXIcon
                  src={xIcon}
                  onClick={() => {
                    updateItemAtIndex(navigationState, {
                      ...categoryIconState[navigationState],
                      selectedOption: undefined,
                    });
                  }}
                ></StyledXIcon>
              </SelectedOption>
            ) : (
              <ChooseOptionButton
                onClick={() => setOptionSelectModalState(true)}
              >
                + 옵션 선택
              </ChooseOptionButton>
            )}
          </OptionInputArea>
        </TextInputDiv>
      </SidebarContentsItem>
      <SidebarContentsItem>
        아이콘 카테고리 {navigationState + 1} 이름
        <TextInputDiv>
          <TextInput
            value={categoryIconState[navigationState].name}
            onChange={(event) => handleOnChangeNameInput(event)}
            placeholder="이름을 지정해주세요"
          ></TextInput>
        </TextInputDiv>
      </SidebarContentsItem>
      {optionSelectModalState && (
        <SelectOptionModal>
          <StyledBigXIcon
            src={xIcon}
            onClick={() => setOptionSelectModalState(false)}
          ></StyledBigXIcon>
          <OptionsInner>
            <ItemWrapper>
              {OPTIONS.slice(
                convertedIndex(1).start,
                convertedIndex(1).end
              ).map((item, index) => (
                <OptionItem
                  key={index}
                  onClick={() =>
                    handleSelectOption(item.propertyName as selectedOptionType)
                  }
                >
                  {categoryIconState[navigationState].selectedOption ===
                  item.propertyName ? (
                    <SelectedOptionIcon src={selected} />
                  ) : (
                    <OptionIcon />
                  )}
                  <OptionName>{item.name}</OptionName>
                </OptionItem>
              ))}
            </ItemWrapper>
            <ItemWrapper>
              {OPTIONS.slice(
                convertedIndex(2).start,
                convertedIndex(2).end
              ).map((item, index) => (
                <OptionItem
                  key={index}
                  onClick={() =>
                    handleSelectOption(item.propertyName as selectedOptionType)
                  }
                >
                  {categoryIconState[navigationState].selectedOption ===
                  item.propertyName ? (
                    <SelectedOptionIcon src={selected} />
                  ) : (
                    <OptionIcon />
                  )}
                  <OptionName>{item.name}</OptionName>
                </OptionItem>
              ))}
            </ItemWrapper>
            <>
              <ItemWrapper>
                {OPTIONS.slice(
                  convertedIndex(3).start,
                  convertedIndex(3).end
                ).map((item, index) => (
                  <OptionItem
                    key={index}
                    onClick={() =>
                      handleSelectOption(
                        item.propertyName as selectedOptionType
                      )
                    }
                  >
                    {categoryIconState[navigationState].selectedOption ===
                    item.propertyName ? (
                      <SelectedOptionIcon src={selected} />
                    ) : (
                      <OptionIcon />
                    )}
                    <OptionName>{item.name}</OptionName>
                  </OptionItem>
                ))}
              </ItemWrapper>
            </>
          </OptionsInner>
        </SelectOptionModal>
      )}
    </SetIconCategoryWrapper>
  );
}

export default SetIconCategory;

const SetIconCategoryWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 829px;
  gap: 50px;
  border-bottom: 3px solid #e6e6e6;
  padding-top: 84px;
  font-weight: 500;
`;

const SidebarContentsItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  font-size: 20px;
`;

const TextInputDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

const OptionInputArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 110px;
  padding: 20px 19px;
  border: 1px dotted #a7a7a7;
  font-family: "Noto Sans KR";
  border-radius: 5px;
  background-color: #f7f8f9;
`;

const ChooseOptionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 131px;
  height: 28px;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
  font-family: "Noto Sans KR";
  font-size: 15px;
`;

const TextInput = styled.textarea`
  width: 100%;
  height: 110px;
  padding: 20px 19px;
  border: 1px dotted #a7a7a7;
  font-family: "Noto Sans KR";
  border-radius: 5px;
  resize: none;
  outline: none;
  background-color: #f7f8f9;
  box-sizing: border-box;
`;

const ContentsItemTop = styled.div`
  display: flex;
  justify-content: space-between;
`;

const UploadImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 240px;
  border-radius: 5px;
  background-color: #f7f8f9;
  border: 1px dotted #a7a7a7;
  font-size: 15px;
  gap: 15px;
`;

const StyledFileInput = styled.input`
  display: none;
`;

const ChooseImage = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 131px;
  height: 28px;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
`;

const UploadedImageView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 240px;
  border-radius: 5px;
  background-color: #f7f8f9;
  border: 1px solid black;
  font-size: 15px;
  gap: 15px;
  overflow: hidden;
`;

const PreviewImage = styled.img`
  width: 100%;
  cursor: pointer;
`;

const SelectOptionModal = styled.div`
  position: absolute;
  width: 700.5px;
  height: 502px;
  background-color: white;
  z-index: 1000;
  padding: 0 75px 0 33px;
  right: -750.5px;
  top: 32%;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

const OptionsInner = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  @media screen and (min-width: 1280px) {
    padding: 47px 0;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 44px 0;
  }
  @media screen and (max-width: 1023px) {
    padding: 37px 0 37px 0;
    overflow: hidden;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1280px) {
    gap: 18px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 17px;
  }
  @media screen and (max-width: 1023px) {
    gap: 18px;
  }
`;

const OptionItem = styled.div`
  display: flex;
  gap: 14px;
  cursor: pointer;
`;

const OptionIcon = styled.div`
  border-radius: 5px;
  background-color: #eaebef;
  @media screen and (min-width: 1280px) {
    width: 21px;
    height: 21px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 19.5px;
    height: 19.5px;
  }
  @media screen and (max-width: 1023px) {
    width: 20.5px;
    height: 20.5px;
  }
`;

const SelectedOptionIcon = styled.img`
  border-radius: 5px;
  @media screen and (min-width: 1280px) {
    width: 21px;
    height: 21px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 19.5px;
    height: 19.5px;
  }
  @media screen and (max-width: 1023px) {
    width: 20.5px;
    height: 20.5px;
  }
`;

const OptionName = styled.div`
  @media screen and (min-width: 1280px) {
    font-size: 19px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 19px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
  }
`;

const SelectedOption = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const StyledBigXIcon = styled.img`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 15px;
  height: 15px;
  cursor: pointer;
`;

const StyledXIcon = styled.img`
  margin-top: 2px;
  width: 10px;
  height: 10px;
  cursor: pointer;
`;
