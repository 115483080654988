import React, { useState } from "react";
import styled from "styled-components";

interface SortOptionsProps {
  onSelect: (option: string, label: string) => void;
}

const MobileSortOptions: React.FC<SortOptionsProps> = ({ onSelect }) => {
  const handleOptionClick = (option: string, label: string) => {
    onSelect(option, label);
  };

  return (
    <Container>
      <Align>정렬</Align>
      <div style={{ position: "fixed", bottom: "0", width: "100%" }}>
        <Option onClick={() => handleOptionClick("latest", "최신순")}>
          최신순
        </Option>
        <Separator />
        <Option onClick={() => handleOptionClick("oldest", "과거순")}>
          과거순
        </Option>
        <Separator />
        <Option onClick={() => handleOptionClick("mostRecommend", "추천순")}>
          추천순
        </Option>
        <Separator />
        <Option onClick={() => handleOptionClick("mostViews", "조회수순")}>
          조회수순
        </Option>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Noto Sans KR";
`;
const Align = styled.div`
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  padding-top: 35px;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  height: 57px;
  padding: 0 20px;
`;

const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e0e0e0;
`;

export default MobileSortOptions;
