import styled from "styled-components";
import ResponsiveWrapper from "../../../../components/ResponsiveWrapper";
import CloneContentsHeader from "./CloneContentsHeader";
import CloneMainItem from "./CloneMainItem";

function CloneMansionReviews() {
  return (
    <ResponsiveWrapper>
      <CloneContentsHeader
        title="입주 후기"
        subtitle="입주자들의 생생한 후기를 확인해보세요!"
      />
      <ContentsWrapper>
        {[1, 2, 3].map((item, index) => (
          <CloneMainItem
            key={index}
            width={361.5}
            height={242.5}
          ></CloneMainItem>
        ))}
      </ContentsWrapper>
    </ResponsiveWrapper>
  );
}

export default CloneMansionReviews;

const ContentsWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin: 15px 0;
  height: 269.5px;
  gap: 37px;
`;
