import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom'; // useLocation 추가
import DownIcon from '../../../assets/images/ResidentReview/DownIcon.png';

interface SortOptionsProps {
  onSelect: (option: string) => void;
}

const SortOptions: React.FC<SortOptionsProps> = ({ onSelect }) => {
  const location = useLocation(); // useLocation 훅 사용

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>('정렬');
  
  // URL 쿼리 파라미터에서 sortBy 값 가져오기
  const queryParams = new URLSearchParams(location.search);
  const initialSort = queryParams.get("sortBy") || "정렬";

  const options = [
    { label: '최신순', value: 'latest' },
    { label: '과거순', value: 'oldest' },
    { label: '추천순', value: 'mostRecommend'},
    { label: '조회수순', value: 'mostViews'},
  ];

  useEffect(() => {
    const matchedOption = options.find((e) => e.value === initialSort);
    setSelectedOption(matchedOption?.value || '정렬');
  }, [initialSort, options]);

  const handleSelect = (option: { label: string, value: string }) => {
    setSelectedOption(option.value);
    onSelect(option.value);
    setIsOpen(false);
  };

  const matchedOption = options.find((e) => e.value === selectedOption);
  const Mlabel = matchedOption?.label || "정렬";

  return (
    <DropdownContainer>
      <DropdownHeader onClick={() => setIsOpen(!isOpen)}>
        {Mlabel}
        <img style={{ width: '15px', height: '8px', paddingLeft: '5px' }} src={DownIcon} alt="Down Icon" />
      </DropdownHeader>
      {isOpen && (
        <DropdownList>
          {options.map((option, index) => (
            <DropdownItem
              key={index}
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};


const DropdownContainer = styled.div`
  position: relative;
  width: auto;
  margin-bottom: 15px;
`;

const DropdownHeader = styled.div`
  padding: 10px 15px;
  background-color: #e8e8e8;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const DropdownList = styled.ul`
  position: absolute;
  width: 90px;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
`;

const DropdownItem = styled.li`
  width: 80px;
  padding: 8px 5px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgb(153, 57, 58,0.7);
  }
`;

export default SortOptions;
