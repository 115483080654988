import styled from "styled-components";
import useDeviceSize from "../../hooks/useDeviceSize";
import PageHeader from "../../components/PageHeader";
import HouberMansionContents from "./components/HouberMansionContents";
import { useEffect, useState } from "react";
import axios from "axios";
import axiosInstance from "../../services/axiosConfig";
import mansionIcon from "../../assets/images/houberMansion/mansion.png";
import { useHouberMansionData } from "../../recoil/mainPge/houberMansionDataStateAtom";

type houberMansionResponseType = {
  data: {
    roomDetails: houberMansionItemType[];
  };
};

export type houberMansionItemType = {
  isScrapbook: boolean;
  name: string;
  recommendPoints: string;
  id: number;
  roomNum: number;
  thumbnailUrl: string;
};

function HouberMansion() {
  const { isPC, isLaptop, isMobile } = useDeviceSize();

  const { houberMansionDataState, setHouberMansionDataState } =
    useHouberMansionData();

  useEffect(() => {
    const fetchHouberMansion = async () => {
      const response: houberMansionResponseType = await axiosInstance.get(
        "/normal/home/detailHouber"
      );
      setHouberMansionDataState(response.data.roomDetails);
    };

    fetchHouberMansion();
  }, []);

  return (
    <HouberMansionWrapper>
      <PageHeader
        src={mansionIcon}
        title="하우버 맨션"
        subtitle="하우버만의 특별한 맨션을 소개해드릴게요"
      />
      <HouberMansionContents houberMansionData={houberMansionDataState} />
    </HouberMansionWrapper>
  );
}

export default HouberMansion;

const HouberMansionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  /* border: 1px solid black; */
  @media screen and (min-width: 1280px) {
    width: 1280px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 1024px;
  }
  @media screen and (max-width: 1023px) {
    width: 425px;
  }
`;
