import { create } from 'zustand';

type DataType = 'location' | 'subway' | null;
interface Prop {
  choiceAreaOpen: boolean;
  dataType: DataType;
  open: (dataType: DataType) => void;
  close: () => void;
}

const useChoiceAreaModalState = create<Prop>((set) => ({
  choiceAreaOpen: false,
  dataType: null,
  open: (dataType: DataType) =>
    set(() => ({ choiceAreaOpen: true, dataType: dataType })),
  close: () => set(() => ({ choiceAreaOpen: false, dataType: null })),
}));

export default useChoiceAreaModalState;
