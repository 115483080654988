import styled from "styled-components";
import ResponsiveWrapper from "../../../components/ResponsiveWrapper";
import ContentsHeader from "./ContentsHeader";
import MainItem from "./MainItem";
import useDeviceSize from "../../../hooks/useDeviceSize";
import MoreInfoButton from "./MoreInfoButton";
import Navigation, { getMaxPage } from "../../../components/Navigation";
import { useEffect, useState } from "react";
import { mainMansionDataType } from "../Main";
import CloneMainItem from "../../adminMainDesignPage/components/MainPreview/CloneMainItem";
import { useNavigate } from "react-router-dom";

function HouberMansions({
  bestBrooklynMansions,
  subBanner,
}: {
  bestBrooklynMansions?: mainMansionDataType[];
  subBanner?: {
    fileUrl: string;
    id: number;
    redirectUrl: string;
  };
}) {
  const navigate = useNavigate();
  const [contentsPageState, setContentsPageState] = useState(0);
  const [currentPageMansions, setCurrentPageMansions] = useState<
    mainMansionDataType[]
  >([]);
  const { isPC, isLaptop, isMobile } = useDeviceSize();

  useEffect(() => {
    if (bestBrooklynMansions) {
      const itemsPerPage = isMobile ? 2 : 4;
      const startIndex = contentsPageState * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setCurrentPageMansions(bestBrooklynMansions.slice(startIndex, endIndex));
    }
  }, [contentsPageState, bestBrooklynMansions, isMobile]);
  useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    // Safari 브라우저일 경우 강제로 이미지 다시 로드
    if (isSafari && subBanner?.fileUrl) {
      const imgElement = document.getElementById("subBannerImage")as HTMLImageElement;
      setTimeout(() => {
        if (imgElement) {
          imgElement.src = subBanner.fileUrl; // 강제 리렌더링
        }     // 다시 원래 URL로 설정하여 리렌더링
      }, 100); // 딜레이 후 src 재설정
    }
  }, [subBanner]);

  return (
    <ResponsiveWrapper>
      <ContentsOuter>
        <Navigation
          onClickNext={() => {
            setContentsPageState(contentsPageState + 1);
          }}
          onClickPrev={() => {
            setContentsPageState(contentsPageState - 1);
          }}
          page={contentsPageState}
          maxPage={getMaxPage(4, bestBrooklynMansions?.length)}
          translateY={isPC ? -47 : -35}
        ></Navigation>
        <ContentsHeader
          title="하우버 맨션"
          subtitle="하우버 전용 맨션을 소개해드릴게요"
          moreInfo="/houberMansion"
        />
        <ContentsWrapper>
          <SubBanner
            onClick={() => {
              if (subBanner) navigate(subBanner?.redirectUrl);
            }}
          >
            <StyledImage src={subBanner?.fileUrl}></StyledImage>
          </SubBanner>
          <SmallItemWrapper>
            {!bestBrooklynMansions || bestBrooklynMansions.length < 1 ? (
              <NoItemArea>조건에 해당하는 매물이 없습니다.</NoItemArea>
            ) : (
              <>
                {!isMobile
                  ? currentPageMansions &&
                    currentPageMansions.map((item, index) => (
                      <MainItem
                        key={index}
                        width={isPC ? 275 : 222}
                        height={isPC ? 183 : 146}
                        itemTitleMargin={13}
                        roomInfo={item}
                        category="room"
                      />
                    ))
                  : currentPageMansions &&
                    currentPageMansions.map((item, index) => (
                      <MainItem
                        key={index}
                        width={187}
                        height={135}
                        itemTitleMargin={10}
                        roomInfo={item}
                        category="room"
                      />
                    ))}
              </>
            )}
          </SmallItemWrapper>
          {isMobile && (
            <MoreInfoButton
              moreInfoText="하우버 맨션 더보기"
              linkTo="/houberMansion"
            />
          )}
        </ContentsWrapper>
      </ContentsOuter>
    </ResponsiveWrapper>
  );
}

export default HouberMansions;

const ContentsOuter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const ContentsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (min-width: 1280px) {
    margin: 28px 0;
    min-height: 453px;
    margin-bottom: 119px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin: 24px 0;
    min-height: 374px;
    margin-bottom: 97px;
  }
  @media screen and (max-width: 1023px) {
    margin: 26px 0;
    margin-bottom: 69px;
    flex-direction: column;
  }
`;

const SmallItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  flex: 1;
  @media screen and (min-width: 1280px) {
    margin-left: 30px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-left: 15px;
  }
  @media screen and (max-width: 1023px) {
    min-height: 160px;
    margin-bottom: 15px;
    margin-top: 11px;
    margin-left: 0;
  }
`;

const SubBanner = styled.div`
  position: relative;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  background-color: none;
  @media screen and (min-width: 1280px) {
    width: 557px;
    height: 453px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 461px;
    height: 375px;
  }
  @media screen and (max-width: 1023px) {
    width: 386px;
    height: 240px;
  }
`;
const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  background-color: none;
  display: inline-block;
  
`;

const NoItemArea = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #837474;
  @media screen and (min-width: 1280px) {
    font-size: 27px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 24px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 18px;
  }
`;
