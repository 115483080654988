import React from "react";
import { styled } from "styled-components";
import CheckBox from "../CheckBoxList/CheckBox";
import { IoClose } from "react-icons/io5";
import useDetailChoiceOpenModalState from "../../../store/modal/detailChoice";
import useCheckItems from "../../../store/modal/checkItems";

type DataType = {
  id: number;
  name: string;
};
interface Props {
  data: DataType[];
}
const DetailChoiceModal = ({ data }: Props) => {
  const { close } = useDetailChoiceOpenModalState();
  const { add, returnItems, del, selectAll } = useCheckItems();

  const isAllChecked =
    data.length > 0 &&
    data.every((item) =>
      returnItems.some((returnItem) => returnItem.name === item.name)
    );

  const handleSelectAll = () => {
    selectAll(!isAllChecked, data);
  };

  return (
    <Container>
      <Box>
        <SelectAllWrapper>
          <SelectAll
            key="select-all"
            text="전체 선택 / 삭제"
            id={0}
            checkItemHandler={handleSelectAll}
            size="xsmall"
            isChecked={isAllChecked}
          />
        </SelectAllWrapper>
        <Close size={20} onClick={() => close()} />
        <Inner>
          {data.map((item) => (
            <CheckBox
              key={item.id}
              text={item.name}
              id={item.id}
              checkItemHandler={({ target }) => {
                if (target.checked) {
                  console.log(item);

                  add([item]);
                } else {
                  del(item.name, "name");
                }
              }}
              size="xsmall"
              isChecked={returnItems.some((value) => value.name === item.name)}
            />
          ))}
        </Inner>
      </Box>
    </Container>
  );
};

export default DetailChoiceModal;

const Container = styled.div`
  position: sticky;
  display: flex;
  bottom: 0;
  height: 250px;
  z-index: 20;
  border: 1.5px solid #dedede;
  width: 100%;
  border-radius: 10px;
  background-color: white;
  padding: 50px 30px 30px;
  @media screen and (max-width: 1023px) {
    height: 150px;
  }
`;
const Box = styled.div`
  position: relative;
  width: 100%;
`;
const Inner = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  overflow-y: scroll;
  gap: 10px 5%;
  height: 100%;
  &::-webkit-scrollbar {
    width: 4px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: none;
  }
`;
const Close = styled(IoClose)`
  position: absolute;
  top: -40px;
  right: -20px;
`;

const SelectAllWrapper = styled.div`
  position: absolute;
  top: -35px;
  left: 0;
`;
const SelectAll = styled(CheckBox)``;
