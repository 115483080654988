import React, { useState, useEffect } from 'react';
import styled from 'styled-components';


const initialTime = 5* 60;

const Timer: React.FC<{ onTimeout: () => void }> = ({ onTimeout }) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(interval);
          onTimeout();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [onTimeout]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return (
    <Timerdiv>
        {formattedMinutes} : {formattedSeconds}
    </Timerdiv>
  );
};


const Timerdiv = styled.div`
  font-size: 21.5px;
  color:red;


  @media (max-width: 1024px) {
  font-size: 13px;

}
  @media (max-width: 500px) {
  font-size: 10px;

}

`;


export default Timer