import styled from "styled-components";
import Title from "./Title";
import Subtitle from "./Subtitle";
import MoreInfoButton from "./MoreInfoButton";
import useDeviceSize from "../../../hooks/useDeviceSize";

function ContentsHeader({
  title,
  subtitle,
  moreInfo,
}: {
  title: string;
  subtitle: string;
  moreInfo: string;
}) {
  const { isMobile } = useDeviceSize();

  return (
    <ContentsHeaderWrapper>
      <Title>{title}</Title>
      <HeaderBottom>
        <Subtitle>{subtitle}</Subtitle>
        {!isMobile && <MoreInfoButton linkTo={moreInfo}></MoreInfoButton>}
      </HeaderBottom>
    </ContentsHeaderWrapper>
  );
}

export default ContentsHeader;

const ContentsHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  @media screen and (min-width: 1280px) {
    height: 60px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    height: 51px;
  }
  @media screen and (max-width: 1023px) {
    height: 50px;
  }
`;
const HeaderBottom = styled.div`
  display: flex;
  justify-content: space-between;
`;
