import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { NavigateFunction } from 'react-router-dom';

let navigateFunction: NavigateFunction | null = null;

export const setNavigateFunction = (navigate: NavigateFunction) => {
  navigateFunction = navigate;
};

const axiosInstance = axios.create({
  baseURL: 'https://houberapp.com',
});

// 요청 인터셉터
axiosInstance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("Authorization");
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 응답 인터셉터
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      const currentPath = window.location.pathname;
      if (currentPath !== '/bridge') {
        sessionStorage.setItem('RedirectAfterBridge', currentPath);
      }

      if (navigateFunction) {
        navigateFunction('/bridge');
      } else {
        setTimeout(() => {
          window.location.href = '/bridge';
        }, 1);
      }

      return new Promise(() => {});
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;





// import axios from "axios";

// const axiosInstance = axios.create({
//   baseURL: "https://houberapp.com",
// });

// axiosInstance.interceptors.request.use(
//   (config) => {
//     const token = sessionStorage.getItem("Authorization");
//     if (token) {
//       config.headers["Authorization"] = token;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     const originalRequest = error.config;

//     if (
//       error.response &&
//       error.response.status === 401 &&
//       !originalRequest._retry
//     ) {
//       originalRequest._retry = true;

//       const currentPath = window.location.pathname;
//       sessionStorage.setItem("RedirectAfterBridge", currentPath);

//      setTimeout(() => {
//         window.location.href = "/bridge";
//       }, 1);

//       return new Promise(() => {});
//     }

//     return Promise.reject(error);
//   }
// );

// export default axiosInstance;










// import axios from 'axios';

// const axiosInstance = axios.create({
//   baseURL: 'https://houberapp.com',
// });

// axiosInstance.interceptors.request.use(
//   (config) => {
//     const token = sessionStorage.getItem('Authorization');
//     if (token) {
//       config.headers['Authorization'] = token;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     const originalRequest = error.config;

//     if (error.response && error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;

//       const bridgePageUrl = '/bridge';
//       window.location.href = bridgePageUrl;

//       return new Promise(() => {});
//     }

//     return Promise.reject(error);
//   }
// );

// export default axiosInstance;
