import styled from "styled-components";
import scrapcolored from "../../../assets/images/ResidentReview/scrapcolored.png"
import scrap from "../../../assets/images/ResidentReview/scrap.png";
import { useState,useEffect } from "react";
import axiosInstance from "../../../services/axiosConfig";
import { SetStateAction,Dispatch } from "react";
interface ReivewItemProps {
  images: string;
  title: string;
  isNew: boolean;
  isScrapped: boolean;
  id: number;
  setMessageState :Dispatch<SetStateAction<boolean>>;
}

const ReviewItem: React.FC<ReivewItemProps> = ({
  images,
  isNew,
  title,
  isScrapped,
  id,
  setMessageState,
}) => {
  const [Scrapped, setScrapped] = useState<boolean>();
  
  useEffect(()=>{
    setScrapped(isScrapped);
  },[isScrapped])

  const handleScrap = async () => {

    try {
      const data = {
        category: "review",
        entityId: id,
      };
      setScrapped(!Scrapped);
      const res = await axiosInstance.post(
        "/normal/user/scrap",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
        if(res.data === "스크랩 추가"){
          setMessageState(true);
          setScrapped(true);
        }else if(res.data.message === "스크랩 취소"){
          setScrapped(false);
        };

    } catch (error) {

    }

  };

  return (
    <ItemWrapper>
      <Image>
        <img src={images} style={{ width: "100%", height: "100%" , borderRadius:'8px', objectFit:'cover'}} />
        {isNew && <NewBadge>NEW</NewBadge>}
        {false !== undefined && (
          <SubscribeButton
            onClick={(e) => {
              e.stopPropagation();
              handleScrap();
            }}
            src={Scrapped ? scrapcolored : scrap}
          />
        )}
      </Image>
      <TitleWrapper>
        <Title>{title}</Title>
      </TitleWrapper>
    </ItemWrapper>
  );
};

export default ReviewItem;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const Image = styled.div`
  position: relative;
  background-color: #eaebef;
  width: 100%;
  height: 240px;
  border-radius: 8px;

`;

const NewBadge = styled.div`
  width: 51px;
  height: 30px;
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #FF7777;
  color: white;
  font-size: 16px;
  border-radius: 4px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1023px) {
    width: 36px;
    height: 25px;
    font-size: 12px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
`;

const Title = styled.div`
  width: 100%;
  font-weight: bold;
  text-align: center;

  @media screen and (min-width: 1280px) {
    font-size: 17px;
    margin-top: 17px;
    margin-bottom: 38px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 19px;
    margin-top: 15.5px;
    margin-bottom: 45px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 16px;
    font-size: 16px;
    margin-bottom: 26px;
  }
`;

const SubscribeButton = styled.img`
  display: block;
  position: absolute;
  z-index: 1000;
  @media screen and (min-width: 1280px) {
    width: 17.5px;
    height: 17.5px;
    right: 17px;
    bottom: 17px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 19.5px;
    height: 19.5px;
    right: 15px;
    bottom: 15px;
  }
  @media screen and (max-width: 1023px) {
    width: 19.5px;
    height: 19.5px;
    right: 15px;
    bottom: 15px;
  }
`;
