import styled from "styled-components";
import { useState } from "react";
import SideBarHeader from "./SidebarHeader";
import SidebarContents from "./SidebarContents";

export type sidebarStateType = "logo" | "main";

function MainDesignSidebar() {
  const [sidebarState, setsidebarState] = useState<sidebarStateType>("logo");
  const getsidebarState = (newState: sidebarStateType) => {
    setsidebarState(newState);
  };

  return (
    <SideBarWrapper>
      <SideBarHeader
        sidebarState={sidebarState}
        getSidebarState={getsidebarState}
      />
      <SidebarContents sidebarState={sidebarState}></SidebarContents>
    </SideBarWrapper>
  );
}

export default MainDesignSidebar;

const SideBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 515px;
  height: 100%;
  background-color: white;
  border-radius: 0 5px 0 0;
`;
