import { atom, useRecoilState } from "recoil";
import { houberMansionItemType } from "../../pages/houberMansionPage/HouberMansion";

export const houberMansionStateAtom = atom<houberMansionItemType[]>({
  key: "houberMansionDataState",
  default: [],
});

export const useHouberMansionData = () => {
  const [houberMansionDataState, setHouberMansionDataState] = useRecoilState<
    houberMansionItemType[]
  >(houberMansionStateAtom);

  return { houberMansionDataState, setHouberMansionDataState };
};
