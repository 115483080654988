import styled from "styled-components";
import ResponsiveWrapper from "../../../../components/ResponsiveWrapper";
import CloneContentsHeader from "./CloneContentsHeader";
import CloneMainItem from "./CloneMainItem";

function ClonePopularMansions() {
  return (
    <Wrapper>
      <ResponsiveWrapper>
        <CloneContentsHeader
          title="베스트 인기 매물"
          subtitle="가장 인기있는 멘션을 소개해드릴게요"
        />
        <ContentsWrapper>
          {[1, 2, 3, 4].map((item, index) => (
            <CloneMainItem key={index} width={269} height={177} />
          ))}
        </ContentsWrapper>
      </ResponsiveWrapper>
    </Wrapper>
  );
}

export default ClonePopularMansions;

const Wrapper = styled.div`
  @media screen and (max-width: 1023px) {
    margin-bottom: 78px;
  }
`;

const ContentsWrapper = styled.div`
  display: flex;
  margin: 15px 0;
  align-items: center;
  position: relative;
  @media screen and (min-width: 1280px) {
    height: 209px;
    gap: 28px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    height: 223px;
    gap: 21px;
  }
  @media screen and (max-width: 1023px) {
    height: 162px;
    justify-content: space-between;
  }
`;
