import React, { useState } from "react";
import styled from "styled-components";
import { mansionPropertyDataStateAtom } from "../../../recoil/mansionPage/mansionPropertyState";
import { useRecoilState } from "recoil";

const MansionMobileSortOption = () => {
  const [mansionPropertyState, setMansionPropertyState] = useRecoilState(
    mansionPropertyDataStateAtom
  );

  const SORTOPTION = [
    {
      name: "면적 넓은 순",
      propertyName: "WideOrder",
    },
    {
      name: "월세 낮은 순",
      propertyName: "LowestRent",
    },
    {
      name: "월세 높은 순",
      propertyName: "HighestRent",
    },
    {
      name: "최신 건축 순",
      propertyName: "LatestConstructed",
    },
  ];

  return (
    <Container>
      <Align>정렬</Align>
      <div style={{ position: "fixed", bottom: "0", width: "100%" }}>
        <Option
          onClick={() =>
            setMansionPropertyState({
              ...mansionPropertyState,
              sortOption: "WideOrder",
            })
          }
        >
          면적 넓은 순
        </Option>
        <Separator />
        <Option
          onClick={() =>
            setMansionPropertyState({
              ...mansionPropertyState,
              sortOption: "LowestRent",
            })
          }
        >
          월세 낮은 순
        </Option>
        <Separator />
        <Option
          onClick={() =>
            setMansionPropertyState({
              ...mansionPropertyState,
              sortOption: "HighestRent",
            })
          }
        >
          월세 높은 순
        </Option>
        <Separator />
        <Option
          onClick={() =>
            setMansionPropertyState({
              ...mansionPropertyState,
              sortOption: "LatestConstructed",
            })
          }
        >
          최신 건축 순
        </Option>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Noto Sans KR";
`;
const Align = styled.div`
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  padding-top: 35px;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  height: 57px;
  padding: 0 20px;
  font-weight: 500;
`;

const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e0e0e0;
`;

export default MansionMobileSortOption;
