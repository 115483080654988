import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AdminRHeader from "./components/AdminRHeader";
import ResidentPagination from "../components/Pagination";
import { useNavigate, useSearchParams } from "react-router-dom";
import axiosInstance from "../../../services/axiosConfig";

interface Review {
  id: number;
  title: string;
  createAt: string;
  nickName: string;
  saveStatus: number;
}

function AdminReview() {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [deletedIds, setDeletedIds] = useState<number[]>([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [searchResults, setSearchResults] = useState<Review[]>([]);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = Number(searchParams.get("page")) || 1;
  const selectedOption = searchParams.get("sortBy") || "정렬";
  const keyword = searchParams.get("searchKeyword") || "";

  const fetchReviews = async () => {
    try {
      const res = await axiosInstance.get("/admin/review/list", {
        params: {
          page: currentPage,
          sortBy: selectedOption,
        },
      });

      const formattedReviews = res.data.content.map((review: Review) => ({
        ...review,
        createAt: review.createAt.split("T")[0],
      }));
      setReviews(formattedReviews);
      setTotalPages(res.data.totalPages);
    } catch (error) {
    }
  };

  const handlesearchKeyword = async (keywordState: string) => {
    searchParams.set("searchKeyword", keywordState);
    setSearchParams(searchParams);
    try {
      const res = await axiosInstance.get("/admin/review/search", {
        params: {
          page: currentPage,
          sortBy: selectedOption,
          searchKeyword: keyword,
        },
      });

      const formattedReviews = res.data.content.map((review: Review) => ({
        ...review,
        createAt: review.createAt.split("T")[0],
      }));

      setSearchResults(formattedReviews);
      if (currentPage > res.data.totalPages) {
        searchParams.set("page", "1");
      } else {
        setTotalPages(res.data.totalPages);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (keyword) {
      handlesearchKeyword(keyword);
    } else {
      fetchReviews();
    }
  }, [currentPage, selectedOption, keyword]);

  const handleSortSelect = (option: string) => {
    searchParams.set("sortBy", option);
    setSearchParams(searchParams);
  };

  const handleEditClick = (id: number) => {
    navigate(`/admin/postreview/${id}`);
  };

  const handlePageChange = (page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const handleCheckboxChange = (id: number) => {
    setSelectedIds((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleDelete = () => {
    setDeletedIds((prev) => [...prev, ...selectedIds]);
    setSelectedIds([]);
  };

  const handleSave = async () => {
    if (deletedIds.length === 0) {
      alert("삭제할 항목이 없습니다.");
      return;
    }
    try {
      const res = await axiosInstance.delete("/admin/review/", {
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          reviewIds: deletedIds,
        },
      });
      setReviews((prevReviews) =>
        prevReviews.filter((review) => !deletedIds.includes(review.id))
      );
      setSearchResults((prevSearchResults) =>
        prevSearchResults.filter((review) => !deletedIds.includes(review.id))
      );
      setDeletedIds([]);
      setSelectedIds([]);
    } catch (error) {
      alert("상위 권한 필요");
    }
  };

  const displayedReviews = keyword ? searchResults : reviews;

  return (
    <ResidentReviewWrapper>
      <Container>
        <AdminRHeader
          selectedOption={selectedOption}
          onSortSelect={handleSortSelect}
          onsearchKeyword={handlesearchKeyword}
          keyword={keyword}
        />
        <TableContainer>
          <TableHeader>
            <TableHeaderCell></TableHeaderCell>
            <TableHeaderCell>순서</TableHeaderCell>
            <TableHeaderCell>제목</TableHeaderCell>
            <TableHeaderCell>후기등록일</TableHeaderCell>
            <TableHeaderCell>닉네임</TableHeaderCell>
            <TableHeaderCell></TableHeaderCell>
          </TableHeader>
          {displayedReviews.map((review, index) => (
            <TableRow key={review.id}>
              <TableCell
                isDeleted={deletedIds.includes(review.id)}
                style={{ paddingLeft: "30px" }}
              >
                <CheckboxContainer>
                  <StyledCheckbox
                    type="checkbox"
                    checked={selectedIds.includes(review.id)}
                    onChange={() => handleCheckboxChange(review.id)}
                  />
                </CheckboxContainer>
              </TableCell>
              <TableCell isDeleted={deletedIds.includes(review.id)}>
                {(currentPage - 1) * 10 + (index + 1)}
              </TableCell>
              <TableCell
                isDeleted={deletedIds.includes(review.id)}
                style={{ justifyContent: "left", padding: "20px" }}
              >
                {review.title}{" "}
                {review.saveStatus === 0 && <SaveIcon>임시저장</SaveIcon>}
              </TableCell>
              <TableCell isDeleted={deletedIds.includes(review.id)}>
                {review.createAt}
              </TableCell>
              <TableCell isDeleted={deletedIds.includes(review.id)}>
                {review.nickName}
              </TableCell>
              <TableCell isDeleted={deletedIds.includes(review.id)}>
                <button
                  onClick={() => handleEditClick(review.id)}
                  style={{ borderRadius: "8px", backgroundColor: "white" }}
                >
                  수정
                </button>
              </TableCell>
            </TableRow>
          ))}
        </TableContainer>

        <BTNDiv>
          <StyledBTN onClick={handleDelete}>삭제</StyledBTN>
          <StyledBTN onClick={handleSave}>저장</StyledBTN>
        </BTNDiv>
        <ResidentPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </Container>
    </ResidentReviewWrapper>
  );
}

export default AdminReview;

const ResidentReviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 0 50px;
  margin-top: 145px;
  margin-bottom: 200px;
`;

const Container = styled.div`
  width: 1280px;
`;

const TableContainer = styled.div`
  display: grid;
  grid-template-columns: 30px 80px 670px 150px 170px 180px;
  margin-bottom: 20px;
  width: 100%;
`;

const TableHeader = styled.div`
  display: contents;
  background-color: #f1f1f1;
`;

const TableRow = styled.div`
  display: contents;
`;

const TableCell = styled.div<{ isDeleted?: boolean }>`
  padding: 20px 10px;
  border-bottom: 2px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: "Noto Sans KR";
  opacity: ${(props) => (props.isDeleted ? 0.2 : 1)};
  transition: opacity 0.3s ease;
`;

const TableHeaderCell = styled.div`
  padding: 20px 10px;
  border-bottom: 2px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: "Noto Sans KR";
  color: #929292;
  font-weight: bold;
`;

const SaveIcon = styled.div`
  font-size: 15px;
  font-weight: bold;
  border: 1px solid #99393a;
  border-radius: 15px;
  margin-left: 5px;
  color: #99393a;
  min-width: 85px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const BTNDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
`;

const StyledBTN = styled.button`
  width: 92px;
  height: 40px;
  background-color: #e6e6e6;
  border: none;
  font-size: 18px;
  border-radius: 5px;
  font-family: "Noto Sans KR";
`;

const StyledCheckbox = styled.input.attrs({ type: "checkbox" })`
  width: 26px;
  height: 26px;
  appearance: none;
  background-color: white;
  border: 2px solid rgba(187, 185, 185, 0.5);
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  margin-right: 8px;
  transition: all 0.2s ease-in-out;
  margin: 0px;
  margin-right: 8px;
  &:before {
    content: "✔";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: transparent;
    font-size: 16px;
  }

  &:checked {
    background-color: white;
    &:before {
      color: #99393a;
      display: block;
    }
  }
`;

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
`;

// import React, { useState, useEffect } from "react";
// import styled from "styled-components";
// import AdminRHeader from "./components/AdminRHeader";
// import ResidentPagination from "../components/Pagination";
// import { useNavigate, useLocation } from "react-router-dom"; // useLocation 추가
// import axiosInstance from "../../../services/axiosConfig";

// interface Review {
//   id: number;
//   title: string;
//   createAt: string;
//   nickName: string;
//   saveStatus: number;
// }

// function AdminReview() {
//   const [reviews, setReviews] = useState<Review[]>([]);
//   const [totalPages, setTotalPages] = useState<number>(0);
//   const [deletedIds, setDeletedIds] = useState<number[]>([]);
//   const [selectedIds, setSelectedIds] = useState<number[]>([]);
//   const [searchKeyword, setSearchKeyWord] = useState<string>("");
//   const [searchResults, setSearchResults] = useState<Review[]>([]);
//   const [isSearching, setIsSearching] = useState<boolean>(false);
//   const navigate = useNavigate();
//   const location = useLocation();

//   const queryParams = new URLSearchParams(location.search);
//   const currentPage = Number(queryParams.get("page")) || 1;
//   const selectedOption = queryParams.get("sortBy") || "정렬";

//   const updateQueryParams = (
//     page: number,
//     sortBy: string,
//   ) => {
//     const queryParams = new URLSearchParams();
//     queryParams.set("page", String(page));
//     queryParams.set("sortBy", sortBy);

//     navigate(`?${queryParams.toString()}`, { replace: true });
//   };

//   const fetchReviews = async () => {
//     try {
//       const res = await axiosInstance.get("/admin/review/list", {
//         params: {
//           page: currentPage,
//           sortBy: selectedOption,
//         },
//       });

//       const formattedReviews = res.data.content.map((review: Review) => ({
//         ...review,
//         createAt: new Date(review.createAt).toISOString().split("T")[0],
//       }));
//       setReviews(formattedReviews);
//       setTotalPages(res.data.totalPages);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     fetchReviews();
//   }, [currentPage, selectedOption]);
//   const handlesearchKeyword = async () => {
//     if (searchKeyword.trim() === "") {
//       setIsSearching(false);
//       fetchReviews();
//       return;
//     }
//     try {
//       const res = await axiosInstance.get("/admin/review/search", {
//         headers: {},
//         params: {
//           page: currentPage,
//           sortBy: selectedOption,
//           searchKeyword: searchKeyword,
//         },
//       });
//       updateQueryParams(1,selectedOption);
//       const formattedReviews = res.data.content.map((review: Review) => ({
//         ...review,
//         createAt: new Date(review.createAt).toISOString().split("T")[0],
//       }));
//       setSearchResults(formattedReviews);
//       setTotalPages(res.data.totalPages);
//       setIsSearching(true);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleSortSelect = (option: string) => {
//     const newParams = new URLSearchParams(location.search);
//     newParams.set("sortBy", option);
//     newParams.set("page", "1");
//     navigate({ search: newParams.toString() });
//   };

//   const handleEditClick = (id: number) => {
//     navigate(`/admin/postreview/${id}`);
//   };

//   const handlePageChange = (page: number) => {
//     const newParams = new URLSearchParams(location.search);
//     newParams.set("page", page.toString());
//     navigate({ search: newParams.toString() });
//   };

//   const handleCheckboxChange = (id: number) => {
//     setSelectedIds((prev) =>
//       prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
//     );
//   };

//   const handleDelete = () => {
//     setDeletedIds((prev) => [...prev, ...selectedIds]);
//     setReviews((prevReviews) =>
//       prevReviews.filter((review) => !selectedIds.includes(review.id))
//     );
//     setSelectedIds([]);
//   };

//   const handleSave = async () => {
//     if (deletedIds.length === 0) {
//       alert("삭제할 항목이 없습니다.");
//       return;
//     }
//     try {
//       const res = await axiosInstance.delete("/admin/review/", {
//         headers: {
//           "Content-Type": "application/json",
//         },
//         data: {
//           reviewIds: deletedIds,
//         },
//       });
//       if(res.status === 200){
//         alert('저장완료')
//       }
//       setDeletedIds([]);
//       setSelectedIds([]);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const displayedReviews = isSearching ? searchResults : reviews;

//   return (
//     <ResidentReviewWrapper>
//       <Container>
//         <AdminRHeader
//           selectedOption={selectedOption}
//           onSortSelect={handleSortSelect}
//           searchKeyword={searchKeyword}
//           setSearchKeyWord={setSearchKeyWord}
//           onsearchKeyword={handlesearchKeyword}
//         />
//         <TableContainer>
//           <TableHeader>
//             <TableHeaderCell></TableHeaderCell>
//             <TableHeaderCell>순서</TableHeaderCell>
//             <TableHeaderCell>제목</TableHeaderCell>
//             <TableHeaderCell>후기등록일</TableHeaderCell>
//             <TableHeaderCell>닉네임</TableHeaderCell>
//             <TableHeaderCell></TableHeaderCell>
//           </TableHeader>
//           {displayedReviews.map((review, index) => (
//             <TableRow key={review.id}>
//               <TableCell style={{ paddingLeft: "30px" }}>
//                 <CheckboxContainer>
//                   <StyledCheckbox
//                     type="checkbox"
//                     checked={selectedIds.includes(review.id)}
//                     onChange={() => handleCheckboxChange(review.id)}
//                   />
//                 </CheckboxContainer>
//               </TableCell>
//               <TableCell>{(currentPage - 1) * 10 + (index + 1)}</TableCell>
//               <TableCell style={{ justifyContent: "left", padding: "20px" }}>
//                 {review.title}{" "}
//                 {review.saveStatus === 0 && <SaveIcon>임시저장</SaveIcon>}
//               </TableCell>
//               <TableCell>{review.createAt}</TableCell>
//               <TableCell>{review.nickName}</TableCell>
//               <TableCell>
//                 <button
//                   onClick={() => handleEditClick(review.id)}
//                   style={{ borderRadius: "8px", backgroundColor: "white" }}
//                 >
//                   수정
//                 </button>
//               </TableCell>
//             </TableRow>
//           ))}
//         </TableContainer>

//         <BTNDiv>
//           <StyledBTN onClick={handleDelete}>삭제</StyledBTN>
//           <StyledBTN onClick={handleSave}>저장</StyledBTN>
//         </BTNDiv>
//         <ResidentPagination
//           currentPage={currentPage}
//           totalPages={totalPages}
//           onPageChange={handlePageChange}
//         />
//       </Container>
//     </ResidentReviewWrapper>
//   );
// }

// export default AdminReview;

// const ResidentReviewWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   background-color: white;
//   padding: 0 50px;
// `;

// const Container = styled.div`
//   width: 1280px;
// `;

// const TableContainer = styled.div`
//   display: grid;
//   grid-template-columns: 30px 80px 670px 150px 170px 180px;
//   margin-bottom: 20px;
//   width: 100%;
// `;

// const TableHeader = styled.div`
//   display: contents;
//   background-color: #f1f1f1;
// `;

// const TableRow = styled.div`
//   display: contents;
//   &:nth-child(even) {
//     background-color: #f9f9f9;
//   }
// `;

// const TableCell = styled.div`
//   padding: 20px 10px;
//   border-bottom: 2px solid #ddd;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 18px;
//   font-family: "Noto Sans KR";
// `;

// const TableHeaderCell = styled(TableCell)`
//   color: #929292;
//   font-weight: bold;
// `;

// const SaveIcon = styled.div`
//   font-size: 15px;
//   font-weight: bold;
//   border: 1px solid #99393a;
//   border-radius: 15px;
//   margin-left: 5px;
//   color: #99393a;
//   min-width: 85px;
//   height: 30px;
//   justify-content: center;
//   align-items: center;
//   display: flex;
// `;

// const BTNDiv = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   gap: 5px;
// `;

// const StyledBTN = styled.button`
//   width: 92px;
//   height: 40px;
//   background-color: #e6e6e6;
//   border: none;
//   font-size: 18px;
//   border-radius: 5px;
//   font-family: "Noto Sans KR";
// `;

// const StyledCheckbox = styled.input.attrs({ type: "checkbox" })`
//   width: 26px;
//   height: 26px;
//   appearance: none;
//   background-color: white;
//   border: 2px solid rgba(187, 185, 185, 0.5);
//   border-radius: 4px;
//   position: relative;
//   cursor: pointer;
//   margin-right: 8px;
//   transition: all 0.2s ease-in-out;
//   margin: 0px;
//   margin-right: 8px;
//   &:before {
//     content: "✔";
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     color: transparent;
//     font-size: 16px;
//   }

//   &:checked {
//     background-color: white;
//     &:before {
//       color: #99393a;
//       display: block;
//     }
//   }
// `;

// const CheckboxContainer = styled.label`
//   display: flex;
//   align-items: center;
// `;
